import React, { useState, useEffect, useRef } from 'react';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import { Card, CardBody } from 'reactstrap';
import './index.css';

interface MessageType {
  name: string;
  message: string;
}

interface ChatProps {
  initialMessage?: string;
  onSendFromUser: (message: string) => void;
  streamReceiver: (options: { next: (message: string) => void }) => void;
}

const Chat: React.FC<ChatProps> = (props) => {
  const { onSendFromUser, streamReceiver } = props;

  const chatBodyRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<MessageType[]>([
    { name: 'assistant', message: 'Hello! How can I help you today?' },
  ]);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const submitMessage = (messageString: string): void => {
    const message: MessageType = { name: 'user', message: messageString };

    setMessages((prevMessages) => [...prevMessages, message]);
    onSendFromUser(messageString);
    setIsWaiting(true);
  };

  useEffect(() => {
    streamReceiver({
      next: (message: string) => {
        setIsWaiting(false);
        setMessages((prev) => [...prev, { name: 'assistant', message }]);
      },
    });
  }, []);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const segments: MessageType[] = [];

  let currentSegment: string[] = [];
  let currentName: string | null = null;

  messages.forEach((message) => {
    if (message.message === '~~~STOP~~~') {
      if (currentName !== null) {
        segments.push({ name: currentName, message: currentSegment.join('') });
      }
      currentSegment = [];
      currentName = null;
      return;
    }

    if (currentName === message.name) {
      currentSegment.push(message.message);
    } else {
      if (currentSegment.length > 0 && currentName !== null) {
        segments.push({ name: currentName, message: currentSegment.join('') });
      }

      currentName = message.name;
      currentSegment = [message.message];
    }
  });

  if (currentSegment.length > 0 && currentName !== null) {
    segments.push({ name: currentName, message: currentSegment.join('') });
  }

  return (
    <Card className="chat">
      <CardBody className="chat-container">
        <div className="chat-body" ref={chatBodyRef}>
          {segments.map((message, index) => (
            <ChatMessage
              key={index}
              message={message.message}
              name={message.name}
            />
          ))}
          {isWaiting && <ChatMessage waiting={true} message="..." name="assistant" />}
        </div>
        <div className="chat-input">
          <ChatInput
            onSubmitMessage={submitMessage}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Chat;
