import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  Input,
  Label,
  FormGroup,
  Table,
} from 'reactstrap';
import CustomSelect from '../../../components/common/custom-select';
import Modal from '../../../components/common/confirm/Modal';
import { displayError, displaySuccess } from 'Dashboard/src/Utils';
import CustomButton from '../../../components/common/button';
import './GoogleAnalyticsProperty.scss';
import { useUser } from '../../../../../common/hooks';
import { get } from 'lodash-es';
import {
  useLazyGetAnalyticsDataStreamsQuery,
  useCreateAnalyticsPropertyMutation,
  useLazyGetGoogleAnalyticsAccountListQuery,
} from 'Dashboard/src/api/dashboardApiSlice';

function GoogleAnalyticsProperty(props) {
  const { params, setParams } = props;

  const [getAnalyticsDataStreams] = useLazyGetAnalyticsDataStreamsQuery();
  const [createAnalyticsProperty] = useCreateAnalyticsPropertyMutation();
  const [getGoogleAnalyticsAccountList] = useLazyGetGoogleAnalyticsAccountListQuery();

  const [isChanging, setChanging] = useState(false);

  const [analyticsAccounts, setAnalyticsAccounts] = useState(null);
  const [analyticsAccountId, setAnalyticsAccountId] = useState(null);
  const [analyticsAccountObject, setAnalyticsAccountObject] = useState(null);

  const [analyticsProperties, setAnalyticsProperties] = useState(null);
  const [analyticsPropertyId, setAnalyticsPropertyId] = useState(null);
  const [analyticsPropertyObject, setAnalyticsPropertyObject] = useState(null);

  const [analyticsDataStreams, setAnalyticsDataStreams] = useState(null);
  const [analyticsDataStreamId, setAnalyticsDataStreamId] = useState(null);
  const [analyticsDataStreamObject, setAnalyticsDataStreamObject] = useState(null);

  const [originalParams, setOriginalParams] = useState(null);

  useEffect(() => {
    if (originalParams || !params.google_analytics_account_id) {
      return;
    }

    setOriginalParams({
      google_analytics_service_account: params.google_analytics_service_account,
      google_analytics_account_id: params.google_analytics_account_id,
      google_analytics_property_id: params.google_analytics_property_id,
      google_analytics_data_stream_id: params.google_analytics_data_stream_id,
    });
  });

  useEffect(() => {
    if (analyticsAccountObject && analyticsAccountId && analyticsPropertyId && analyticsDataStreamId) {
      setParams({
        ...params,
        google_analytics_service_account: analyticsAccountObject[0],
        google_analytics_account_id: analyticsAccountId,
        google_analytics_property_id: analyticsPropertyId,
        google_analytics_data_stream_id: analyticsDataStreamId,
      });
    }
  }, [analyticsAccountId, analyticsPropertyId, analyticsDataStreamId]);

  useEffect(() => {
    setAnalyticsAccountId(params.google_analytics_account_id);
  }, [params.google_analytics_account_id]);

  useEffect(() => {
    setAnalyticsPropertyId(params.google_analytics_property_id);
  }, [params.google_analytics_property_id]);

  useEffect(() => {
    setAnalyticsDataStreamId(params.google_analytics_data_stream_id);
  }, [params.google_analytics_data_stream_id]);

  useEffect(() => {
    if (analyticsAccountId && analyticsAccounts) {
      const obj = analyticsAccounts.find((i) => i[1] === analyticsAccountId);
      if (obj) {
        setAnalyticsAccountObject(obj);

        setAnalyticsProperties(obj[3]);

        if (obj[3].length > 0) {
          setAnalyticsPropertyId(obj[3][0][0]);
        }
      }
    }
  }, [analyticsAccountId]);

  useEffect(() => {
    if (analyticsPropertyId && analyticsProperties) {
      setAnalyticsPropertyObject(analyticsProperties.find((i) => i[0] === analyticsPropertyId));
    }
  }, [analyticsPropertyId]);

  useEffect(() => {
    if (analyticsDataStreamId && analyticsDataStreams) {
      setAnalyticsDataStreamObject(analyticsDataStreams.find((i) => i[0] === analyticsDataStreamId));
    }
  }, [analyticsDataStreamId]);

  useEffect(() => {
    if (analyticsDataStreams && analyticsDataStreams.length > 0) {
      setAnalyticsDataStreamId(analyticsDataStreams[0][0]);
    }
  }, [analyticsDataStreams]);

  useEffect(() => {
    (async () => {
      if (!analyticsPropertyId || !isChanging || !analyticsAccountObject) {
        return;
      }

      try {
        const response = await getAnalyticsDataStreams({
          instanceId: params.instance_id,
          siteId: params.id,
          serviceAccountNumber: analyticsAccountObject[0],
          accountId: analyticsAccountObject[1],
          propertyId: analyticsPropertyId,
          enableSpinner: true,
        });
        setAnalyticsDataStreams(response.data.data);
      } catch (err) {
        displayError(err?.message);
      }
    })();
  }, [analyticsPropertyId]);

  const user = useUser();

  const email = get(user, 'email', '');

  const createProperty = async (e) => {
    e.preventDefault();

    const el = document.createElement('div');
    const root = createRoot(el);

    const [result, propertyName] = await new Promise((resolve) => {
      root.render(
        <Modal
          container={window.modalRef}
          title="Confirm Create Google Analytics Property"
          message={(
            <>
              This will create a new blank Google Analytics 4 property. You should only do this when:
              <ol>
                <li>The final domain has been created and assigned as the primary domain</li>
                <li>There is not already an existing Analytics account for the site</li>
                <li>The timezone has been correctly set for the site</li>
              </ol>
              {email
                ? (
                    <>
                      <br />
                      If your email address (
                      <b>
                        {email}
                      </b>
                      ) is a Google account, it will be added to the property as an Administrator
                    </>
                  )
                : ''}
              <br />
              <br />
              Enter a name for the new property:
              <br />
              <Input
                type="text"
                id="property_name"
                defaultValue={params.display_name}
              />
            </>
          )}
          confirmText="Ok"
          cancelText="Cancel"
          confirmColor="primary"
          cancelColor="danger"
          onOpened={() => { document.querySelector('#property_name').focus(); }}
          onClose={(modalResult) => {
            resolve([modalResult, document.querySelector('#property_name').value]);
          }}
        />,
        el,
      );
    });

    if (!result) return false;

    const apiParams = {
      instanceId: params.instance_id,
      id: params.id,
      name: propertyName,
      time_zone: params.time_zone,
    };

    try {
      const response = await createAnalyticsProperty(apiParams);
      const [property, stream] = response.data.data;

      setParams({
        ...params,
        google_analytics_service_account: 1,
        google_analytics_account_id: property.account.replace(/[^\d]/g, ''),
        google_analytics_property_id: property.name.replace(/[^\d]/g, ''),
        google_analytics_data_stream_id: stream.measurement_id,
      });
      setChanging(false);
      displaySuccess(response.data.messages);
    } catch (err) {
      displayError(err?.message);
    }

    return false;
  };

  const useExistingProperty = async (e) => {
    e.preventDefault();

    try {
      const response = await getGoogleAnalyticsAccountList({
        instanceId: params.instance_id,
        siteId: params.id,
        enableSpinner: true,
      });

      setParams({ ...params, ...originalParams });
      setAnalyticsAccounts(response.data.data);
      setChanging(true);
    } catch (err) {
      displayError(err?.message);
    }
  };

  let propertyElement = null;

  if (analyticsProperties) {
    const propertyOptions = analyticsProperties.map((i) => ({ label: `${i[1]} (${i[0]})`, value: i[0] }));

    if (propertyOptions.length === 0) {
      propertyOptions.push({ label: <i>None</i>, value: null });
    }

    propertyElement = (
      <FormGroup>
        <Label>
          Property:
        </Label>
        <CustomSelect
          options={propertyOptions}
          value={analyticsPropertyObject
            ? {
                label: `${analyticsPropertyObject[1]} (${analyticsPropertyObject[0]})`, value: analyticsPropertyId,
              }
            : propertyOptions[0]}
          onChange={(e) => { setAnalyticsPropertyId(e.value); }}
        />
      </FormGroup>
    );
  }

  let streamElement = null;

  if (analyticsDataStreams) {
    const streamOptions = analyticsDataStreams.map((i) => ({ label: `${i[1]} (${i[0]})`, value: i[0] }));

    if (streamOptions.length === 0) {
      streamOptions.push({ label: <i>None</i>, value: null });
    }

    streamElement = (
      <FormGroup>
        <Label>
          {analyticsPropertyObject && analyticsPropertyObject[0].match(/ua/i) ? 'Profile/View' : 'Data Stream'}
          :
        </Label>
        <CustomSelect
          options={streamOptions}
          value={analyticsDataStreamObject
            ? {
                label: `${analyticsDataStreamObject[1]} (${analyticsDataStreamObject[0]})`, value: analyticsDataStreamId,
              }
            : streamOptions[0]}
          onChange={(e) => setAnalyticsDataStreamId(e.value)}
        />
      </FormGroup>
    );
  }

  let changingElement;

  if (analyticsAccounts) {
    changingElement = (
      <>
        <FormGroup>
          <Label>
            Analytics Account:
          </Label>
          <CustomSelect
            options={analyticsAccounts.map((i) => ({ label: `${i[2]} (${i[1]})`, value: i[1] }))}
            onChange={(e) => setAnalyticsAccountId(e.value)}
          />
        </FormGroup>
        {propertyElement}
        {streamElement}
        <CustomButton
          size="md"
          color="info"
          title="Cancel"
          onClick={(e) => {
            e.preventDefault();
            setParams({ ...params, ...originalParams });
            setChanging(false);
          }}
        />
      </>
    );
  } else {
    changingElement = (
      <>
        <CustomButton size="md" color="info" title="Create New Property" onClick={createProperty} />
        {' '}
        <CustomButton size="md" color="info" title="Use Existing Property" onClick={useExistingProperty} />
      </>
    );
  }

  const fixedElement = (
    <>
      {analyticsAccountId && analyticsPropertyId
        ? (
            <>
              <Table>
                <tbody>
                  <tr>
                    <td>Version</td>
                    <td>{analyticsPropertyId.match(/ua/i) ? 'Universal Analytics' : 'Google Analytics 4'}</td>
                  </tr>
                  <tr>
                    <td>Account ID</td>
                    <td>{analyticsAccountId}</td>
                  </tr>
                  <tr>
                    <td>Property ID</td>
                    <td>{analyticsPropertyId}</td>
                  </tr>
                  <tr>
                    <td>
                      {analyticsPropertyId.match(/ua/i) ? 'Profile/View ' : 'Data Stream '}
                      ID
                    </td>
                    <td>{analyticsDataStreamId}</td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <CustomButton
                size="md"
                color="info"
                title="Change"
                onClick={(e) => {
                  e.preventDefault();
                  setAnalyticsAccountId(null);
                  setAnalyticsPropertyId(null);
                  setAnalyticsDataStreamId(null);
                  setChanging(true);
                }}
              />
              {' '}
              <CustomButton
                size="md"
                color="info"
                title="Unlink Property"
                onClick={(e) => {
                  e.preventDefault();
                  setAnalyticsAccountId(null);
                  setAnalyticsPropertyId(null);
                  setAnalyticsDataStreamId(null);
                  setChanging(false);

                  setParams({
                    ...params,
                    google_analytics_service_account: null,
                    google_analytics_account_id: null,
                    google_analytics_property_id: null,
                    google_analytics_data_stream_id: null,
                  });
                }}
              />
            </>
          )
        : (
            <>
              <CustomButton size="md" color="info" title="Create New Property" onClick={createProperty} />
              {' '}
              <CustomButton size="md" color="info" title="Use Existing Property" onClick={useExistingProperty} />
            </>
          )}
    </>
  );

  return isChanging ? changingElement : fixedElement;
}

export default GoogleAnalyticsProperty;
