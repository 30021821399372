import React, { Fragment } from 'react';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { uniq, compact } from 'lodash-es';
import ReactTable8 from 'Dashboard/src/components/common/Table8';
import { useGetAiTextPromptsQuery } from 'Dashboard/src/api/dashboardApiSlice';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { mainRoutes, replacePathParams, settingRoutes } from 'Dashboard/src/constants/routes';
import AITextPrompt from 'common/models/AITextPrompt';

function AITextPrompts(props) {
  const { navigate } = props;
  const { data: aiTextPrompts } = useGetAiTextPromptsQuery();

  if (!aiTextPrompts) {
    return <></>;
  }

  const userColumns = [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'description',
      header: 'Description',
    },
    {
      accessorKey: 'model',
      header: 'Model',
    },
    {
      header: 'Edit',
      cell: ({ row: { original } }) => (
        <Button
          className="custom-simple-icon"
          color="info"
          size="xs"
          onClick={() => navigate(replacePathParams(mainRoutes.aiTextPrompt, [{ key: 'id', value: original.id }], props))}
        >
          <i className="fal fa-pen" />
        </Button>
      ),
    },
  ];

  const contexts = compact(uniq(aiTextPrompts.map((p: AITextPrompt) => p.context)));

  return (
    <>
      <style>
        {`
          .AITextPrompts {
            table td:nth-child(1) { width: 300px; }
            table td:nth-child(3) { width: 220px; }
            table td:nth-child(4) { width: 100px; }
          }
        `}
      </style>
      <HeaderComponent setPath={{
        headingName: 'AI Text Prompts',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Settings',
        backToPath: replacePathParams(mainRoutes.setting, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData: [
          { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
          { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
        ],
      }}
      />
      <Card className="AITextPrompts">
        <CardBody>
          <h2
            className="mb-3"
          >
            System Prompts
          </h2>
          {contexts.map((context) => (
            <Card
              key={context || ''}
              className="mt-1 mb-3"
            >
              <CardBody>
                <CardTitle>{context || ''}</CardTitle>
                <ReactTable8
                  data={aiTextPrompts.filter((aiTextPrompt) => aiTextPrompt.type === 'system' && aiTextPrompt.context === context)}
                  columns={userColumns}
                />
              </CardBody>
            </Card>
          ))}
          <h2
            className="mb-3"
          >
            User Prompts
          </h2>
          {contexts.map((context) => (
            <Card
              key={context || ''}
              className="mt-1 mb-3"
            >
              <CardBody>
                <CardTitle>{context || ''}</CardTitle>
                <ReactTable8
                  data={aiTextPrompts.filter((aiTextPrompt) => aiTextPrompt.type === 'user' && aiTextPrompt.context === context)}
                  columns={userColumns}
                />
              </CardBody>
            </Card>
          ))}
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(AITextPrompts);
