import React, { ReactElement } from 'react';
import { createStore } from './rootStore';
import { map, isEmpty, isNumber, get, maxBy } from 'lodash-es';
import Color from 'color';
import { CSSObjectWithLabel } from 'react-select';
import SectionContent from './PublicFacingSite/Sections/Content';
import SectionForm from './PublicFacingSite/Sections/Form';
import SectionMirror from './PublicFacingSite/Sections/Mirror';
import SectionReviewForm from './PublicFacingSite/Sections/ReviewForm';
import SectionImageGallery from './PublicFacingSite/Sections/ImageGallery';
import SectionColumn from './PublicFacingSite/Sections/Column';
import SectionReviewList from './PublicFacingSite/Sections/ReviewList';
import SectionEcwid from './PublicFacingSite/Sections/Ecwid';
import SectionComponent from './PublicFacingSite/Sections/Component';
import SectionCustom from './PublicFacingSite/Sections/Custom';
import macroReplacerString from './PublicFacingSite/macroReplacerString';
import { deviceType } from './common/schema/formSection';
import ActionPageInstantContactInterstitial from './PublicFacingSite/Components/ActionPageInstantContactInterstitial';
import ActionPageInstantContactChat from './PublicFacingSite/Components/ActionPageInstantContactChat';
import SectionContentEmail from './Dashboard/src/components/SectionContentEmail';
import SectionColumnEmail from './Dashboard/src/components/SectionColumnEmail';
import Section from 'common/models/Section';
import Page from 'common/models/Page';
import {
  ActionComponentMap,
  RGBA,
  StringArrayMap,
  UpdateStylesheetParams,
  DropdownOption,
  FontSizeBreakpoint,
  PresetColor,
  LinkObject,
} from 'ts/interfaces';

const urlStopWords: string[] = [
  'am',
  'an',
  'and',
  'as',
  'at',
  'co',
  'com',
  'eg',
  'et',
  'etc',
  'ex',
  'for',
  'i\'d',
  'i\'ll',
  'i\'m',
  'i\'ve',
  'ie',
  'if',
  'in',
  'inc',
  'is',
  'isn\'t',
  'it\'d',
  'it\'ll',
  'it\'s',
  'its',
  'me',
  'nd',
  'no',
  'of',
  'oh',
  'or',
  'rd',
  're',
  'so',
  'th',
  'the',
  'to',
  'un',
];

interface DataField {
  label: string;
  value: string;
}

export const specialFieldsArr: string[] = ['Join IP Address', 'Join Country', 'Join Method', 'Join Date', 'Included in import'];

const SectionMap: Record<string, (page: Page, section: Section, onClick: (section: Section) => void) => JSX.Element> = {
  SectionContent: (page, section, onClick) => (
    page?.page_type === 'email'
      ? <SectionContentEmail key={section.id} section={section} onClick={onClick} />
      : <SectionContent key={section.id} section={section} onClick={onClick} />
  ),
  SectionForm: (page, section, onClick) => (
    <SectionForm key={section.id} section={section} onClick={onClick} />
  ),
  SectionColumn: (page, section, onClick) => (
    page?.page_type === 'email'
      ? <SectionColumnEmail key={section.id} section={section} />
      : <SectionColumn key={section.id} section={section} onClick={onClick} />
  ),
  SectionMirror: (page, section, onClick) => (
    <SectionMirror key={section.id} section={section} onClick={onClick} />
  ),
  SectionReviewForm: (page, section, onClick) => (
    <SectionReviewForm key={section.id} section={section} onClick={onClick} />
  ),
  SectionImageGallery: (page, section, onClick) => (
    <SectionImageGallery key={section.id} section={section} onClick={onClick} />
  ),
  SectionReviewList: (page, section, onClick) => (
    <SectionReviewList key={section.id} section={section} onClick={onClick} />
  ),
  SectionEcwid: (page, section, onClick) => (
    <SectionEcwid key={section.id} section={section} onClick={onClick} />
  ),
  SectionComponent: (page, section, onClick) => (
    <SectionComponent key={section.id} section={section} onClick={onClick} />
  ),
  SectionCustom: (page, section, onClick) => (
    <SectionCustom key={section.id} section={section} onClick={onClick} />
  ),
};

export const sectionObject = (page: Page, section: Section, onClick: (section: Section) => void): ReactElement | null => {
  const sectionType = section?.type;

  if (sectionType && sectionType in SectionMap) {
    const renderSection = SectionMap[sectionType];
    // If a matching component is found, return it
    return renderSection(page, section, onClick);
  }
  // Otherwise return null or a default component
  return null;
};

export const validateEmail = (value: string) => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(value);

// Helper function to be called from inside an iframe to set its height. Only works for internal iframes, ie on the
// same origin as the parent document. It matches based on the URL of the iframe, meaning it will have problems if we
// have two iframes with the same src, but that's unlikely.
export const setIframeHeight = (src: string, height: number): void => {
  const iframes = Array.from(document.querySelectorAll('iframe')).filter((f) => {
    if (f.src === src) {
      return true;
    }

    // If the iframe's URL has changed (ie by clicking a link inside the iframe) the above won't work because the src
    // attribute of the iframe doesn't change. The below catches this situation.
    if (f.contentDocument?.location?.href === src) {
      return true;
    }

    return false;
  });

  if (iframes.length > 0) {
    const currentHeight = parseInt(iframes[0].style.height || '0', 10);
    if (height > currentHeight) {
      iframes[0].style.height = `${height + 1}px`;
    }
  }
};

export const setCookie = (cName: string, cValue: string, expDays: number, path: string = '/') => {
  // We have had a few situations where we have tried to set a cookie to the string "undefined". This can happen when
  // we redirect to a URL with an undefined parameter and we get the literal "undefined" in the URL, and then we try to
  // set that as a cookie. I (MP) think we will probably never want to set a cookie to "undefined", and it's probably
  // best to disallow it.
  if (cValue === 'undefined') {
    console.trace();
    throw new Error('Trying to set undefined cookie');
  }

  const expires = new Date(Date.now() + expDays * 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = `${cName}=${encodeURIComponent(cValue)}; expires=${expires}; path=${path}; SameSite=None; Secure;`;
};

export const getCookie = (cName: string) => {
  const name = `${cName}=`;
  const cookies = document.cookie.split(';');
  const cookie = cookies.find(c => c.trim().startsWith(name));
  return cookie ? decodeURIComponent(cookie.trim().substring(name.length)) : '';
};

export const deleteCookie = (name: string, path: string = '/') => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; SameSite=None; Secure;`;
};

export const isInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const removeHighlights = (): void => {
  const elements: HTMLElement[] = [
    ...Array.from(document.querySelectorAll('.page-edit-highlight')! as NodeListOf<HTMLElement>),
    ...(document.getElementById('adminRoot')?.shadowRoot?.querySelectorAll('.page-edit-highlight') as NodeListOf<HTMLElement> || []),
  ];

  elements.forEach((e: HTMLElement) => {
    e.classList.replace('page-edit-highlight', 'page-edit-highlight-removed');
  });
};

export const highlightElement = (el: HTMLElement) => {
  removeHighlights();

  if (isInViewport(el)) {
    el.classList.add('page-edit-highlight');
    setTimeout(removeHighlights, 1000);
  } else {
    const rect = el.getBoundingClientRect();
    let top = (rect.top + window.scrollY) - 100;
    if (top < 0) {
      top = 0;
    }

    const onScroll = () => {
      const atBottom = (window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight;
      if (Math.round(window.scrollY) === Math.round(top) || atBottom) {
        window.removeEventListener('scroll', onScroll);
        el.classList.add('page-edit-highlight');
        setTimeout(removeHighlights, 1000);
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({ top, behavior: 'smooth' });
  }
};

export const replaceLinks = (
  ref: HTMLElement | null,
  allLinks: LinkObject[],
): HTMLElement | null => {
  if (!ref) {
    return null;
  }

  const { pageData: { section: allSections, site } } = createStore().getState();

  ref.querySelectorAll('a[href*=links').forEach((a: Element) => {
    const anchor = a as HTMLAnchorElement;
    anchor.href.match(/\/links\/[0-9a-f]{24}/g)?.forEach((link) => {
      const linkId = link.match(/[0-9a-f]{24}/)?.[0];
      if (!linkId) return;

      const linkObject = allLinks?.find((l) => l.id === linkId);
      if (!linkObject) {
        return;
      }

      anchor.href = macroReplacerString(linkObject.url);

      if (linkObject.type === 'toggle_visibility_of_section') {
        anchor.classList.add('toggleVisibility');

        if (linkObject.close_other_sections) {
          anchor.dataset.closeOtherSections = 'true';
        }

        const targetSection = allSections.find((s: Section) => s.id === linkObject.section_id);
        if (targetSection) {
          anchor.dataset.originId = targetSection.origin_id;
        }
      } else if (linkObject.type === 'phone_number' && linkObject.site_phone && !isEmpty(site.displayed_phone_number)) {
        let inner: HTMLElement | null;
        if (a.innerHTML.match(/button-inner/)) {
          // This link is a button, we need to preserve the button and the icons, which use <i> tags.
          inner = a.querySelector('.button-inner');
          if (inner) {
            inner.innerHTML = site.displayed_phone_number;
          }
        } else if (a.innerHTML.match(/<button/)) {
          // This link is a button but without the button-inner... not sure how this happens, but it does.
          inner = a.querySelector('button');
          if (inner) {
            inner.innerHTML = site.displayed_phone_number;
          }
        } else {
          if (anchor.querySelector('span[data-custom-style]')) {
            // This is an ms3 (aka Tiptap) textblock, it uses spans for styling.
            anchor.querySelector('span[data-custom-style]')!.innerHTML = site.displayed_phone_number;
          } else {
            anchor.innerHTML = site.displayed_phone_number;
          }
        }

        if (anchor.href && anchor.href.match(/^tel:/)) {
          anchor.href = 'tel:' + site.displayed_phone_number;
        }
      } else if (linkObject.link_target === '_blank') {
        anchor.target = '_blank';
      } else if (linkObject.link_target === 'framebox') {
        anchor.classList.add('showModal');
      } else {
        // Sometimes the HTML in the textblock has the link target set, so we need to override it.
        anchor.target = '_self';
      }

      if (linkObject.type === 'phone_number') {
        // Phone number links should never open in a new tab.
        anchor.target = '_top';
      }
    });
  });

  return ref;
};

export const generateURL = (str: string): string => {
  const expStr = urlStopWords.join('|');

  const url = str
    .toLowerCase()
    .replace(new RegExp(`\\b(${expStr})\\b`, 'g'), ' ')
    .replace(/^\s+/, '')
    .replace(/\s$/, '')
    .replace(/\s+/g, ' ')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-'); // strip out multiple hyphens in a row

  // Use only the first three words.
  return url.split('-').slice(0, 3).join('-');
};

export const validateDomain = (value: string): boolean | string => {
  if (value.length > 0) {
    if (/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/g.test(value)) {
      return true;
    }
    return 'Enter Valid Domain Name';
  }
  return 'Enter Domain Name';
};

export const validatePhoneNumber = (value: string) => {
  const reg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  if (!reg.test(value)) {
    return false;
  }
  return true;
};

export const setPageTitle = (title: string) => {
  document.title = `Web Genius - ${title}`;
};

export const getDomainName = (str: string): string | null => {
  const reg = /(?:[\w-]+\.)+[\w-]+/g;
  const result = reg.exec(str);
  return result ? result[0] : null;
};

export const selectColorStyles = {
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    backgroundColor: 'var(--background-color)',
  }),
  option: (styles: CSSObjectWithLabel, state: { isSelected: boolean }) => ({
    ...styles,
    color: state.isSelected ? 'var(--foreground-color)' : 'var(--primary-color) !important',
    backgroundColor: state.isSelected ? 'var(--theme-color-1)' : 'var(--foreground-color)',
  }),
  singleValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: 'var(--primary-color) !important',
  }),
  menuPortal: (styles: CSSObjectWithLabel) => ({
    ...styles,
    zIndex: 9999,
  }),
};

export const getSelectedOption = (
  options: DropdownOption[],
  selectedOption: string | null,
  keyName: string = 'value',
): DropdownOption | undefined => {
  if (!options) {
    return undefined;
  }
  return options.find((item) => item[keyName] === selectedOption);
};

export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const secondsToHms = (e: number): string => {
  const d = Number(e);
  // const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  // const hDisplay = h > 0 ? `${h}h` : "";
  const mDisplay = m > 0 ? `${m}m` : '';
  const sDisplay = s > 0 ? `${s}s` : '';
  return `${mDisplay} ${sDisplay}`;
};

export const getFlagEmoji = (countryCode: string): string => {
  if (typeof countryCode !== 'string') {
    return '';
  }

  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const getPageType = (page: Page): string => (
  (page?.page_type === 'normal' || page?.page_type === 'master') ? 'web' : page?.page_type || 'unknown'
);

export const colourPalettePreset = (
  data: string | string[],
  presetColor: PresetColor[],
): string | string[] => {
  if (Array.isArray(data)) {
    data.forEach((item, rowIndex) => {
      presetColor.forEach((element) => {
        if (item === element.hex) {
          data[rowIndex] = `-${element.id}`;
        }
      });
    });
    return data;
  }

  const getHex = presetColor.filter((color) => color.hex === data);
  return isEmpty(getHex) ? data : `-${getHex[0].id}`;
};

export const rgbaToHex = (rgb: RGBA, isEmailPage: boolean): string => {
  const outParts: string[] = [
    rgb.r.toString(16),
    rgb.g.toString(16),
    rgb.b.toString(16),
  ];

  if (!isEmailPage && rgb.a !== undefined) {
    outParts.push(Math.round(rgb.a * 255).toString(16).substring(0, 2));
  }

  outParts.forEach((part, i) => {
    if (part.length === 1) {
      outParts[i] = `0${part}`;
    }
  });

  return (`#${outParts.join('')}`);
};

export const selectBoxOptions = (
  arr: StringArrayMap[],
  labelKey: string,
  valueKey: string,
) => map(arr, (option) => ({
  ...option,
  label: labelKey ? option[labelKey] : option,
  value: valueKey ? option[valueKey] : option,
}));

export const getUnitType = (value: string): string => {
  if (!value) {
    return '';
  }

  const len = value.length;
  if (!len)
    return 'px';

  let i = len;
  while (i--)
    if (!isNaN(Number(value[i])))
      return value.slice(i + 1, len) || 'px';

  return 'px';
};

export const getFontSize = (data: FontSizeBreakpoint): string | number | null => {
  const allSize = get(data, 'break_point.all_styles.font-size');
  if (allSize && allSize !== undefined) {
    return allSize;
  }

  const desktopSize = get(data, 'break_point.desktop_styles.font-size');
  if (desktopSize && desktopSize !== undefined) {
    return desktopSize;
  }

  if (data.font_size != null && isNumber(data.font_size)) {
    return data.font_size;
  }

  return null;
};

export const formatBytes = (bytes: number, b?: number): string => {
  if (bytes === 0) {
    return '-';
  }
  const c = 1024;
  const d = b || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(bytes) / Math.log(c));
  return `${parseFloat((bytes / c ** index).toFixed(d))} ${sizes[index]}`;
};

export const getMaxValueByKey = (arr: StringArrayMap[], key: string): number | null => {
  if (isEmpty(arr)) {
    return 1;
  }

  const max = maxBy(arr, (s) => s[key]);

  if (max) {
    return max[key] + 1;
  }

  return null;
};

export const getArrIndex = (arr: StringArrayMap[], key: string, value: string) => arr.findIndex((e) => e[key] === value);

export const getDataFieldOptions = (data: any[]) => {
  const specialField: DataField[] = [];
  const dbField: DataField[] = [];
  map(data, (option) => {
    const fieldName = option.attributes.field_name;
    if (specialFieldsArr.includes(fieldName)) {
      specialField.push({ label: fieldName, value: option.id });
    } else {
      dbField.push({ label: fieldName, value: option.id });
    }
  });
  return [...dbField, ...specialField];
};

export const getSpecialFieldIdByName = (arr: DataField[], keyName: string): string | null => {
  const res = arr.filter((ele) => ele.label === keyName);
  return isEmpty(res) ? null : res[0].value;
};

export const setTheme = (theme: string): void => {
  const cl = document.body.classList;
  if (cl.contains(`theme-${theme}`)) {
    return;
  }

  cl.forEach((c) => {
    if (c.match(/theme-/)) {
      cl.remove(c);
    }
  });

  cl.add(`theme-${theme}`);
};

// The color package has many constructors: https://github.com/Qix-/color#constructors
type ColorConstructor = string | number[] | { r: number; g: number; b: number; a?: number };

export const getContrast = (c1: ColorConstructor, c2: ColorConstructor): number | null => {
  const parsed1 = Color(c1);
  const parsed2 = Color(c2);

  if (!parsed1 || !parsed2) {
    return null;
  }

  const object1 = parsed1.object();
  const object2 = parsed2.object();

  const g1 = [Math.pow(object1.r / 255, 2.2), Math.pow(object1.g / 255, 2.2), Math.pow(object1.b / 255, 2.2)];
  const g2 = [Math.pow(object2.r / 255, 2.2), Math.pow(object2.g / 255, 2.2), Math.pow(object2.b / 255, 2.2)];

  const luminance1 = (0.2126 * g1[0] + 0.7152 * g1[1] + 0.0722 * g1[2]);
  const luminance2 = (0.2126 * g2[0] + 0.7152 * g2[1] + 0.0722 * g2[2]);

  return luminance1 > luminance2 ? ((luminance1 + 0.05) / (luminance2 + 0.05)) : ((luminance2 + 0.05) / (luminance1 + 0.05));
};

export const getOppositeColor = (color: string) => {
  const parsed = Color(color);
  if (!parsed) {
    return color;
  }
  const object = parsed.object();
  return `rgb(${255 - object.r}, ${255 - object.g}, ${255 - object.b})`;
};

export const getDeviceType = (e: string): string => {
  switch (e) {
    case 'desktop':
      return deviceType.DESKTOP;
    case 'tablet':
      return deviceType.TABLET;
    case 'phone':
      return deviceType.PHONE;
    default: return deviceType.DESKTOP;
  }
};

// Get the component for a given action string. Only for the actions which are rendered via React, not server-side actions.
type ActionKey = keyof ActionComponentMap;
export const getActionComponentFromString = (action: ActionKey): React.ComponentType | undefined => {
  const actionComponentMap: ActionComponentMap = {
    InstantContactInterstitial: ActionPageInstantContactInterstitial,
    InstantContactChat: ActionPageInstantContactChat,
  };

  return actionComponentMap[action];
};

export const updateStylesheet = async (params: UpdateStylesheetParams = {}): Promise<void> => {
  const store = createStore();
  const { pageData: { admin_domain } } = store.getState();
  const adminDomain = admin_domain ? `https://${admin_domain}/api/v1` : '/api/v1';
  const { siteId, pageId, pageVersionId } = params;

  if (window.wg.env === 'public') {
    if (!siteId || !pageId || !pageVersionId)
      return;

    const el = document.getElementById('pageStyles') as HTMLElement | null;
    if (!el) return;

    const result = await fetch(`${adminDomain}/sites/${siteId}/pages/${pageId}/page_versions/${pageVersionId}/stylesheet?rand=${crypto.randomUUID()}`);
    if (result) {
      const data = await result.json();
      el.innerHTML = data.data;
    }
  } else {
    let el = document.getElementById('dashboard-style-link') as HTMLLinkElement | null;
    if (el === null) {
      el = document.createElement('link');
      el.id = 'dashboard-style-link';
      el.rel = 'stylesheet';
    }

    // We want to allow updating the existing stylesheet even if we don't have the parameters available. If we have the
    // parameters then we use them, otherwise we just update the existing stylesheet URL with a new random parameter to
    // force a refresh.

    if (siteId && pageId && pageVersionId) {
      el.href = `${adminDomain}/sites/${siteId}/pages/${pageId}/page_versions/${pageVersionId}/stylesheet?prefix=true&rand=${crypto.randomUUID()}`;
    } else {
      el.href = el.href.replace(/rand=.*/, `rand=${crypto.randomUUID()}`);
    }

    document.head.appendChild(el);
  }
};

export const removeStylesheet = (): void => {
  const el = document.getElementById('dashboard-style-link');
  if (el === null) {
    return;
  }
  el.remove();
};

// Get contrasting color from a list of predefined test colors
export function getContrastingColor(colors: string[]): string {
  // These colors were chosen because they're fairly bland pastels, plus white and black.
  const testColors: string[] = [
    '#ffffff', // white
    '#000000', // black
    '#9bf6ff', // blue
    '#caffbf', // green
    '#bea9df', // purple
    '#fdffb6', // yellow
    '#ffd6a5', // orange
    '#ffadad', // red
  ];

  for (let i = 0; i < testColors.length; i++) {
    const testColor = testColors[i];
    const hasContrast = colors.every((hex) => {
      const contrast = getContrast(testColor, hex);
      return contrast !== null && contrast >= 1.25;
    });
    if (hasContrast) {
      return testColor;
    }
  }

  // Default to white if no sufficient contrast found
  return '#ffffff';
}

declare global {
  interface Array<T> {
    pad(size: number, value: T): T[];
  }
}

Array.prototype.pad = function <T>(this: T[], size: number, value: T): T[] {
  const arr = this.slice(0);
  while (arr.length < size) {
    arr.push(value);
  }
  return arr;
};
