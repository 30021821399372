import React, { useEffect, useState } from 'react';
import {
  Card,
  CardColumns,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import BottomActionToolbar from '../../../components/common/toolbar';
import {
  BlogName,
  BlogPage,
  BlogDescription,
  BlogSocialMedia,
  BlogMasterPage,
  BlogTextStyles,
  BlogListLayout,
  BlogPostLayout,
} from '../../../components/fields/site';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import withRouter from '../../../helpers/withRouter';
import { useUpdateSiteMutation } from 'Dashboard/src/api/dashboardApiSlice';
import UserFeatureComponent from 'Dashboard/src/components/common/user-feature-component';
import { useInstance, useSite } from '../../../../../common/hooks';
import { addRecentItem } from 'Dashboard/src/Utils';

function SiteSettingBlog(props) {
  const [params, setParams] = useState({});
  const site = useSite();
  const instance = useInstance();

  const [updateSite] = useUpdateSiteMutation();

  useEffect(() => {
    if (site) {
      setParams(site);
    }
  }, [site]);

  const onSave = () => {
    updateSite({
      id: site?.id,
      instance_id: instance?.id,
      ...params,
    });

    return true;
  };

  if (instance?.id) {
    addRecentItem({
      instance_id: instance.id,
      type: 'settings-general',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'General', url: '' },
  ];

  return (
    <div className="EditSite">
      <HeaderComponent
        setPath={{
          headingName: 'Blog Settings',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.siteList,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <CardColumns>
        <Card>
          <CardBody>
            <Container>
              <UserFeatureComponent feature="blog_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <BlogName params={params} setParams={setParams} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <BlogPage params={params} setParams={setParams} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <BlogListLayout params={params} setParams={setParams} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <BlogPostLayout params={params} setParams={setParams} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <BlogDescription params={params} setParams={setParams} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <BlogSocialMedia params={params} setParams={setParams} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12}>
                    <BlogMasterPage params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
            </Container>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Container>
              <UserFeatureComponent feature="blog_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <BlogTextStyles params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
            </Container>
          </CardBody>
        </Card>
      </CardColumns>

      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onSave}
            onContinue={replacePathParams(settingRoutes.siteList, [], props)}
          />
        )}
      />
    </div>
  );
}

export default withRouter(SiteSettingBlog);
