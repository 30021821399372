import React from 'react';
import { selectBoxOptions, validateEmail } from '../../../../../Utils';
import { displayError } from 'Dashboard/src/Utils';
import CreatableSelect from 'react-select/creatable';
import { isEmpty, uniq } from 'lodash-es';
import { useUser } from '../../../../../common/hooks';

function CarbonCopyEmail({ options = [], selectedEmails, onChange }) {
  const user = useUser();

  const handleCreate = (e) => {
    const setValue = Array.isArray(e) ? e.map((x) => x.value) : [];
    onChange(setValue);
  };

  const onCreateOption = (e) => {
    if (e && !validateEmail(e)) {
      displayError('Enter a valid email address.');
    } else if (selectedEmails.includes(e)) {
      displayError('This email address already exists.');
    } else {
      onChange([...selectedEmails, e]);
    }
  };

  return (
    <CreatableSelect
      isMulti
      isClearable={false}
      value={isEmpty(selectedEmails) ? [] : selectBoxOptions(selectedEmails)}
      onCreateOption={onCreateOption}
      onChange={(newValue) => handleCreate(newValue)}
      options={selectBoxOptions(uniq([...options, ...selectedEmails, user?.email]))}
    />
  );
}

export default CarbonCopyEmail;
