import React, { useState, useEffect } from 'react';
import { cloneDeep, get, isEmpty, map } from 'lodash-es';
import { Form, Input, Progress } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import {
  useGenerateBriefingAIContentMutation,
  useGetSiteBriefingQuery,
  useUpdateSiteBriefingMutation,
} from '../../api/dashboardApiSlice';
import Briefing from 'common/models/Briefing';
import { displayError } from 'Dashboard/src/Utils';
import './index.scss';

const paymentMethods = [
  'Cash',
  'Bank Transfers',
  'Credit Cards',
  'Debit Cards',
  'Mobile Payments (e.g., Apple Pay, Google Pay)',
  'PayPal',
  'Gift Cards',
  'Financing',
  'Other',
];

const days = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',
];

const defaultDays = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday',
];

function BDQ(props) {
  const { params } = props;

  // TEMP: For testing
  // const [currentStep, setCurrentStep] = useState(55);
  const [currentStep, setCurrentStep] = useState(1);

  const { data: briefing } = useGetSiteBriefingQuery(params.briefingId);
  const [tempBriefing, setTempBriefing] = useState<Briefing | null>(null);
  const [updateBriefing] = useUpdateSiteBriefingMutation();
  const [generateBriefingAIContent] = useGenerateBriefingAIContentMutation();

  useEffect(() => {
    if (briefing && !tempBriefing) {
      setTempBriefing(briefing);
    }
  }, [briefing]);

  useEffect(() => {
    document.querySelector('.page-container.active')?.scrollTo(0, 0);

    // Focus first form item
    setTimeout(() => {
      const activePage = document.querySelector('.page-container.active');
      const firstInput = activePage?.querySelector<HTMLInputElement | HTMLTextAreaElement>('input[type="text"], input[type="number"], textarea');
      if (firstInput) {
        firstInput.focus();
      }
    }, 250);
  }, [currentStep]);

  // Check a bunch of checkboxes by default
  useEffect(() => {
    const tempBriefingWithSelected = cloneDeep(tempBriefing);

    if (!tempBriefingWithSelected?.info?.site_crawl_data) {
      return;
    }

    let changed;

    tempBriefingWithSelected.info.site_crawl_data.forEach((page) => {
      page.themes?.forEach((theme) => {
        if (!('is_relevant' in theme)) {
          theme['is_relevant'] = true;
          changed = true;
        }
      });
    });

    if (!('selling_points_selected' in tempBriefingWithSelected.info)) {
      tempBriefingWithSelected.info.selling_points_selected = tempBriefingWithSelected.info.selling_point_options;
      changed = true;
    }

    if (!('key_differentiators_selected' in tempBriefingWithSelected.info)) {
      tempBriefingWithSelected.info.key_differentiators_selected = get(tempBriefingWithSelected, 'ai_content.KeyDifferentiators', []);
      changed = true;
    }

    if (!('google_keywords_selected' in tempBriefingWithSelected.info)) {
      tempBriefingWithSelected.info.google_keywords_selected = get(tempBriefingWithSelected, 'ai_content.GoogleKeywords', []);
      changed = true;
    }

    tempBriefingWithSelected.info.asap_pages.forEach((_page, pageIndex) => {
      tempBriefingWithSelected.info!.asap_selling_points_selected ||= [];
      if (typeof get(tempBriefingWithSelected, `info.asap_selling_points_selected.${pageIndex}`) === 'undefined') {
        tempBriefingWithSelected.info!.asap_selling_points_selected[pageIndex] = get(tempBriefingWithSelected, `ai_content.asap_page_details.${pageIndex}.ASAPPageSellingPoints`, []);
        changed = true;
      }

      tempBriefingWithSelected.info!.asap_google_keywords_selected ||= [];
      if (typeof get(tempBriefingWithSelected, `info.asap_google_keywords_selected.${pageIndex}`) === 'undefined') {
        tempBriefingWithSelected.info!.asap_google_keywords_selected[pageIndex] = get(tempBriefingWithSelected, `ai_content.asap_page_details.${pageIndex}.ASAPPageGoogleKeywords`, []);
        changed = true;
      }
    });

    if (!('job_satisfaction_selected' in tempBriefingWithSelected.info)) {
      tempBriefingWithSelected.info.job_satisfaction_selected = get(tempBriefingWithSelected, 'ai_content.JobSatisfactionOptions', []);
      changed = true;
    }

    if (changed) {
      setTempBriefing({ ...tempBriefingWithSelected });
    }
  }, [tempBriefing?.info?.site_crawl_data]);

  const setInfo = (key: string, value: any) => {
    if (!tempBriefing) {
      return;
    }

    setTempBriefing({
      ...tempBriefing,
      info: {
        ...tempBriefing.info,
        [key]: value,
      },
    });
  };

  const setTempBriefingAndSave = async (newBriefing: Briefing) => {
    setTempBriefing(newBriefing);
    await updateBriefing(newBriefing);
  };

  if (!briefing || !tempBriefing || !tempBriefing.info) {
    return <></>;
  }

  const steps = [] as any[];

  const BottomBar = () => (
    <div className="bottom-bar">
      <small>Progress</small>
      <Progress
        value={currentStep}
        max={steps.length}
      />
      <br />
      <button
        type="button"
        className="btn btn-secondary mb-3"
        onClick={() => setCurrentStep(currentStep - 1)}
      >
        <i className="fal fa-chevron-left" />
        &nbsp;&nbsp;
        Back
      </button>
      <button
        type="button"
        className="btn btn-secondary float-end"
        onClick={async () => {
          // Check form validity
          const form = document.querySelector<HTMLFormElement>('form.bdq');

          const inputs = form!.querySelectorAll<HTMLInputElement>('input[required]');
          let isValid = true;

          inputs.forEach(input => {
            // Check if input is visible
            if (window.getComputedStyle(input).visibility !== 'hidden') {
              if (!input.checkValidity()) {
                isValid = false;
              }
            }
          });

          if (!isValid) {
            displayError('Please fill in all required fields.');
            return;
          }

          await updateBriefing(tempBriefing);

          // Sometimes we need to generate AI content after a particular step has been completed.

          // Updates ai_content
          const updateFromResult = async (result) => {
            if (result?.data?.data) {
              setTempBriefing({
                ...tempBriefing,
                ai_content: result.data.data.ai_content,
              });
            }

            if (result?.error) {
              displayError('An error occurred while generating AI content.');
              return;
            }
          };

          const keyDifferentiators = get(tempBriefing, 'ai_content.KeyDifferentiators', []);
          if (tempBriefing.info!.selling_points_selected?.length > 0 && isEmpty(keyDifferentiators)) {
            updateFromResult(await generateBriefingAIContent({
              briefing: tempBriefing,
              prompt_name: 'KeyDifferentiators',
            }));
          }

          const draftCoreBusinessProposition = get(tempBriefing, 'ai_content.DraftCoreBusinessProposition', '');
          if (tempBriefing.info!.selling_points_selected?.length > 0 && tempBriefing.info!.key_differentiators_selected?.length > 0 && isEmpty(draftCoreBusinessProposition)) {
            updateFromResult(await generateBriefingAIContent({
              briefing: tempBriefing,
              prompt_name: 'DraftCoreBusinessProposition',
            }));
          }

          if (isEmpty(get(tempBriefing, 'ai_content.UpdateCoreBusinessProposition', ''))) {
            const coreBusinessPropositionChanges = get(tempBriefing, 'info.core_business_proposition_changes', '');

            if (tempBriefing.info!.core_business_proposition_ok === 'yes') {
              await updateBriefing({
                ...tempBriefing,
                ai_content: {
                  ...tempBriefing.ai_content,
                  UpdateCoreBusinessProposition: draftCoreBusinessProposition,
                },
              });
            } else if (!isEmpty(draftCoreBusinessProposition) && !isEmpty(coreBusinessPropositionChanges)) {
              updateFromResult(await generateBriefingAIContent({
                briefing: tempBriefing,
                prompt_name: 'UpdateCoreBusinessProposition',
              }));
            }
          }

          if (isEmpty(get(tempBriefing, 'ai_content.DraftBusinessStory', '')) && !isEmpty(get(tempBriefing, 'ai_content.UpdateCoreBusinessProposition', ''))) {
            updateFromResult(await generateBriefingAIContent({
              briefing: tempBriefing,
              prompt_name: 'DraftBusinessStory',
            }));
          }

          if (isEmpty(get(tempBriefing, 'ai_content.DraftBusinessToday', '')) && !isEmpty(get(tempBriefing, 'ai_content.UpdateCoreBusinessProposition', ''))) {
            updateFromResult(await generateBriefingAIContent({
              briefing: tempBriefing,
              prompt_name: 'DraftBusinessToday',
            }));
          }

          if (!isEmpty(get(tempBriefing, 'ai_content.UpdateCoreBusinessProposition', '')) && isEmpty(get(tempBriefing, 'ai_content.JobSatisfactionOptions', []))) {
            updateFromResult(await generateBriefingAIContent({
              briefing: tempBriefing,
              prompt_name: 'JobSatisfactionOptions',
            }));
          }

          if (
            (tempBriefing.ai_content!.GoogleKeywords || []).length === 0
            && tempBriefing.info!.selling_points_selected?.length > 0
            && tempBriefing.info!.key_differentiators_selected?.length > 0
          ) {
            updateFromResult(await generateBriefingAIContent({
              briefing: tempBriefing,
              prompt_name: 'GoogleKeywords',
            }));
          }

          // ASAP page selling point options
          tempBriefing.info!.asap_pages?.forEach(async (page, pageIndex) => {
            if (isEmpty(get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.ASAPPageSellingPoints`, []))) {
              updateFromResult(await generateBriefingAIContent({
                briefing: tempBriefing,
                prompt_name: 'ASAPPageSellingPoints',
                page_index: pageIndex,
              }));
            }

            if (isEmpty(get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.ASAPPageGoogleKeywords`, [])) && !isEmpty(get(tempBriefing, `info.asap_selling_points_selected.${pageIndex}`, []))) {
              updateFromResult(await generateBriefingAIContent({
                briefing: tempBriefing,
                prompt_name: 'ASAPPageGoogleKeywords',
                page_index: pageIndex,
              }));
            }
          });

          setCurrentStep(currentStep + 1);
        }}
      >
        Next
        &nbsp;&nbsp;
        <i className="fal fa-chevron-right" />
      </button>
    </div>
  );

  steps.push(
    <div className="page page-intro">
      <div>
        <h1>
          <strong>Business</strong>
          <br />
          Discovery Quiz
        </h1>
        <h2 className="mb-4">
          <em>
            For
            {' '}
            {briefing.info.company_name}
          </em>
        </h2>
        <p>
          Hi
          {' '}
          {get(briefing, 'info.business_owners.0.0')}
          {' '}
          {get(briefing, 'info.business_owners.0.1')}
          ,
        </p>
        <p>
          Let&apos;s start a conversation to uncover key strategic details that will help us create an amazing website for your amazing business!
        </p>
        <p>
          This quiz is a crucial step towards creating the best possible website for you.
          It consists of a series of
          {' '}
          <strong>
            easy-to-answer, one-line, checkbox and multi-choice questions.
          </strong>
        </p>
        <p>
          Firstly we provide a summary of our communication so far for you to comment on, then there are four sections to complete:
        </p>
        <ul>
          <li>
            {isEmpty(briefing.info.existing_website)
              ? (
                  <>Website photos and logo</>
                )
              : (
                  <>Your existing logo and website</>
                )}
          </li>
          <li>Your marketing pillars</li>
          <li>Your website&apos;s products and services pages</li>
          <li>Your business story</li>
        </ul>
        <p>
          Your responses will then feed into your upcoming Briefing Session with your Project Manager.
        </p>
        <p>
          &nbsp;
        </p>
        <p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Let&apos;s Go!
          </button>
        </p>
      </div>
      <div />
    </div>,
  );

  steps.push(
    <div className="page page-book">
      <div>
        <h2>
          Before We Dive In: A Quick Note on the Magic Behind This Quiz
        </h2>
        <h3>
          Let&apos;s talk about the secret sauce powering this quiz &mdash; a blend of cutting-edge AI and time-honoured expertise.
        </h3>
        <p>
          For over 25 years, Web Genius has been at the forefront of web marketing, refining our methodologies to deliver industry-leading results. Our founder, Richard Calkin, literally wrote the book on the subject: The Kiwi Business Hero&apos;s Internet Marketing Handbook. It&apos;s now in its fourth edition, and as our valued client, you can grab a copy &mdash; absolutely free!
        </p>
        <p>
          {tempBriefing.requested_book && (
            <div className="border p-4 mb-4">
              Thanks! Your copy of The Kiwi Business Hero&apos;s Internet Marketing Handbook is on its way.
            </div>
          )}
          {!tempBriefing.requested_book && (
            <button
              type="button"
              onClick={() => setTempBriefingAndSave({ ...tempBriefing, requested_book: true })}
            >
              Yes, send me a free copy!
            </button>
          )}
        </p>
        <p>
          This quiz combines the insights from Richard&apos;s book with the playful, creative power of AI to uncover valuable strategic details about your business. While the AI adds an element of fun, its suggestions aren&apos;t always spot-on &mdash; and that&apos;s intentional.
        </p>
        <p>
          Think of this as a conversation starter, setting the stage for your upcoming Briefing Session with your Project Manager, who is an actual human! (Because even in this age of technology the personal touch is still important to us at Web Genius).
        </p>
        <p>
          Let&apos;s have some fun and get those creative juices flowing!
        </p>
        <p>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setCurrentStep(currentStep + 1)}
          >
            Let&apos;s Create!
          </button>
        </p>
      </div>
      <div></div>
    </div>,
  );

  steps.push(
    <div className="page generic-page page-communication-summary">
      <div className="main">
        <h1>Our communication summary</h1>

        <h3>
          Before we get started, here&apos;s a summary of all our communication to date from the sales and onboarding teams.
        </h3>

        <br />

        <div className="border p-4 mb-4">
          <em>
            <strong>
              Summary of Communication with
              {' '}
              {briefing.info.company_name}
            </strong>
            <br />
            <br />
            <div style={{ whiteSpace: 'pre-line' }}>
              {briefing.ai_content.ClientConversationSummary}
            </div>
          </em>
        </div>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder="If you have any additions, changes or comments, please share them here before proceeding."
          value={tempBriefing.info.communication_summary_changes}
          onChange={(e) => setInfo('communication_summary_changes', e.target.value)}
        />
      </div>

      <BottomBar />
    </div>,
  );

  // steps.push(
  //   <div className="page-cont">
  //     <div className="bg-image-cont-1" />
  //     <div className="page page-ad-1">
  //       <div className="ad-box-1">
  //         <div className="blue-stroke"></div>
  //         Independent research shows Web Genius websites have
  //         {' '}
  //         <strong>
  //           500% more top-ten Google rankings
  //         </strong>
  //         {' '}
  //         than other websites
  //       </div>
  //       <BottomBar />
  //     </div>
  //   </div>,
  // );

  if (!isEmpty(tempBriefing.info.existing_website)) {
    const tidiedUpExistingWebsite = tempBriefing.info.existing_website
      .replace(/^https?:\/\//, '')
      .replace(/\/$/, '')
    ;

    steps.push(
      <div className="page generic-page page-existing-website">
        <div className="main">
          <h1>Your existing website</h1>

          <h3>
            We currently have your current website as:
          </h3>

          <br />

          <Input
            value={tidiedUpExistingWebsite}
            onChange={(e) => setInfo('existing_website', e.target.value)}
            className="mb-5"
          />

          <p>
            Please edit the above if incorrect.
          </p>

          <p>
            Once you are happy it is correct please click &ldquo;Next&rdquo;.
          </p>
        </div>

        <BottomBar />
      </div>,
    );
  }

  // Only display the existing content relevance pages if we have some product pages from the site crawl
  if (get(tempBriefing, 'info.site_crawl_data', []).filter((page) => page.is_product_page).length > 0) {
    if (!isEmpty(tempBriefing.info.existing_website)) {
      steps.push(
        <div className="page generic-page page-existing-website">
          <div className="main">
            <h1>How relevant is the content on the existing website?</h1>

            <h3>
              Please select one of the following three options:
            </h3>

            <br />

            <div className="form-check mb-3">
              <input
                required
                className="form-check-input"
                type="radio"
                name="existingWebsiteRelevant"
                id="existingWebsiteRelevant1"
                checked={tempBriefing.info.existing_website_relevance === 'not_relevant'}
                onChange={() => setInfo('existing_website_relevance', 'not_relevant')}
              />
              <label className="form-check-label" htmlFor="existingWebsiteRelevant1">
                The content on the existing website is not relevant at all
              </label>
            </div>

            <div className="form-check mb-3">
              <input
                required
                className="form-check-input"
                type="radio"
                name="existingWebsiteRelevant"
                id="existingWebsiteRelevant2"
                checked={tempBriefing.info.existing_website_relevance === 'all_relevant'}
                onChange={() => setInfo('existing_website_relevance', 'all_relevant')}
              />
              <label className="form-check-label" htmlFor="existingWebsiteRelevant2">
                All (or almost all) of the content on the existing is still relevant (even if it might need further development)
              </label>
            </div>

            <div className="form-check mb-3">
              <input
                required
                className="form-check-input"
                type="radio"
                name="existingWebsiteRelevant"
                id="existingWebsiteRelevant3"
                checked={tempBriefing.info.existing_website_relevance === 'some_relevant'}
                onChange={() => setInfo('existing_website_relevance', 'some_relevant')}
              />
              <label className="form-check-label" htmlFor="existingWebsiteRelevant3">
                Some of the content on the existing website is still relevant and some isn&apos;t
              </label>
            </div>
          </div>

          <BottomBar />
        </div>,
      );
    }

    if (tempBriefing.info.existing_website_relevance === 'some_relevant') {
      steps.push(
        <div className="page generic-page page-relevance-existing-content-intro">
          <div className="main">
            <h1>Relevance of existing website content</h1>

            <p>
              You&apos;ve told us that some of the content on the existing website is still relevant and some isn&apos;t.
            </p>
            <p>
              Next, we have a look at summaries of some of your main website pages, so you can tell us what is still relevant and what isn&apos;t.
            </p>
          </div>

          <BottomBar />
        </div>,
      );

      tempBriefing.info.site_crawl_data?.forEach((page, pageIndex) => {
        if (!page.is_product_page) {
          return;
        }

        steps.push(
          <div
            key={pageIndex}
            className="page generic-page page-relevance-existing-content-intro"
          >
            <div className="main">
              <h1>
                Page:
                {' '}
                <strong>{isEmpty(page.name) ? page.title : page.name}</strong>
              </h1>

              <p>
                Here is a summary of the main content themes on this page. Untick any that are not relevant:
              </p>

              {map(page.themes, (theme, themeIndex) => (
                <div
                  key={themeIndex}
                  className="form-check mb-3"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="existingPageRelevant"
                    checked={get(tempBriefing, `info.site_crawl_data.${pageIndex}.themes.${themeIndex}.is_relevant`)}
                    onChange={(e) => {
                      const tempSiteCrawlData = cloneDeep(tempBriefing.info!.site_crawl_data);
                      tempSiteCrawlData[pageIndex].themes[themeIndex].is_relevant = (e.target as HTMLInputElement).checked;
                      setInfo('site_crawl_data', tempSiteCrawlData);
                    }}
                    id={`existingContentPage_${pageIndex}_${themeIndex}`}
                  />
                  <label className="form-check-label" htmlFor={`existingContentPage_${pageIndex}_${themeIndex}`}>
                    {theme.theme}
                  </label>
                </div>
              ))}
              <p>
                Please make any other comments about the relevance of the content of this page:
              </p>

              <Input
                type="textarea"
                rows={4}
                className="mb-5"
                placeholder="Optional (skip if you prefer)"
              />
            </div>

            <BottomBar />
          </div>,
        );
      });
    }
  }

  if (isEmpty(tempBriefing.info.existing_website)) {
    steps.push(
      <div className="page generic-page page-photos-importance">
        <div className="main">
          <h1>Website Photos</h1>

          <p>
            The photos on your new website are a crucial factor in its success. Here are some key points to keep in mind:
          </p>

          <ul className="mb-3">
            <li className="mb-3">Photos are an important element in telling your business story and creating a positive visual impact.</li>
            <li className="mb-3">Website photos typically include a mix of generic stock images and photos taken by the business over time.</li>
            <li className="mb-3">The sooner you can supply any photos you already have the better and faster your website project will proceed.</li>
          </ul>

          {tempBriefing.google_drive_folder_id && (
            <>
              <p>
                Please upload all available photos to the Google Drive folder we have set up for you ASAP. You will need a Google account to do this.
              </p>
              <p>
                Simply use this link and follow the instructions:
              </p>
              <a href={`https://drive.google.com/drive/folders/${tempBriefing.google_drive_folder_id}`} target="_blank" rel="noreferrer">
                <button
                  type="button"
                  className="btn btn-primary mb-5"
                >
                  Upload Images to Google Drive
                </button>
              </a>
            </>
          )}
        </div>
        <BottomBar />
      </div>,
    );
  } else {
    steps.push(
      <div className="page generic-page page-photos-importance">
        <div className="main">
          <h1>Website Photos</h1>

          <p>Can we use the photos from your existing website?</p>

          <div className="form-check mb-3">
            <input
              required
              className="form-check-input"
              type="radio"
              name="existingWebsitePhotos"
              id="existingPhotosYes"
              checked={tempBriefing.info.existing_website_photos === 'yes'}
              onChange={() => setInfo('existing_website_photos', 'yes')}
            />
            <label className="form-check-label" htmlFor="existingPhotosYes">
              Yes
            </label>
          </div>
          <div className="form-check mb-3">
            <input
              required
              className="form-check-input"
              type="radio"
              name="existingWebsitePhotos"
              id="existingPhotosNo"
              checked={tempBriefing.info.existing_website_photos === 'no'}
              onChange={() => setInfo('existing_website_photos', 'no')}
            />
            <label className="form-check-label" htmlFor="existingPhotosNo">
              No
            </label>
          </div>

          <p>
            Please add any further information about the photos on your existing website:
          </p>

          <Input
            type="textarea"
            rows={4}
            className="mb-5"
            placeholder="Optional (skip if you prefer)"
            value={tempBriefing.info.existing_website_photos_summary_changes}
            onChange={(e) => setInfo('existing_website_photos_summary_changes', e.target.value)}
          />

          {tempBriefing.google_drive_folder_id && (
            <>
              <p>
                If you have other photos that we can use on your new website, please upload them to the Google Drive folder we have set up for you ASAP. You will need a Google account to do this.
              </p>
              <p>
                Simply use this link and follow the instructions:
              </p>
              <a href={`https://drive.google.com/drive/folders/${tempBriefing.google_drive_folder_id}`} target="_blank" rel="noreferrer">
                <button
                  type="button"
                  className="btn btn-primary mb-5"
                >
                  Upload Images to Google Drive
                </button>
              </a>
            </>
          )}
        </div>

        <BottomBar />
      </div>,
    );
  }

  steps.push(
    <div className="page generic-page page-marketing-pillars">
      <div className="main">
        <h1>Your Marketing Pillars</h1>

        <h3>Now we look at your four key marketing pillars:</h3>

        <ul className="unstyled-list my-4">
          <li>Your Business Strengths and Selling Points</li>
          <li>Your Key Differentiators</li>
          <li>Your Core Business Proposition</li>
          <li>Your Google Keywords</li>
        </ul>
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-business-strengths">
      <div className="main">
        <h1>Your Business Strengths and Selling Points</h1>

        <p>
          Here we gather information on the main strengths and selling points of
          {' '}
          {briefing.info.company_name}
          {' '}
          and what you pride yourself on being good at.
          Untick any that you don&apos;t believe apply from this list and add any others below:
        </p>

        {map(tempBriefing.info.selling_point_options, (sellingPoint, sellingPointIndex) => (
          <div className="form-check mb-3" key={sellingPointIndex}>
            <input
              className="form-check-input"
              type="checkbox"
              name="businessStrength"
              id={`selling_points_${sellingPointIndex}`}
              checked={get(tempBriefing, 'info.selling_points_selected', []).includes(sellingPoint)}
              onChange={(e) => {
                const tempSellingPointsSelected = cloneDeep(tempBriefing.info!.selling_points_selected) || [];
                if ((e.target as HTMLInputElement).checked) {
                  tempSellingPointsSelected.push(sellingPoint);
                } else {
                  tempSellingPointsSelected.splice(tempSellingPointsSelected.indexOf(sellingPoint), 1);
                }
                setInfo('selling_points_selected', tempSellingPointsSelected);
              }}
            />
            <label className="form-check-label" htmlFor={`selling_points_${sellingPointIndex}`}>
              {sellingPoint}
            </label>
          </div>
        ))}

        <p>List any others here:</p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder="Optional (skip if you prefer)"
          value={tempBriefing.info.other_selling_points}
          onChange={(e) => setInfo('other_selling_points', e.target.value)}
        />
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-key-differentiators">
      <div className="main">
        <h1>Your Key Differentiator(s)</h1>

        <p>
          What are the things that make you different from and better than the competition?
          Untick any that you don&apos;t believe apply from this list and add any others below:
        </p>

        {map(get(tempBriefing, 'ai_content.KeyDifferentiators', []), (differentiator, differentiatorIndex) => (
          <div className="form-check mb-3" key={differentiatorIndex}>
            <input
              className="form-check-input"
              type="checkbox"
              name="keyDifferentiator"
              id={`differentiators_${differentiatorIndex}`}
              checked={get(tempBriefing, 'info.key_differentiators_selected', [])?.includes(differentiator)}
              onChange={(e) => {
                const tempKeyDifferentiatorsSelected = cloneDeep(tempBriefing.info!.key_differentiators_selected) || [];
                if ((e.target as HTMLInputElement).checked) {
                  tempKeyDifferentiatorsSelected.push(differentiator);
                } else {
                  tempKeyDifferentiatorsSelected.splice(tempKeyDifferentiatorsSelected.indexOf(differentiator), 1);
                }
                setInfo('key_differentiators_selected', tempKeyDifferentiatorsSelected);
              }}
            />
            <label className="form-check-label" htmlFor={`differentiators_${differentiatorIndex}`}>
              {differentiator}
            </label>
          </div>
        ))}

        <p>List any others here:</p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder="Optional (skip if you prefer)"
          value={tempBriefing.info.other_key_differentiators}
          onChange={(e) => setInfo('other_key_differentiators', e.target.value)}
        />
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-core-business-proposition">
      <div className="main">
        <h1>Your Core Business Proposition</h1>

        <p>
          Your Core Business Proposition is a clear, concise statement that defines the unique value of your business, capturing the essence of why customers should choose your services.
        </p>

        <p>
          Based on our conversations so far we have come up with a draft Core Business Proposition…
        </p>

        <div className="border p-4 mb-4">
          <em>
            {get(tempBriefing, 'ai_content.DraftCoreBusinessProposition', '')}
          </em>
        </div>

        <p>
          After you have read the above draft Core Business Proposition, select the best option from the following:
        </p>

        <div className="form-check mb-3">
          <input
            required
            className="form-check-input"
            type="radio"
            name="coreBusinessPropositionOk"
            id="coreBusinessPropositionOkYes"
            checked={tempBriefing.info.core_business_proposition_ok === 'yes'}
            onChange={() => setInfo('core_business_proposition_ok', 'yes')}
          />
          <label className="form-check-label" htmlFor="coreBusinessPropositionOkYes">
            This sums up our Core Business Proposition well
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            required
            className="form-check-input"
            type="radio"
            name="coreBusinessPropositionOk"
            id="coreBusinessPropositionOkSomewhat"
            checked={tempBriefing.info.core_business_proposition_ok === 'somewhat'}
            onChange={() => setInfo('core_business_proposition_ok', 'somewhat')}
          />
          <label className="form-check-label" htmlFor="coreBusinessPropositionOkSomewhat">
            This is quite good, but needs some changes
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            required
            className="form-check-input"
            type="radio"
            name="coreBusinessPropositionOk"
            id="coreBusinessPropositionOkNo"
            checked={tempBriefing.info.core_business_proposition_ok === 'no'}
            onChange={() => setInfo('core_business_proposition_ok', 'no')}
          />
          <label className="form-check-label" htmlFor="coreBusinessPropositionOkNo">
            This isn&apos;t right and needs to be re-worked
          </label>
        </div>
      </div>

      <BottomBar />
    </div>,
  );

  if (tempBriefing.info.core_business_proposition_ok !== 'yes') {
    steps.push(
      <div className="page generic-page page-draft-core-business-proposition">
        <div className="main">
          <h1>Draft Core Business Proposition</h1>

          <p>What are the main changes that are needed to the Draft Core Business Proposition?</p>

          <Input
            type="textarea"
            rows={4}
            className="mb-5"
            placeholder="Please suggest the changes here"
            value={tempBriefing.info.core_business_proposition_changes}
            onChange={(e) => setInfo('core_business_proposition_changes', e.target.value)}
          />

          <p>
            Here it is again if you need to refer to it, to suggest what needs to be changed.
          </p>

          <div className="border p-4 mb-4">
            <em>
              {get(tempBriefing, 'ai_content.DraftCoreBusinessProposition', '')}
            </em>
          </div>

          <p>We&apos;ll take your feedback on board and amend ahead of your upcoming Briefing Session with your Project Manager.</p>
        </div>

        <BottomBar />
      </div>,
    );
  }

  steps.push(
    <div className="page generic-page page-google-keywords">
      <div className="main">
        <h1>Your Google Keywords</h1>

        <p>
          Here are some keyword phrases that may apply to help your business get found on Google. Untick any that are
          not relevant:
        </p>

        {map(tempBriefing.ai_content?.GoogleKeywords, (keyword, keywordIndex) => (
          <div className="form-check mb-3" key={keywordIndex}>
            <input
              className="form-check-input"
              type="checkbox"
              name="googleKeyword"
              id={`google_keywords_${keywordIndex}`}
              checked={get(tempBriefing, 'info.google_keywords_selected', [])?.includes(keyword)}
              onChange={(e) => {
                const tempGoogleKeywordsSelected = cloneDeep(tempBriefing.info!.google_keywords_selected) || [];
                if ((e.target as HTMLInputElement).checked) {
                  tempGoogleKeywordsSelected.push(keyword);
                } else {
                  tempGoogleKeywordsSelected.splice(tempGoogleKeywordsSelected.indexOf(keyword), 1);
                }
                setInfo('google_keywords_selected', tempGoogleKeywordsSelected);
              }}
            />
            <label className="form-check-label" htmlFor={`google_keywords_${keywordIndex}`}>
              {keyword}
            </label>
          </div>
        ))}

        <br />

        <p>List any other potentially relevant keywords you can think of:</p>
        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder="Optional (skip if you prefer)"
          value={tempBriefing.info.other_google_keywords}
          onChange={(e) => setInfo('other_google_keywords', e.target.value)}
        />
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-products-services">
      <div className="main">
        <h1>Your website&apos;s products and services pages</h1>

        <h3>
          As it currently stands the proposed topics of your
          {' '}
          {tempBriefing.info.asap_pages.length}
          {' '}
          ASAP (product and service) pages for your new website will be:
        </h3>

        <ol className="unstyled-list">
          {map(tempBriefing.info.asap_pages, (page, index) => (
            <li key={index}>
              {page}
            </li>
          ))}
        </ol>

        <p>Please tell us if you need any changes to the names and topics of these ASAP pages.</p>
        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder="Optional (skip if you prefer)"
          value={tempBriefing.info.asap_page_changes}
          onChange={(e) => setInfo('asap_page_changes', e.target.value)}
        />

        <p>
          If you now believe you may need more than
          {' '}
          {tempBriefing.info.asap_pages.length}
          {' '}
          ASAP pages, there may be some additional investment required, which we are happy to discuss with you.
        </p>
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-other-products-services">
      <div className="main">
        <h1>Other products and services</h1>

        <p>
          On top of the topics for your ASAP Pages (i.e.
          {' '}
          {map(tempBriefing.info.asap_pages, (page, index) => (
            <span key={index}>
              {index + 1}
              .
              {' '}
              {page}
              {index < tempBriefing.info!.asap_pages.length - 1 && ', '}
            </span>
          ))}
          ), do you offer any other products and services?
        </p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder="Optional (skip if you prefer)"
          value={tempBriefing.info.other_products_services}
          onChange={(e) => setInfo('other_products_services', e.target.value)}
        />

      </div>
      <BottomBar />
    </div>,
  );

  {
    map(tempBriefing.info.asap_pages, (page, pageIndex) => {
      steps.push(
        <div className="page generic-page" key={`${page}-step-1`}>
          <div className="main">
            <h3>
              ASAP Page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
            </h3>
            <h1>
              Strengths and Selling Points
            </h1>
            <p>
              Here are some possible strengths and selling points for ASAP page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
              . Untick any that aren&apos;t relevant and add any others below:
            </p>
            {map(get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.ASAPPageSellingPoints`, []), (sellingPoint, sellingPointIndex) => (
              <div className="form-check mb-3" key={sellingPointIndex}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={`asap_selling_points_${pageIndex}`}
                  id={`asap_selling_points_${pageIndex}_${sellingPointIndex}`}
                  checked={get(tempBriefing, `info.asap_selling_points_selected.${pageIndex}`, []).includes(sellingPoint)}
                  onChange={(e) => {
                    const tempAsapSellingPointsSelected = cloneDeep(tempBriefing.info!.asap_selling_points_selected) || [];
                    if ((e.target as HTMLInputElement).checked) {
                      tempAsapSellingPointsSelected[pageIndex] ||= [];
                      tempAsapSellingPointsSelected[pageIndex].push(sellingPoint);
                    } else {
                      tempAsapSellingPointsSelected[pageIndex].splice(tempAsapSellingPointsSelected[pageIndex].indexOf(sellingPoint), 1);
                    }
                    setInfo('asap_selling_points_selected', tempAsapSellingPointsSelected);
                  }}
                />
                <label className="form-check-label" htmlFor={`asap_selling_points_${pageIndex}_${sellingPointIndex}`}>{sellingPoint}</label>
              </div>
            ))}
            <p>List any other strengths and selling points you can think of:</p>
            <Input
              type="textarea"
              rows={4}
              className="mb-5"
              placeholder="Optional (skip if you prefer)"
              value={get(tempBriefing, `info.asap_pages_other_selling_points.${pageIndex}`, '')}
              onChange={(e) => {
                const tempAsapPagesOtherSellingPoints = cloneDeep(tempBriefing.info!.asap_pages_other_selling_points) || [];
                tempAsapPagesOtherSellingPoints[pageIndex] = e.target.value;
                setInfo('asap_pages_other_selling_points', tempAsapPagesOtherSellingPoints);
              }}
            />
          </div>
          <BottomBar />
        </div>,
      );

      steps.push(
        <div className="page generic-page" key={`${page}-step-2`}>
          <div className="main">
            <h3>
              ASAP Page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
            </h3>
            <h1>What crucial points can we add to showcase how awesome your offering is?</h1>

            <p>
              Now&apos;s not the time to be modest! Tell us about
              {' '}
              <strong>what</strong>
              ,
              {' '}
              <strong>why</strong>
              , and
              {' '}
              <strong>how</strong>
              {' '}
              you&apos;re awesome in this particular area!
            </p>

            <p>
              Here are some ideas:
            </p>

            <h3>Exclusive, or preferred, products and/or services:</h3>
            <p>What particular products and/or services do you use that are worthy of mention?</p>

            <Input
              type="textarea"
              className="mb-5"
              rows={4}
              value={get(tempBriefing, `info.asap_page_details.${pageIndex}.exclusive_products_services`)}
              onChange={(e) => {
                const tempAsapPageDetails = cloneDeep(tempBriefing.info!.asap_page_details) || [];
                tempAsapPageDetails[pageIndex] ||= {};
                tempAsapPageDetails[pageIndex].exclusive_products_services = e.target.value;
                setInfo('asap_page_details', tempAsapPageDetails);
              }}
            />

            <p>
              Just click NEXT if nothing comes to mind, but
              {' '}
              <strong>any notes you can make will be very helpful!</strong>
            </p>
          </div>

          <BottomBar />
        </div>,
      );

      steps.push(
        <div className="page generic-page" key={`${page}-step-3`}>
          <div className="main">
            <h3>
              ASAP Page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
            </h3>
            <h1>Highlight Your Expertise</h1>

            <p>What specific experience or expertise could you highlight on this page?</p>

            <Input
              type="textarea"
              className="mb-5"
              rows={4}
              value={get(tempBriefing, `info.asap_page_details.${pageIndex}.expertise`)}
              onChange={(e) => {
                const tempAsapPageDetails = cloneDeep(tempBriefing.info!.asap_page_details) || [];
                tempAsapPageDetails[pageIndex] ||= {};
                tempAsapPageDetails[pageIndex].expertise = e.target.value;
                setInfo('asap_page_details', tempAsapPageDetails);
              }}
            />

            <p>
              Just click NEXT if nothing comes to mind, but
              {' '}
              <strong>any notes you can make will be very helpful!</strong>
            </p>
          </div>

          <BottomBar />
        </div>,
      );

      steps.push(
        <div className="page generic-page" key={`${page}-step-4`}>
          <div className="main">
            <h3>
              ASAP Page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
            </h3>
            <h1>Your Process or Methodology:</h1>

            <p>What&apos;s unique about the way you create, deliver, or approach the subject of this page?</p>

            <Input
              type="textarea"
              className="mb-5"
              rows={4}
              value={get(tempBriefing, `info.asap_page_details.${pageIndex}.process`)}
              onChange={(e) => {
                const tempAsapPageDetails = cloneDeep(tempBriefing.info!.asap_page_details) || [];
                tempAsapPageDetails[pageIndex] ||= {};
                tempAsapPageDetails[pageIndex].process = e.target.value;
                setInfo('asap_page_details', tempAsapPageDetails);
              }}
            />

            <p>
              Just click NEXT if nothing comes to mind, but
              {' '}
              <strong>any notes you can make will be very helpful!</strong>
            </p>
          </div>

          <BottomBar />
        </div>,
      );

      steps.push(
        <div className="page generic-page" key={`${page}-step-5`}>
          <div className="main">
            <h3>
              ASAP Page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
            </h3>
            <h1>Comparison with Competitors:</h1>

            <p>What do you do differently (and better!) than most competitors in this area?</p>

            <Input
              type="textarea"
              className="mb-5"
              rows={4}
              value={get(tempBriefing, `info.asap_page_details.${pageIndex}.competitors`)}
              onChange={(e) => {
                const tempAsapPageDetails = cloneDeep(tempBriefing.info!.asap_page_details) || [];
                tempAsapPageDetails[pageIndex] ||= {};
                tempAsapPageDetails[pageIndex].competitors = e.target.value;
                setInfo('asap_page_details', tempAsapPageDetails);
              }}
            />

            <p>
              Just click NEXT if nothing comes to mind, but
              {' '}
              <strong>any notes you can make will be very helpful!</strong>
            </p>
          </div>

          <BottomBar />
        </div>,
      );

      steps.push(
        <div className="page generic-page" key={`${page}-step-6`}>
          <div className="main">
            <h3>
              ASAP Page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
            </h3>
            <h1>Your Passion and Drive:</h1>

            <p>What gets you excited about offering this product or service?</p>

            <Input
              type="textarea"
              className="mb-5"
              rows={4}
              value={get(tempBriefing, `info.asap_page_details.${pageIndex}.passion`)}
              onChange={(e) => {
                const tempAsapPageDetails = cloneDeep(tempBriefing.info!.asap_page_details) || [];
                tempAsapPageDetails[pageIndex] ||= {};
                tempAsapPageDetails[pageIndex].passion = e.target.value;
                setInfo('asap_page_details', tempAsapPageDetails);
              }}
            />

            <p>
              Just click NEXT if nothing comes to mind, but
              {' '}
              <strong>any notes you can make will be very helpful!</strong>
            </p>
          </div>

          <BottomBar />
        </div>,
      );

      steps.push(
        <div className="page generic-page" key={`${page}-step-7`}>
          <div className="main">
            <h3>
              ASAP Page
              {' '}
              {pageIndex + 1}
              :
              {' '}
              {page}
            </h3>
            <h1>
              Google Keywords
            </h1>
            <p>
              Here are some keyword phrases that may apply to help this page get found on Google. Untick any that are
              not relevant:
            </p>

            {map(get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.ASAPPageGoogleKeywords`, []), (keyword, keywordIndex) => (
              <div className="form-check mb-3" key={keywordIndex}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="googleKeyword"
                  id={`asap_google_keywords_${pageIndex}_${keywordIndex}`}
                  checked={get(tempBriefing, `info.asap_google_keywords_selected.${pageIndex}`, []).includes(keyword)}
                  onChange={(e) => {
                    const tempAsapGoogleKeywordsSelected = cloneDeep(tempBriefing.info!.asap_google_keywords_selected) || [];
                    if ((e.target as HTMLInputElement).checked) {
                      tempAsapGoogleKeywordsSelected[pageIndex] ||= [];
                      tempAsapGoogleKeywordsSelected[pageIndex].push(keyword);
                    } else {
                      tempAsapGoogleKeywordsSelected[pageIndex].splice(tempAsapGoogleKeywordsSelected[pageIndex].indexOf(keyword), 1);
                    }
                    setInfo('asap_google_keywords_selected', tempAsapGoogleKeywordsSelected);
                  }}
                />
                <label className="form-check-label" htmlFor={`asap_google_keywords_${pageIndex}_${keywordIndex}`}>{keyword}</label>
              </div>
            ))}
            <p>List any other potentially relevant keywords you can think of:</p>
            <Input
              type="textarea"
              className="mb-5"
              rows={4}
              placeholder="Optional (skip if you prefer)"
              value={get(tempBriefing, `info.asap_page_details.${pageIndex}.other_keywords`)}
              onChange={(e) => {
                const tempAsapPageDetails = cloneDeep(tempBriefing.info!.asap_page_details) || [];
                tempAsapPageDetails[pageIndex] ||= {};
                tempAsapPageDetails[pageIndex].other_keywords = e.target.value;
                setInfo('asap_page_details', tempAsapPageDetails);
              }}
            />
          </div>
          <BottomBar />
        </div>,
      );
    });
  }

  steps.push(
    <div className="page generic-page your-business-story">
      <div className="main">
        <h1>Your business story</h1>

        <p>
          Your business story, which will be featured on your “About Us” page is vital for building trust and connection with potential customers, helping visitors understand who you are, what sets you apart and why they should choose you over competitors.
        </p>

        <p>
          Tell us about the history of the business and how it started:
        </p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder=""
          value={typeof tempBriefing.info.business_story === 'undefined' ? tempBriefing.ai_content?.DraftBusinessStory : tempBriefing.info.business_story}
          onChange={(e) => setInfo('business_story', e.target.value)}
        />
      </div>
      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page">
      <div className="main">
        <h1>Your business story</h1>

        <p>
          Tell us about how the business looks today:
        </p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder=""
          value={typeof tempBriefing.info.business_looks_today === 'undefined' ? tempBriefing.ai_content?.DraftBusinessToday : tempBriefing.info.business_looks_today}
          onChange={(e) => setInfo('business_looks_today', e.target.value)}
        />
      </div>
      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page">
      <div className="main">
        <h1>Your business story</h1>

        <p>
          How do you approach a typical job or customer?
        </p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder=""
          value={tempBriefing.info.business_approach}
          onChange={(e) => setInfo('business_approach', e.target.value)}
        />
      </div>
      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page owner-profiles">
      <div className="main">
        <h1>Your business story</h1>

        <p>
          Tell us a little about your (ie the business owner&apos;s) background and how you gained the experience to run this business?
        </p>

        {/* owner[2] == true means they have been added by the client during the BDQ. */}
        {get(tempBriefing, 'info.business_owners', []).map((owner, ownerIndex) => (
          <>
            {(owner[0] || owner[1] || owner[2]) // React needs a "continue"...
              ? (
                  <div key={ownerIndex}>
                    {owner?.[2] && (
                      <>
                        <Input
                          type="text"
                          className="mb-5 d-inline-block"
                          style={{ maxWidth: 'calc(50% - 10px)' }}
                          placeholder="First name"
                          value={owner[0]}
                          onChange={(e) => {
                            const tempBusinessOwners = cloneDeep(tempBriefing.info!.business_owners) || [];
                            tempBusinessOwners[ownerIndex][0] = e.target.value;
                            setInfo('business_owners', tempBusinessOwners);
                          }}
                        />
                        {' '}
                        <Input
                          type="text"
                          className="mb-5 d-inline-block"
                          style={{ maxWidth: 'calc(50% - 10px)' }}
                          placeholder="Last name"
                          value={owner[1]}
                          onChange={(e) => {
                            const tempBusinessOwners = cloneDeep(tempBriefing.info!.business_owners) || [];
                            tempBusinessOwners[ownerIndex][1] = e.target.value;
                            setInfo('business_owners', tempBusinessOwners);
                          }}
                        />
                      </>
                    )}
                    {(!isEmpty(owner?.[0]) || !isEmpty(owner?.[1])) && (
                      <p>
                        <strong>
                          Profile for
                          {' '}
                          {owner[0]}
                          {' '}
                          {owner[1]}
                          :
                        </strong>
                      </p>
                    )}
                    <Input
                      type="textarea"
                      rows={4}
                      className="mb-5"
                      placeholder=""
                      value={get(tempBriefing, 'info.business_owner_background', [])[ownerIndex]}
                      onChange={(e) => {
                        const tempBusinessOwners = cloneDeep(tempBriefing.info!.business_owner_background) || [];
                        tempBusinessOwners[ownerIndex] = e.target.value;
                        setInfo('business_owner_background', tempBusinessOwners);
                      }}
                    />
                  </div>
                )
              : null}
          </>
        ))}

        <button
          type="button"
          className="btn btn-primary mb-5"
          onClick={() => {
            const tempBusinessOwners = cloneDeep(tempBriefing.info!.business_owners) || [];
            tempBusinessOwners.push(['', '', true]);
            setInfo('business_owners', tempBusinessOwners);
          }}
        >
          + Add another person to profile
        </button>
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page">
      <div className="main">
        <h1>Your business story</h1>

        <p>
          Which other team members need to be featured on the About page of your website? (Leave blank if not applicable).
        </p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder=""
          value={tempBriefing.info.other_team_members}
          onChange={(e) => setInfo('other_team_members', e.target.value)}
        />
      </div>
      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-client-job-satisfaction">
      <div className="main">
        <h1>Client and Job Satisfaction</h1>

        <p>
          What is it that drives your job satisfaction in your business? Here are some possibilities. Untick any which
          are not relevant and add any others:
        </p>

        {map(get(tempBriefing, 'ai_content.JobSatisfactionOptions'), (item, itemIndex) => (
          <div className="form-check mb-3" key={itemIndex}>
            <input
              className="form-check-input"
              type="checkbox"
              name="jobSatisfaction"
              id={`job_satisfaction_${itemIndex}`}
              checked={get(tempBriefing, 'info.job_satisfaction_selected', []).includes(item)}
              onChange={(e) => {
                const tempJobSatisfactionSelected = cloneDeep(tempBriefing.info!.job_satisfaction_selected) || [];
                if ((e.target as HTMLInputElement).checked) {
                  tempJobSatisfactionSelected.push(item);
                } else {
                  tempJobSatisfactionSelected.splice(tempJobSatisfactionSelected.indexOf(item), 1);
                }
                setInfo('job_satisfaction_selected', tempJobSatisfactionSelected);
              }}
            />
            <label className="form-check-label" htmlFor={`job_satisfaction_${itemIndex}`}>
              {item}
            </label>
          </div>
        ))}

        <p>
          Other:
        </p>

        <Input
          type="textarea"
          rows={4}
          className="mb-5"
          placeholder="Optional (skip if you prefer)"
          value={tempBriefing.info.job_satisfaction_other}
          onChange={(e) => setInfo('job_satisfaction_other', e.target.value)}
        />
      </div>
      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-business-details">
      <div className="main">
        <h1>Business Details</h1>

        <p>Here we gather some more useful facts about your business. Firstly...</p>

        <p>How many members are in your team, including you?</p>

        <div className="d-flex align-items-center mb-5">
          <button
            type="button"
            className="btn btn-outline-secondary px-3"
            onClick={() => {
              const currentValue = parseInt(tempBriefing.info!.team_count || '0', 10);
              if (currentValue > 1) {
                setInfo('team_count', (currentValue - 1).toString());
              }
            }}
          >
            <i className="fal fa-minus" />
          </button>

          <Input
            type="number"
            className="mx-3 text-center"
            style={{ maxWidth: '100px' }}
            min="1"
            value={tempBriefing.info.team_count || '1'}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              setInfo('team_count', value < 1 ? '1' : e.target.value);
            }}
          />

          <button
            type="button"
            className="btn btn-outline-secondary px-3"
            onClick={() => {
              const currentValue = parseInt(tempBriefing.info!.team_count || '0', 10);
              setInfo('team_count', (currentValue + 1).toString());
            }}
          >
            <i className="fal fa-plus" />
          </button>
        </div>
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page">
      <div className="main">
        <h1>Business Details</h1>

        <p>Do you have a fleet of vehicles?</p>

        <div className="form-check mb-3 d-flex align-items-center gap-2">
          <input
            className="form-check-input"
            type="radio"
            name="haveVehiclesNo"
            id="haveVehiclesYes"
            checked={tempBriefing.info.have_vehicles === 'yes'}
            onChange={() => setInfo('have_vehicles', 'yes')}
          />
          <label className="form-check-label mr-2" htmlFor="haveVehiclesYes">
            Yes
          </label>
        </div>

        {tempBriefing.info.have_vehicles === 'yes' && (
          <div className="ms-5">
            <p>
              How many?
            </p>
            <div className="ms-5 mb-3">
              <div className="d-flex align-items-center]">
                <button
                  type="button"
                  className="btn btn-outline-secondary px-3"
                  onClick={() => {
                    const currentValue = parseInt(tempBriefing.info!.vehicles_count || '0', 10);
                    if (currentValue > 1) {
                      setInfo('vehicles_count', (currentValue - 1).toString());
                    }
                  }}
                >
                  <i className="fal fa-minus" />
                </button>

                <Input
                  type="number"
                  className="mx-3 text-center"
                  style={{ maxWidth: '100px' }}
                  min="1"
                  value={tempBriefing.info.vehicles_count || '1'}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    setInfo('vehicles_count', value < 1 ? '1' : e.target.value);
                  }}
                />

                <button
                  type="button"
                  className="btn btn-outline-secondary px-3"
                  onClick={() => {
                    const currentValue = parseInt(tempBriefing.info!.vehicles_count || '0', 10);
                    setInfo('vehicles_count', (currentValue + 1).toString());
                  }}
                >
                  <i className="fal fa-plus" />
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="c"
            id="haveVehiclesNo"
            checked={tempBriefing.info.have_vehicles === 'no'}
            onChange={() => setInfo('have_vehicles', 'no')}
          />
          <label className="form-check-label" htmlFor="haveVehiclesNo">
            No
          </label>
        </div>

      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-geographical-boundaries">
      <div className="main">
        <h1>Business Details</h1>

        <p>What are your geographical servicing boundaries (if applicable)?</p>

        <div className="mb-3">
          <Input
            type="textarea"
            rows={4}
            autoFocus
            placeholder="Please detail"
            value={tempBriefing.info.geographical_boundaries}
            onChange={(e) => setInfo('geographical_boundaries', e.target.value)}
          />
        </div>
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-refer-to-team">
      <div className="main">
        <h1>Business Details</h1>

        <p>What are your hours of operation?</p>

        <div>
          {days.map((day) => {
            const isChecked = get(tempBriefing, `info.hours_of_operation`, defaultDays).includes(day);
            return (
              <div className="form-check mb-3" key={day}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`hours_${day}`}
                  checked={isChecked}
                  onChange={() => {
                    const tempHoursOfOperation = cloneDeep(tempBriefing.info!.hours_of_operation) || defaultDays;
                    if (isChecked) {
                      tempHoursOfOperation.splice(tempHoursOfOperation.indexOf(day), 1);
                    } else {
                      tempHoursOfOperation.push(day);
                    }
                    setInfo('hours_of_operation', tempHoursOfOperation);
                  }}
                />
                <label className="form-check-label" htmlFor={`hours_${day}`}>
                  {day}
                </label>
              </div>
            );
          })}
        </div>

        <div className="row mb-3">
          <div className="col-6">
            <label>
              Start
            </label>
            <Input
              type="select"
              value={get(tempBriefing, 'info.hours_of_operation_start', '') || '8'}
              onChange={(e) => {
                setInfo('hours_of_operation_start', e.target.value);
              }}
            >
              <option value="">Select</option>
              {[...Array(48).keys()].map((index) => {
                const value = index / 2;
                const hour = Math.floor(value);
                const minutes = value % 1 === 0 ? '00' : '30';
                return (
                  <option key={index} value={value}>
                    {hour}
                    :
                    {minutes}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="col-6">
            <label>
              End
            </label>
            <Input
              type="select"
              value={get(tempBriefing, 'info.hours_of_operation_end', '') || '17'}
              onChange={(e) => {
                setInfo('hours_of_operation_end', e.target.value);
              }}
            >
              <option value="">Select</option>
              {[...Array(48).keys()].map((index) => {
                const value = index / 2;
                const hour = Math.floor(value);
                const minutes = value % 1 === 0 ? '00' : '30';
                return (
                  <option key={index} value={value}>
                    {hour}
                    :
                    {minutes}
                  </option>
                );
              })}
            </Input>
          </div>
        </div>
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page">
      <div className="main">
        <h1>Business Details</h1>

        <p>What Methods of Payment Do You Accept?</p>

        {map(paymentMethods, (method) => (
          <div className="form-check mb-3" key={method}>
            <input
              className="form-check-input"
              type="checkbox"
              name="paymentMethods"
              id={`paymentMethods_${method}`}
              checked={get(tempBriefing, 'info.payment_methods', []).includes(method)}
              onChange={(e) => {
                const tempPaymentMethods = cloneDeep(tempBriefing.info!.payment_methods) || [];
                if ((e.target as HTMLInputElement).checked) {
                  tempPaymentMethods.push(method);
                } else {
                  tempPaymentMethods.splice(tempPaymentMethods.indexOf(method), 1);
                }
                setInfo('payment_methods', tempPaymentMethods);
              }}
            />
            <label className="form-check-label" htmlFor={`paymentMethods_${method}`}>
              {method}
            </label>
          </div>
        ))}

        {tempBriefing.info.payment_methods?.includes('Other') && (
          <div className="ms-5 mb-3">
            <Input
              type="textarea"
              rows={4}
              autoFocus
              placeholder="Please detail"
              className="ml-4"
              value={tempBriefing.info.payment_methods_other}
              onChange={(e) => setInfo('payment_methods_other', e.target.value)}
            />
          </div>
        )}
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-guarantees">
      <div className="main">
        <h1>Business Details</h1>

        <p>Do You Provide Any Specific Guarantees?</p>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="guarantees"
            id="guaranteesNo"
            checked={tempBriefing.info.provides_guarantees === 'no'}
            onChange={() => setInfo('provides_guarantees', 'no')}
          />
          <label className="form-check-label" htmlFor="guaranteesNo">
            No
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="guarantees"
            id="guaranteesYes"
            checked={tempBriefing.info.provides_guarantees === 'yes'}
            onChange={() => setInfo('provides_guarantees', 'yes')}
          />
          <label className="form-check-label" htmlFor="guaranteesYes">
            Yes
          </label>
        </div>

        {tempBriefing.info.provides_guarantees === 'yes' && (
          <div className="ms-5 mb-3">
            <Input
              type="textarea"
              rows={4}
              autoFocus
              placeholder="Please detail"
              value={tempBriefing.info.guarantees_details}
              onChange={(e) => setInfo('guarantees_details', e.target.value)}
            />
          </div>
        )}
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-guarantees">
      <div className="main">
        <h1>Business Details</h1>

        <p>Have you won any business or industry awards?</p>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="businessAwards"
            id="businessAwardsNo"
            checked={tempBriefing.info.business_awards === 'no'}
            onChange={() => setInfo('business_awards', 'no')}
          />
          <label className="form-check-label" htmlFor="businessAwardsNo">
            No
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="businessAwards"
            id="businessAwardsYes"
            checked={tempBriefing.info.business_awards === 'yes'}
            onChange={() => setInfo('business_awards', 'yes')}
          />
          <label className="form-check-label" htmlFor="businessAwardsYes">
            Yes
          </label>
        </div>

        {tempBriefing.info.business_awards === 'yes' && (
          <div className="ms-5 mb-3">
            <Input
              type="textarea"
              rows={4}
              autoFocus
              placeholder="Please detail"
              value={tempBriefing.info.business_awards_details}
              onChange={(e) => setInfo('business_awards_details', e.target.value)}
            />
          </div>
        )}
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-guarantees">
      <div className="main">
        <h1>Business Details</h1>

        <p>Are you a member of any industry or professional associations?</p>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="memberOfAssociations"
            id="memberOfAssociationsNo"
            checked={tempBriefing.info.member_of_association === 'no'}
            onChange={() => setInfo('member_of_association', 'no')}
          />
          <label className="form-check-label" htmlFor="memberOfAssociationsNo">
            No
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="memberOfAssociations"
            id="memberOfAssociationsYes"
            checked={tempBriefing.info.member_of_association === 'yes'}
            onChange={() => setInfo('member_of_association', 'yes')}
          />
          <label className="form-check-label" htmlFor="memberOfAssociationsYes">
            Yes
          </label>
        </div>

        {tempBriefing.info.member_of_association === 'yes' && (
          <div className="ms-5 mb-3">
            <Input
              type="textarea"
              rows={4}
              autoFocus
              placeholder="Please detail"
              value={tempBriefing.info.member_of_association_details}
              onChange={(e) => setInfo('member_of_association_details', e.target.value)}
            />
          </div>
        )}
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page page-guarantees">
      <div className="main">
        <h1>Business Details</h1>

        <p>Does your business provide any sponsorship in the community or for sports teams etc</p>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="sponsorship"
            id="sponsorshipNo"
            checked={tempBriefing.info.provides_sponsorship === 'no'}
            onChange={() => setInfo('provides_sponsorship', 'no')}
          />
          <label className="form-check-label" htmlFor="sponsorshipNo">
            No
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="radio"
            name="sponsorship"
            id="sponsorshipYes"
            checked={tempBriefing.info.provides_sponsorship === 'yes'}
            onChange={() => setInfo('provides_sponsorship', 'yes')}
          />
          <label className="form-check-label" htmlFor="sponsorshipYes">
            Yes
          </label>
        </div>

        {tempBriefing.info.provides_sponsorship === 'yes' && (
          <div className="ms-5 mb-3">
            <Input
              type="textarea"
              rows={4}
              autoFocus
              placeholder="Please detail"
              value={tempBriefing.info.provides_sponsorship_details}
              onChange={(e) => setInfo('provides_sponsorship_details', e.target.value)}
            />
          </div>
        )}
      </div>

      <BottomBar />
    </div>,
  );

  steps.push(
    <div className="page generic-page">
      <div className="main">
        <h1>Business Details</h1>

        <p>
          Lastly, please tell us anything else about your business story that you think may be relevant:
        </p>

        <Input
          type="textarea"
          id="additionalInformationAboutBusinessStory"
          rows={4}
          placeholder="Optional (skip if you prefer)"
          value={tempBriefing.info.additional_information}
          onChange={(e) => setInfo('additional_information', e.target.value)}
        />
      </div>
      <br />

      <div className="bottom-bar d-flex justify-content-center mt-3">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={async () => {
            await updateBriefing({
              ...tempBriefing,
              bdq_completed: true,
            });

            setCurrentStep(currentStep + 1);
          }}
        >
          COMPLETE QUIZ
        </button>
      </div>
    </div>,
  );

  steps.push(
    <div className="page generic-page">
      <div className="main">
        Thanks!
      </div>
    </div>,
  );

  return (
    <Form className="bdq">
      <div className="scroll-container">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`page-container ${index === currentStep - 1 ? 'active' : ''}`}
            data-page-number={index + 1}
          >
            {step}
          </div>
        ))}
      </div>
    </Form>
  );
}

export default withRouter(BDQ);
