import React, { useState, SyntheticEvent } from 'react';
import { isEmpty, filter } from 'lodash-es';
import FullScreenSpinner from '../../FullScreenSpinner';
import { createStore } from '../../../rootStore';
import Section from 'common/models/Section';

const constantLabels = ['text', 'checkboxes', 'radio_button', 'file_upload'];

type InputType = {
  [key: string]: string;
};

const inputType: InputType = {
  single_line_text: 'text',
  multi_line_text: 'textarea',
  short_text: 'text',
  long_text: 'textarea',
  radio_button: 'radio',
  checkboxes: 'checkbox',
  hidden: 'hidden',
  numeric: 'number',
  date: 'date',
  url: 'url',
  email: 'email',
};

const escapeHtml = (str: string): string => str
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&#039;');

interface SectionFormProps {
  section: Section;
  onClick: (section: Section) => void;
}

const SectionForm: React.FC<SectionFormProps> = ({ section, onClick }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const store = createStore();
  const { pageData: { site } } = store.getState();

  const nameForCheckboxRadioLabel = (item: any, formItemType: string): string => {
    if (formItemType === 'checkboxes') {
      return `${item.id}[]`;
    }
    return item.id;
  };

  const onSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const form = (e.target as HTMLElement).closest('form') as HTMLFormElement;

    if (!form.reportValidity()) {
      alert('Please fill out all required fields.');
      return;
    }

    setSubmitting(true);

    const data = new FormData(form);
    const recaptcha = form.querySelector<HTMLInputElement>('[name=g-recaptcha-response]');
    if (recaptcha) {
      const token = recaptcha.value;
      data.append('g-recaptcha-response', token);
    }

    try {
      const response = await fetch('/api/v1/after_form_submit', {
        method: 'POST',
        body: data,
      });

      setSubmitting(false);

      if (response.ok) {
        const result = await response.json();
        if (result.data.length !== 0) {
          if (result.data.dialog_box_message) {
            alert(result.data.dialog_box_message);
          }

          if (result.data.url) {
            window.top!.location.href = result.data.url;
          }

          if (!result.data.dialog_box_message && !result.data.url) {
            alert('Thank you.\nYour message has been sent.');
          }
        }
        form.reset();
      } else {
        const errorData = await response.json();
        form.insertAdjacentHTML('afterbegin', `<div class='alert alert-danger'>${errorData.messages}</div>`);
      }
    } catch (error: any) {
      setSubmitting(false);
      form.insertAdjacentHTML('afterbegin', `<div class='alert alert-danger'>An error occurred: ${error.message}</div>`);
    }
  };

  const buttonHtml = (): JSX.Element => {
    if (section.submit_button_type === 'button_style' && section.button_style_id) {
      return (
        <span data-button-style-id={section.button_style_id}>
          <button
            onClick={onSubmit}
          >
            {section.button_text}
          </button>
        </span>
      );
    }
    if (section.submit_button_type === 'image' && section.button_image_id) {
      return (
        <>
          <input
            type="image"
            onClick={onSubmit}
            src={`${section.button_image_url}`}
            className="original-src"
            alt="Submit Form"
          />
          {section.button_hover_image_id && (
            <input
              type="image"
              onClick={onSubmit}
              src={`${section.button_hover_image_url}`}
              className="hover-image"
              alt="Submit Form"
            />
          )}
        </>
      );
    }

    return (
      <input
        type="submit"
        value={`${section.button_text}`}
        onClick={onSubmit}
      />
    );
  };

  const formSubmit = (): JSX.Element => <div className={`form-submit-${section?.id}`}>{buttonHtml()}</div>;

  const dbFieldType = (type: string): string => ({
    select_list: 'drop_down_list',
    radio_button: 'radio_button',
    checkboxes: 'checkboxes',
    long_text: 'multi_line_text',
  }[type] || 'single_line_text');

  const formItems = filter(section.form_items, (item: any) => item.section_form_id === section.id).map((item: any) => {
    const showLabel = (section.field_label_position === 'above_field'
      && item.type !== 'hidden')
      || (section.field_label_position === 'inside_field'
        && constantLabels.includes(item.type));

    const formItemType = item.database_field
      ? dbFieldType(item.database_field?.field_type)
      : item.type;
    const placeHolder = section.field_label_position === 'inside_field' ? item.name : '';
    const requiredField = (section.add_to_database && item.database_field?.field_type === 'email') || item.is_required;

    const formItemTextType = item.database_field
      ? item.database_field?.field_type
      : item.type;
    const formItemInputType = inputType[formItemTextType];

    const addClosingTagForMultiple = (item: any, formItemType: string, requiredField: boolean): JSX.Element => {
      const values = item.database_field
        ? item.database_field.values
        : item.values;

      switch (formItemType) {
        case 'checkboxes':
        case 'radio_button':
          return (
            <div>
              {values.map((value: string, index: number) => {
                const id = `form-item-${item.id}-${index}`;

                return (
                  <div
                    key={value}
                    className={`form-check ${item.break_values_in_newline ? 'form-check-inline' : ''}`}
                  >
                    <input
                      className={`form-check-input ${requiredField ? 'required' : ''}`}
                      type={inputType[formItemType]}
                      name={nameForCheckboxRadioLabel(item, formItemType)}
                      id={id}
                      value={value}
                    />
                    <label className="form-check-label" htmlFor={id}>
                      {value}
                    </label>
                  </div>
                );
              })}
              {item.other_option && (
                <div
                  className={`form-check ${item.break_values_in_newline ? 'form-check-inline' : ''}`}
                >
                  <input
                    className={`form-check-input ${requiredField ? 'required' : ''}`}
                    type={inputType[formItemType]}
                    name={nameForCheckboxRadioLabel(item, formItemType)}
                    id={`form-item-${item.id}-other`}
                    value="Other"
                  />
                  <label className="form-check-label" htmlFor={`form-item-${item.id}-other`}>
                    Other
                  </label>
                </div>
              )}
            </div>
          );
        case 'drop_down_list':
          return (
            <select name={item.id} className="form-control">
              {section.field_label_position === 'inside_field' && (
                <option value="" selected disabled hidden>
                  {escapeHtml(item.name)}
                </option>
              )}
              {values.map((e: string) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          );
        case 'text':
          return values.map((e: string) => (
            <span key={e}>
              {e}
              <br />
            </span>
          ));
        default:
          return <></>;
      }
    };

    const renderFormItemInput = (): JSX.Element => {
      switch (formItemType) {
        case 'single_line_text':
          return (
            <input
              type={formItemInputType}
              name={item.id}
              className="form-control"
              placeholder={placeHolder}
              required={requiredField}
            />
          );
        case 'multi_line_text':
          return (
            <textarea
              name={item.id}
              className="form-control"
              placeholder={placeHolder}
              required={requiredField}
            />
          );
        case 'hidden':
          return (
            <input type="hidden" name={item.id} value={item.default_value} />
          );
        case 'file_upload':
          return (
            <input
              id={item.id}
              name={`[file][${item.id}][]`}
              type="file"
              className="form-control"
              multiple
            />
          );
        case 'date':
          return (
            <input
              type="date"
              name={item.id}
              className="form-control"
              required={requiredField}
            />
          );
        default:
          return addClosingTagForMultiple(item, formItemType, true);
      }
    };

    return (
      <div key={item.id} className={`form-item-${item.id}`}>
        {showLabel && (
          <div className="form-label">
            <label>
              {item.name}
              {requiredField && <span className="requiredMark">*</span>}
            </label>
          </div>
        )}
        <div className="form-item-value">{renderFormItemInput()}</div>
      </div>
    );
  });

  return (
    <>
      {submitting && <FullScreenSpinner />}
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionForm bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{ display: (section.initial_visibility || window.wg.env === 'dashboard') ? '' : 'none' }}
        onClick={() => onClick(section)}
      >
        <form
          method="POST"
          action="/api/v1/after_form_submit"
          encType="multipart/form-data"
          name={`form${section.id}`}
          id={`form${section.id}`}
          className={`form-section labels-${section.field_label_position}`}
          onSubmit={onSubmit}
        >
          <input type="hidden" name="formId" value={section.id} />
          {formItems}
          {formSubmit()}
        </form>
        <div className="privacy-policy-link tb">
          <a href="/?Action=PrivPol" id={`privacy-policy-${section.id}`} target="_blank">
            Privacy Policy
          </a>
          {!isEmpty(site?.recaptcha_site_key) && (
            <>
              {' '}
              | This site is protected by reCAPTCHA and the Google
              {' '}
              <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              {' '}
              and
              {' '}
              <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                Terms of Service
              </a>
              {' '}
              apply.
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default SectionForm;
