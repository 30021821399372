import React, { useState } from 'react';
import { replacePathParams, messageCenterRoutes, settingRoutes } from '../../../constants/routes';
import { Row, Col, Card, CardBody } from 'reactstrap';
import CustomButton from '../../../components/common/button';
import { get, isEmpty } from 'lodash-es';
import confirm from '../../../components/common/confirm';
import HeaderComponent from '../../../components/common/header-component';
import BottomActionToolbar from '../../../components/common/toolbar';
import { useParams } from 'react-router';
import EmailProof from '../email-proof';
import EmailPreview from '../../email-preview';
import withRouter from '../../../helpers/withRouter';
import {
  useEmailPageCreateMutation,
  useEmailPageDeleteMutation,
  useEmailPageDuplicateMutation,
  useGetEmailsQuery,
} from '../../../api/dashboardApiSlice';
import { useSite } from '../../../../../common/hooks';
import { displaySuccess } from 'Dashboard/src/Utils';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function EmailPages(props) {
  const params = useParams();
  const { instanceId } = params;
  const [modal, setModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const site = useSite();
  const siteId = !isEmpty(site) ? site?.value : null;
  const { data: emailPages = [] } = useGetEmailsQuery({ instanceId }, { skip: isEmpty(instanceId) });
  const [duplicateEmailPage] = useEmailPageDuplicateMutation();
  const [createEmailPage] = useEmailPageCreateMutation();
  const [deleteEmailPage] = useEmailPageDeleteMutation();

  const onHandleAddEmailPage = async () => {
    const sendParams = {
      type: 'email',
      site_id: siteId,
      instance_id: instanceId,
    };
    const res = await createEmailPage(sendParams);
    if (res && res?.data?.meta?.is_success) {
      displaySuccess(res?.data?.meta?.messages);
    }
  };

  const onHandleDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const sendParams = {
        id,
        site_id: siteId,
        instance_id: instanceId,
      };
      const res = await deleteEmailPage(sendParams);
      if (res && res?.data?.meta?.is_success) {
        displaySuccess(res?.data?.meta?.messages);
      }
    }
  };

  const onHandleEdit = (id, pageVersionId) => {
    const { navigate } = props;
    navigate(replacePathParams(messageCenterRoutes.messageCenterEmailPage, [
      { key: 'pageId', value: id },
      { key: 'pageVersionId', value: pageVersionId },
      { key: 'pageType', value: 'email' },
    ], props));
  };

  const onHandleDuplicate = async (id) => {
    const sendParams = {
      id,
      instance_id: instanceId,
    };
    const res = await duplicateEmailPage(sendParams);
    if (res && res?.data?.meta?.is_success) {
      displaySuccess(res?.data?.meta?.messages);
    }
  };

  const onHandleProof = (id, subject) => {
    setModalDetails({ id, subject });
    setModal('proof');
  };

  const onHandlePreview = (id) => {
    setModalDetails({ id });
    setModal('preview');
  };

  const toggle = () => {
    setModal(null);
  };

  const tableColumns = [
    {
      accessorKey: 'page_version.name',
      header: 'Page Name',
    },
    {
      header: 'Proof',
      id: 'proof',
      cell: ({ row }) => (
        <CustomButton
          disabled={isEmpty(get(row, 'original.page_version.template_id', true))}
          size="xs"
          title=" "
          color="primary"
          icon="fal fa-inbox-out"
          className="custom-simple-icon"
          onClick={() => onHandleProof(row.original.id, row.original.page_version.default_subject)}
        />
      ),
    },
    {
      header: 'Preview',
      id: 'preview',
      cell: ({ row }) => (
        <CustomButton
          size="xs"
          title=" "
          color="primary"
          icon="far fa-eye"
          className="custom-simple-icon"
          onClick={() => onHandlePreview(row.original.id)}
        />
      ),
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <>
          <CustomButton
            size="xs"
            title=" "
            color="primary"
            icon="fal fa-pen"
            className="custom-simple-icon"
            onClick={() => onHandleEdit(row.original.id, row.original.page_version.id)}
          />
          {' '}
          <CustomButton
            size="xs"
            title=" "
            color="primary"
            icon="fal fa-copy"
            className="custom-simple-icon"
            onClick={() => onHandleDuplicate(row.original.id)}
          />
          {' '}
          <CustomButton
            size="xs"
            title=" "
            color="danger"
            icon="fal fa-trash"
            className="custom-simple-icon"
            style={{ background: '#dc3545' }}
            onClick={() => onHandleDelete(row.original.id)}
          />
        </>
      ),
    },
  ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Message Centre', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Message Centre',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: messageCenterRoutes.messageCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mt-3 mb-4 rounded">
        <CardBody>
          <Row className="text-center">
            <Col lg={12} className="mb-2">
              <h2 className="fw-bold">Emails</h2>
            </Col>
          </Row>
          <hr />
          <ReactTable8
            columns={tableColumns}
            data={emailPages}
          />
        </CardBody>
      </Card>
      {modal === 'proof' && (
        <EmailProof
          pageId={modalDetails.id}
          subject={modalDetails.subject}
          closeModal={toggle}
          instanceId={instanceId}
        />
      )}
      {modal === 'preview' && (
        <EmailPreview
          id={modalDetails.id}
          closeModal={toggle}
          instanceId={instanceId}
        />
      )}
      <BottomActionToolbar
        component={(
          <CustomButton
            color="success"
            className="common-success-button button-md fw-bold"
            title="ADD"
            onClick={() => onHandleAddEmailPage()}
          />
        )}
      />
    </>
  );
}

export default withRouter(EmailPages);
