import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Col,
  Row,
} from 'reactstrap';
import DialogBox from '../../../../PublicFacingSite/Components/DialogBox/index';
import { useDashboardSupportFormMutation } from '../../api/dashboardApiSlice';
import { useInstance, useUser } from 'common/hooks';
import './index.css';

interface FormData {
  name: string;
  email: string;
  phone: string;
  details: string;
};

const DashboardSupportForm: React.FC = () => {
  const user = useUser();
  const instance = useInstance();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    details: '',
  });

  useEffect(() => {
    if (user && !formData.name && !formData.email) {
      setFormData(prev => ({
        ...prev,
        name: user.full_name || '',
        email: user.email || '',
      }));
    }
  }, [user]);

  const [dashboardSupport, { isLoading, isSuccess, isError, error }] = useDashboardSupportFormMutation();

  if (!user?.hasFeature('dashboard_support_form')) {
    return null;
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await dashboardSupport({
        instance_id: instance?.id,
        ...formData,
      });
    } catch (err) {
      console.error('Error submitting support form:', err);
    }
  };

  const formContent = (
    <div className="dashboard-support-form">
      <p>
        Before reaching out, check out our
        {' '}
        <a
          href="https://www.webgenius.co.nz/cms-training"
          target="_blank"
          rel="noopener noreferrer"
        >
          How-To Video
        </a>
        {' '}
        for step-by-step guidance on using the Web Genius CMS.
        <br />
        <br />
        If you&apos;re still experiencing issues, our support team is ready to assist. Simply fill out the form below,
        and we&apos;ll get back to you as soon as possible. Please note that support requests are covered under your 1 hour
        of included Support time per calendar month. Once this time is fully utilised, any additional support will be
        chargeable.
      </p>

      {isSuccess && (
        <Alert color="success">
          Your message has been sent successfully! We&apos;ll get back to you shortly.
        </Alert>
      )}

      {isError && (
        <Alert color="danger">
          {error?.data?.message || 'An error occurred while submitting the form. Please try again later.'}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="email">Email address</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="phone">Phone number</Label>
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <Label for="details">Details</Label>
          <Input
            type="textarea"
            id="details"
            name="details"
            rows={5}
            value={formData.details}
            onChange={handleInputChange}
            required
          />
        </FormGroup>

        <Row>
          <Col>
            <img src="/assets/images/logo-mobile.png" />
          </Col>
          <Col>
            <FormGroup className="text-end">
              <Button
                type="submit"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
            </FormGroup>
          </Col>
        </Row>

      </Form>
    </div>
  );

  return (
    <>
      <DialogBox
        open={isOpen}
        toggle={setIsOpen}
        content={formContent}
        header="Need assistance? We're here to help!"
        size="lg"
      />

      <Button
        className="dashboard-support-button"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        <span>Need help?</span>
      </Button>
    </>
  );
};

export default DashboardSupportForm;
