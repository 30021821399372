import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useGetInstanceByIdQuery, useGetSiteByIdQuery, useGetUserProfileQuery } from '../api/commonApiSlice';
import { getCookie } from '../../Utils';
import { RootState } from '../../rootStore';
import Instance from 'common/models/Instance';
import User from 'common/models/User';
import Site from 'common/models/Site';

export function useInstance(): Instance | null {
  const [instance, setInstance] = useState<Instance | null>(null);
  const params = useParams();
  const getInstanceId = getCookie('INSTANCE_ID');
  const publicInstance = useSelector((state: RootState) => state.pageData?.instance);
  const instanceId = params?.instanceId || getInstanceId || publicInstance?.id || '';

  const { data } = useGetInstanceByIdQuery(instanceId, { skip: window.wg.env === 'public' || isEmpty(instanceId) });

  useEffect(() => {
    if (window.wg.env === 'public' && publicInstance) {
      setInstance(publicInstance);
    } else if (data) {
      setInstance(data);
    }
  }, [publicInstance, data]);

  return instance;
}

export function useSite(): Site | null {
  const [site, setSite] = useState<Site | null>(null);
  const params = useParams();
  const getSiteId = getCookie('SITE_ID');
  const publicSite = useSelector((state: RootState) => state.pageData?.site);
  const siteId = params?.siteId || getSiteId || publicSite?.id || '';

  const { data } = useGetSiteByIdQuery(siteId, { skip: window.wg.env === 'public' || isEmpty(siteId) });

  useEffect(() => {
    if (window.wg.env === 'public' && publicSite) {
      setSite(publicSite);
    } else if (data) {
      setSite(data);
    }
  }, [publicSite, data]);

  return site;
}

export function useFireRenderEvent() {
  useEffect(() => {
    document.dispatchEvent(new Event('render'));
  });
}

// This is a handy function which makes all buttons in the component the same width.
export function useButtonsMax() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let maxWidth = 0;
    const buttons = ref.current.querySelectorAll('button');

    if (buttons) {
      buttons.forEach((button) => {
        const width = getComputedStyle(button).width;
        const parsedWidth = parseFloat(width);

        if (parsedWidth > maxWidth) {
          maxWidth = parsedWidth;
        }
      });

      buttons.forEach((button) => {
        button.style.width = `${maxWidth}px`;
      });
    }
  });

  return ref;
}

export function useUser() {
  const { data } = useGetUserProfileQuery({}) as { data: User };

  return data;
}

// This hook loads the user profile, but only if the wg_admin cookie is not set to 0. It returns the user object if the
// user is logged into the admin dashboard, or false otherwise.
export function useUserConditional() {
  const skip = document.cookie.match(/wg_admin=0/) ? true : false;
  const data = useGetUserProfileQuery({}, { skip }) as { status: string; data: User };

  if (skip || data.status === 'rejected') {
    return false;
  }

  return data.data;
}

export function useIsMobile() {
  const { width } = useSelector((state: RootState) => state.windowSize);
  return width < 768;
};

// This hook adds a class to the <html> element when the component is mounted, and removes it when the component is unmounted.
export function usePageClassName(className: string) {
  useEffect(() => {
    document.documentElement.classList.add(className);

    return () => {
      document.documentElement.classList.remove(className);
    };
  }, []);
};
