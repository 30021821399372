import Base from './Base';

interface Attributes {
  db_field?: DBField[];
}

interface DBField {
  id?:              string;
  field_name?:      string;
  field_type?:      string;
  position?:        number;
  db_field_values?: string[];
  value?:           Array<number | string>;
  short_name?:      string;
  default?:         boolean;
  is_special?:      boolean;
}

export default class Member extends Base {
  id?:         string;
  type?:       string;
  attributes?: Attributes;
  macros?:     { [key: string]: string }; // This is not a database field, it only exists in the public-facing context.

  constructor(props) {
    super(props);
    Object.assign(this, props);
  }
}
