import React, { useEffect, useState } from 'react';
import {
  Card,
  CardColumns,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import BottomActionToolbar from '../../../components/common/toolbar';
import {
  SiteDisplayName,
  TimeZone,
  OrientationStatement,
  MediaSelectBox,
  DefaultFormEmailAddresses,
  DefaultFormEmailSubject,
  DefaultFormMobileNumber,
  UnsubscribePage,
  MasterPageSelectBox,
  EcwidStoreID,
} from '../../../components/fields/site';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import withRouter from '../../../helpers/withRouter';
import { useUpdateSiteMutation } from '../../../api/dashboardApiSlice';
import UserFeatureComponent from 'Dashboard/src/components/common/user-feature-component';
import { useInstance, useSite } from '../../../../../common/hooks';
import { get } from 'lodash-es';
import { addRecentItem } from 'Dashboard/src/Utils';
import './EditSite.scss';
function SiteSettingGeneral(props) {
  const [params, setParams] = useState({});
  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);

  const [updateSite] = useUpdateSiteMutation();

  useEffect(() => {
    if (site) {
      setParams(site);
    }
  }, [site]);

  const onSave = () => {
    updateSite({
      id: siteId,
      instance_id: instanceId,
      ...params,
    });

    return true;
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'settings-general',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'General', url: '' },
  ];

  return (
    <div className="EditSite">
      <HeaderComponent
        setPath={{
          headingName: 'General',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.siteList,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <CardColumns>
        <Card>
          <CardBody>
            <Container>
              <UserFeatureComponent feature="general_site_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <SiteDisplayName params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="general_site_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <TimeZone params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="general_site_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <OrientationStatement params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="general_site_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <FormGroup>
                      <Label>Favourite Icon</Label>
                      <MediaSelectBox
                        value={params?.favicon_id}
                        onchange={(e) => setParams({ ...params, favicon_id: e ? e.value : null })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="set_default_form_email_addresses">
                <DefaultFormEmailAddresses params={params} setParams={setParams} siteId={siteId} />
              </UserFeatureComponent>
              <UserFeatureComponent feature="set_default_form_subject">
                <Row>
                  <Col lg={12} md={12}>
                    <DefaultFormEmailSubject params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="set_default_form_email_addresses">
                <Row>
                  <Col lg={12} md={12}>
                    <DefaultFormMobileNumber params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="general_site_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <Label>
                      Site Map Master Page
                    </Label>
                    <MasterPageSelectBox
                      siteId={siteId}
                      params={params}
                      setParams={setParams}
                      instanceId={instanceId}
                      keyName="site_map_master_page_id"
                    />
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="general_site_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <UnsubscribePage params={params} setParams={setParams} siteId={siteId} instanceId={instanceId} />
                  </Col>
                </Row>
              </UserFeatureComponent>
              <UserFeatureComponent feature="general_site_settings">
                <Row>
                  <Col lg={12} md={12}>
                    <EcwidStoreID params={params} setParams={setParams} />
                  </Col>
                </Row>
              </UserFeatureComponent>
            </Container>
          </CardBody>
        </Card>
      </CardColumns>

      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onSave}
            onContinue={replacePathParams(settingRoutes.siteList, [], props)}
          />
        )}
      />
    </div>
  );
}

export default withRouter(SiteSettingGeneral);
