import React, { useState, useEffect } from 'react';
import {
  Container, Row, Card, CardTitle, Form, Label, Input,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { setPageTitle, setCookie } from '../../../../Utils';
import CustomButton from '../../components/common/button';
import IntlMessages from '../../helpers/IntlMessages';
import { Colxx } from '../../components/common/CustomBootstrap';
import './index.scss';
import withRouter from '../../helpers/withRouter';
import GoogleLogin from './GoogleLogin';
import { useLoginUserEmailMutation, useLoginUserGoogleMutation } from '../../api/dashboardApiSlice';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [userLoginEmail] = useLoginUserEmailMutation();
  const [userLoginGoogle] = useLoginUserGoogleMutation();

  useEffect(() => {
    setPageTitle('Login');
  }, []);

  const onUserLogin = async (e) => {
    e.preventDefault();
    const sendParams = { email, password };
    if (email !== '' && password !== '') {
      const response = await userLoginEmail({ user: sendParams });
      if (response) {
        setCookie('USER_TOKEN', response.data.data.token, 9999, '/');
        window.location = '/';
      }
    }
  };

  const onHandleSuccessResponse = async (e) => {
    const { credential } = e;
    const sendParams = { token_id: credential };

    const response = await userLoginGoogle(sendParams);
    if (response) {
      setCookie('USER_TOKEN', response.data.data.token, 9999, '/');
      window.location = '/';
    }
  };

  return (
    <Container className="h-100 Login">
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side-login">
              <div className="login-image login-image-1" />
              <div className="login-image login-image-2" />
              <div className="login-image login-image-3" />
            </div>
            <div className="form-side">
              <NavLink to="/" className="white">
                <span className="logo-single mb-3" />
              </NavLink>
              <CardTitle className="mb-4">
                <h2>Log In</h2>
              </CardTitle>
              <Form onSubmit={onUserLogin}>
                <Label className="form-group has-float-label mb-4">
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                  />
                  <IntlMessages id="user.email" />
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <IntlMessages
                    id="user.password"
                    defaultValue={password}
                  />
                </Label>
                <Label className="has-float-label text-end">
                  <NavLink to="/user/forgot-password">
                    <IntlMessages id="user.forgot-password-question" />
                  </NavLink>
                </Label>
                <div className="d-flex align-items-center justify-content-center mb-2">
                  <CustomButton
                    color="info"
                    className="common-info-button button-lg fw-bold"
                    title="LOG IN"
                    onClick={onUserLogin}
                  />
                </div>
                <div className="login-divider d-flex align-items-center justify-content-center">
                  <span>OR</span>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2 mb-3">
                  <GoogleLogin onGoogleSignIn={onHandleSuccessResponse} />
                </div>
              </Form>
            </div>
          </Card>
        </Colxx>
      </Row>
    </Container>
  );
}

export default withRouter(Login);
