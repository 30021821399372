import React, { ReactNode } from 'react';
import { ErrorBoundary as ErrorBoundaryBase } from 'react-error-boundary';
import PropTypes from 'prop-types';
import CustomButton from '../../components/common/button';
import { useCreateErrorLogMutation } from '../../api/dashboardApiSlice';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const fallbackRender = ({ error: { message, stack } }: { error: { message: string; stack: string } }) => (
    <div className="d-flex align-items-center justify-content-center">
      <section className="error_section text-center" style={{ width: '50%' }}>
        <h1 style={{ fontSize: '10em', fontWeight: 700 }}>Oops!</h1>
        <h2>Sorry, something went wrong.</h2>
        <CustomButton
          color="info"
          onClick={() => window.location.reload()}
          className="common-info-button button-lg rounded fw-normal"
          title="RELOAD THIS PAGE"
        />
        <details
          style={{
            whiteSpace: 'pre-wrap',
            textAlign: 'left',
          }}
          className="mt-3"
        >
          {message}
          <br />
          {stack}
        </details>
      </section>
    </div>
  );

  const [createErrorLog] = useCreateErrorLogMutation();

  const logError = async (error: Error) => {
    await createErrorLog({
      error_message: error.message,
      error_type: error.name,
      stack: error.stack,
      url: window.location.href,
    });
  };

  return (
    <ErrorBoundaryBase fallbackRender={fallbackRender} onError={logError}>
      {children}
    </ErrorBoundaryBase>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
