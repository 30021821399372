import React from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from 'Dashboard/src/constants/routes';
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
} from 'reactstrap';
import { addRecentItem } from 'Dashboard/src/Utils';
import HeaderComponent from '../../components/common/header-component';
import confirm from '../../components/common/confirm';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import {
  useCreateContentBriefingMutation,
  useGetContentBriefingsQuery,
  useDeleteContentBriefingMutation,
} from 'Dashboard/src/api/dashboardApiSlice';
import ContentBriefing from 'common/models/ContentBriefing';
import BottomActionToolbar from '../../components/common/toolbar';
import CustomButton from '../../components/common/button';
import { dateTimeString } from '../../components/common/DateTimeFormat';

function ContentCentre(props) {
  const { navigate } = props;
  const params = useParams();
  const { instanceId, siteId } = params;
  const { data: cbs } = useGetContentBriefingsQuery();

  const [createContentBriefing] = useCreateContentBriefingMutation();
  const [deleteContentBriefing] = useDeleteContentBriefingMutation();

  addRecentItem({
    instance_id: instanceId,
    site_id: siteId,
    type: 'content-center',
  });

  const addContentBriefing = async () => {
    const result = await createContentBriefing(new ContentBriefing({}));
    onEdit(result.data.data);
  };

  const onEdit = (cb: ContentBriefing) => {
    const url = replacePathParams(contentCenterRoutes.contentBriefing, [
      {
        key: 'id',
        value: cb.id as string,
      },
    ], props);

    navigate(url);
  };

  const onDelete = async (cb: ContentBriefing) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      deleteContentBriefing(cb);
    }
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Content Centre', url: '' },
  ];

  if (!cbs) {
    return <></>;
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Content Centre',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: statsCenterRoutes.contentCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mt-3 mb-4 rounded">
        <CardBody>
          <CardTitle className="mb-4">
            Content Briefings
          </CardTitle>

          <Table>
            <thead>
              <tr>
                <th>Business Name</th>
                <th>Created</th>
                <th>Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cbs.map((cb: ContentBriefing) => (
                <tr key={cb.id}>
                  <td>
                    {cb.business_name}
                  </td>
                  <td>
                    {dateTimeString(cb.created_at)}
                  </td>
                  <td>
                    {dateTimeString(cb.updated_at)}
                  </td>
                  <td>
                    <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEdit(cb)}>
                      <i className="fal fa-pen" />
                    </Button>
                    {' '}
                    <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onDelete(cb)}>
                      <i className="fal fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <BottomActionToolbar
        component={(
          <CustomButton
            color="success"
            className="common-success-button button-md fw-bold"
            title="ADD"
            onClick={() => addContentBriefing()}
          />
        )}
      />
    </>
  );
}

export default withRouter(ContentCentre);
