import React from 'react';
import { FrameBoxProps, ImagePreviewProps } from 'common/models/Section';
import DialogBox from 'PublicFacingSite/Components/DialogBox';

export function FrameBox({ url, modal, toggle }: FrameBoxProps) {
  return (
    <DialogBox
      size="xl"
      open={modal}
      toggle={toggle}
      header=" "
      content={<iframe src={url} id="framebox" width="100%" />}
    />
  );
}

export function ImagePreview({ item, modal, toggle }: ImagePreviewProps) {
  return (
    <DialogBox
      size="xl"
      open={modal}
      toggle={toggle}
      content={(
        <img
          src={item.image_url}
          alt={item.alt || 'Image'}
          width={item.width_click_to_zoom ?? undefined}
          style={{ maxWidth: '100%' }}
        />
      )}
      className="text-center"
    />
  );
}
