import React, { useEffect, useState } from 'react';
import { filter, isEmpty, map } from 'lodash-es';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import webSafeFontOptions from 'common/schema/webSafeFontOptions';
import CustomSelect from '../custom-select';
import { components } from 'react-select';
import { getSelectedOption, selectBoxOptions, selectColorStyles } from '../../../../../Utils';
import { addGoogleFont } from 'Dashboard/src/Utils';
import withRouter from '../../../helpers/withRouter';
import BreakPoint from '../breakpoint';
import { useGetButtonStylesQuery, useGetCustomFontsQuery, useGetTextStylesQuery } from '../../../api/dashboardApiSlice';
import { preview as buttonPreview } from 'common/api/button';
import { useSite } from '../../../../../common/hooks';
import { get } from 'lodash-es';

const setFontStyleOptions = [
  { value: 'normal', label: 'Normal' },
  { value: 'italic', label: 'Italic' },
];

const unitTypes = [
  { value: 'px', label: 'Pixels' },
  { value: '%', label: 'Percentage' },
];

const fontWeights = [
  { label: '100', value: 100 },
  { label: '200', value: 200 },
  { label: '300', value: 300 },
  { label: '400 (normal)', value: 400 },
  { label: '500', value: 500 },
  { label: '600', value: 600 },
  { label: '700 (bold)', value: 700 },
  { label: '800', value: 800 },
  { label: '900', value: 900 },
];

const borderStyleOptions = [
  { label: 'None', value: 'none' },
  { label: 'Hidden', value: 'hidden' },
  { label: 'Dotted', value: 'dotted' },
  { label: 'Dashed', value: 'dashed' },
  { label: 'Solid', value: 'solid' },
  { label: 'Double', value: 'double' },
  { label: 'Groove', value: 'groove' },
  { label: 'Ridge', value: 'ridge' },
  { label: 'Inset', value: 'inset' },
  { label: 'Outset', value: 'outset' },
];

const iconSideOptions = [
  { label: 'Left', value: 'left' },
  { label: 'Right', value: 'right' },
];

const templateTypeOptions = [
  { value: 'web', label: 'Web' },
  { value: 'email', label: 'Email' },
];

const textAlignOptions = [
  { value: 'left', label: 'Left' },
  { value: 'center', label: 'Centre' },
  { value: 'right', label: 'Right' },
  { value: 'justify', label: 'Justify' },
];

const HTMLTagOptions = [
  { value: 'span', label: '<span>' },
  { value: 'div', label: '<div>' },
  { value: 'h1', label: '<h1>' },
  { value: 'h2', label: '<h2>' },
  { value: 'h3', label: '<h3>' },
  { value: 'h4', label: '<h4>' },
  { value: 'h5', label: '<h5>' },
  { value: 'h6', label: '<h6>' },
];

const textDecorationStyleOptions = [
  { label: 'underline', value: 'underline' },
  { label: 'line-through', value: 'line-through' },
  { label: 'overline', value: 'overline' },
  { label: 'solid', value: 'solid' },
  { label: 'double', value: 'double' },
  { label: 'dotted', value: 'dotted' },
  { label: 'dashed', value: 'dashed' },
  { label: 'wavy', value: 'wavy' },
];

const backgroundPositionsX = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'right' },
];

const backgroundPositionsY = [
  { label: 'Top', value: 'top' },
  { label: 'Center', value: 'center' },
  { label: 'Bottom', value: 'bottom' },
];

const backgroundSize = [
  { label: 'Auto', value: 'auto' },
  { label: 'Contain', value: 'contain' },
  { label: 'Cover', value: 'cover' },
  { label: 'Inherit', value: 'inherit' },
  { label: 'Initial', value: 'initial' },
  { label: 'Revert', value: 'revert' },
  { label: 'Unset', value: 'unset' },
];

const backgroundRepeat = [
  { label: 'Inherit', value: 'inherit' },
  { label: 'Initial', value: 'initial' },
  { label: 'No repeat', value: 'no-repeat' },
  { label: 'Repeat horizontally (repeat-x)', value: 'repeat-x' },
  { label: 'Repeat vertically (repeat-y)', value: 'repeat-y' },
  { label: 'Repeat both directions', value: 'repeat' },
  { label: 'Revert', value: 'revert' },
  { label: 'Round', value: 'round' },
  { label: 'Space', value: 'space' },
  { label: 'Unset', value: 'unset' },
];

const displayOptions = [
  { label: 'block', value: 'block' },
  { label: 'inline', value: 'inline' },
  { label: 'inline-block', value: 'inline-block' },
  { label: 'flex', value: 'flex' },
  { label: 'inline-flex', value: 'inline-flex' },
  { label: 'grid', value: 'grid' },
  { label: 'inline-grid', value: 'inline-grid' },
  { label: 'flow-root', value: 'flow-root' },
  { label: 'none', value: 'none' },
  { label: 'contents', value: 'contents' },
  { label: 'table', value: 'table' },
  { label: 'table-row', value: 'table-row' },
  { label: 'list-item', value: 'list-item' },
];

const gFonts = (f) => map(f, (item) => (
  { value: item.name, label: item.name, variants: item.variants }
));

export const renderBreakpoint = (params) => {
  const {
    id,
    siteId,
    onChange,
    breakPoint,
    objectType,
    isEmailPage,
    settingName,
    activeDeviceBtn,
    showSettingType = true,
  } = params;
  return (
    <BreakPoint
      objectId={id}
      siteId={siteId}
      objectType={objectType}
      breakpoint={breakPoint}
      isEmailPage={isEmailPage}
      settingName={settingName}
      activeDeviceBtn={activeDeviceBtn}
      showSettingType={showSettingType}
      handleSaveBreakpoint={(bps) => onChange(bps)}
    />
  );
};

export const removeDuplicateValues = (arr) => {
  const getArr = arr.reduce((unique, o) => {
    if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
      unique.push(o);
    }
    return unique;
  }, []);
  return filter(getArr, (o) => (o.value));
};

export const filterVariants = (variants) => {
  const googleFontVariant = map(variants, (weight) => {
    if (weight === 'light') {
      return { value: '300', label: '300' };
    }

    if (weight === 'regular' || weight === 'normal') {
      return { value: '400', label: '400' };
    }

    if (weight === 'bold') {
      return { value: '700', label: '700' };
    }

    const fWeight = weight.replace(/[^\d.-]/g, '');
    return { value: fWeight, label: fWeight };
  });
  return removeDuplicateValues(googleFontVariant);
};

export function Fonts(props) {
  const {
    templateType, onChange, font, keyName,
  } = props;
  const { data: fonts } = useGetCustomFontsQuery();

  const fontOptions = templateType === 'web' && fonts
    ? gFonts(
      [...webSafeFontOptions, ...fonts],
    )
    : gFonts(webSafeFontOptions);

  return (
    <CustomSelect
      {...props}
      onChange={onChange}
      options={fontOptions}
      styles={selectColorStyles}
      value={getSelectedOption(fontOptions, font, keyName)}
    />
  );
}

export function FallbackFonts(props) {
  const { onChange, value } = props;
  return (
    <CustomSelect
      {...props}
      onChange={onChange}
      styles={selectColorStyles}
      options={gFonts(webSafeFontOptions)}
      value={getSelectedOption(gFonts(webSafeFontOptions), value)}
    />
  );
}

export function FontStyles(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={setFontStyleOptions}
      value={getSelectedOption(setFontStyleOptions, value)}
    />
  );
}

export function FontWeight(params) {
  const { onChange, value, fontName } = params;
  const { data: fonts } = useGetCustomFontsQuery();

  const getFontWeightOptions = (fName) => {
    if (isEmpty(fName)) {
      return fontWeights;
    }
    const getData = filter(fonts, (el) => el.name === fName);
    const checkFontWeight = !isEmpty(getData) ? filterVariants(getData[0].variants) : fontWeights;
    return checkFontWeight;
  };

  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={getFontWeightOptions(fontName)}
      value={getSelectedOption(getFontWeightOptions(fontName), value)}
    />
  );
}

export function TextTransform(params) {
  const { onChange, value } = params;
  const options = [
    { label: 'none', value: 'none' },
    { label: 'capitalize', value: 'capitalize' },
    { label: 'uppercase', value: 'uppercase' },
    { label: 'lowercase', value: 'lowercase' },
  ];

  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={options}
      value={getSelectedOption(options, value)}
    />
  );
}

export function CSSUnitType(params) {
  const {
    onChange, value, excludeUnit, disabled,
  } = params;
  const [unitDropdownOpen, setUnitDropdownOpen] = useState(false);

  const getUnitTypes = (units) => {
    if (!isEmpty(excludeUnit)) {
      return filter(units, (e) => !excludeUnit.includes(e.value));
    }
    return units;
  };

  return (
    <ButtonDropdown
      disabled={disabled}
      isOpen={unitDropdownOpen}
      toggle={() => setUnitDropdownOpen(!unitDropdownOpen)}
    >
      <DropdownToggle
        caret
        color="primary"
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      >
        {value}
      </DropdownToggle>
      <DropdownMenu>
        {map(getUnitTypes(unitTypes), (unit) => (
          <DropdownItem onClick={() => onChange(unit.value)} key={unit.value}>
            {unit.value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export function BorderStyles(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={borderStyleOptions}
      value={getSelectedOption(borderStyleOptions, value)}
    />
  );
}

export function IconSide(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={iconSideOptions}
      value={getSelectedOption(iconSideOptions, value)}
    />
  );
}

export function TemplateTypes(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={templateTypeOptions}
      value={getSelectedOption(templateTypeOptions, value)}
    />
  );
}

export function TextAlignStyle(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={textAlignOptions}
      value={getSelectedOption(textAlignOptions, value)}
    />
  );
}

export function HTMLTags(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={HTMLTagOptions}
      value={getSelectedOption(HTMLTagOptions, value)}
    />
  );
}

export function TextDecorationStyle(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={textDecorationStyleOptions}
      value={getSelectedOption(textDecorationStyleOptions, value)}
    />
  );
}

export function FontSize(params) {
  const { onChange, value, templateType } = params;
  if (templateType === 'web') {
    return renderBreakpoint(params);
  }
  return (
    <Input
      {...params}
      min={1}
      step={0.1}
      type="number"
      name="font_size"
      value={value}
      onChange={(e) => onChange(e)}
      styles={selectColorStyles}
    />
  );
}

export function BackgroundSize(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={backgroundSize}
      value={getSelectedOption(backgroundSize, value)}
    />
  );
}

export function BackgroundPositionX(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={backgroundPositionsX}
      value={getSelectedOption(backgroundPositionsX, value)}
    />
  );
}

export function BackgroundPositionY(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={backgroundPositionsY}
      value={getSelectedOption(backgroundPositionsY, value)}
    />
  );
}

export function BackgroundRepeat(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={backgroundRepeat}
      value={getSelectedOption(backgroundRepeat, value)}
    />
  );
}

export function DisplayStyles(params) {
  const { onChange, value } = params;
  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={displayOptions}
      value={getSelectedOption(displayOptions, value)}
    />
  );
}

export function ButtonStylesDropdown(params) {
  const { onChange, value, siteId } = params;
  const { data } = useGetButtonStylesQuery(siteId);
  const styleOptions = selectBoxOptions(data, 'name', 'id');
  const site = useSite();

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        {buttonPreview(props.data, get(site, 'colors', []))}
      </components.Option>
    );
  };

  return (
    <CustomSelect
      {...params}
      onChange={onChange}
      styles={selectColorStyles}
      options={styleOptions}
      components={{ Option: CustomOption }}
      value={getSelectedOption(styleOptions, value)}
    />
  );
}

function TextStyleListing(props) {
  const { onChange, params: { pageType = 'web' }, style } = props;
  const site = useSite();
  const { data: textStyleList } = useGetTextStylesQuery(site?.id, { skip: isEmpty(site?.id) });

  useEffect(() => {
    if (!isEmpty(textStyleList)) {
      addGoogleFont(map(textStyleList, (e) => ({
        fontName: e.fonts,
        fontWeight: e.font_weight,
      })));
    }
  }, [textStyleList]);

  const options = selectBoxOptions(textStyleList, 'name', 'id').filter((e) => e.type === pageType);

  return (
    <CustomSelect
      {...props}
      onChange={onChange}
      styles={selectColorStyles}
      value={getSelectedOption(options, style)}
      options={options}
    />
  );
}

export default withRouter(TextStyleListing);
