import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  Input,
  Label,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
  InputGroup,
  InputGroupText,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from 'reactstrap';
import classnames from 'classnames';
import {
  get, isEmpty, map, omit,
} from 'lodash-es';
import Switch from 'rc-switch';
import {
  getDomainName,
  generateURL,
  getPageType,
  getSelectedOption,
} from '../../../../../Utils';
import {
  displayError,
  displayInfo,
  displaySuccess,
} from 'Dashboard/src/Utils';
import confirm from '../../../components/common/confirm';
import CustomSelect from '../../../components/common/custom-select';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import './index.scss';
import { replacePathParams, siteCenter, messageCenterRoutes } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import {
  PopupVisibility,
  PopupFrequency,
  PopupPage,
  PopupStyle,
  PopupTrigger,
  PopupWidth,
} from '../../../components/fields/common';
import {
  useEmailPageUpdateMutation, useGetMasterPagesQuery, useGetTemplatesQuery, useLoadLinkToolQuery,
  usePageUpdateMutation,
  usePageVersionUpdateMutation,
} from '../../../api/dashboardApiSlice';
import { getPageHierarchy, getPageSections } from '../../../components/common/PageHierarchy';
import { Radio } from '../../../components/common/inputFields';
import { filterTemplateOption } from './PageEdit';
import SectionBox from '../../../components/fields/page/SectionBox';
import CustomTooltip from '../../../components/common/CustomTooltip';
import PageMetaTags from './PageMetaTags';
import { useSite, useUser } from '../../../../../common/hooks';
import { DateTime } from 'luxon';
import { MediaSelectBox } from 'Dashboard/src/components/fields/site';
import CreatableSelect from 'react-select/creatable';

const fmt = 'yyyy-LL-dd\'T\'HH:mm';

const advancedSettingOptions = (isEmailPage) => {
  const duplicateOption = {
    label: 'Duplicate Page',
    value: 'duplicate_page',
  };

  const pageUrlOption = {
    label: 'Page URL',
    value: 'page_url',
  };

  if (isEmailPage) {
    return (
      [duplicateOption]
    );
  }

  return (
    [
      pageUrlOption,
      duplicateOption,
    ]
  );
};

const redirectPageOptions = [
  { label: 'None', value: '' },
  { label: 'A page on your site', value: 'page_on_your_site' },
  { label: 'A section on a page on your site', value: 'section_on_page_on_your_site' },
  { label: 'A page on another site', value: 'page_on_another_site' },
];

export const formatGroupLabel = (data) => {
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  return (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
};

export const getTemplateOptions = (type, masterPages, getTemplates) => {
  if (type === 'email') {
    return [{ label: 'Templates', options: getTemplates }];
  }
  return [
    { label: 'Master Pages', options: masterPages },
    { label: 'Templates', options: getTemplates },
  ];
};

export const getDefaultTemplate = (selectedOption, masterPages, getTemplates) => {
  let data = getTemplates.filter((option) => option.value === selectedOption);
  if (data.length === 0) {
    data = masterPages.filter((option) => option.value === selectedOption);
  }
  return data;
};

function PageEditForm(props) {
  const {
    url,
    previewUrl,
    page_version,
    showMasterSection,
    onAdvancedSettingChange,
    params: { instanceId, siteId, pageId },
  } = props;

  const site = useSite();
  const user = useUser();

  const [currentUrl, setCurrentUrl] = useState('');
  const [titleChange, setTitleChange] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editingUrl, setEditingUrl] = useState(false);
  const [checkedMasterSection, setCheckedMasterSection] = useState('fa fa-square-o');
  const [activeTab, setActiveTab] = useState('general');
  const [redirectPageTo, setRedirectPageTo] = useState('');
  const [pageVersion, setPageVersion] = useState(page_version || {});
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [page, setPage] = useState(props?.page || {});
  const [showSEOWarnings, setShowSEOWarnings] = useState([]);

  const { data: masterPages } = useGetMasterPagesQuery({ siteId, instanceId }, { skip: isEmpty(instanceId) || isEmpty(siteId) });
  const { data } = useGetTemplatesQuery({ siteId, instanceId }, { skip: isEmpty(instanceId) || isEmpty(siteId) });
  const { data: linkToolData } = useLoadLinkToolQuery(instanceId, { skip: isEmpty(instanceId) });
  const [pageVersionUpdate] = usePageVersionUpdateMutation();
  const [pageUpdate] = usePageUpdateMutation();
  const [emailPageUpdate] = useEmailPageUpdateMutation();

  const oldUrl = get(url, 'url', '');

  const pageType = getPageType(page);

  const getTemplates = map(filterTemplateOption(data, pageType), (option) => (
    { value: option.id, label: option.name, type: 'template' }
  ));

  const masterPageOptions = () => {
    if (pageType !== 'master' && masterPages) {
      return map(masterPages, (option) => ({ value: option.id, label: option.name, type: 'master' }));
    }
    return [];
  };

  useEffect(() => {
    if (localStorage.getItem('showMasterSection') === null) {
      localStorage.setItem('showMasterSection', false);
    } else if (JSON.parse(localStorage.getItem('showMasterSection'))) {
      setCheckedMasterSection('fa fa-check-square-o');
    }
  }, []);

  useEffect(() => {
    if (page) {
      const template = page_version && page_version.template_id ? page_version?.template_id : page_version?.master_page_id;
      setSelectedTemplate(template);
      setCurrentUrl(get(url, 'url', ''));
    }
  }, [page, page_version, url]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setShowSEOWarnings([...showSEOWarnings, name]);

    if (oldUrl !== value && name === 'currentUrl') {
      const getValue = value.replace(/[^a-zA-Z0-9-]+/g, '');
      setTitleChange(true);
      setCurrentUrl(getValue);
    } else {
      setTitleChange(false);
      setCurrentUrl(oldUrl);
      setPageVersion({
        ...pageVersion,
        [name]: value,
      });
    }
  };

  const handleURLKeyUp = (e) => {
    if (e.key === 'Escape') {
      setEditingUrl(false);
      setCurrentUrl(oldUrl);
    } else if (e.key === 'Enter') {
      setEditingUrl(false);
    }
  };

  const callPageUpdateAPI = async (params, isEmailPage) => {
    if (isEmailPage) {
      params.id = params?.page_id;
      params = omit(
        params,
        [
          'url',
          'site_id',
          'is_offline',
          'is_in_nav',
          'page_title',
          'is_indexable',
          'is_in_sitemap',
          'is_url_update',
          'master_page_id',
          'version_number',
        ],
      );
      const res = await emailPageUpdate(params);
      if (res && res?.data?.meta?.is_success) {
        displaySuccess(res?.data?.meta?.messages);
      }
    } else {
      pageVersionUpdate(params);
      const res = await pageUpdate(params);
      if (res && res?.data?.meta?.is_success) {
        displaySuccess(res?.data?.meta?.messages);
      }
    }
  };

  const onUpdatePage = async () => {
    const isEmailPage = getPageType(page) === 'email';
    const getCurrentUrl = generateURL(currentUrl);

    const sendParams = {
      ...page,
      ...pageVersion,
      site_id: siteId,
      id: page_version.id,
      instance_id: instanceId,
      is_url_update: titleChange,
      url: titleChange ? getCurrentUrl : currentUrl,
    };

    try {
      if (titleChange) {
        const result = await confirm({
          title: <b>Confirm!</b>,
          message: `Are you sure you want to update? URL: ${getCurrentUrl}`,
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
        });
        sendParams.is_url_update = result;
        if (!result) {
          setCurrentUrl(oldUrl);
        }
        await callPageUpdateAPI(sendParams, isEmailPage);
      } else {
        await callPageUpdateAPI(sendParams, isEmailPage);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const handleTemplateChange = (e) => {
    const { value, type } = e;
    const isMaterTemplate = type === 'master';
    setSelectedTemplate(value);
    setPageVersion({
      ...pageVersion,
      template_id: isMaterTemplate ? null : value,
      master_page_id: isMaterTemplate ? value : null,
    });
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const onUrlEditClick = () => {
    setEditingUrl(!editingUrl);
    if (!editingUrl) {
      setTimeout(() => {
        document.getElementById('currentUrl').focus();
      }, 100);
    }
  };

  const onHandleSwitch = (e, name) => {
    setPage({
      ...page,
      [name]: e,
    });
  };

  const onHandleShowMasterSection = () => {
    if (JSON.parse(localStorage.getItem('showMasterSection'))) {
      setCheckedMasterSection('fa fa-square-o');
      localStorage.setItem('showMasterSection', false);
    } else {
      setCheckedMasterSection('fa fa-check-square-o');
      localStorage.setItem('showMasterSection', true);
    }
    showMasterSection(JSON.parse(localStorage.getItem('showMasterSection')));
  };

  const redirectionTargetView = () => (
    <Col className="mt-3 mb-3">
      <Label className="has-tooltip" id="currentTab">Redirection Target:</Label>
      <UncontrolledTooltip placement="right" target="currentTab" style={{ textAlign: 'left' }}>
        The &quot;New tab&quot; redirection target only applies when clicking links from the navigation menu on your site.
      </UncontrolledTooltip>
      <div>
        <Radio
          value={page.redirect_target === 'current' ? 'Current tab' : 'New tab'}
          options={['Current tab', 'New tab']}
          onChange={(e) => {
            setPage({
              ...page,
              redirect_target: e === 'Current tab' ? 'current' : 'new',
            });
          }}
        />
      </div>
    </Col>
  );

  const onPageChange = (e) => {
    if (e.value === pageId) {
      displayInfo('You can not redirect a page to itself.');
    } else {
      setPage({
        ...page,
        redirect_page_id: e.value,
        redirect_section_id: null,
      });
    }
  };

  if (!page || !page_version) {
    return <></>;
  }

  const tempRedirectPageTo = isEmpty(redirectPageTo)
    ? (
        page.redirect_section_id
          ? 'section_on_page_on_your_site'
          : (
              page.redirect_page_id
                ? 'page_on_your_site'
                : (
                    page.redirect_url ? 'page_on_another_site' : ''
                  )
            )
      )
    : redirectPageTo;

  const isEmailPage = pageType === 'email';
  const isMasterPage = pageType === 'master';
  const isBlogPage = pageType === 'blog';

  const pageURL = `https://${getDomainName(previewUrl)}/${currentUrl}`;
  const colSize = isEmailPage ? 4 : 6;
  const pageURLParts = pageURL.split(/\//);

  const pathParams = [
    { key: 'siteId', value: siteId },
    { key: 'instanceId', value: instanceId },
  ];

  let continueRoute;

  if (isEmailPage) {
    continueRoute = messageCenterRoutes.messageCenter;
  } else if (isBlogPage) {
    continueRoute = siteCenter.blogPosts;
  } else {
    continueRoute = siteCenter.pageList;
  }

  const handleTagChange = (newTags) => {
    const updatedTags = Array.isArray(newTags) ? newTags.map(tag => tag.value) : [];
    setPage({ ...page, blog_tags: updatedTags });
  };

  const onContinuePath = replacePathParams(continueRoute, pathParams);

  let pageData = linkToolData && linkToolData.filter((e) => e.option_value === 'section_on_page_on_your_site');

  if (!isEmpty(pageData)) {
    // Some sites have numeric IDs, and comparing with === doesn't work because the URL parameter is a string.

    pageData = pageData && pageData?.[0].sites.filter((site) => site.id == siteId)?.[0]?.pages;
  }

  const getPageOptions = getPageHierarchy(pageData, '');
  const getSections = getPageSections(pageData);

  return (
    <Card body className="rounded h-100 PageSetting">
      <Row className="mb-3">
        <Col>
          <h2>Page Settings</h2>
        </Col>
        <Col className="text-end">
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret className="custom-simple-txt-btn">
              <i className="fa fa-cog" />
            </DropdownToggle>
            <DropdownMenu end>
              {
                advancedSettingOptions(isEmailPage).map((item) => (
                  <DropdownItem
                    className="custom-cursor-pointer-draggable"
                    key={item.value}
                    onClick={() => onAdvancedSettingChange(item.value)}
                  >
                    {item.label}
                  </DropdownItem>

                ))
              }
              <button
                type="button"
                className="custom-cursor-pointer-draggable dropdown-item"
                onClick={() => onHandleShowMasterSection()}
              >
                <i className={checkedMasterSection} aria-hidden="true" />
                {' Show master sections'}
              </button>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'general' })}
            onClick={() => setActiveTab('general')}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'content' })}
            onClick={() => setActiveTab('content')}
          >
            Content
          </NavLink>
        </NavItem>
        {!isEmailPage && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'seo' })}
              onClick={() => setActiveTab('seo')}
            >
              SEO
            </NavLink>
          </NavItem>
        )}
        {!isEmailPage && !isBlogPage && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'redirection' })}
              onClick={() => setActiveTab('redirection')}
            >
              Redirection
            </NavLink>
          </NavItem>
        )}
        {!isEmailPage && user?.hasFeature('manage_page_popups') && !isBlogPage && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'popup' })}
              onClick={() => setActiveTab('popup')}
            >
              Popup
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="general">
          <CardBody className="ps-0 pe-0">
            <Form>
              <Row>
                <Col lg={colSize}>
                  {isBlogPage
                    ? (
                        <FormGroup>
                          <Label>Post Publish Date</Label>
                          <Input
                            type="datetime-local"
                            value={
                              page?.blog_publish_date
                                ? DateTime.fromISO(page?.blog_publish_date, { zone: 'utc' }).toLocal().toFormat(fmt)
                                : null
                            }
                            onChange={(e) =>
                              setPage({
                                ...page,
                                blog_publish_date: DateTime.fromFormat(e.target.value, fmt).toUTC().toISO(),
                              })}
                          />

                        </FormGroup>
                      )
                    : (
                        <FormGroup>
                          <Label for="page_name">Page Name</Label>
                          <Input
                            type="text"
                            name="name"
                            value={get(pageVersion, 'name', '')}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      )}
                </Col>
                {isEmailPage || isMasterPage
                  ? ''
                  : (
                      <Col lg={colSize}>
                        <FormGroup>
                          <Label for="page_title">
                            {isBlogPage ? 'Post ' : 'Page '}
                            Title
                          </Label>
                          <InputGroup>
                            <Input
                              type="text"
                              name="page_title"
                              value={get(pageVersion, 'page_title', '')}
                              onChange={handleChange}
                            />
                            {showSEOWarnings.includes('page_title') && (
                              <InputGroupText className="seo-warning">
                                <CustomTooltip name="SEOWarningGeneral">
                                  <i className="fa-solid fa-message-exclamation" />
                                </CustomTooltip>
                              </InputGroupText>
                            )}
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )}
                {isEmailPage
                && (
                  <Col lg={colSize}>
                    <FormGroup>
                      <Label>Default Subject</Label>
                      <Input
                        type="text"
                        name="default_subject"
                        value={get(pageVersion, 'default_subject', '')}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                )}
                {
                  isBlogPage
                  && (
                    <>
                      <Col>
                        <FormGroup>
                          <Label for="page_name">Post Author</Label>
                          <Input
                            type="text"
                            name="blog_author"
                            value={get(page, 'blog_author', '')}
                            onChange={(e) =>
                              setPage({
                                ...page,
                                blog_author: e.target.value,
                              })}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <Label for="page_name">Post Image</Label>
                        <MediaSelectBox
                          value={get(page, 'blog_post_image_id', null)}
                          onchange={(e) => {
                            setPage({
                              ...page,
                              blog_post_image_id: e ? e.value : null,
                            });
                          }}
                        />
                      </Col>
                    </>
                  )
                }
                {
                  isBlogPage && (
                    <Col lg={12}>
                      <FormGroup>
                        <Label for="page_name">Post Tags</Label>
                        <CreatableSelect
                          id="post_tags"
                          isMulti
                          isClearable={false}
                          options={site?.blog_tags.map(tag => ({ value: tag, label: tag }))}
                          value={page?.blog_tags.map(tag => ({ value: tag, label: tag }))}
                          onChange={handleTagChange}
                        />
                      </FormGroup>
                    </Col>
                  )
                }
                {!isEmailPage && (
                  <Col lg={12}>
                    <Label for="page_url">URL</Label>
                    <InputGroup className="align-items-center">
                      {!editingUrl
                        ? (
                            <a
                              href={pageURL}
                              className="custom-text-color-blue fw-bold page-url-field"
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                paddingBottom: '1px',
                              }}
                            >
                              {pageURL}
                            </a>
                          )
                        : (
                            <>
                              <a
                                href={pageURL}
                                className="custom-text-color-blue fw-bold page-url-field"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {`${pageURLParts[0]}//${pageURLParts[2]}/`}
                              </a>
                              <Input
                                type="text"
                                id="currentUrl"
                                name="currentUrl"
                                value={pageURLParts[3] || ''}
                                disabled={!editingUrl}
                                onChange={handleChange}
                                onKeyUp={handleURLKeyUp}
                                className="custom-text-color-blue"
                                style={{
                                  fontWeight: 'bold',
                                  padding: '0',
                                  margin: '0',
                                  borderTopWidth: '0',
                                  borderLeftWidth: '0',
                                  borderRightWidth: '0',
                                }}
                              />
                            </>
                          )}
                      <Button
                        className="ms-2 custom-simple-txt-btn"
                        onClick={() => onUrlEditClick()}
                      >
                        <i className={!editingUrl ? 'fa fa-pencil' : 'fa fa-times'} />
                      </Button>
                    </InputGroup>
                  </Col>
                )}
              </Row>
              {!isEmailPage
              && (
                <Row className="mt-3">
                  <Col>
                    <Label>
                      Is Online
                      <Switch
                        className="custom-switch custom-switch-primary"
                        checked={!get(page, 'is_offline', true)}
                        onChange={(e) => onHandleSwitch(!e, 'is_offline')}
                      />
                    </Label>
                  </Col>
                  {!isBlogPage && (
                    <Col>
                      <Label>
                        Is In Nav
                        <Switch
                          className="custom-switch custom-switch-primary"
                          checked={get(page, 'is_in_nav', true)}
                          onChange={(e) => onHandleSwitch(e, 'is_in_nav')}
                        />
                      </Label>
                    </Col>
                  )}
                  <Col>
                    <Label>
                      Is Indexable
                      <Switch
                        name="is_indexable"
                        className="custom-switch custom-switch-primary"
                        checked={get(page, 'is_indexable', true)}
                        onChange={(e) => onHandleSwitch(e, 'is_indexable')}
                      />
                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      Is In Sitemap
                      <Switch
                        name="is_in_sitemap"
                        className="custom-switch custom-switch-primary"
                        checked={get(page, 'is_in_sitemap', true)}
                        onChange={(e) => onHandleSwitch(e, 'is_in_sitemap')}
                      />
                    </Label>
                  </Col>
                  <Col>
                    <Label>
                      Back to Top
                      <Switch
                        name="back_to_top"
                        className="custom-switch custom-switch-primary"
                        checked={get(page, 'back_to_top', true)}
                        onChange={(e) => onHandleSwitch(e, 'back_to_top')}
                      />
                    </Label>
                  </Col>
                </Row>
              )}
            </Form>
          </CardBody>
        </TabPane>
        <TabPane tabId="content">
          <CardBody className="ps-0 pe-0">
            <Form>
              <Row>
                <Col lg={colSize}>
                  <FormGroup>
                    <Label>{isMasterPage || isEmailPage ? 'Template' : 'Template / Master Page'}</Label>
                    <CustomSelect
                      value={getDefaultTemplate(selectedTemplate, masterPageOptions(), getTemplates)}
                      placeholder="Please select template"
                      onChange={handleTemplateChange}
                      options={getTemplateOptions(pageType, masterPageOptions(), getTemplates)}
                      formatGroupLabel={formatGroupLabel}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {!isMasterPage && !isEmailPage && (
                <Row>
                  <Col lg={colSize}>
                    <PageMetaTags page={page} setPage={(p) => setPage({ ...p })} />
                  </Col>
                </Row>
              )}
            </Form>
          </CardBody>
        </TabPane>
        <TabPane tabId="seo">
          <CardBody className="ps-0 pe-0">
            <Form>
              <Label for="description">Page Description</Label>
              <InputGroup>
                <Input
                  type="textarea"
                  name="description"
                  value={get(pageVersion, 'description', '')}
                  onChange={handleChange}
                />
                {showSEOWarnings.includes('description') && (
                  <InputGroupText className="seo-warning">
                    <CustomTooltip name="SEOWarningGeneral">
                      <i className="fa-solid fa-message-exclamation" />
                    </CustomTooltip>
                  </InputGroupText>
                )}
              </InputGroup>
            </Form>
          </CardBody>
        </TabPane>

        <TabPane tabId="redirection">
          <CardBody className="ps-0 pe-0">
            <Row>
              <Col lg={colSize}>
                <FormGroup>
                  <Label>Redirect page to:</Label>
                  <CustomSelect
                    value={getSelectedOption(redirectPageOptions, tempRedirectPageTo)}
                    onChange={(e) => {
                      setRedirectPageTo(e.value);
                      if (isEmpty(e.value)) {
                        setPage({
                          ...page,
                          redirect_page_id: null,
                          redirect_section_id: null,
                          redirect_url: null,
                        });
                      } else if (e.value === 'page_on_your_site') {
                        setPage({
                          ...page,
                          redirect_section_id: null,
                          redirect_url: null,
                        });
                      } else if (e.value === 'section_on_page_on_your_site') {
                        setPage({
                          ...page,
                          redirect_url: null,
                        });
                      } else if (e.value === 'page_on_another_site') {
                        setPage({
                          ...page,
                          redirect_page_id: null,
                          redirect_section_id: null,
                        });
                      }
                    }}
                    options={redirectPageOptions}
                  />
                </FormGroup>
              </Col>
              {['page_on_your_site', 'section_on_page_on_your_site'].includes(tempRedirectPageTo) && (
                <>
                  <Col lg={12}>
                    <Row>
                      <Col>
                        <Label>Page:</Label>
                        <CustomSelect
                          value={getSelectedOption(getPageOptions, page.redirect_page_id)}
                          onChange={(e) => onPageChange(e, getSections)}
                          options={getPageOptions}
                        />
                      </Col>
                      {['section_on_page_on_your_site'].includes(tempRedirectPageTo) && (
                        <Col>
                          <Label>Section:</Label>
                          <SectionBox
                            params={props?.params}
                            isDisabled={isEmpty(page.redirect_page_id)}
                            menuPortalTarget={document.body}
                            selectedPageId={page.redirect_page_id}
                            onChange={(e) => setPage({
                              ...page,
                              redirect_section_id: e.value,
                            })}
                            value={page.redirect_section_id}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {redirectionTargetView()}
                </>
              )}
              {['page_on_another_site'].includes(tempRedirectPageTo) && (
                <>
                  <Col lg={12}>
                    <FormGroup>
                      <Label>URL:</Label>
                      <Input
                        type="url"
                        value={page.redirect_url}
                        onChange={(e) => {
                          setPage({
                            ...page,
                            redirect_url: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {redirectionTargetView()}
                </>
              )}
            </Row>
          </CardBody>
        </TabPane>

        <TabPane tabId="popup">
          <CardBody className="ps-0 pe-0">
            <Row>
              <Col lg={colSize}>
                <PopupPage
                  params={page}
                  siteId={siteId}
                  instanceId={instanceId}
                  setParams={(e) => {
                    setPage({
                      ...page,
                      ...e,
                      popup_options: {
                        frequency: 'once_per_visitor',
                        frame: true,
                        width: '400',
                        trigger: 'delay',
                        trigger_value: 0,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            {page.popup_page_id && (
              <>
                <Row>
                  <Col lg={colSize}>
                    <PopupVisibility
                      params={page}
                      setParams={(e) => {
                        setPage(e);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={colSize}>
                    <PopupFrequency
                      params={page}
                      setParams={(e) => {
                        setPage(e);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={colSize}>
                    <PopupStyle
                      params={page}
                      setParams={(e) => {
                        setPage(e);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={colSize}>
                    <PopupWidth
                      params={page}
                      setParams={(e) => {
                        setPage(e);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={colSize}>
                    <PopupTrigger
                      params={page}
                      setParams={(e) => {
                        setPage(e);
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </TabPane>
      </TabContent>
      <Row>
        <Col lg={12} className="text-center">
          <SaveAndContinue
            onSave={() => onUpdatePage()}
            onContinue={onContinuePath}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default withRouter(PageEditForm);
