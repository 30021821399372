import React, { useState, useEffect } from 'react';
import {
  Row, Col, Label, Button,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import { isEmpty, map, get } from 'lodash-es';
import Switch from 'rc-switch';
import Pagination from '../../../components/common/custom-pagination';
import CustomSelect from '../custom-select';
import withRouter from '../../../helpers/withRouter';
import { useGetSitesQuery } from '../../../api/dashboardApiSlice';
import { useUser } from '../../../../../common/hooks';

const timeframeOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Past 7 days', value: 'past_7' },
  { label: 'Past 30 days', value: 'past_30' },
  { label: 'Current month', value: 'current_month' },
  { label: 'Previous month', value: 'previous_month' },
  { label: 'Past 90 days', value: 'past_90' },
  { label: 'Past 6 months', value: 'past_6_month' },
  { label: 'Past year', value: 'past_year' },
  { label: 'All time', value: 'all_time' },
  { label: 'Custom', value: 'custom' },
];

const getDateObj = (year, m, date, hr, min, sec, milli) => new Date(year, m, date, hr, min, sec, milli);

const getStartDate = (value, fullYear, month, dt, hours, minutes, seconds, milliSeconds) => {
  switch (value) {
    case 'yesterday':
      return getDateObj(fullYear, month, dt - 1, hours, minutes, seconds, milliSeconds);
    case 'past_7':
      return getDateObj(fullYear, month, dt - 6, hours, minutes, seconds, milliSeconds);
    case 'past_30':
      return getDateObj(fullYear, month, dt - 29, hours, minutes, seconds, milliSeconds);
    case 'current_month':
      return getDateObj(fullYear, month, 1, hours, minutes, seconds, milliSeconds);
    case 'previous_month':
      return getDateObj(fullYear, month - 1, 1, hours, minutes, seconds, milliSeconds);
    case 'past_90':
      return getDateObj(fullYear, month, dt - 89, hours, minutes, seconds, milliSeconds);
    case 'past_6_month':
      return getDateObj(fullYear, month - 6, 1, hours, minutes, seconds, milliSeconds);
    case 'past_year':
      return getDateObj(fullYear - 1, month, dt, hours, minutes, seconds, milliSeconds);
    case 'all_time':
      return getDateObj(2000, 1, 1, hours, minutes, seconds, milliSeconds);
    default: return getDateObj(fullYear, month, dt, hours, minutes, seconds, milliSeconds);
  }
};

const getEndDate = (value, datetime, fullYear, month, dt, hours, minutes, seconds, milliSeconds) => {
  if (['previous_month', 'past_6_month', 'yesterday'].includes(value)) {
    const getDt = value === 'yesterday' ? dt : 1;
    return getDateObj(fullYear, month, getDt, hours, minutes, seconds, milliSeconds);
  }
  return datetime;
};

const getTimeframeOptions = (options, exl) => {
  if (isEmpty(exl)) {
    return options;
  }

  return options.filter((opt) => !exl.includes(opt.value));
};

const getSiteOptions = (options) => map(options, (opt) => ({ label: opt.label, value: opt.id }));

function TimeFrameSelector(props) {
  const {
    ctaType,
    typeName,
    CTALogs,
    pageSize,
    currentPage,
    includeSpam,
    onChangePage,
    getPagination,
    onHandleSubmit,
    changePageSize,
    excludeTimeframe,
    onHandleSiteChange,
    onHandleIncludeSpam,
    showOnlyTimeFrameSelector,
    params: { instanceId },
  } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTimeframe, setTimeframe] = useState('past_30');
  const [isCustomTimeframe, setCustomTimeframe] = useState(false);
  const user = useUser();
  const timeZone = get(user, 'time_zone', 'Pacific/Auckland');

  const datetime = new Date();
  const localDate = new Date(datetime.toLocaleString('en-US', { timeZone }));
  const fullYear = localDate.getFullYear();
  const month = localDate.getMonth();
  const dt = localDate.getDate();
  const hours = datetime.getHours();
  const minutes = datetime.getMinutes();
  const seconds = datetime.getSeconds();
  const milliSeconds = datetime.getMilliseconds();

  const getStartDt = (d) => getStartDate(d, fullYear, month, dt, hours, minutes, seconds, milliSeconds).toISOString();

  const getEndDt = (d) => getEndDate(d, datetime, fullYear, month, dt, hours, minutes, seconds, milliSeconds).toISOString();

  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });

  useEffect(() => {
    if (!isEmpty(sites)) {
      onHandleSubmit({
        startDt: getStartDt(selectedTimeframe),
        endDt: getEndDt(selectedTimeframe),
      });
    }
  }, [sites]);

  const onHandleChange = (e) => {
    const { value } = e;
    setTimeframe(value);
    if (value === 'custom') {
      setCustomTimeframe(true);
      setStartDate(null);
      setEndDate(null);
    } else {
      setCustomTimeframe(false);

      onHandleSubmit({
        startDt: getStartDt(e.value),
        endDt: getEndDt(e.value),
      });
    }
  };

  const onHandleChangeDate = (e, type) => {
    if (type === 'start_date') {
      setStartDate(e);
    } else {
      setEndDate(e);
    }
  };

  const timeFrameBox = () => (
    <CustomSelect
      options={getTimeframeOptions(timeframeOptions, excludeTimeframe)}
      onChange={(e) => onHandleChange(e)}
      value={getTimeframeOptions(timeframeOptions, excludeTimeframe).filter((el) => el.value === selectedTimeframe)}
    />
  );

  const customDateTimeBox = () => isCustomTimeframe && (
    <Row>
      <Col>
        <Label className="w-100">
          Start date
          <DatePicker.default
            selected={startDate}
            placeholderText="Start date"
            onChange={(e) => onHandleChangeDate(e, 'start_date')}
            showYearDropdown
            scrollableYearDropdown
            dropdownMode="scroll"
            dateFormat="dd/MM/yyyy"
          />
        </Label>
      </Col>
      <Col>
        <Label className="w-100">
          End date
          <DatePicker.default
            selected={endDate}
            placeholderText="End date"
            onChange={(e) => onHandleChangeDate(e, 'end_date')}
            showYearDropdown
            scrollableYearDropdown
            dropdownMode="scroll"
            dateFormat="dd/MM/yyyy"
          />
        </Label>
      </Col>
      <Col className="mt-4">
        <Button
          color="success"
          className="common-success-button button-sm"
          onClick={() => onHandleSubmit({
            startDt: DateTime.fromISO(startDate.toISOString(), { zone: timeZone }),
            endDt: DateTime.fromISO(endDate.toISOString(), { zone: timeZone }),
          })}
        >
          <b>Search</b>
        </Button>
      </Col>
    </Row>
  );

  if (showOnlyTimeFrameSelector) {
    return (
      <>
        {timeFrameBox()}
        {customDateTimeBox()}
      </>
    );
  }
  return (
    <>
      <Row>
        <Col md={12} lg={3}>
          <Label className="w-100">
            View
            {' '}
            {typeName[1]}
            {' '}
            from:
            {timeFrameBox()}
          </Label>
        </Col>
        <Col md={12} lg={3}>
          <Label className="w-100">
            Site:
            <CustomSelect
              isClearable
              onChange={(e) => onHandleSiteChange(e)}
              options={getSiteOptions(sites)}
              isDisabled={isEmpty(sites)}
            />
          </Label>
        </Col>
        {user?.hasFeature('view_spam_ctas') && ['CTALogFormSubmission'].includes(ctaType)
        && (
          <Col>
            <Label>
              Include spam
              <Switch
                className="custom-switch custom-switch-primary"
                checked={includeSpam}
                onChange={onHandleIncludeSpam}
              />
            </Label>
          </Col>
        )}

        {customDateTimeBox()}
      </Row>
      <Pagination
        type={typeName}
        currentPage={Number(currentPage)}
        records={CTALogs}
        onChangePage={onChangePage}
        getPagination={getPagination}
        pageSize={pageSize}
        changePageSize={changePageSize}
      />
    </>
  );
}

export default withRouter(TimeFrameSelector);
