import React, { useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { setPageTitle } from '../../../../../../Utils';
import HeaderComponent from '../../../../components/common/header-component';
import UserFeaturePage from '../../../../components/common/user-feature-page';
import { siteCenter, replacePathParams, settingRoutes } from '../../../../constants/routes';
import withRouter from '../../../../helpers/withRouter';
import { addRecentItem } from 'Dashboard/src/Utils';

function DesignHome(props) {
  const { navigate, params: { instanceId } } = props;
  useEffect(() => {
    setPageTitle('Design Options');
  });

  const redirectToComponent = (route) => {
    navigate(replacePathParams(route, [], props));
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'design-options',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    { name: 'Design Options', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Design Options',
          backToPath: replacePathParams(siteCenter.pageList, [], props),
        }}
      />
      <UserFeaturePage feature="design_options">
        <Row className="settings-menu-outer">
          <Col lg={2}>
            <Button color="info" onClick={() => redirectToComponent(siteCenter.templateList)}>
              <i className="fal fa-file-code" />
              <p>Templates</p>
            </Button>
          </Col>
          <Col lg={2}>
            <Button color="info" onClick={() => redirectToComponent(siteCenter.templateFragmentList)}>
              <i className="fal fa-book-open" />
              <p>Template Fragments</p>
            </Button>
          </Col>
          <Col lg={2}>
            <Button color="info" onClick={() => redirectToComponent(siteCenter.styleOverrides)}>
              <i className="fal fa-pencil-paintbrush" />
              <p>Style Overrides (BETA)</p>
            </Button>
          </Col>
          <Col lg={2}>
            <Button color="info" onClick={() => redirectToComponent(siteCenter.textStyleList)}>
              <i className="fal fa-magic" />
              <p>Text Styles</p>
            </Button>
          </Col>
          <Col lg={2}>
            <Button color="info" onClick={() => redirectToComponent(siteCenter.colourPalette)}>
              <i className="fal fa-palette" />
              <p>Colour Palette</p>
            </Button>
          </Col>
          <Col lg={2}>
            <Button color="info" onClick={() => redirectToComponent(siteCenter.buttons)}>
              <i className="fa-light fa-rectangle" />
              <p>Buttons</p>
            </Button>
          </Col>
          <Col lg={2}>
            <Button color="info" onClick={() => redirectToComponent(siteCenter.customCodeListing)}>
              <i className="fa-light fa-pen-to-square" />
              <p>Custom Code</p>
            </Button>
          </Col>
        </Row>
      </UserFeaturePage>
    </>
  );
}
export default withRouter(DesignHome);
