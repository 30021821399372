import Base from './Base';

type SectionTypes =
  | 'SectionContent'
  | 'SectionComponent'
  | 'SectionColumn'
  | 'SectionForm'
  | 'SectionImageGallery'
  | 'SectionMirror'
  | 'SectionReviewList'
  | 'SectionReviewForm'
  | 'SectionEcwid'
  | 'SectionCustom';

interface ImageGalleryOptions {
  include_arrows: boolean;
  display_captions: 'yes' | 'no';
  time_milliseconds: string;
  include_indicators: boolean;
  allow_click_to_zoom: 'yes' | 'no';
  width_click_to_zoom: number;
  scroll_automatically: 'yes' | 'no';
}

interface BPStyles {
  [key: string]: string[];
}

interface IVars {
  [key: string]: string;
}

export interface BreakPoint {
  id?:              string;
  all_styles:       BPStyles;
  desktop_styles:   BPStyles;
  tablet_styles:    BPStyles;
  phone_styles:     BPStyles;
  object_type?:     string;
  object_id?:       string;
  site_id?:         string;
  element?:         string;
  sector?:          any[];
  template_ids?:    any[];
}

export interface SectionImageGalleryItem {
  id:                   string;
  image_url:            string;
  alt?:                 string;
  width:                number;
  height:               number;
  caption?:             string;
  link_id?:             string;
  link_url?:            string;
  link_target?:         string;
  width_click_to_zoom?: number;
}

export interface ImagePreviewProps {
  item: SectionImageGalleryItem;
  modal: boolean;
  toggle: (state: boolean) => void;
}

export interface FrameBoxProps {
  url: string;
  modal: boolean;
  toggle: (state: boolean) => void;
}

export interface FormItem {
  id?:                      string;
  name?:                    string;
  type?:                    string;
  order?:                   number;
  is_required?:             boolean;
  placeholder?:             string;
  default_value?:           string;
  values?:                  string[];
  other_option?:            boolean;
  section_form_id?:         string;
  database_field_id?:       string | null;
  database_field?:          any | null;
  break_values_in_newline?: boolean;
  field_type?:              string;
}

export interface Review {
  id?:                string;
  unique_identifier?: string;
  url?:               string;
  reviewer_name?:     string;
  rating?:            number;
  text?:              string;
  response?:          string;
  platform?:          string;
  created_at?:        string;
}
export interface SectionProps {
  // Common
  id?:                          string | number;
  instance_id?:                 string | number;
  type?:                        SectionTypes;
  page_id?:                     string | number;
  page_name?:                   string;
  page_version_id?:             string | number;
  sector?:                      string;
  sector_color?:                string;
  order?:                       number;
  is_offline?:                  boolean;
  width?:                       null;
  visible_to?:                  string;
  origin_id?:                   string;
  initial_visibility?:          boolean;
  url?:                         string;
  notes?:                       string;
  css_classes?:                 any[];
  // Form Section
  field_label_position?:        string;
  add_to_database?:             boolean;
  submit_button_type?:          string;
  button_text?:                 string;
  button_image_id?:             null | string | number;
  button_hover_image_id?:       null | string | number;
  button_style_id?:             null | string | number;
  button_style_css?:            null;
  button_image_url?:            null;
  button_hover_image_url?:      null;
  form_items?:                  FormItem[];

  // Review List Section
  reviews?:                     Review[];
  review_count?:                number;
  review_average?:              number;
  include_average?:             boolean;

  // Component Section
  boot_func?:                   string;
  full_size?:                   boolean;
  html?:                        string;

  column_section?:              ColumnSectionColumnSection;
  content?:                     string;
  component_id?:                null | string | number;
  html_vars?:                   IVars;
  scss_vars?:                   IVars;
  layout?:                      string;
  desktop_layout?:              any[];
  tablet_layout?:               any[];
  phone_layout?:                any[];
  column_sections?:             ColumnSectionElement[];
  send_email?:                  boolean;
  email_addresses?:             any[];
  email_subject_line?:          string;
  send_sms?:                    boolean;
  mobile_number?:               string;
  redirect_to_page?:            boolean;
  redirect_page_id?:            null | string | number;
  image_width?:                 null | string | number;
  image_height?:                null | string | number;
  image_width_type?:            string;
  style?:                       string;
  text_style_id?:               null | string | number;

  // Image Gallery Section
  section_image_gallery_items?: SectionImageGalleryItem[];
  caption_tag?:                 string;
  options?:                     ImageGalleryOptions;

  enable_phone_breakpoint?:     boolean;
  enable_tablet_breakpoint?:    boolean;
  privacy_policy_color?:        string;
  email_series_ids?:            any[];
  display_dialog_box?:          boolean;
  dialog_box_message?:          string;

  // SectionEcwid
  element?:                     string;
  category_id?:                 number;

  // Mirror Section
  mirror_of_id?:                null | string | number;
}

export interface ColumnSectionColumnSection {
  id:    string;
  order?: number;
}

interface ColumnSectionElement {
  id:                 string | number;
  type:               SectionTypes;
  page_id:            string | number;
  page_name:          string;
  page_version_id:    string | number;
  sector:             string;
  sector_color:       string;
  order:              number;
  is_offline:         boolean;
  width:              null;
  visible_to:         string;
  origin_id:          string;
  initial_visibility: boolean;
  url:                string;
  notes:              string;
  css_classes:        any[];
  column_section:     ColumnSectionColumnSection;
  break_point:        BreakPoint;
  text_blocks:        any[];
}

class Section extends Base {
  // Common
  id?:                          string | number;
  instance_id?:                 string | number;
  type?:                        SectionTypes;
  page_id?:                     string | number;
  page_name?:                   string;
  page_version_id?:             string | number;
  sector?:                      string;
  sector_color?:                string;
  order?:                       number;
  is_offline?:                  boolean;
  width?:                       null;
  visible_to?:                  string;
  origin_id?:                   string;
  initial_visibility?:          boolean;
  url?:                         string;
  notes?:                       string;
  css_classes?:                 any[];
  // Form Section
  field_label_position?:        string;
  add_to_database?:             boolean;
  submit_button_type?:          string;
  button_text?:                 string;
  button_image_id?:             null | string | number;
  button_hover_image_id?:       null | string | number;
  button_style_id?:             null | string | number;
  button_style_css?:            null;
  button_image_url?:            null;
  button_hover_image_url?:      null;
  form_items?:                  FormItem[];

  // Review List Section
  reviews?:                     Review[];
  review_count?:                number;
  review_average?:              number;
  include_average?:             boolean;

  // Component Section
  boot_func?:                   string;
  full_size?:                   boolean;
  html?:                        string;

  column_section?:              ColumnSectionColumnSection;
  content?:                     string;
  component_id?:                null | string | number;
  html_vars?:                   IVars;
  scss_vars?:                   IVars;
  layout?:                      string;
  desktop_layout?:              any[];
  tablet_layout?:               any[];
  phone_layout?:                any[];
  column_sections?:             ColumnSectionElement[];
  send_email?:                  boolean;
  email_addresses?:             any[];
  email_subject_line?:          string;
  send_sms?:                    boolean;
  mobile_number?:               string;
  redirect_to_page?:            boolean;
  redirect_page_id?:            null | string | number;
  image_width?:                 null | string | number;
  image_height?:                null | string | number;
  image_width_type?:            string;
  style?:                       string;
  text_style_id?:               null | string | number;

  // Image Gallery Section
  section_image_gallery_items?: SectionImageGalleryItem[];
  caption_tag?:                 string;
  options?:                     ImageGalleryOptions;

  enable_phone_breakpoint?:     boolean;
  enable_tablet_breakpoint?:    boolean;
  privacy_policy_color?:        string;
  email_series_ids?:            any[];
  display_dialog_box?:          boolean;
  dialog_box_message?:          string;

  // SectionEcwid
  element?:                     string;
  category_id?:                 number;

  // Mirror Section
  mirror_of_id?:                null | string | number;

  constructor(props: SectionProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default Section;
