import React, { useEffect, useState } from 'react';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { mainRoutes, replacePathParams, settingRoutes } from 'Dashboard/src/constants/routes';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { isEmpty, map, filter } from 'lodash-es';
import { FormGroup, Label, Card, CardBody, CardColumns, CardTitle } from 'reactstrap';
import Switch from 'rc-switch';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import { getSelectedOption } from '../../../../../Utils';
import { displayError } from 'Dashboard/src/Utils';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import { useGetEmailSeriesQuery, useGetInstancesQuery, useGetXeroConnectionQuery, useUpdateInstanceMutation, useXeroConnectMutation, useXeroDisconnectMutation } from '../../../api/dashboardApiSlice';

function Services(props) {
  const { params: { instanceId } } = props;
  const { data: instances = [] } = useGetInstancesQuery();
  const instanceData = filter(instances, (instance) => instance.id == instanceId)?.[0] || {};
  const [xeroConnection, setXeroConnection] = useState(null);
  const haveOauth2 = !isEmpty(instanceData) && !isEmpty(instanceData?.oauth2_details?.xero) && xeroConnection;
  const [updateInstance] = useUpdateInstanceMutation();
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instanceId, { skip: isEmpty(instanceId) && !isEmpty(haveOauth2) });

  const { meta: connectionMeta } = useGetXeroConnectionQuery({ instanceId }, {
    skip: !instanceId || !instances.length || !instanceData?.oauth2_details?.xero,
  });

  const [xeroConnect] = useXeroConnectMutation();
  const [xeroDisconnect] = useXeroDisconnectMutation();

  useEffect(() => {
    if (connectionMeta?.is_success !== undefined) {
      setXeroConnection(connectionMeta.is_success);
    }
  }, [connectionMeta]);

  const onHandleConnectXero = async () => {
    try {
      const response = await xeroConnect({ instance_id: instanceId });
      const loginUrl = response?.data?.data?.login_url;

      if (loginUrl) {
        window.location.href = loginUrl;
      } else {
        displayError('Error getting Xero login URL.');
      }
    } catch (err) {
      console.error('Error connecting to Xero:', err);
      displayError('Error connecting to Xero.');
    }
  };

  const onHandleDisconnectXero = async () => {
    const result = await xeroDisconnect({ instance_id: instanceId });
    if (result && result?.data?.meta?.is_success) {
      setXeroConnection(false);
    }
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    { name: 'Services', url: '' },
  ];

  const emailSeriesOptions = map(emailSeries, (item) => ({ label: item.name, value: item.id }));

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Services',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: replacePathParams(settingRoutes.dashboard, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="connections">
        <CardColumns>
          <Card>
            <CardBody>
              <CardTitle>Xero</CardTitle>
              <FormGroup>
                <Label check>{haveOauth2 ? 'Connected — Click to disconnect' : 'Connect to Xero'}</Label>
                <Switch
                  className="custom-switch custom-switch-primary"
                  checked={haveOauth2}
                  onClick={() => {
                    if (!haveOauth2) {
                      onHandleConnectXero();
                    } else {
                      onHandleDisconnectXero();
                    }
                  }}
                />
              </FormGroup>
              {haveOauth2 && (
                <>
                  <FormGroup>
                    <Label check className="mt-2">Send Review Requests via email</Label>
                    <Switch
                      className="custom-switch custom-switch-primary"
                      checked={instanceData.xero_send_email}
                      onChange={() => {
                        const sendParams = {
                          ...instanceData,
                          instance_id: instanceId,
                          xero_send_email: !instanceData.xero_send_email,
                        };
                        updateInstance(sendParams);
                      }}
                    />
                  </FormGroup>
                  {instanceData.xero_send_email && (
                    <FormGroup>
                      <Label className="mt-2">
                        Review Request Email Series
                        <CustomSelect
                          value={getSelectedOption(emailSeriesOptions, instanceData?.review_request_email_series_id)}
                          options={emailSeriesOptions}
                          onChange={(e) => {
                            const sendParams = {
                              ...instanceData,
                              instance_id: instanceId,
                              review_request_email_series_id: e.value,
                            };
                            updateInstance(sendParams);
                          }}
                        />
                      </Label>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label check className="mt-2">Send Review Requests via SMS</Label>
                    <Switch
                      className="custom-switch custom-switch-primary"
                      checked={instanceData.xero_send_sms}
                      onChange={() => {
                        const sendParams = {
                          ...instanceData,
                          instance_id: instanceId,
                          xero_send_sms: !instanceData.xero_send_sms,
                        };
                        updateInstance(sendParams);
                      }}
                    />
                  </FormGroup>
                </>
              )}
            </CardBody>
          </Card>
        </CardColumns>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(Services);
