import React from 'react';
import { isEmpty, get, map } from 'lodash-es';
import DropdownButtonGroup from 'Dashboard/src/components/common/DropdownButtonGroup';
import { getSelectedOption } from '../../../../../Utils';
import { useGetDatabaseFieldsQuery } from '../../../api/dashboardApiSlice';
import { useInstance } from 'common/hooks';

interface DataFieldSelectBoxProps {
  labelPrefix?: string;
  onChange: (selectedOption: any) => void;
  selectedId?: string | null;
}

interface DataFieldOption {
  id: string | number;
  attributes: {
    field_name: string;
  };
}

const getDataFieldOptions = (data: DataFieldOption[]) => (
  map(data, (option) => ({ label: option.attributes.field_name, value: option.id }))
);

const DataFieldSelectBox: React.FC<DataFieldSelectBoxProps> = ({
  onChange,
  selectedId = null,
  labelPrefix = '',
}) => {
  const instance = useInstance();
  const instanceId = get(instance, 'id', null);
  const { data: dataFieldSelectOptions, isLoading, isError } = useGetDatabaseFieldsQuery(
    { instanceId },
    { skip: isEmpty(instanceId) },
  );

  if (isLoading) return <p>Loading...</p>;
  if (isError || !dataFieldSelectOptions) return <p>Error loading data fields.</p>;

  const options = getDataFieldOptions(dataFieldSelectOptions);
  const selectedOption = getSelectedOption(options, selectedId);

  return (
    <DropdownButtonGroup
      outline
      labelPrefix={labelPrefix}
      options={options}
      selectedOption={selectedOption}
      onOptionSelect={onChange}
    />
  );
};

export default DataFieldSelectBox;
