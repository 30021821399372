import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import { setTheme } from '../../Utils';
import Loader from './components/common/spinner';
import AppRoutes from './AppRoutes';
import { defaultTheme, themeStorageKey } from './constants/defaultValues';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './components/common/ErrorBoundary';
import './assets/css/sass/_gogo.style.scss';
import './assets/css/sass/custom.style.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-switch/assets/index.css';
import 'react-grid-layout/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { windowSizeSlice } from 'common/api/apiSlice';
import { deviceSlice } from 'common/api/commonSlice';

declare global {
  interface Window {
    addEventListener(
      type: 'message',
      listener: (event: MessageEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
  }
}

window.addEventListener(
  'message',
  (event: MessageEvent) => {
    if (event.origin !== window.origin) {
      return;
    }

    if (!event.data.source || event.data.source !== 'wg-iframe-height') {
      return;
    }

    const el = document.getElementById(event.data.id);
    if (!el) {
      return;
    }

    // Only set the height if it is greater than the current height
    if (event.data.height > el.clientHeight)
      el.style.height = `${event.data.height}px`;
  },
  false,
);

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.onresize = () => {
      dispatch(windowSizeSlice.actions.setWindowSize());
      dispatch(deviceSlice.actions.setDevice());
    };

    document.body.classList.add('ltr');

    // Add theme class to body
    let theme = localStorage.getItem(themeStorageKey) || defaultTheme;

    // We previously used 'light.purple' as the default, now it's just 'light' or 'dark'.
    if (theme !== 'dark' && theme !== 'light') {
      theme = 'light';
    }

    setTheme(theme);
  }, []);

  const currentAppLocale = AppLocale.en;

  return (
    <ErrorBoundary>
      <Loader />
      <IntlProvider
        textComponent="span"
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ToastContainer />
        <Router>
          <AppRoutes />
        </Router>
      </IntlProvider>
    </ErrorBoundary>
  );
};

export default App;
