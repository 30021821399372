import { createCable, Channel } from '@anycable/web';
import { getCookie } from '/Utils';

export const cable = createCable({
  // logLevel: 'debug',
  protocol: 'actioncable-v1-ext-json',
  websocketImplementation: WebSocket,
  websocketAuthStrategy: 'header',
  auth: { token: getCookie('USER_TOKEN') },
});

export class UserPresenceChannel extends Channel {
  static identifier = 'UserPresenceChannel';

  async online(instanceId: string) {
    return this.perform('online', { instance_id: instanceId });
  }

  async away(instanceId: string) {
    return this.perform('away', { instance_id: instanceId });
  }
};

export class DashboardChatChannel<T extends { thread_id: string | number | boolean } = { thread_id: string | number | boolean }> extends Channel<T> {
  static identifier = 'DashboardChatChannel';

  async sendMessage(message: { instance_id: string | number; site_id: string | number; thread_id: string | null; user_message: string }) {
    return this.perform('send_message', message);
  }
};

export class BriefingMeetingChannel extends Channel<{ briefing_id: string }> {
  static identifier = 'BriefingMeetingChannel';

  // Change the page of the briefing.
  // @param briefingId The ID of the briefing.
  // @param page The page number to change to.
  // @param rand A random number, this is used to position the page correctly even when the page number is not being changed.
  // @returns A promise that resolves when the message is sent.
  async changePage(briefingId: string, page: number, rand: number = Math.random()) {
    return this.perform('change_page', { briefing_id: briefingId, page, rand });
  }

  // Trigger the client to refetch the briefing.
  // @param briefingId The ID of the briefing.
  // @returns A promise that resolves when the message is sent.
  async refetchBriefing(briefingId: string) {
    return this.perform('refetch_briefing', { briefing_id: briefingId });
  }
};
