import React from 'react';
import withRouter from '../../helpers/withRouter';
import { useGetSiteBriefingQuery } from '../../api/dashboardApiSlice';
import { usePageClassName } from 'common/hooks';
import DiscussionDeck from 'Dashboard/src/views/DiscussionDeck';
import PMMeetingForm from 'Dashboard/src/views/PMMeetingForm';
import './index.css';

interface Props {
  params: {
    briefingId: string;
  };
}

// This view shows both the PM Meeting form and the Discussion Deck, it's meant for users who only have one monitor.
function PMMeeting(props: Props) {
  usePageClassName('PMMeeting');

  const { params } = props;

  const { data: briefing } = useGetSiteBriefingQuery(params.briefingId);

  if (!briefing) {
    return null;
  }

  return (
    <>
      <div className="top">
        <DiscussionDeck
          params={params}
          inPMMeeting={true}
        />
      </div>
      <PMMeetingForm
        briefing={briefing}
        singleWindow={true}
      />
    </>
  );
}

export default withRouter(PMMeeting);
