import React from 'react';
import { useGetSectionHTMLQuery } from '../api/dashboardApiSlice';
import withRouter from '../helpers/withRouter';

function SectionContentEmail(props) {
  const {
    section, onClick,
    params: {
      instanceId,
    },
  } = props;

  const { data: html } = useGetSectionHTMLQuery({ instanceId: instanceId, sectionId: section.id });

  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      onClick={() => onClick(section)}
    />
  );
};

export default withRouter(SectionContentEmail);
