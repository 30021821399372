import React, { useEffect, useState } from 'react';
import { setPageTitle } from '../../../../../../Utils';
import { displaySuccess } from 'Dashboard/src/Utils';
import HeaderComponent from '../../../../components/common/header-component';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../../constants/routes';
import { Button, Card, CardBody, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import withRouter from '../../../../helpers/withRouter';
import BottomActionToolbar from '../../../../components/common/toolbar';
import confirm from '../../../../components/common/confirm';
import UserFeaturePage from '../../../../components/common/user-feature-page';
import {
  useCreateComponentCategoryMutation,
  useDeleteComponentCategoryMutation,
  useGetComponentCategoriesQuery,
} from '../../../../api/dashboardApiSlice';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function ComponentCategoryList(props) {
  const { navigate } = props;
  const [showModal, setModal] = useState(false);
  const [newName, setNewName] = useState('');

  const { data: componentCategories = [] } = useGetComponentCategoriesQuery();
  const [createCategory] = useCreateComponentCategoryMutation();
  const [deleteCategory] = useDeleteComponentCategoryMutation();

  useEffect(() => {
    setPageTitle('Component Categories');
  }, []);

  const onEditAction = (cat) => {
    navigate(replacePathParams(
      settingRoutes.componentList,
      [
        { key: 'categoryId', value: cat.id },
      ], props,
    ));
  };

  const onHandleToggle = () => {
    setModal(!showModal);
  };

  const addNewCategory = async () => {
    const result = await createCategory({ name: newName });
    const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
    if (isSuccess) {
      displaySuccess(messages);
      setModal(false);
      setNewName('');
    }
  };

  const onDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const result = await deleteCategory({ id });
      const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
      if (isSuccess) {
        displaySuccess(messages);
        setModal(false);
      }
    }
  };

  const tableColumns = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Action',
      id: 'action',
      cell: ({ row }) => (
        <>
          <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditAction(row.original)}>
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onDelete(row.original.id)}>
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    { name: 'Component Categories', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Component Categories',
          addNewBtnName: '',
          addNewPath: '',
        }}
      />
      <UserFeaturePage feature="manage_components">
        <Card className="mb-4">
          <CardBody>
            <ReactTable8
              columns={tableColumns}
              data={componentCategories}
            />
          </CardBody>
        </Card>
        {showModal && (
          <Modal isOpen={showModal} toggle={onHandleToggle} container={window.modalRef}>
            <ModalHeader toggle={onHandleToggle}>Category</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => addNewCategory()}>SAVE</Button>
            </ModalFooter>
          </Modal>
        )}
        <BottomActionToolbar
          component={(
            <Button color="info" onClick={() => onHandleToggle()}>NEW CATEGORY</Button>
          )}
        />
      </UserFeaturePage>
    </>
  );
}

export default withRouter(ComponentCategoryList);
