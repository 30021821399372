import React from 'react';
import { Label, FormGroup, Input } from 'reactstrap';

function BlogListLayout({ params, setParams }) {
  return (
    <FormGroup>
      <Label>Blog List Page Layout</Label>

      <Input
        type="select"
        value={params.blog_list_layout}
        onChange={(e) => setParams({ ...params, blog_list_layout: e.target.value })}
      >
        <option value="masonry">Masonry</option>
        <option value="one_col_with_sidebar">One Column with Sidebar</option>
      </Input>
    </FormGroup>
  );
}

export default BlogListLayout;
