import React, { useState, useEffect } from 'react';
import CustomSelect from '../../../components/common/custom-select';
import { getSelectedOption } from '../../../../../Utils';
import { getPageHierarchy } from '../../../components/common/PageHierarchy';
import { useInstance, useSite } from 'common/hooks';
import { Label, FormGroup } from 'reactstrap';
import { isEmpty } from 'lodash-es';
import { useGetInstanceDataQuery } from 'Dashboard/src/api/dashboardApiSlice';

function BlogPage({ params, setParams }) {
  const instance = useInstance();
  const site = useSite();

  const [pageData, setPageData] = useState([]);
  const { data } = useGetInstanceDataQuery(instance?.id, { skip: isEmpty(instance?.id) });

  useEffect(() => {
    if (data) {
      // Some sites have numeric IDs, and comparing with === doesn't work because the URL parameter is a string.

      const siteData = data.filter((item) => item.id == instance?.id)[0].sites;
      // Some sites have numeric IDs, and comparing with === doesn't work because the URL parameter is a string.

      setPageData(siteData.filter((item) => item.id == site?.id)[0]?.pages || []);
    }
  }, [data]);

  return (
    <FormGroup>
      <Label for="blog_page_id">Blog Page</Label>
      <CustomSelect
        options={getPageHierarchy(pageData, '')}
        className="flex-grow-1"
        value={getSelectedOption(getPageHierarchy(pageData), params.blog_page_id)}
        onChange={(e) => setParams({ ...params, blog_page_id: e.value })}
      />
    </FormGroup>
  );
}

export default BlogPage;
