import React, { Fragment, useEffect, useState } from 'react';
import {
  Card, CardBody, Button,
} from 'reactstrap';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { settingRoutes, replacePathParams, mainRoutes } from 'Dashboard/src/constants/routes';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import UserFeatureComponent from '../../components/common/user-feature-component';
import withRouter from 'Dashboard/src/helpers/withRouter';
import {
  useRoiReportSitesQuery,
  useRoiRegenerateReportMutation,
  useRoiRegenerateAllMutation,
  useRoiPullFromMYOBMutation,
  useRoiSendMutation,
} from 'Dashboard/src/api/dashboardApiSlice';
import './index.scss';
function ROIReports(props) {
  const { data: sites, refetch: refetchSites } = useRoiReportSitesQuery();
  const [regenerateReport] = useRoiRegenerateReportMutation();
  const [regenerateAll] = useRoiRegenerateAllMutation();
  const [pullFromMYOB] = useRoiPullFromMYOBMutation();
  const [send] = useRoiSendMutation();
  const [uncheckedSiteIds, setUncheckedSiteIds] = useState({});
  const [showingDetails, setShowingDetails] = useState([]);

  let timeout;

  const fetchSiteData = async () => {
    refetchSites();
    timeout = setTimeout(fetchSiteData, 2000);
  };

  useEffect(() => {
    fetchSiteData();

    return () => clearTimeout(timeout);
  }, []);

  const regenerateAllWithConfirm = () => {
    if (!confirm('Are you sure?')) {
      return;
    }

    regenerateAll();
  };

  const uncheckAll = () => {
    const tempIds = {};
    sites.forEach((site) => tempIds[site.id] = true);
    setUncheckedSiteIds(tempIds);
  };

  const sendWithConfirm = async () => {
    if (!confirm('Are you sure?')) {
      return;
    }

    const sendResult = await send({ skip_site_ids: Object.keys(uncheckedSiteIds) });
    if (sendResult.data.meta.is_success) {
      alert('Sent!');
    }
  };

  const toggleDetails = (site) => {
    const index = showingDetails.indexOf(site.id);
    if (index === -1) {
      showingDetails.push(site.id);
    } else {
      showingDetails.splice(index, 1);
    }
    setShowingDetails([...showingDetails]);
  };

  const onClickSend = (e, site) => {
    if (e.target.checked) {
      delete uncheckedSiteIds[site.id];
    } else {
      uncheckedSiteIds[site.id] = true;
    }
    setUncheckedSiteIds({ ...uncheckedSiteIds });
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'ROI Reports', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'ROI Reports',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="view_roi_report_list">
            <Button color="danger" onClick={regenerateAllWithConfirm}>
              Regenerate All
            </Button>
            {' '}
            <Button color="danger" onClick={uncheckAll}>
              Uncheck All
            </Button>
            <UserFeatureComponent feature="send_roi_reports">
              {' '}
              <Button color="danger" onClick={sendWithConfirm}>
                Send
              </Button>
            </UserFeatureComponent>
            <hr />
            <table className="table roi-report-list">
              <thead>
                <tr>
                  <th>Send</th>
                  <th>Company Name</th>
                  <th>Site</th>
                  <th>Status</th>
                  <th>View (Live)</th>
                  <th>View (PDF)</th>
                  <th>Download</th>
                  <th>Regenerate</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {sites?.map((site) => (
                  <Fragment key={site.id}>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          checked={!uncheckedSiteIds[site.id]}
                          onChange={(e) => onClickSend(e, site)}
                        />
                      </td>
                      <td>
                        {site.billing_company_name}
                      </td>
                      <td>
                        <a href={`https://${site.domain}/`} target="_blank" rel="noreferrer">{site.domain}</a>
                      </td>
                      <td>
                        {site.report_status}
                      </td>
                      <td>
                        <a
                          href={`/api/v1/instances/${site.instance_id}/sites/${site.id}/roi_report/live`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                        </a>
                      </td>
                      <td>
                        {site.report_status === 'Ready'
                          ? (
                              <a
                                href={`/api/v1/instances/${site.instance_id}/sites/${site.id}/roi_report/pdf?disposition=inline`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a>
                            )
                          : site.report_status}
                      </td>
                      <td>
                        {site.report_status === 'Ready'
                          ? (
                              <a
                                href={`/api/v1/instances/${site.instance_id}/sites/${site.id}/roi_report/pdf?disposition=attachment`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download
                              </a>
                            )
                          : site.report_status}
                      </td>
                      <td>
                        {(() => {
                          if (site.report_status === 'Ready' || site.report_status === 'None') {
                            return (
                              <a
                                href="#"
                                onClick={(e) => {
                                  regenerateReport(site);
                                  e.preventDefault();
                                }}
                              >
                                Regenerate
                              </a>
                            );
                          }

                          return site.report_status;
                        })()}
                      </td>
                      <td className="text-center">
                        <a
                          href="#"
                          onClick={(e) => {
                            toggleDetails(site);
                            e.preventDefault();
                          }}
                        >
                          {showingDetails.indexOf(site.id) === -1 && (
                            <i className="fa-light fa-circle-info" />
                          )}
                          {showingDetails.indexOf(site.id) !== -1 && (
                            <i className="fa-light fa-circle-xmark" />
                          )}
                        </a>
                      </td>
                    </tr>
                    {showingDetails.indexOf(site.id) !== -1 && (
                      <tr>
                        <td colSpan="8">
                          <Card>
                            <CardBody>
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      MYOB ID
                                    </td>
                                    <td>
                                      <a href={`https://webgenius.myobadvanced.com/(W(13))/Main?CompanyID=WebGenius&ScreenId=AR303000&AcctCD=${site.myob_id}`} target="_blank" rel="noreferrer">
                                        {site.myob_id}
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Advisor ID
                                    </td>
                                    <td>
                                      {site.billing_advisor_id}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Campaign Budget Percentage
                                    </td>
                                    <td>
                                      {site.campaign_budget_percentage === -1
                                        ? 'Management Fee Only'
                                        : `${site.campaign_budget_percentage}%`}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Website Monthly Fee
                                    </td>
                                    <td>
                                      $
                                      {site.billing_monthly_fee}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Ads Monthly Fee
                                    </td>
                                    <td>
                                      $
                                      {site.billing_monthly_fee_ads}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      SEO Monthly Fee
                                    </td>
                                    <td>
                                      $
                                      {site.billing_monthly_fee_seo}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      ACE Monthly Fee
                                    </td>
                                    <td>
                                      $
                                      {site.billing_monthly_fee_ace}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Google Review Engine Monthly Fee
                                    </td>
                                    <td>
                                      $
                                      {site.billing_monthly_fee_gre}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Contact Name
                                    </td>
                                    <td>
                                      {site.billing_contact_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Email Addresses
                                    </td>
                                    <td>
                                      {site.billing_emails?.join(', ')}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      WFL Upgrade Date
                                    </td>
                                    <td>
                                      {site.billing_wfl_upgrade_date}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Related Sites
                                    </td>
                                    <td>
                                      {site.billing_related_sites}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <Button color="danger" onClick={() => pullFromMYOB(site)}>
                                Re-pull from MYOB
                              </Button>
                            </CardBody>
                          </Card>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </UserFeaturePage>
        </CardBody>
      </Card>
    </>
  );
}
export default withRouter(ROIReports);
