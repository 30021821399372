import Base from './Base';

export interface TemplateLayout {
  w?:               number;
  h?:               number;
  x?:               number;
  y?:               number;
  i?:               string;
  label?:           string;
  custom_html?:     string;
  wrapper_styles?:  any;
  sector_styles?:   any;
  overlay?:         boolean;
  sticky?:          boolean;
  parallax?:        boolean;
}

interface TemplateProps {
  id?:              string;
  name?:            string;
  template_type?:   string;
  grid?:            boolean;
  html?:            string;
  desktop_layout?:  TemplateLayout[];
  tablet_layout?:   TemplateLayout[];
  phone_layout?:    TemplateLayout[];
  desktop_width?:   string;
  tablet_width?:    string;
  phone_width?:     string;
  site_id?:         string;
  instance_id?:     string;
  sectors?:         string[];
  compiled_html?:   string;
}

class Template extends Base {
  id?:              string;
  name?:            string;
  template_type?:   string;
  grid?:            boolean;
  html?:            string;
  desktop_layout?:  TemplateLayout[];
  tablet_layout?:   TemplateLayout[];
  phone_layout?:    TemplateLayout[];
  desktop_width?:   string;
  tablet_width?:    string;
  phone_width?:     string;
  site_id?:         string;
  instance_id?:     string;
  sectors?:         string[];
  compiled_html?:   string;

  constructor(props: TemplateProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default Template;
