import React from 'react';
import { useParams } from 'react-router';
import { Card, CardBody, CardTitle } from 'reactstrap';
import HeaderComponent from '../../components/common/header-component';
import { replacePathParams, settingRoutes } from '../../constants/routes';
import CtaReportReview from '../../views/cta-reports/ctaReportReview';
import CtaReportNegativeReview from '../../views/cta-reports/ctaReportNegativeReview';
import withRouter from '../../helpers/withRouter';
import { addRecentItem } from 'Dashboard/src/Utils';

function ReviewCenterLog(props) {
  const { instanceId } = useParams();

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'review-center',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Review Centre', url: '' },
  ];
  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Review Centre',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mb-4">
        <CardBody>
          <CardTitle>
            Reviews
          </CardTitle>
          <CtaReportReview instanceId={instanceId} />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle>
            Negative Reviews
          </CardTitle>
          <CtaReportNegativeReview instanceId={instanceId} />
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(ReviewCenterLog);
