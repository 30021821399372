import React, { useEffect, useState, ElementType } from 'react';
import { isEmpty, map } from 'lodash-es';
import { FrameBox, ImagePreview } from './Popup';
import Section, { SectionImageGalleryItem } from 'common/models/Section';

interface SectionImageGalleryProps {
  section: Section;
  onClick:  (section: Section) => void;
}

const SectionImageGallery: React.FC<SectionImageGalleryProps> = ({ section, onClick }) => {
  const {
    id,
    style,
    origin_id,
    caption_tag,
    options,
    section_image_gallery_items,
  } = section;

  const include_arrows = options?.include_arrows ?? false;
  const display_captions = options?.display_captions ?? 'no';
  const time_milliseconds = options?.time_milliseconds ?? '0';
  const include_indicators = options?.include_indicators ?? false;
  const allow_click_to_zoom = options?.allow_click_to_zoom ?? 'no';
  const width_click_to_zoom = options?.width_click_to_zoom ?? 0;
  const scroll_automatically = options?.scroll_automatically ?? 'no';

  const scrollAutomatically = scroll_automatically === 'yes';

  const [modal, setModal] = useState(false);
  const [frameURL, setFrameURL] = useState('');
  const [imagePreview, setImagePreview] = useState<SectionImageGalleryItem | null>(null);

  const CaptionTag = (caption_tag || 'p') as ElementType;

  useEffect(() => {
    document.addEventListener('render', () => {
      const el = document.querySelector(`#carousel-${id}`);
      if (el) {
        const carousel = new window.bootstrap.Carousel(el);
        carousel.cycle();
      }
    });
  }, []);

  const onClickItem = (e, item) => {
    e.preventDefault();

    const { link_target, link_id, link_url } = item;

    if (!isEmpty(link_id)) {
      if (link_target === 'framebox') {
        setModal(true);
        setFrameURL(link_url);
        setImagePreview(null);
      } else {
        setModal(false);
        setFrameURL('');
        setImagePreview(null);
        window.open(link_url, link_target);
      }
    } else {
      setModal(true);
      setFrameURL('');
      setImagePreview(item);
    }
  };

  const indicators = () => (
    <div className="carousel-indicators">
      {map(section_image_gallery_items, (i, index) => (
        <button
          key={index}
          type="button"
          data-bs-target={`#carousel-${id}`}
          data-bs-slide-to={index}
          aria-current="true"
          aria-label={`Slide ${index}`}
          className={index === 0 ? 'active' : ''}
        />
      ))}
    </div>
  );

  const includeArrows = () => (
    <>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target={`#carousel-${id}`}
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target={`#carousel-${id}`}
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">next</span>
      </button>
    </>
  );

  const pictureTag = (item) => (
    <picture className="gallery-picture">
      <img
        className={`image-gallery-item-${item.id}`}
        id={`image-gallery-item-${item.id}`}
        src={item?.image_url}
        alt={item?.alt}
        style={{ aspectRatio: `${item.width}/${item.height}` }}
      />
    </picture>
  );

  const renderItems = (item) => {
    if (!isEmpty(item?.link_id)) {
      return (
        <a href="#" onClick={(e) => onClickItem(e, item)}>
          {pictureTag(item)}
        </a>
      );
    }

    if (allow_click_to_zoom === 'yes' && isEmpty(item?.link_id)) {
      return (
        <a href="#" onClick={(e) => onClickItem(e, item)}>
          {pictureTag(item)}
        </a>
      );
    }

    return (
      pictureTag(item)
    );
  };

  const returnStyle = () => {
    if (style === 'fade') {
      return (
        <div
          id={`carousel-${id}`}
          className={`fade-gallery-${id} carousel slide carousel-fade`}
          data-bs-pause="false"
          data-bs-interval={`${parseInt(time_milliseconds)}`}
        >
          {include_indicators && indicators()}
          <div className="carousel-inner">
            {map(section_image_gallery_items, (item, index) => (
              <div key={item.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                {renderItems(item)}
                {display_captions === 'yes' && item?.caption !== '' && (
                  <CaptionTag className={`carousel-caption section-caption-${item.id}`}>{item.caption}</CaptionTag>
                )}
              </div>
            ))}
          </div>
          {include_arrows && includeArrows()}
        </div>
      );
    }
    if (style === 'scroll') {
      return (
        <div className={`image-gallery-scroll${id}`}>
          <ul className={`${!scrollAutomatically ? 'overflow-auto' : ''}`}>
            {section_image_gallery_items?.map((item) => (
              <li key={item?.id}>
                <div className="el">
                  {renderItems(item)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <a className="anchor" id={`anchor${origin_id}`} />
      <section
        id={`section${id}`}
        className={`SectionImageGallery ${style === 'scroll' ? 'gallery-style-scroll' : ''} bp-${id} origin${origin_id} ${section.css_classes?.join(' ')}`}
        style={{ display: (section.initial_visibility || window.wg.env === 'dashboard') ? '' : 'none' }}
        onClick={() => onClick(section)}
      >
        {returnStyle()}
      </section>
      {modal && frameURL !== '' && !imagePreview
        ? (
            <FrameBox
              modal={modal}
              url={frameURL}
              toggle={(e) => {
                setModal(e);
                setFrameURL('');
              }}
            />
          )
        : modal && imagePreview
          ? (
              <ImagePreview
                item={{
                  ...(imagePreview && typeof imagePreview === 'object' ? imagePreview : {}),
                  width_click_to_zoom,
                  id: imagePreview?.id ?? null,
                  width: imagePreview?.width ?? 0,
                  height: imagePreview?.height ?? 0,
                  image_url: imagePreview?.image_url ?? '',
                }}
                modal={modal}
                toggle={(e) => {
                  setModal(e);
                  setFrameURL('');
                  setImagePreview(null);
                }}
              />
            )
          : <></>}
    </>
  );
};

export default SectionImageGallery;
