import React from 'react';
import { Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

function CheckBox({
  title,
  icon = '',
  display = '',
  disabled = false,
  ...props
}) {
  return (
    <Label className={`user-select-none ${display}`}>
      <Input {...props} type="checkbox" disabled={disabled} />
      {icon !== '' && (<i className={`ps-2 pe-1 ${icon}`} />)}
      {` ${title || ''}`}
    </Label>
  );
}

CheckBox.propTypes = {
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckBox;
