import React from 'react';
import { useGetEmailSeriesQuery, useCancelMemberEmailSeriesSubscriptionMutation } from 'Dashboard/src/api/dashboardApiSlice';
import { isEmpty, sortBy } from 'lodash-es';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DateTime } from 'luxon';
import DateTimeFormat from 'Dashboard/src/components/common/DateTimeFormat';
import confirm from 'Dashboard/src/components/common/confirm';
import { displayError, displaySuccess } from 'Dashboard/src/Utils';
import { useInstance } from 'common/hooks';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

const MemberEmailSeriesSubscription = (props) => {
  const { toggle, member } = props;
  const instance = useInstance();
  const [cancelMemberEmailSeriesSubscription] = useCancelMemberEmailSeriesSubscriptionMutation();
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instance?.id, { skip: isEmpty(instance?.id) });

  const tableColumns = [
    {
      id: 'series',
      header: 'Series',
      accessorFn: (row) => emailSeries?.find((e) => e?.id === row?.email_series_id)?.name || '',
      cell: (info) => info.getValue(),
    },
    {
      id: 'date',
      header: 'Subscription date',
      accessorFn: (row) => row.created_at,
      cell: (info) => (
        <DateTimeFormat datetime={info.getValue()} localeString={DateTime.DATETIME_MED} />
      ),
    },
    {
      id: 'sent',
      header: 'Emails sent',
      accessorFn: (row) => row.sent_email_series_emails?.length,
      cell: (info) => info.getValue(),
    },
    {
      id: 'finished',
      header: 'Finished',
      accessorFn: (row) => row?.finished,
      cell: (info) =>
        info.getValue() ? <i className="fal fa-check" /> : '',
    },
    {
      id: 'cancel',
      header: 'Cancel',
      cell: ({ row }) => {
        const sub = row.original;
        if (sub.finished) {
          return '';
        }
        return (
          <Button
            color="link"
            className="text-danger"
            onClick={async () => {
              try {
                const result = await confirm({
                  title: <b>Confirm!</b>,
                  message: 'Are you sure you want to cancel this subscription?',
                  confirmText: 'Yes',
                  confirmColor: 'success',
                  cancelColor: 'btn btn-danger',
                  cancelText: 'No',
                });
                if (result) {
                  const cancelResult = await cancelMemberEmailSeriesSubscription({
                    instance_id: instance?.id,
                    ...sub,
                  });
                  if (cancelResult?.data?.meta?.is_success) {
                    displaySuccess(cancelResult.data.meta.messages);
                  }
                }
              } catch (error) {
                displayError(`${error?.name}: ${error?.message}`);
              }
            }}
          >
            Cancel
          </Button>
        );
      },
    },
  ];

  const sortedSubscriptions = sortBy(member?.email_series_subscriptions, 'created_at').reverse();

  return (
    <Modal isOpen={true} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Email Series Subscriptions</ModalHeader>
      <ModalBody>
        <ReactTable8 columns={tableColumns} data={sortedSubscriptions || []} />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          <b>CLOSE</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MemberEmailSeriesSubscription;
