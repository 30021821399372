import React, { useRef, useEffect } from 'react';
import './index.scss';

interface DialogBoxProps {
  open: boolean;
  toggle: (state: boolean) => void;
  content: React.ReactNode;
  header?: string | React.ReactNode;
  footerButtons?: Array<{ label: string; onClick: () => void }>;
  size?: 'lg' | 'xl';
  className?: string;
  dismissOnClickOutside?: boolean;
}

const DialogBox: React.FC<DialogBoxProps> = ({
  toggle,
  content,
  header,
  open,
  size = 'lg',
  className,
  footerButtons,
  dismissOnClickOutside = true,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;

    if (dialog) {
      if (open) {
        dialog.showModal();
      } else {
        dialog.close();
      }
    }
  }, [open]);

  if (!open) return null;

  return (
    <dialog
      ref={dialogRef}
      onCancel={() => toggle(false)}
      onClick={(e) => {
        if (dismissOnClickOutside && e.target === dialogRef.current) {
          toggle(false);
        }
      }}
    >
      <div className={`dialog-content ${size} ${className}`}>
        {header && (
          <div className="dialog-header pt-1">
            <h5 className="dialog-title">{header}</h5>
            <button
              onClick={() => toggle(false)}
              className="close-btn"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
        )}

        <div className="dialog-body">
          {content}
        </div>

        {footerButtons && footerButtons.length > 0 && (
          <div className="dialog-footer pb-1">
            {footerButtons.map((button, index) => (
              <button
                key={index}
                onClick={button.onClick}
                className="btn btn-primary ms-2"
              >
                {button.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </dialog>
  );
};

export default DialogBox;
