import React, { useEffect, useState } from 'react';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { Card, CardBody, Input, Container, Row, Col, Button, FormGroup, Label, Alert } from 'reactstrap';
import {
  useGetAiModelsQuery,
  useGetAiTextPromptQuery,
  useUpdateAiTextPromptMutation,
  useTestAiTextPromptMutation,
  useGetSiteBriefingsQuery,
} from 'Dashboard/src/api/dashboardApiSlice';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import { mainRoutes, replacePathParams, settingRoutes } from 'Dashboard/src/constants/routes';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import BottomActionToolbar from 'Dashboard/src/components/common/toolbar';
import SaveAndContinue from 'Dashboard/src/components/common/saveAndContinue';
import { displayError } from 'Dashboard/src/Utils';
import Briefing from 'common/models/Briefing';

const briefingContexts = [
  'Briefing',
  'Briefing Product',
  'BDQ',
];

function AITextPrompt(props) {
  const { navigate } = props;
  const { data: aiModels } = useGetAiModelsQuery();
  const { data: aiTextPrompt } = useGetAiTextPromptQuery(props.params.id);
  const { data: briefings } = useGetSiteBriefingsQuery({ page_size: 10000 });
  const [models, setModels] = useState<string[]>([]);
  const [prompt, setPrompt] = useState('');
  const [model, setModel] = useState('');
  const [temperature, setTemperature] = useState(1);
  const [topP, setTopP] = useState(1);
  const [briefing, setBriefing] = useState<Briefing | null>(null);
  const [briefingProductId, setBriefingProductId] = useState<number | null>(null);
  const [updatePrompt] = useUpdateAiTextPromptMutation();
  const [testPrompt] = useTestAiTextPromptMutation();
  const [testOutputs, setTestOutputs] = useState<{
    model: string;
    system_prompt: string;
    user_prompt: string;
    result: string;
  }[]>([]);

  useEffect(() => {
    if (aiTextPrompt) {
      setModel(aiTextPrompt.model);
      setPrompt(aiTextPrompt.text);
      setTemperature(aiTextPrompt.temperature);
      setTopP(aiTextPrompt.top_p);
    }
  }, [aiTextPrompt]);

  if (!aiTextPrompt || !briefings?.briefings) {
    return <></>;
  }

  const briefingOptions = briefings?.briefings.map((b) => ({
    label: b.info.company_name,
    value: b.id,
  }));

  const briefingProductOptions = briefing?.info?.product_pages?.map((p, i) => ({
    label: p.name,
    value: i,
  })) || [];

  return (
    <>
      <style>
        {`
          .AITextPrompt {
            .output { white-space: pre-wrap; }
          }
        `}
      </style>
      <HeaderComponent setPath={{
        headingName: aiTextPrompt.name,
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Settings',
        backToPath: replacePathParams(mainRoutes.setting, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData: [
          { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
          { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
          { name: 'AI Text Prompts', url: replacePathParams(mainRoutes.aiTextPrompts, [], props) },
        ],
      }}
      />
      <Card className="AITextPrompt">
        <CardBody>
          <Container fluid>
            <Row>
              <Col md="9">
                <Input
                  type="textarea"
                  className="mb-3"
                  style={{ height: '400px' }}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />

                {aiTextPrompt.type === 'user' && (
                  <>
                    <FormGroup>
                      <Label>
                        Model
                      </Label>
                      <CustomSelect
                        options={aiModels?.map((m) => ({
                          label: m.name,
                          value: m.id,
                        }))}
                        value={model}
                        onChange={(e) => setModel(e.value)}
                      />
                    </FormGroup>

                    <h2>Test Prompt</h2>
                    {briefingContexts.includes(aiTextPrompt.context)
                      ? (
                          <>
                            <FormGroup>
                              <Label>
                                Briefing
                              </Label>
                              <CustomSelect
                                className="mb-3"
                                value={briefing?.id}
                                options={briefingOptions}
                                onChange={(e) => setBriefing(briefings.briefings.find((b) => b.id === e.value))}
                              />
                            </FormGroup>
                            {aiTextPrompt.context === 'Briefing Product'
                              ? (
                                  <FormGroup>
                                    <Label>
                                      Product
                                    </Label>
                                    <CustomSelect
                                      className="mb-3"
                                      value={briefingProductId}
                                      options={briefingProductOptions}
                                      onChange={(e) => setBriefingProductId(e.value)}
                                    />
                                  </FormGroup>
                                )
                              : null}
                          </>
                        )
                      : null}
                    <FormGroup>
                      <Label>
                        Models
                      </Label>
                      <CustomSelect
                        isMulti
                        value={aiModels?.filter((m) => models.includes(m.id)).map((m) => ({ label: m.name, value: m.id }))}
                        options={aiModels?.map((m) => ({
                          label: m.name,
                          value: m.id,
                        }))}
                        onChange={(e) => setModels(e.map((m) => m.value))}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Temperature (OpenAI default is
                        &nbsp;
                        <code>1</code>
                        )
                      </Label>
                      <Input
                        type="number"
                        value={temperature}
                        onChange={(e) => setTemperature(parseFloat(e.target.value))}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        Top P (OpenAI default is
                        &nbsp;
                        <code>1</code>
                        )
                      </Label>
                      <Input
                        type="number"
                        value={topP}
                        onChange={(e) => setTopP(parseFloat(e.target.value))}
                      />
                    </FormGroup>
                    <Button
                      color="success"
                      className="fw-bold common-success-button"
                      onClick={async () => {
                        if (!briefing) {
                          displayError('Please select a briefing');
                          return;
                        }

                        const testRequests = models.map((model) => {
                          return testPrompt({
                            id: aiTextPrompt.id,
                            briefing_id: briefing.id,
                            briefing_product_id: briefingProductId,
                            model,
                            text: prompt,
                            temperature,
                            top_p: topP,
                          });
                        });

                        Promise.all(testRequests)
                          .then((responses) => {
                            setTestOutputs(responses.map(({ data: { data } }, idx) => ({
                              ...data,
                              model: models[idx],
                            })));
                          })
                          .catch((err) => displayError(err.message));
                      }}
                    >
                      TEST PROMPT
                    </Button>
                  </>
                )}
              </Col>
              <Col md="3">
                <h3>Macros</h3>
                {aiTextPrompt.prompt_macros.map((macro) => (
                  <>
                    {macro}
                    <br />
                  </>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                {testOutputs.length > 0 && (
                  <div
                    className="mt-3"
                  >
                    <Alert>System Prompt</Alert>
                    <div className="output border mb-3 p-3">{testOutputs[0].system_prompt}</div>
                    <Alert>User Prompt</Alert>
                    <div className="output border mb-3 p-3">{testOutputs[0].user_prompt}</div>
                    <Container fluid>
                      <Row>
                        {testOutputs.map((testOutput) => (
                          <Col
                            key={testOutput.model}
                            xl="6"
                          >
                            <h2>{aiModels.find((m) => m.id === testOutput.model)?.name}</h2>
                            <div className="output border mb-3 p-3">{testOutput.result}</div>
                          </Col>
                        ))}
                      </Row>
                    </Container>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={() => updatePrompt({
              id: aiTextPrompt.id,
              text: prompt,
              model,
              temperature,
              top_p: topP,
            })}
            onContinue={() => navigate(replacePathParams(mainRoutes.aiTextPrompts, [], props))}
          />
        )}
      />
    </>
  );
}

export default withRouter(AITextPrompt);
