class Base {
  created_at?: Date;
  updated_at?: Date;

  _destroy?: boolean;

  constructor(props: { [key: string]: any }) {
    const { toJSON, ...rest } = props || {};
    Object.assign(this, rest);
  }

  // JSON.stringify() works fine for converting model objects to JSON, but RTK-Query wants a toJSON() method to be
  // defined, otherwise we send "[object Object]" back to the API.
  toJSON?(): { [key: string]: any } {
    return Object.keys(this).reduce((acc, key) => {
      if (this[key] !== undefined) {
        acc[key] = this[key];
      }
      return acc;
    }, {});
  }
}

export default Base;
