import React from 'react';
import { useAddNewPageMutation, useDeletePageMutation, useGetBlogPostsQuery } from 'Dashboard/src/api/dashboardApiSlice';
import { useInstance, useSite } from 'common/hooks';
import { isEmpty } from 'lodash-es';
import ReactTable8 from 'Dashboard/src/components/common/Table8';
import { Badge, Button, Card, CardBody } from 'reactstrap';
import BottomActionToolbar from 'Dashboard/src/components/common/toolbar';
import { getPageEditUrl } from 'Dashboard/src/constants/routes';
import { useNavigate } from 'react-router';
import withRouter from 'Dashboard/src/helpers/withRouter';
import confirm from 'Dashboard/src/components/common/confirm';
import { DateTime } from 'luxon';
import DateTimeFormat from 'Dashboard/src/components/common/DateTimeFormat';
import { displaySuccess } from 'Dashboard/src/Utils';

function BlogPosts(props) {
  const instance = useInstance();
  const site = useSite();

  const navigate = useNavigate();

  const { data } = useGetBlogPostsQuery(
    { instanceId: instance?.id, siteId: site?.id },
    { skip: isEmpty(instance?.id) || isEmpty(site?.id) },
  );

  const [addNewPage] = useAddNewPageMutation();
  const [deletePage] = useDeletePageMutation();

  const addNewBlogPost = async () => {
    const params = {
      instance_id: instance?.id,
      site_id: site?.id,
      type: 'blog',
      master_page_id: site?.default_blog_post_master_page_id,
    };
    const res = await addNewPage(params);
    if (res && res?.data?.meta?.is_success) {
      const { data: { data: { page_version, id } } } = res;
      displaySuccess('Blog post created successfully!');
      navigate(getPageEditUrl(page_version?.id, id, 'web', props));
    }
  };

  const deleteBlogPost = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure you want to delete this blog post?',
      confirmText: 'Yes',
      confirmColor: 'danger',
      cancelColor: 'success',
      cancelText: 'No',
    });

    if (result) {
      deletePage({
        instance_id: instance?.id,
        site_id: site?.id,
        current_page: id,
        type: 'blog',
      });
    }
  };

  const tableColumns = [
    {
      accessorKey: 'title',
      header: 'Post title',
    },
    {
      accessorKey: 'blog_publish_date',
      header: 'Publish date',
      cell: ({ row }) => (
        isEmpty(row.original.blog_publish_date)
          ? <Badge color="warning">DRAFT</Badge>
          : <DateTimeFormat datetime={row.original.blog_publish_date} localeString={DateTime.DATETIME_MED} />
      ),
    },
    {
      accessorKey: 'blog_tags',
      header: 'Tags',
      cell: ({ row }) => row.original.blog_tags.join(', '),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <>
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => {
              const { id, page_version } = row.original;
              navigate(getPageEditUrl(page_version.id, id, 'web', props));
            }}
          >
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={() => deleteBlogPost(row.original.id)}
          >
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <style>
        {`
          .BlogPosts {
            table td:nth-child(3) { max-width: 300px; }
          }
        `}
      </style>
      <h1>Blog Posts</h1>
      <Card className="BlogPosts">
        <CardBody>
          <ReactTable8
            columns={tableColumns}
            data={data || []}
          />
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <Button
            color="success"
            className="fw-bold common-success-button"
            onClick={addNewBlogPost}
          >
            NEW BLOG POST
          </Button>
        )}
      />
    </>
  );
}

export default withRouter(BlogPosts);
