import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetSiteBriefingsQuery,
  useCreateSiteBriefingMutation,
  useDeleteSiteBriefingMutation,
  useGenerateInstanceFromSiteBriefingMutation,
  useGenerateContentFromSiteBriefingMutation,
  useCrawlSiteMutation,
  useSendBDQNotificationMutation,
  useSendDiscussionDeckNotificationMutation,
  useGetUsersQuery,
} from '../../../api/dashboardApiSlice';
import {
  Card,
  CardBody,
  Button,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  CardTitle,
  CardText,
  Table,
} from 'reactstrap';
import CustomButton from '../../../components/common/button';
import { setPageTitle } from '../../../../../Utils';
import { displaySuccess, displayError, GreenTick, RedCross } from 'Dashboard/src/Utils';
import { useUser } from '../../../../../common/hooks';
import UserFeaturePage from '../../../components/common/user-feature-page';
import UserFeatureComponent from '../../../components/common/user-feature-component';
import HeaderComponent from '../../../components/common/header-component';
import CustomSelect from '../../../components/common/custom-select';
import { DateTime } from 'luxon';
import { dateTimeString } from '../../../components/common/DateTimeFormat';
import confirm from '../../../components/common/confirm';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import BottomActionToolbar from '../../../components/common/toolbar';
import { get, isArray, isEmpty } from 'lodash-es';
import Pagination from '../../../components/common/custom-pagination';
import ReactTable8 from 'Dashboard/src/components/common/Table8';
import RadioButton from 'Dashboard/src/components/common/radioButton';

const oneWeekFromToday = DateTime.now().plus({ days: 7 }).toFormat(`yyyy-MM-dd'T'HH:00`);

function SiteBriefingList(props) {
  const navigate = useNavigate();
  const [stages, setStages] = useState(['PM Only']);
  const [companyFilter, setCompanyFilter] = useState('');
  const { pageNo } = useParams();
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('updated_at');
  const [orderDirection, setOrderDirection] = useState('desc');
  const [currentBriefing, setCurrentBriefing] = useState(null);
  const [BDQModal, setBDQModal] = useState(false);
  const [DiscussionDeckModal, setDiscussionDeckModal] = useState(false);
  const [projectManagerId, setProjectManagerId] = useState(null);
  const [meetingDateTime, setMeetingDateTime] = useState(oneWeekFromToday);
  const [emailToSendBDQ, setEmailToSendBDQ] = useState('');
  const [emailToSendDiscussionDeck, setEmailToSendDiscussionDeck] = useState('');

  const bdqEmailOptions = new Set();

  currentBriefing?.info?.email_addr_general?.forEach((email) => bdqEmailOptions.add(email[0]));
  currentBriefing?.info?.email_addr_owner?.forEach((email) => bdqEmailOptions.add(email[0]));

  const sendParams = {
    page_size: pageSize,
    page: pageNo,
    order_by: orderBy,
    order_direction: orderDirection,
    stages,
    company_filter: companyFilter,
  };

  const { data } = useGetSiteBriefingsQuery(sendParams);
  const briefings = get(data, 'briefings', []);
  const pagination = get(data, 'pagination', {});

  const { data: users } = useGetUsersQuery();

  const [createSiteBriefing] = useCreateSiteBriefingMutation();
  const [deleteBriefing] = useDeleteSiteBriefingMutation();
  const [generateInstance] = useGenerateInstanceFromSiteBriefingMutation();
  const [generateContent] = useGenerateContentFromSiteBriefingMutation();
  const [crawlSite] = useCrawlSiteMutation();
  const [sendBDQNotification] = useSendBDQNotificationMutation();
  const [sendDiscussionDeckNotification] = useSendDiscussionDeckNotificationMutation();

  const user = useUser();

  useEffect(() => {
    setPageTitle('Site Briefing List');
  }, []);

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Briefings', url: '' },
  ];

  const headerButton = (title, key) => (
    <Button
      onClick={() => {
        setOrderBy(key);
        setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
      }}
      className="custom-simple-txt-btn fw-bold text-start"
    >
      <span>
        {`${title} `}
        {orderBy === key
          ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            )
          : ''}
      </span>
    </Button>
  );

  const boolCell = ({ getValue }) => getValue() ? <GreenTick /> : <RedCross />;

  const tableColumns = [];

  if (stages.includes('PM Only')) {
    tableColumns.push({
      header: headerButton('Instance Created', 'site_id'),
      id: 'instance_created',
      accessorFn: (b) => b.site_id,
      cell: boolCell,
    });

    tableColumns.push({
      header: 'Instance Created At',
      id: 'instance_created_at',
      accessorFn: (b) => b.site_created_at,
      cell: ({ getValue }) => {
        const value = getValue();
        return value ? dateTimeString(value, user?.time_zone, DateTime.DATE_SHORT) : '';
      },
    });

    tableColumns.push({
      header: headerButton('Content Created', 'content_created'),
      id: 'content_created',
      accessorFn: (b) => b.content_created,
      cell: boolCell,
    });
  }

  if (stages.includes('Adviser')) {
    tableColumns.push({
      header: headerButton('Adviser Completed', 'adviser_completed'),
      id: 'adviser_completed',
      accessorFn: (b) => b.adviser_completed,
      cell: boolCell,
    });
  }

  if (stages.includes('BDQ')) {
    tableColumns.push({
      header: headerButton('BDQ Completed', 'bdq_completed'),
      id: 'bdq_completed',
      accessorFn: (b) => b.bdq_completed,
      cell: boolCell,
    });
  }

  tableColumns.push({
    header: headerButton('Company Name', 'info.company_name'),
    accessorKey: 'info.company_name',
    minSize: 180,
  });

  if (stages.includes('PM Only')) {
    tableColumns.push({
      header: headerButton('Domain', 'info.temp_domain'),
      id: 'domain',
      accessorFn: (b) =>
        b.info.temp_domain ? `www.${b.info.temp_domain}.wb.gs` : '',
    });

    tableColumns.push({
      header: 'Project Manager',
      accessorKey: 'project_manager_name',
    });
  }

  tableColumns.push({
    header: 'Adviser',
    accessorKey: 'adviser_name',
  });

  if (stages.includes('PM Only')) {
    tableColumns.push({
      header: headerButton('Brief Date', 'info.brief_date'),
      id: 'brief_date',
      accessorFn: (b) => b.info.brief_date,
      cell: ({ getValue }) =>
        dateTimeString(getValue(), user?.time_zone, DateTime.DATE_SHORT),
    });
  }

  tableColumns.push({
    header: headerButton('Updated', 'updated_at'),
    id: 'updated_at',
    accessorFn: (b) => b.updated_at,
    cell: ({ getValue }) =>
      dateTimeString(getValue(), user?.time_zone, DateTime.DATE_SHORT),
  });

  tableColumns.push({
    header: 'Actions',
    id: 'actions',
    size: 180,
    cell: ({ row }) => {
      const b = row.original;
      const options = [
        { label: 'Edit', value: 'Edit' },
        { label: 'Delete', value: 'Delete' },
      ];

      if (b.pm_only) {
        if (b.site_id) {
          if (!b.content_created) {
            options.push({ label: 'Generate content', value: 'Generate content' });
          }
        } else {
          options.push(
            { label: 'Generate instance (NO content)', value: 'Generate instance (NO content)' },
            { label: 'Generate instance (WITH content)', value: 'Generate instance (WITH content)' },
          );
        }
      } else {
        if (b.adviser_completed) {
          // These two do the same thing, just changing the label to make it clear that the user is RE-sending the BDQ request.
          if (b.bdq_request_sent) {
            options.push({ label: 'Re-send BDQ request', value: 'Send BDQ request' });
          } else {
            options.push({ label: 'Send BDQ request', value: 'Send BDQ request' });
          }
        }

        if (b.bdq_completed) {
          options.push({ label: 'Send Discussion Deck request', value: 'Send Discussion Deck request' });
          options.push({ label: 'PM Meeting (single window)', value: 'PM Meeting (single window)' });
          options.push({ label: 'PM Meeting (dual window)', value: 'PM Meeting (dual window)' });
        }
      }

      options.push({ label: 'Download PDF', value: 'Download PDF' });

      if (b.adviser_completed) {
        options.push({ label: 'View BDQ', value: 'View BDQ' });
      }

      if (b.bdq_completed) {
        options.push({ label: 'View Discussion Deck', value: 'View Discussion Deck' });
      }

      return (
        <CustomSelect
          options={options}
          onChange={(e) => {
            switch (e.value) {
              case 'Edit':
                return onEdit(b);
              case 'Delete':
                return onDelete(b);
              case 'Generate content':
                return onGenerateContent(b);
              case 'Generate instance (NO content)':
                return onGenerateInstance({ briefing: b, content: false });
              case 'Generate instance (WITH content)':
                return onGenerateInstance({ briefing: b, content: true });
              case 'Send BDQ request': {
                setCurrentBriefing(b);
                setBDQModal(true);

                const allEmails = [...(b.info.email_addr_general || []), ...(b.info.email_addr_owner || [])];
                setEmailToSendBDQ(allEmails.find((email) => email[4])?.[0] || allEmails[0]?.[0] || '');

                break;
              }
              case 'Send Discussion Deck request': {
                setCurrentBriefing(b);
                setProjectManagerId(b.project_manager_id);
                setMeetingDateTime(b.meeting_date_time);
                setDiscussionDeckModal(true);
                break;
              }
              case 'Download PDF':
                window.location.assign(`/api/v1/briefings/${b.id}/pdf`);
                break;
              case 'View BDQ':
                window.open(`/bdq/briefing/${b.id}`, '_blank');
                break;
              case 'View Discussion Deck':
                window.open(`/discussion-deck/briefing/${b.id}`, '_blank');
                break;
              case 'PM Meeting (single window)':
                window.open(`/pm-meeting/briefing/${b.id}`, '_blank');
                break;
              case 'PM Meeting (dual window)':
                window.open(`/discussion-deck/briefing/${b.id}`, '_blank');
                window.open(`/pm-meeting-form/briefing/${b.id}`, '_blank');
                break;
              default:
                return null;
            }
          }}
        />
      );
    },
  });

  const onEdit = (briefing) => {
    if (briefing.pm_only) {
      navigate(replacePathParams(settingRoutes.siteBriefingForm, [{ key: 'id', value: briefing.id }], props));
    } else {
      // if (!briefing.adviser_completed) {
      navigate(replacePathParams(settingRoutes.siteBriefingAdviserForm, [{ key: 'id', value: briefing.id }], props));
      //   return;
      // }
      // if (!briefing.bdq_completed) {
      //   navigate(replacePathParams(settingRoutes.siteBriefingBdqForm, [{ key: 'id', value: briefing.id }], props));
      //   return;
      // }
      // if (!briefing.pm_completed) {
      //   navigate(replacePathParams(settingRoutes.siteBriefingPmForm, [{ key: 'id', value: briefing.id }], props));
      //   return;
      // }
    }
  };

  const onDelete = async (briefing) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const res = await deleteBriefing(briefing);
      if (res && res?.data?.meta?.is_success) {
        displaySuccess(res?.data?.meta?.messages);
      }
    }
  };

  const onGenerateInstance = async (options) => {
    const confirmed = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (confirmed) {
      const result = await generateInstance(options);
      if (result.error) {
        displayError(`Errors in instance generation:\n\n${Object.keys(result.error.data.data).map((k) => result.error.data.data[k])}`);
      } else {
        displaySuccess('Instance generation started.');
      }
    }
  };

  const onGenerateContent = async (briefing) => {
    const confirmed = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (confirmed) {
      const result = await generateContent(briefing);
      if (result.error) {
        displayError(`Errors in content generation:\n\n${Object.keys(result.error.data.data).map((k) => result.error.data.data[k])}`);
      } else {
        displaySuccess('Content generation started.');
      }
    }
  };

  const onChangePage = (e) => {
    navigate(
      replacePathParams(
        settingRoutes.siteBriefings,
        [
          { key: 'pageNo', value: e },
        ],
        props,
      ),
    );
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const stageButton = (stage) => (
    <Button
      color="primary"
      outline
      onClick={() => {
        if (stages.includes(stage)) {
          setStages(stages.filter((s) => s !== stage));
        } else if (stage === 'PM Only') {
          setStages(['PM Only']);
        } else {
          setStages([...stages.filter((s) => s !== 'PM Only'), stage]);
        }
      }}
      active={stages.includes(stage)}
      className="text-center"
    >
      {stage}
    </Button>
  );

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Briefings',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Settings',
        backToPath: replacePathParams(mainRoutes.setting, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />

      <UserFeaturePage feature={['view_briefings', 'create_edit_briefings']}>
        <UserFeatureComponent feature="view_briefings">
          <Card className="mb-4">
            <CardBody>
              <div className="d-flex">
                <div className="d-flex">
                  {stageButton('PM Only')}
                </div>
                <div className="d-flex align-items-center mx-2">
                  OR Filter by Stage:
                </div>
                <div className="d-flex align-items-center">
                  {stageButton('Adviser')}
                  &nbsp;&nbsp;&nbsp;
                  ⟶
                  &nbsp;&nbsp;&nbsp;
                  {stageButton('PC Review')}
                  &nbsp;&nbsp;&nbsp;
                  ⟶
                  &nbsp;&nbsp;&nbsp;
                  {stageButton('BDQ')}
                  &nbsp;&nbsp;&nbsp;
                  ⟶
                  &nbsp;&nbsp;&nbsp;
                  {stageButton('Briefing Session')}
                  &nbsp;&nbsp;&nbsp;
                  ⟶
                  &nbsp;&nbsp;&nbsp;
                  {stageButton('Content Prep')}
                  &nbsp;&nbsp;&nbsp;
                  ⟶
                  &nbsp;&nbsp;&nbsp;
                  {stageButton('Implementation')}
                </div>
              </div>
              <FormGroup className="mt-4">
                <Input
                  type="text"
                  placeholder="Filter by Company Name"
                  onChange={(e) => setCompanyFilter(e.target.value)}
                />
              </FormGroup>
            </CardBody>
          </Card>

          {pagination && pagination?.count > 0 && (
            <Pagination
              type={['brief', 'briefs']}
              currentPage={Number(pageNo)}
              totalRecord={pagination.count}
              perPage={pagination.per_page}
              totalPages={pagination.pages}
              records={briefings}
              onChangePage={onChangePage}
              getPagination={pagination}
              pageSize={pageSize}
              changePageSize={changePageSize}
            />
          )}
          {pagination?.count === 0 && (
            <div className="text-center fw-bold mb-4">
              No briefs found.
            </div>
          )}
          <Card className="SiteBriefing mb-4">
            <CardBody>
              <ReactTable8
                columns={tableColumns}
                data={briefings || []}
              />
            </CardBody>
          </Card>
        </UserFeatureComponent>

        <UserFeatureComponent feature="create_edit_briefings">
          <BottomActionToolbar
            component={(
              <>
                <CustomButton
                  color="info"
                  className="add-new-button button-md fw-bold"
                  title="ADD BRIEFING"
                  icon="fa fa-plus"
                  onClick={() => {
                    createSiteBriefing({
                      sales_adviser_id: user?.role?.name === 'Sales Adviser' ? user?.id : null,
                    });
                  }}
                />
                <CustomButton
                  color="info"
                  className="add-new-button fw-bold px-3 ms-2"
                  title="ADD PM-ONLY BRIEFING"
                  icon="fa fa-plus"
                  onClick={() => createSiteBriefing({ pm_only: true })}
                />
              </>
            )}
          />
        </UserFeatureComponent>

        {BDQModal && (
          <Modal isOpen={true} toggle={() => setBDQModal(false)} size="lg">
            <ModalHeader toggle={() => setBDQModal(false)}>Send BDQ Request</ModalHeader>
            <ModalBody>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle>
                    Adviser Brief Outcome
                  </CardTitle>
                  <CardText>
                    <Table>
                      <tbody>
                        <tr style={{ verticalAlign: 'middle' }}>
                          <td>Site Crawl</td>
                          <td>
                            {isArray(currentBriefing?.info?.site_crawl_data) && currentBriefing?.info?.site_crawl_data.length > 0
                              ? (
                                  <>
                                    <GreenTick />
                                    {' '}
                                    {currentBriefing?.info?.site_crawl_data.length}
                                    {' '}
                                    {currentBriefing?.info?.site_crawl_data.length > 1 ? 'pages' : 'page'}
                                    {' '}
                                    crawled
                                  </>
                                )
                              : (
                                  <>
                                    <RedCross />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button
                                      className="btn btn-primary"
                                      onClick={async () => {
                                        const confirmResult = await confirm({
                                          title: <b>Confirm!</b>,
                                          message: <p>This will take some time. Are you sure?</p>,
                                          confirmText: 'Yes',
                                          confirmColor: 'success',
                                          cancelColor: 'btn btn-danger',
                                          cancelText: 'No',
                                        });

                                        if (!confirmResult) return;

                                        const crawlResult = await crawlSite(currentBriefing);
                                        if (crawlResult.error) {
                                          displayError(crawlResult.error.data.data);
                                        } else {
                                          displaySuccess('Site crawled successfully.');

                                          setCurrentBriefing({
                                            ...currentBriefing,
                                            info: {
                                              ...currentBriefing.info,
                                              site_crawl_data: crawlResult.data.data,
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      Re-crawl Site
                                    </button>
                                  </>
                                )}
                          </td>
                        </tr>
                        <tr>
                          <td>Temporary User Created</td>
                          <td>
                            {currentBriefing?.temp_user_id
                              ? <GreenTick />
                              : <RedCross />}
                          </td>
                        </tr>
                        <tr>
                          <td>AI Content Generated</td>
                          <td>
                            {/*
                              TODO: We are currently just checking one prompt from the "PreBDQ" context, we really
                              should have some way to check all of them (without having to list every prompt here).
                            */}
                            {!isEmpty(currentBriefing?.ai_content?.ClientConversationSummary)
                              ? <GreenTick />
                              : <RedCross />}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardText>
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <FormGroup>
                    <Label>Select the email address to send the BDQ to:</Label>
                    {(() => {
                      return [...bdqEmailOptions].map((email) => (
                        <div key={email}>
                          <RadioButton
                            title={email}
                            checked={email === emailToSendBDQ}
                            onChange={() => setEmailToSendBDQ(email)}
                          />
                        </div>
                      ));
                    })()}
                    <div>
                      <RadioButton
                        title="Other:"
                        checked={!bdqEmailOptions.has(emailToSendBDQ)}
                        onChange={() => setEmailToSendBDQ('')}
                      />
                    </div>
                  </FormGroup>
                  {!bdqEmailOptions.has(emailToSendBDQ) && (
                    <FormGroup className="ms-4">
                      <Label>Other Email Address:</Label>
                      <Input
                        type="email"
                        value={emailToSendBDQ}
                        onChange={(e) => setEmailToSendBDQ(e.target.value)}
                        disabled={bdqEmailOptions.has(emailToSendBDQ)}
                      />
                    </FormGroup>
                  )}
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                onClick={async () => {
                  if (emailToSendBDQ === '') {
                    displayError('Please select an email address.');
                    return;
                  }

                  const result = await sendBDQNotification({
                    briefing: currentBriefing,
                    email: emailToSendBDQ,
                  });
                  if (result.error) {
                    displayError(result.error.data.data);
                  } else {
                    displaySuccess('BDQ request sent.');
                    setBDQModal(false);
                  }
                }}
              >
                SEND
              </Button>
              <Button color="danger" onClick={() => setBDQModal(false)}>
                CANCEL
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {DiscussionDeckModal && (
          <Modal isOpen={true} toggle={() => setDiscussionDeckModal(false)}>
            <ModalHeader toggle={() => setDiscussionDeckModal(false)}>Send Discussion Deck</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Project Manager:</Label>
                <CustomSelect
                  options={users?.filter((u) => u.role?.name === 'Project Manager').map((u) => ({ label: u.full_name, value: u.id }))}
                  value={{ label: users?.find((u) => u.id === projectManagerId)?.full_name, value: projectManagerId }}
                  onChange={(e) => setProjectManagerId(e.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Meeting Date/Time:</Label>
                <Input
                  type="datetime-local"
                  value={meetingDateTime}
                  onChange={(e) => setMeetingDateTime(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Select the email address to send the Discussion Deck to:</Label>
                {(() => {
                  return [...bdqEmailOptions].map((email) => (
                    <div key={email}>
                      <RadioButton
                        title={email}
                        checked={email === emailToSendDiscussionDeck}
                        onChange={() => setEmailToSendDiscussionDeck(email)}
                      />
                    </div>
                  ));
                })()}
                <div>
                  <RadioButton
                    title="Other:"
                    checked={!bdqEmailOptions.has(emailToSendDiscussionDeck)}
                    onChange={() => setEmailToSendDiscussionDeck('')}
                  />
                </div>
              </FormGroup>
              {!bdqEmailOptions.has(emailToSendDiscussionDeck) && (
                <FormGroup className="ms-4">
                  <Label>Other Email Address:</Label>
                  <Input
                    type="email"
                    value={emailToSendDiscussionDeck}
                    onChange={(e) => setEmailToSendDiscussionDeck(e.target.value)}
                    disabled={bdqEmailOptions.has(emailToSendDiscussionDeck)}
                  />
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                onClick={async () => {
                  if (!projectManagerId) {
                    displayError('Please select a Project Manager.');
                    return;
                  }

                  if (emailToSendDiscussionDeck === '') {
                    displayError('Please select an email address.');
                    return;
                  }

                  const result = await sendDiscussionDeckNotification({
                    briefing: currentBriefing,
                    project_manager_id: projectManagerId,
                    meeting_date_time: meetingDateTime,
                    email: emailToSendDiscussionDeck,
                  });
                  if (result.error) {
                    displayError(result.error.data.data);
                  } else {
                    displaySuccess('Discussion Deck sent.');
                    setDiscussionDeckModal(false);
                  }
                }}
              >
                SEND
              </Button>
              <Button color="danger" onClick={() => setDiscussionDeckModal(false)}>
                CANCEL
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </UserFeaturePage>
    </>
  );
}

export default withRouter(SiteBriefingList);
