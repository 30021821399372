import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import { Form, Row, Col } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import {
  useGetSiteBriefingQuery,
} from '../../api/dashboardApiSlice';
import Briefing from 'common/models/Briefing';
import { usePageClassName } from 'common/hooks';
import { cable, BriefingMeetingChannel } from 'Dashboard/src/Channels';
import { isEmpty, get } from 'lodash-es';
import './index.css';

interface Props {
  params: {
    briefingId: string;
  };
  inPMMeeting: boolean;
}

type Message = object | string | number;

type BriefingMeetingMessage = {
  action: string;
  briefing_id: string;
  page: number;
};

function DiscussionDeck(props: Props) {
  usePageClassName('DiscussionDeck');

  const { params, inPMMeeting } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [random, setRandom] = useState(Math.random());
  const [height, setHeight] = useState<number | null>(null);
  const { data: briefing, refetch: refetchBriefing } = useGetSiteBriefingQuery(params.briefingId);
  const [tempBriefing, setTempBriefing] = useState<Briefing | null>(null);

  const formRef = useCallback((node: HTMLFormElement | null) => {
    if (!inPMMeeting || !random)
      return;

    if (!node || !(node.parentNode instanceof HTMLDivElement))
      return;

    setHeight(node.parentNode.clientHeight);
  }, [inPMMeeting, random]);

  useEffect(() => {
    if (briefing && !tempBriefing) {
      setTempBriefing(briefing);

      // TEMP: For testing
      // setCurrentPage(43);
    }
  }, [briefing, tempBriefing]);

  useEffect(() => {
    const channel = new BriefingMeetingChannel({ briefing_id: params.briefingId });
    cable.subscribe(channel);

    channel.on('message', async (message: Message) => {
      const briefingMessage = message as BriefingMeetingMessage;

      if (briefingMessage.briefing_id !== params.briefingId) {
        return;
      }

      switch (briefingMessage.action) {
        case 'change_page':
          setCurrentPage((message as BriefingMeetingMessage).page);
          setRandom(Math.random());
          break;
        case 'refetch_briefing': {
          const result = await refetchBriefing();
          if (result?.data?.data) {
            setTempBriefing(result.data.data);
          }
          break;
        }
      }
    });
  }, [params.briefingId, refetchBriefing]);

  // Scroll to the current page
  useEffect(() => {
    const pageContainer = document.querySelector(`.page-container[data-page-number="${currentPage}"]`);
    if (pageContainer) {
      pageContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentPage, random]);

  if (!tempBriefing) {
    return null;
  }

  const pmImage = tempBriefing.project_manager_name?.toLowerCase().replace(' ', '-');

  const pages: ReactNode[] = [];

  pages.push(
    <div className="page intro-page">
      <div className="logo-cont" />
      <div className="left-cont">
        <div>
          <h1>
            <strong>
              Website Brief
            </strong>
            <br />
            Discussion Deck
          </h1>
          <em>
            For
            {' '}
            {tempBriefing.info!.company_name}
          </em>
          <br />
          <br />
          <p>
            Each slide in the deck covers a content element that will feed into the website.
          </p>
          {!inPMMeeting && (
            <>
              <p>
                At your brief we will go through each slide in the deck with you to get the information we need.
              </p>
              <p>
                If possible, take some time before your brief to flick through the deck to consider your responses. That&apos;s it: nothing else required for now.
              </p>
            </>
          )}
          {inPMMeeting && (
            <p>
              We will go through each slide in the deck with you to get the information we need.
            </p>
          )}
        </div>
      </div>
      <div className="right-cont" />
    </div>,
  );

  pages.push(
    <div className="page project-manager-page">
      <div className="left">
        <h1>
          Hi, I&apos;m
          {' '}
          <strong>
            {tempBriefing.project_manager_name?.split(' ')[0]}
          </strong>
          ,
          {' '}
          your website Project Manager
        </h1>

        {!inPMMeeting && (
          <>
            <p>
              I&apos;m looking forward to your online Briefing Session on
            </p>

            <button
              type="button"
              className="btn mb-3"
            >
              Tuesday 16 March at 9.30am
            </button>

            <p>
              I will send you a link to join the online meeting the day before.
            </p>
          </>
        )}

        <div
          style={{
            border: '1px solid white',
            borderRadius: '10px',
            padding: '12px 20px',
            lineHeight: '36px',
          }}
        >
          <div style={{ fontSize: '26px' }}>
            {tempBriefing.project_manager_name}
          </div>
          <div>
            Web Genius Project Manager
          </div>
          <div>
            <i className="fal fa-phone" style={{ marginRight: '8px' }} />
            {' '}
            Phone: 04 299 2285
          </div>
          <div>
            <i className="fal fa-envelope" style={{ marginRight: '8px' }} />
            {' '}
            Email:
            {' '}
            {tempBriefing.project_manager_email}
          </div>
        </div>
      </div>
      <div className="right">
        <img src={`https://www.webgenius.co.nz/media/0/0/wg-staff-${pmImage}`} />
      </div>
    </div>,
  );

  pages.push(
    <div className="page contact-details-page tall-page">
      <div className="inner">
        <h2 className="b">
          1
          {' '}
          ⟶
          {' '}
          Contact Details
        </h2>

        <p>
          Check the following contact details to make sure all phone numbers and addresses are correct.
        </p>
        <p>
          This also ensures all enquiries and reports are directed to the right places.
        </p>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gridTemplateRows: 'auto auto',
            gap: '20px',
            fontSize: '14px',
          }}
        >
          <div>
            <button
              type="button"
              className="btn btn-secondary mb-3"
            >
              Phone Numbers
            </button>
            <div>
              <table className="table">
                <tbody>
                  <tr>
                    <td className="b">Business Landline:</td>
                    <td>{tempBriefing.info?.phone_number_landline}</td>
                  </tr>
                  <tr>
                    <td className="b">Freephone Number:</td>
                    <td>{tempBriefing.info?.phone_number_freephone}</td>
                  </tr>
                  {!isEmpty(tempBriefing.info?.phone_number_mobile_1) && (
                    <tr>
                      <td className="b">Owner/Manager Mobile 1:</td>
                      <td>{tempBriefing.info?.phone_number_mobile_1}</td>
                    </tr>
                  )}
                  {!isEmpty(tempBriefing.info?.phone_number_mobile_2) && (
                    <tr>
                      <td className="b">Owner/Manager Mobile 2:</td>
                      <td>{tempBriefing.info?.phone_number_mobile_2}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-secondary mb-3"
            >
              Physiscal Address
            </button>
            <div>
              <table className="table">
                {(tempBriefing?.info?.premiseses || [{}]).map((premises, index) => (
                  <tr key={index}>
                    <td className="b">
                      {premises?.types?.join(', ')}
                      :
                    </td>
                    <td>{premises.location}</td>
                  </tr>
                ))}
                <tr>
                  <td className="b">Postal Address:</td>
                  <td>{tempBriefing.info?.postal_address}</td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-secondary mb-3"
            >
              Email
            </button>
            <div>
              <table className="table">
                <tr>
                  <td className="b">General Business Addresses:</td>
                  <td>
                    {tempBriefing?.info?.email_addr_general?.map((email) => email[0]).join(', ')}
                  </td>
                </tr>
                <tr>
                  <td className="b">Business Owner/Manager Addresses:</td>
                  <td>
                    {tempBriefing?.info?.email_addr_owner?.map((email) => email[0]).join(', ')}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-secondary mb-3"
            >
              Social Media
            </button>
            <div>
              <table className="table">
                {!isEmpty(tempBriefing?.info?.social_google_business_profile) && (
                  <tr>
                    <td className="b">Google Profile:</td>
                    <td>{tempBriefing.info?.social_google_business_profile}</td>
                  </tr>
                )}
                {!isEmpty(tempBriefing?.info?.social_facebook) && (
                  <tr>
                    <td className="b">Facebook:</td>
                    <td>{tempBriefing.info?.social_facebook}</td>
                  </tr>
                )}
                {!isEmpty(tempBriefing?.info?.social_instagram) && (
                  <tr>
                    <td className="b">Instagram:</td>
                    <td>{tempBriefing.info?.social_instagram}</td>
                  </tr>
                )}
                {!isEmpty(tempBriefing?.info?.social_youtube) && (
                  <tr>
                    <td className="b">Youtube:</td>
                    <td>{tempBriefing.info?.social_youtube}</td>
                  </tr>
                )}
                {!isEmpty(tempBriefing?.info?.social_other) && (
                  <tr>
                    <td className="b">Other:</td>
                    <td>{tempBriefing.info?.social_other}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>,
  );

  pages.push(
    <div className="page website-structure-page inverse-page">
      <div className="inner">
        <h2 className="b">WEBSITE STRUCTURE</h2>

        <p>
          {!inPMMeeting ? 'At your brief we will finalise' : 'This shows'}
          {' '}
          the page structure of your website.
        </p>
        <p>
          Research shows the best structure to follow is that which your customers are likely to expect, therefore making it easier for them navigate and find what they are looking for.
        </p>
        {!inPMMeeting && (
          <p>
            The next slide has your draft structure which we will discuss and finalise at your Briefing Session.
          </p>
        )}
      </div>
    </div>,
  );

  let websiteStructure = tempBriefing.meeting_feedback?.WebsiteStructure;

  if (isEmpty(websiteStructure)) {
    websiteStructure = [
      {
        id: 'Home',
        name: 'Home',
        children: [
          {
            id: 'About Us',
            name: 'About Us',
          },
          ...(tempBriefing?.info?.asap_pages || []).map((page) => ({
            id: page,
            name: page,
          })),
          {
            id: 'Contact Us',
            name: 'Contact Us',
          },
        ],
      },
    ];
  }

  const renderPage = (page) => (
    <li key={page.id}>
      {page.name}
      {page.children && page.children.length > 0 && (
        <ul>
          {page.children.map((child) => renderPage(child))}
        </ul>
      )}
    </li>
  );

  pages.push(
    <div className="page draft-structure-page tall-page">
      <div className="inner">
        <h2 className="b">Draft Website Structure</h2>

        <ul>
          {renderPage(websiteStructure[0])}
        </ul>

        <p><strong>Other possibilities include:</strong></p>
        <ul>
          <li>Testimonials</li>
          <li>Online reviews</li>
          <li>FAQs</li>
          <li>Custom content..?</li>
        </ul>

        <p>
          {!inPMMeeting && (
            <>
              Look forward to confirming this with you at your Briefing Session.
              <br />
            </>
          )}
          (Bear in mind that adding pages may sometimes require additional investment)
        </p>
      </div>
    </div>,
  );

  pages.push(
    <div className="page content-snippets-page inverse-page">
      <div className="inner">
        <h2 className="b">CONTENT SNIPPETS</h2>

        <p>
          The next section covers a range of &quot;Content Snippets&quot;.
        </p>
        <p>
          These will be used as the basis to create your website copy.
        </p>
        {!inPMMeeting && (
          <>
            <p>
              Read them through and we will discuss your thoughts at your Briefing Session.
            </p>
            <p>
              Don&apos;t worry about anything that is inaccurate or incomplete as we can rectify them at your Briefing Session.
            </p>
          </>
        )}
      </div>
    </div>,
  );

  pages.push(
    <div className="page customer-needs-page">
      <div className="inner">
        <h2 className="b">What are your customers looking for?</h2>

        <div className="border p-4 my-5">
          <em>
            {tempBriefing.ai_content!.DraftCustomersLookingFor}
          </em>
        </div>
      </div>
    </div>,
  );

  pages.push(
    <div className="page big-promise-page">
      <div className="inner">
        <h2 className="b">Your Big Promise</h2>

        <div className="border p-4 my-5">
          <em>
            {tempBriefing.ai_content!.DraftBigPromise}
          </em>
        </div>
      </div>
    </div>,
  );

  pages.push(
    <div className="page differentiators-page">
      <div className="inner">
        <h2 className="b">Your Key Differentiators</h2>

        <div className="border p-4 my-5">
          <em>
            {tempBriefing.ai_content!.DraftKeyDifferentiators}
          </em>
        </div>
      </div>
    </div>,
  );

  pages.push(
    <div className="page keyword-phrases-page tall-page">
      <div className="inner">
        <h2 className="b">Main Google Keyword Phrases for website</h2>

        {!inPMMeeting && (
          <p>
            We will discuss these keywords at your Briefing Session and finalise the ones we use
            for our unique keywording systems for the Google optimisation of your website.
          </p>
        )}

        <ul>
          {tempBriefing.ai_content!.UpdatedHomePageSeedKeywordPhrases?.map((keyword, index) => (
            <li key={index}>{keyword}</li>
          ))}
          {tempBriefing.ai_content!.UpdatedAboutPageSeedKeywordPhrases?.map((keyword, index) => (
            <li key={index}>{keyword}</li>
          ))}
        </ul>

        {!isEmpty(tempBriefing.info!.other_google_keywords) && (
          <p>
            Other keywords:
            {' '}
            {tempBriefing.info!.other_google_keywords}
          </p>
        )}
      </div>
    </div>,
  );

  pages.push(
    <div className="page business-history-page">
      <div className="inner">
        <h2 className="b">Business History</h2>

        <div className="border p-4 my-5">
          <em>
            {isEmpty(tempBriefing.info?.business_story) ? tempBriefing.ai_content?.DraftBusinessStory : tempBriefing.info?.business_story}
          </em>
        </div>
      </div>
    </div>,
  );

  pages.push(
    <div className="page business-today-page">
      <div className="inner">
        <h2 className="b">The Business Today</h2>

        <div className="border p-4 my-5">
          <em>
            {isEmpty(tempBriefing.info?.business_looks_today) ? tempBriefing.ai_content?.DraftBusinessToday : tempBriefing.info?.business_looks_today}
          </em>
        </div>
      </div>
    </div>,
  );

  tempBriefing.info!.business_owners.forEach((owner, index) => {
    if (isEmpty(tempBriefing.info?.business_owner_background?.[index]))
      return;

    pages.push(
      <div className="page owner-profile-page tall-page" key={index}>
        <div className="inner">
          <h2 className="b">
            Business Owner Profile for
            {' '}
            {owner[0]}
            {' '}
            {owner[1]}
          </h2>

          <div className="border p-4 my-5">
            <em>
              <div style={{ maxHeight: '284px', overflowY: 'hidden' }}>
                {tempBriefing.info?.business_owner_background[index]}
              </div>
            </em>
          </div>
        </div>
      </div>,
    );
  });

  pages.push(
    <div className="page owner-quote-page">
      <div className="inner">
        <h2 className="b">Business Owner Quotes</h2>

        {tempBriefing.ai_content?.ClientSatisfaction?.map((quote, index) => (
          <blockquote key={index}>
            {quote}
          </blockquote>
        ))}
      </div>
    </div>,
  );

  pages.push(
    <div className="page team-members-page tall-page">
      <div className="inner">
        <h2 className="b">Team Members</h2>

        <div className="border p-4 my-5">
          <em>
            {tempBriefing.info?.other_team_members}
          </em>
        </div>

        <p>
          Which of these is the most suitable? We&apos;d like to choose one for your About Us page.
        </p>

        <p>
          Consider what if any other team members should be featured on your website.
          {!inPMMeeting && ' We will discuss at your Briefing Session.'}
        </p>
      </div>
    </div>,
  );

  tempBriefing.info!.asap_pages.forEach((page, pageIndex) => {
    pages.push(
      <div className="page selling-points-page">
        <div className="inner">
          <h2 className="b">
            {page}
            {' '}
            page: 7 Strengths/Selling Points
          </h2>

          <ol>
            {get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.ASAPSellingPoints`, []).map((point, index) => (
              <li key={index}>{point.selling_point}</li>
            ))}
          </ol>
        </div>
      </div>,
    );

    pages.push(
      <div className="page content-differentiators-page tall-page">
        <div className="inner">
          <h2 className="b">
            {page}
            {' '}
            page: Differentiators to emphasise
          </h2>

          <ul>
            {get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.ASAPDifferentiators`, []).map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>

          <p>
            Also consider any details that should be featured on this page, related to the benefits of doing business with you.
            {' '}
            {inPMMeeting && (
              'Even if it is obvious to you, it may not be obvious to your potential customers, so note down anything helpful.'
            )}
            {!inPMMeeting && (
              'We will discuss at your brief but even if it is obvious to you, it may not be obvious to your potential customers, so note down anything helpful.'
            )}
          </p>
        </div>
      </div>,
    );

    pages.push(
      <div className="page content-topics-page tall-page">
        <div className="inner">
          <h2 className="b">
            {page}
            {' '}
            page: Potential Content Topics
          </h2>

          <ol>
            {get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.ASAPPotentialContentTopics`, []).map((topic, index) => (
              <li key={index}>{topic.topic}</li>
            ))}
          </ol>

          <p>
            What else? Have a think about what else needs to be featured on this page
            {!inPMMeeting && ' and we will discuss at your Briefing Session'}
            .
          </p>
        </div>
      </div>,
    );

    pages.push(
      <div className="page page-keywords-page">
        <div className="inner">
          <h2 className="b">
            {page}
            {' '}
            page: Google Keyword Phrases
          </h2>

          <ul>
            {get(tempBriefing, `ai_content.asap_page_details.${pageIndex}.UpdatedProductPageSeedKeywordPhrases`, []).map((phrase, index) => (
              <li key={index}>{phrase}</li>
            ))}
          </ul>

          {!inPMMeeting && (
            <p>
              We will discuss these keywords at your Briefing Session and finalise the ones we use
              for our unique keywording systems for the Google optimisation of your website.
            </p>
          )}
        </div>
      </div>,
    );
  });

  pages.push(
    <div className="page design-details-page inverse-page">
      <div className="inner">
        <h2 className="b">DESIGN AND GRAPHIC DETAILS</h2>

        <p>
          {!inPMMeeting ? 'At your Briefing Session we\'ll cover' : 'These are'}
          {' '}
          your design preferences to make sure your website:
        </p>
        <ul>
          <li>Reflects your visual brand</li>
          <li>Presents your business in a professional light</li>
          <li>Appeals visually to your target market</li>
          <li>Effectively carries your main marketing messages</li>
        </ul>

        <p>
          All you need to do now is consider the designs on the following slides and think about which options best align with your preferences.
        </p>
        <p>
          It&apos;s okay if you don&apos;t have any specific preferences as you can leave it up to our designers.
        </p>
      </div>
    </div>,
  );

  pages.push(
    <div className="page design-ideas-page">
      <div className="inner">
        <h2 className="b">Design Ideas</h2>

        <h3>Design ideas</h3>
        <p>
          Over the next few slides we present some designs of Web Genius websites. Have a look through, noting your preferences
          {!inPMMeeting && ', and we will discuss at your Briefing Session'}
          .
        </p>
      </div>
    </div>,
  );

  const images = [
    ['https://www.sjk.co.nz/', 'https://cdn.webgenius.co.nz/cdn-cgi/image/format=auto%2Cwidth=1500/https://cdn.webgenius.co.nz/c/67eae4bd16d3c8a5fdf83bc3/portfolio-image-ex1?h=546441d2'],
    ['https://www.lifestylekitchensandbathrooms.co.nz/', 'https://cdn.webgenius.co.nz/cdn-cgi/image/format=auto%2Cwidth=1500/https://cdn.webgenius.co.nz/c/67eae4beba9c06bf9a76f251/portfolio-image-ex2?h=b0406647'],
    ['https://www.cromwellglass.co.nz/', 'https://cdn.webgenius.co.nz/cdn-cgi/image/format=auto%2Cwidth=1500/https://cdn.webgenius.co.nz/c/67eae4ba16d3c8a5fdf83bc0/portfolio-image-ex3?h=44eabe8f'],
    ['https://www.andersoncontracting.co.nz/', 'https://cdn.webgenius.co.nz/cdn-cgi/image/format=auto%2Cwidth=1500/https://cdn.webgenius.co.nz/c/67eae4bb9b82379808de1598/portfolio-image-ex4?h=3c315870'],
    ['https://www.archpoint.co.nz/', 'https://cdn.webgenius.co.nz/cdn-cgi/image/format=auto%2Cwidth=1500/https://cdn.webgenius.co.nz/c/67eae4bc33f2855f9f76f232/portfolio-image-ex5?h=2866acee'],
  ];

  images.forEach((imageDetails, index) => {
    pages.push(
      <div
        key={index}
        className="page design-ideas-page"
        style={{
          padding: '24px',
        }}
      >
        <a
          className="text-center"
          href={imageDetails[0]}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={imageDetails[1]}
            style={{
              height: 'calc(167mm - 48px)',
            }}
          />
        </a>
      </div>,
    );
  });

  pages.push(
    <div className="page photo-brief-page">
      <div className="inner">
        <h2 className="b">Photo/Images Brief</h2>

        <p>
          The photos and images on your website are a crucial part of presenting an impressive visual impression and telling your business story.
        </p>
        <p>
          Your photos can be a combination of your own photos and stock images from your industry.
        </p>
        <p>
          We can use photos from your existing website, and any you upload to the folder we have set up. We cover this on the next slide.
        </p>
      </div>
    </div>,
  );

  if (tempBriefing.google_drive_folder_id) {
    pages.push(
      <div className="page photos-upload-page inverse-page">
        <div className="inner">
          <h2 className="b">Upload Your Photos</h2>

          <p>
            Please upload all available photos to the Google Drive folder we have set up for you, preferably before your brief. You will need a Google account to do this.
          </p>
          <p>
            Simply use this link and follow the instructions:
          </p>

          <a href={`https://drive.google.com/drive/folders/${tempBriefing.google_drive_folder_id}`} target="_blank" rel="noreferrer">
            <button
              type="button"
              className="btn btn-primary mb-5"
            >
              Upload Images to Google Drive
            </button>
          </a>
        </div>
      </div>,
    );
  }

  pages.push(
    <div className="page other-elements-page inverse-page">
      <div className="inner">
        <h2 className="b">OTHER PAGES AND ELEMENTS</h2>

        <p>
          The last few slides ask you to consider the following content:
        </p>
        <ul>
          <li>Website header and footer</li>
          <li>Calls to action</li>
          <li>Content on your website&apos;s other pages</li>
        </ul>
        <p>Then you&apos;re done!</p>
      </div>
    </div>,
  );

  pages.push(
    <div className="page header-footer-page">
      <div className="inner">
        <h2 className="b">Website Header and Footer</h2>

        <h3>HEADER:</h3>
        <p>
          The standard content of your website header is Logo, Contact Phone Number and Orientation Statement (a plain English statement that describes what you do and the region you cover) Here&apos;s an example:
        </p>

        <img
          src="/assets/images/discussion-deck/header.png"
          className="mt-2 mb-5"
          style={{
            maxWidth: '100%',
          }}
        />

        {!inPMMeeting && (
          <p>
            We can explain in more depth at your Briefing Session and discuss any other requirements you may have.
          </p>
        )}
      </div>
    </div>,
  );

  pages.push(
    <div className="page header-footer-page tall-page">
      <div className="inner">
        <h2 className="b">Website Header and Footer</h2>

        <Row>
          <Col md="4">
            <h3>FOOTER:</h3>
            <p>
              The standard features of the website footer include a contact form, contact details, and industry logos, as per the example on the right.
            </p>
            {!inPMMeeting && (
              <p>
                Again we can discuss your specific requirements at your Briefing Session.
              </p>
            )}
          </Col>
          <Col md="8">
            <img
              src="/assets/images/discussion-deck/footer.png"
              className="mt-2 mb-5"
              style={{
                maxWidth: '100%',
              }}
            />
          </Col>
        </Row>
      </div>
    </div>,
  );

  pages.push(
    <div className="page calls-to-action-page">
      <div className="inner">
        <h2 className="b">Calls to Action</h2>

        <p>
          Your Calls to Action are the ways that your website visitors make contact with you, and can include:
        </p>
        <ul>
          <li>Call us</li>
          <li>Text us</li>
          <li>Chat with our chatbot</li>
          <li>Contact us</li>
          <li>Get a quote</li>
          <li>Book a consultation</li>
          <li>Contact us for &quot;reason here&quot;</li>
          <li>Arrange a site visit</li>
        </ul>

        <p>
          <strong>Website forms</strong>
          {' '}
          – default fields are: First name, Last name, Email address, Contact number, Details. Do you require anything else?
        </p>
        <p>
          How quickly can you get back to customers who fill in a form? Within the hour? Within one business day?
        </p>
        {!inPMMeeting && (
          <p>
            We will discuss your calls to action at your Briefing Session.
          </p>
        )}
      </div>
    </div>,
  );

  pages.push(
    <div className="page home-page-elements-page tall-page">
      <div className="inner">
        <h2 className="b">Home Page Elements</h2>

        <p>
          Your Home Page can include a number of optional features, such as:
        </p>

        <Row>
          <Col md="4">
            <h3>Testimonials or Testimonial slider:</h3>
          </Col>
          <Col md="8">
            <img
              src="/assets/images/discussion-deck/testimonials.png"
              className="mb-2"
              style={{
                maxWidth: '100%',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>&quot;Why Choose Us?&quot;</h3>
            <p>4 short statements on your main benefits:</p>
          </Col>
          <Col md="8">
            <img
              src="/assets/images/discussion-deck/why-choose-us.png"
              style={{
                maxWidth: '100%',
              }}
            />
          </Col>
        </Row>
      </div>
    </div>,
  );

  pages.push(
    <div className="page contact-page-elements-page">
      <div className="inner">
        <h2 className="b">Contact Page Elements</h2>

        <p>
          Standard contact page elements include:
        </p>
        <ul>
          <li>Phone and email contacts</li>
          <li>Contact form</li>
          <li>Address location</li>
          <li>Map</li>
        </ul>

        <p>
          You can also include optional elements such as a file upload function.
        </p>
        {!inPMMeeting && (
          <p>
            We will discuss your requirements at your Briefing Session.
          </p>
        )}
      </div>
    </div>,
  );

  pages.push(
    <div className="page other-page-elements-page">
      <div className="inner">
        <h2 className="b">Other Page Elements</h2>

        <p>
          Other possible pages for your Dominator website include
        </p>
        <ul>
          <li>Testimonials</li>
          <li>Gallery</li>
          <li>Enquiry</li>
          <li>Quote</li>
          <li>FAQs</li>
        </ul>

        {!inPMMeeting
          ? (
              <p>
                This can be discussed at your Briefing Session (remembering that adding pages may sometimes require additional investment.)
              </p>
            )
          : (
              <p>
                (Remember that adding pages may sometimes require additional investment.)
              </p>
            )}
      </div>
    </div>,
  );

  pages.push(
    <div className="page thank-you-page inverse-page">
      <div className="inner">
        <h2 className="b">That&apos;s it!</h2>

        {!inPMMeeting
          ? (
              <>
                <p>
                  Thank you and I look forward to discussing all this with you in more depth soon.
                </p>
                <p>
                  {tempBriefing.project_manager_name?.split(' ')[0]}
                  .
                </p>
              </>
            )
          : (
              <p>
                Thank you for your time today.
                <br />
                {tempBriefing.project_manager_name?.split(' ')[0]}
                .
              </p>
            )}

        <div style={{ marginTop: '30px' }}>
          <strong>
            {tempBriefing.project_manager_name}
          </strong>
          <br />
          Phone: 04 299 2285
          <br />
          Email:
          {' '}
          {tempBriefing.project_manager_email}
        </div>
      </div>
    </div>,
  );

  // pages.push(
  //   <div className="page">
  //     NEW BUILDS page:
  //     Potential FAQ questions
  //     What sets Resolute Construction apart as custom home builders for tailored residential new builds?
  //     How does Resolute Construction ensure eco-friendly and sustainable homes in new build projects?
  //     What should I expect from Resolute Construction&apos;s comprehensive project management for high-quality new construction?
  //     Can Resolute Construction design new builds to match my lifestyle and specific requirements?
  //     Does Resolute Construction offer competitive pricing for high-quality construction services in Kapiti and Wellington?
  //     How experienced is Resolute Construction in delivering tailored residential new builds in Greater Wellington?

  //     Eco Housing page:
  //     Potential FAQ Questions
  //     How does Resolute Construction ensure sustainable building solutions for eco housing projects in Kapiti?
  //     What are the benefits of passive homes, and how can they reduce energy costs?
  //     Can Resolute Construction customise eco-friendly home designs to suit my lifestyle and sustainability goals?
  //     What energy-efficient materials does Resolute Construction use to enhance insulation and sustainability?
  //     How does Resolute Construction&apos;s environmentally conscious construction minimise waste and environmental impact?
  //     What makes Resolute Construction the preferred choice for high-quality eco housing in Greater Wellington?

  //     Eco Housing page:
  //     Problems Solved
  //     Problem 1: Difficulty finding eco-friendly builders who balance sustainability with high-quality craftsmanship.
  //     Solution: Resolute Construction integrates sustainable materials and innovative designs with superior craftsmanship, delivering eco-friendly homes that meet your environmental and aesthetic expectations without compromising quality or functionality.
  //     Problem 2: Uncertainty about long-term energy savings and cost efficiency in eco-housing.
  //     Solution: Resolute Construction uses energy-efficient materials and Passive House solutions, ensuring reduced utility bills and improved energy performance, offering both immediate and long-term financial benefits.
  //     Problem 3: Lack of clear guidance on creating a sustainable home tailored to individual needs.
  //     Solution: Resolute Construction provides personalised eco-housing designs, seamlessly blending your lifestyle goals with sustainability, offering homes uniquely tailored to your vision and values.

  //     Renovations/Extensions page:
  //     Potential FAQ Questions
  //     How does Resolute Construction ensure seamless integration of renovations with my existing home&apos;s design and structure?
  //     Can Resolute Construction create bespoke home renovations tailored to my unique lifestyle and requirements?
  //     What eco-friendly materials and sustainable practices does Resolute Construction use in home extensions?
  //     How does Resolute Construction minimise disruption to daily life during home renovation projects?
  //     What makes Resolute Construction the preferred choice for high-quality renovations and extensions in Kapiti?
  //     How does Resolute Construction maintain clear and transparent communication throughout the renovation or extension process?

  //   </div>,
  // );

  // 167 is the height of the page-container (from DiscussionDeck/index.css), 3.78 is 1cm in px, and 0.95 is so we have
  // a bit of room on the top and bottom.
  let scale = height === null ? 1 : (height / (167 * 3.78)) * 0.95;
  if (scale > 1) {
    scale = 1;
  }

  return (
    <Form innerRef={formRef}>
      {pages.map((page, index) => (
        <div
          key={index}
          className={`page-container ${index === currentPage - 1 ? 'active' : ''}`}
          data-page-number={index + 1}
          style={{
            scale: `${scale}`, // This is converted to 0 without putting it in a string, not sure why
          }}
        >
          {page}
        </div>
      ))}
    </Form>
  );
}

export default withRouter(DiscussionDeck);
