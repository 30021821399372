import React, { useState } from 'react';
import { Button, Input, Row, Col, Card, CardBody } from 'reactstrap';
import { selectColorStyles, colourPalettePreset } from '../../../../../../Utils';
import { displayError, displaySuccess } from 'Dashboard/src/Utils';
import confirm from 'Dashboard/src/components/common/confirm';
import Select from 'react-select';
import CheckBox from 'Dashboard/src/components/common/checkBox';
import { isEmpty, get, map } from 'lodash-es';
import DragDropComponent from 'Dashboard/src/components/common/reactSortable';
import ColorPicker from 'Dashboard/src/components/common/color-picker';
import {
  useSectorCreateMutation,
  useSectorDeleteMutation,
  useSectorUpdateMutation,
  useSectorPositionMutation,
} from '../../../../api/dashboardApiSlice';

export const onKeyDownPrevent = (event) => {
  const { key, keyCode, code } = event;

  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace'].indexOf(code) > -1) {
    return true;
  }

  if (
    (keyCode >= 65 && keyCode <= 90) // A-Z
    || (keyCode >= 48 && keyCode <= 57) // 0-9
    || (keyCode >= 97 && keyCode <= 122) // a-z
  ) {
    return true;
  }

  if (key === '-' || key === '_') {
    return true;
  }

  return event.preventDefault();
};

const masterContentPositionOptions = [{ label: 'Top', value: 'top' }, { label: 'Bottom', value: 'bottom' }];

const TemplateSector = (props) => {
  const {
    site,
    instanceId,
    templateId,
    sectorList,
    onHandleChangeSectorData,
    onHandleUpdateSectorOrder,
  } = props;

  const [editThisId, setEditThisId] = useState('');

  const [createSector] = useSectorCreateMutation();
  const [updateSector] = useSectorUpdateMutation();
  const [deleteSector] = useSectorDeleteMutation();
  const [sectorPostion] = useSectorPositionMutation();

  const onHandleAddNewSector = async () => {
    const newSectorLabel = '';
    const checkSectorName = sectorList.filter((item) => item.label === newSectorLabel)[0];
    if (checkSectorName) {
      displayError('Rename blank sector before adding new.');
    } else {
      const sendParams = {
        instance_id: instanceId,
        label: newSectorLabel,
        background: '#ffffff',
        template_id: templateId,
      };
      const result = await createSector(sendParams);
      const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
      if (isSuccess) {
        displaySuccess(messages);
      }
    }
  };

  const onHandleUpdateSectorLabel = (e, index) => {
    const { target: { value, name } } = e;
    const sectorLabels = sectorList.map((sector) => (sector.label));

    if (!value) {
      displayError('Label name should have at least one character!');
    } else if (sectorLabels.includes(value)) {
      displayError(`${value} already exists!`);
    } else {
      onHandleChangeSectorData(index, value, name);
    }
  };

  const onHandleSetPrimarySector = (index, value) => {
    onHandleChangeSectorData(index, !value, 'is_primary');
  };

  const onHandleUpdateSectorId = (e) => {
    setEditThisId(e);
  };

  const onCancelUpdate = () => {
    setEditThisId('');
  };

  const renderLabelTextBox = (data, index) => {
    if (editThisId === data.id) {
      return (
        <Input
          autoFocus
          name="label"
          className="custom-input-add-border"
          value={data.label || ''}
          onKeyDown={onKeyDownPrevent}
          onChange={(e) => onHandleUpdateSectorLabel(e, index)}
          disabled={false}
        />
      );
    }
    return `${data.label}`;
  };

  const renderColorBox = (data, index) => {
    const color = get(data, 'background', '');
    const backgroundColor = color || '';
    return (
      <ColorPicker
        isDisabled={editThisId !== data?.id}
        color={backgroundColor}
        onChange={(e) => onHandleChangeSectorData(index, e.target.value, 'background')}
        onDeleteValue={() => onHandleChangeSectorData(index, null, 'background')}
      />
    );
  };

  const onHandleUpdateSectorData = async (id) => {
    const getSector = sectorList.filter((item) => item.id === id)[0];
    if (!getSector.label) {
      displayError('Please enter label name!');
    } else {
      const sendParams = {
        id: getSector.id,
        label: getSector.label,
        instance_id: instanceId,
        template_id: templateId,
        is_primary: getSector.is_primary,
        master_content_position: getSector.master_content_position,
        background: getSector.background,
      };
      const tempParams = { ...sendParams };
      tempParams.background = colourPalettePreset(sendParams.background, get(site, 'colors', []));
      const result = await updateSector(tempParams);
      const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
      if (isSuccess) {
        displaySuccess(messages);
        onCancelUpdate();
      }
    }
  };

  const renderUpdateButton = (id, index) => {
    if (editThisId === id) {
      return (
        <Button
          className="custom-simple-icon"
          color="success"
          size="xs"
          onClick={() => onHandleUpdateSectorData(id, index)}
        >
          <i className="fal fa-check" />
        </Button>
      );
    }
    return (
      <Button
        className="custom-simple-icon"
        color="info"
        size="xs"
        onClick={() => onHandleUpdateSectorId(id)}
      >
        <i className="fal fa-pen" />
      </Button>
    );
  };

  const onHandleDeleteSector = async (id) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        const res = await deleteSector({ id, instanceId });
        const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = res;
        if (isSuccess) {
          displaySuccess(messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const renderDeleteButton = (data) => {
    if (editThisId === data.id) {
      return (
        <Button
          className="custom-simple-icon"
          color="danger"
          size="xs"
          onClick={onCancelUpdate}
        >
          <i className="fal fa-times" />
        </Button>
      );
    }
    return (
      <Button
        className="custom-simple-icon"
        color="danger"
        size="xs"
        onClick={() => onHandleDeleteSector(data.id)}
      >
        <i className="fal fa-trash" />
      </Button>
    );
  };

  const handleMasterContentPosition = (e, index) => {
    onHandleChangeSectorData(index, e.value, 'master_content_position');
  };

  const renderSectorListing = (sectorList) => map(sectorList, (sector, index) => {
    return (
      <Row className="mb-4" key={sector.id}>
        <Col className="col-sm-1">
          <ul className="custom-ul custom-text-align-left">
            <Button className="custom-simple-txt-btn drag-handle me-2">
              <li>
                <i className="fal fa-arrows-alt" />
              </li>
            </Button>
          </ul>
        </Col>
        <Col className="mt-1">{renderLabelTextBox(sector, index)}</Col>
        <Col className="mt-1">
          <Select
            value={masterContentPositionOptions.filter((res) => res.value === sector.master_content_position)}
            placeholder="Please select master content position"
            onChange={(e) => handleMasterContentPosition(e, index)}
            options={masterContentPositionOptions}
            isDisabled={editThisId !== sector.id}
            styles={selectColorStyles}
          />
        </Col>
        <Col className="mt-1">{renderColorBox(sector, index)}</Col>
        <Col>
          <CheckBox
            checked={sector.is_primary}
            disabled={editThisId !== sector.id}
            title={sector.is_primary ? 'Primary' : 'Make this primary'}
            onChange={() => onHandleSetPrimarySector(index, sector.is_primary)}
          />
        </Col>
        <Col className="mt-1">
          {renderUpdateButton(sector.id, index)}
          {' '}
          {renderDeleteButton(sector)}
        </Col>
      </Row>
    );
  });

  const onPositionUpdate = async (e) => {
    const result = await sectorPostion(e);
    const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
    if (isSuccess) {
      displaySuccess(messages);
    }
  };

  return (
    <Card className="mt-3 mb-4">
      <CardBody>
        <Row>
          <Col lg={10}><h2>Sectors</h2></Col>
          <Col lg={2} className=" text-end">
            <Button
              className="common-info-button button-md fw-bold"
              color="info"
              onClick={onHandleAddNewSector}
            >
              <b>
                <i className="fal fa-plus-circle" />
                {' ADD'}
              </b>
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          {sectorList.length > 0
            ? (
                <div className="w-100">
                  <Row className="mb-4">
                    <Col className="fw-bold col-sm-1" />
                    <Col className="fw-bold">Label</Col>
                    <Col className="fw-bold">Master Content Position</Col>
                    <Col className="fw-bold">Preview Background Color</Col>
                    <Col className="fw-bold">Primary</Col>
                    <Col className="fw-bold">Action</Col>
                  </Row>
                  <DragDropComponent
                    keyName="sector_ids"
                    list={sectorList}
                    params={{
                      instance_id: instanceId,
                      template_id: templateId,
                    }}
                    disabled={isEmpty(templateId)}
                    APICall={(payload) => onPositionUpdate(payload)}
                    setNewState={(e) => onHandleUpdateSectorOrder(e)}
                    returnData={renderSectorListing(sectorList)}
                    onSetList={(newState) => onHandleUpdateSectorOrder(newState)}
                  />
                </div>
              )
            : ''}
        </Row>
      </CardBody>
    </Card>
  );
};

export default TemplateSector;
