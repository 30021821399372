interface WebSafeFontOption {
  name: string;
  label: string;
  variants: string[];
}

const webSafeFontOptions: WebSafeFontOption[] = [
  { name: 'Verdana', label: 'Verdana', variants: ['normal', 'bold'] },
  { name: 'Arial', label: 'Arial', variants: ['normal', 'bold'] },
  { name: 'Helvetica', label: 'Helvetica', variants: ['normal', 'bold'] },
  { name: 'Times New Roman', label: 'Times New Roman', variants: ['normal', 'bold'] },
  { name: 'Courier', label: 'Courier', variants: ['normal', 'bold'] },
  { name: 'Tahoma', label: 'Tahoma', variants: ['normal', 'bold'] },
  { name: 'Georgia', label: 'Georgia', variants: ['normal', 'bold'] },
  { name: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode', variants: ['normal', 'bold'] },
  { name: 'Trebuchet MS', label: 'Trebuchet MS', variants: ['normal', 'bold'] },
];

export default webSafeFontOptions;
