import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, mainRoutes, replacePathParams } from '../../../constants/routes';
import UserFeaturePage from '../../../components/common/user-feature-page';
import confirm from '../../../components/common/confirm';
import withRouter from '../../../helpers/withRouter';
import { useParams } from 'react-router';
import { useCreateSiteMutation, useDeleteSiteMutation, useGetSitesQuery } from '../../../api/dashboardApiSlice';
import { isEmpty } from 'lodash-es';
import { displaySuccess } from 'Dashboard/src/Utils';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function SiteSettingListing(props) {
  const params = useParams();
  const { instanceId } = params;
  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });
  const [createSite] = useCreateSiteMutation();
  const [deleteSite] = useDeleteSiteMutation();

  const onHandleCreate = async () => {
    const res = await createSite(instanceId);
    if (res && res?.data?.meta?.is_success) {
      displaySuccess(res?.data?.meta?.messages);
    }
  };

  const onHandleDelete = async (site) => {
    const result = await confirm({
      title: (<strong>Confirm!</strong>),
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const sendParams = {
        id: site.id,
        instanceId,
      };
      const res = await deleteSite(sendParams);
      if (res && res?.data?.meta?.is_success) {
        displaySuccess(res?.data?.meta?.messages);
      }
    }
  };

  const tableColumns = [
    {
      header: 'Host Name',
      accessorKey: 'label',
    },
    {
      header: 'Site Id',
      accessorKey: 'id',
    },
    {
      header: 'Action',
      id: 'action',
      cell: ({ row: { original: site } }) => (
        <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onHandleDelete(site)}>
          <i className="fal fa-trash" />
        </Button>
      ),
    },
  ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Sites', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          headingName: 'Sites',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: replacePathParams(mainRoutes.setting, [], props),
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <UserFeaturePage feature="manage_sites">
        <Card>
          <CardBody>
            <div className="text-end">
              <Button
                className="custom-simple-icon mt-3"
                color="info"
                onClick={onHandleCreate}
              >
                <i className="fal fa-plus-circle" />
                <b> Add New</b>
              </Button>
            </div>
            <ReactTable8
              columns={tableColumns}
              data={sites || []}
            />
          </CardBody>
        </Card>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(SiteSettingListing);
