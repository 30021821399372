import React from 'react';
import { useSelector } from 'react-redux';
import { compact, get } from 'lodash-es';
import macroReplacerString from './macroReplacerString';
import { RootState } from 'ts/interfaces';
import File from 'common/models/File';
import Media from 'common/models/Media';

interface TextBlockMediaProps {
  media: Media;
}

type DeviceType = 'desktop' | 'tablet' | 'phone' | 'all';

// The elements returned by this component are recreated by SectionContent, this means we can't add any event handlers.
const TextBlockMedia: React.FC<TextBlockMediaProps> = ({ media }) => {
  const allFiles = useSelector((state: RootState) => state.pageData.file);
  const allBreakpoints = useSelector((state: RootState) => state.pageData.breakpoint);
  const allLinks = useSelector((state: RootState) => state.pageData.link);
  const allSections = useSelector((state: RootState) => state.pageData.section);
  const cdn_domain = useSelector((state: RootState) => state.pageData.cdn_domain);

  const {
    id,
    file_id,
    link_id,
    alt_text,
    media_url,
    phone_media_url,
    tablet_media_url,
    hover_image_id,
    tablet_image_id,
    phone_image_id,
    clickable_thumbnail,
  } = media;
  const mainFile = file_id ? allFiles.find((f) => f.id === file_id) : null;
  const tabletFile = tablet_image_id ? allFiles.find((f) => f.id === tablet_image_id) : null;
  const phoneFile = phone_image_id ? allFiles.find((f) => f.id === phone_image_id) : null;
  const hoverFile = hover_image_id ? allFiles.find((f) => f.id === hover_image_id) : null;
  const fileMap: Record<DeviceType, File | null | undefined> = {
    desktop: mainFile,
    tablet: tabletFile || mainFile,
    phone: phoneFile || mainFile,
    all: mainFile,
  };
  const breakpoint = allBreakpoints.find((b) => b.object_id === id)
    || { all_styles: { width: ['100%'] } };

  const getUrl = (file: File, width: number | null = null): string =>
    `https://${cdn_domain}/cdn-cgi/image/format=auto%2Cwidth=${width === null ? file.width : width}/https://${cdn_domain}/c/${file.id}/${file.filename}?h=${file.file_hash}`;

  const imageUrl = (device: DeviceType): string | undefined => {
    switch (device) {
      case 'tablet':
        return tablet_media_url;
      case 'phone':
        return phone_media_url;
      default: return media_url;
    }
  };

  const srcset = (device: DeviceType): string => {
    const srcs: Record<string, number> = {};
    const file = fileMap[device];
    if (!file) return '';

    const deviceStyles = breakpoint[`${device}_styles`];
    const allStyles = breakpoint.all_styles;

    const width = get(deviceStyles, 'width.0') || get(allStyles, 'width.0');
    const maxWidth = get(deviceStyles, 'max-width.0') || get(allStyles, 'max-width.0');
    let displayedWidth: number;

    if (maxWidth && maxWidth.match(/px/)) {
      displayedWidth = parseFloat(maxWidth);
    } else if (width && width.match(/px/)) {
      displayedWidth = parseFloat(width);
    } else {
      displayedWidth = file.width || 0;
    }

    if (file.width || 0 > displayedWidth) {
      srcs[`${getUrl(file, displayedWidth * 2)} 2x`] = 1;
    }

    srcs[`${getUrl(file, displayedWidth)} 1x`] = 1;

    return Object.keys(srcs).join(',');
  };

  let el: JSX.Element | JSX.Element[] | null = null;
  let hoverEl: JSX.Element | null = null;

  if (mainFile?.mime_type?.match(/^image\//)) {
    el = (
      !tablet_image_id && !phone_image_id
        ? (
            <img
              className={`original-src tb-media bp-${id}${clickable_thumbnail ? ' clickable-thumbnail' : ''}`}
              srcSet={srcset('desktop')}
              src={imageUrl('all') || ''}
              data-src={imageUrl('all') || ''}
              alt={alt_text || ''}
              style={{ aspectRatio: `${mainFile.width} / ${mainFile.height}`, objectFit: 'contain' }}
            />
          )
        : compact((['desktop', 'tablet', 'phone'] as DeviceType[]).map((device) => {
          const file = fileMap[device];
          if (!file) return null;

          return (
            <img
              key={device}
              className={`original-src only-${device} tb-media bp-${id}${clickable_thumbnail ? ' clickable-thumbnail' : ''}`}
              srcSet={srcset(device)}
              src={imageUrl(device) || ''}
              data-src={imageUrl('all') || ''}
              alt={alt_text || ''}
              style={{ aspectRatio: `${file.width} / ${file.height}`, objectFit: 'contain' }}
            />
          );
        }))
    );
  } else if (mainFile?.mime_type?.match(/^video\//)) {
    el = (
      <video controls preload="auto">
        <source src={imageUrl('all') || ''} type="video/mp4" />
        {alt_text}
      </video>
    );
  }

  if (hoverFile && mainFile) {
    // We use the main file's aspect-ratio so that the page doesn't jump around if the normal and hover images are
    // different sizes. We set object-fit: contain in show_style.css.erb to avoid the hover image being stretched.
    hoverEl = (
      <img
        className={`hover-image tb-media bp-${id}`}
        src={getUrl(hoverFile)}
        alt={alt_text || ''}
        style={{ aspectRatio: `${mainFile.width} / ${mainFile.height}`, objectFit: 'contain' }}
      />
    );
  }

  if (el) {
    el = (
      <span className={`image-container${hoverEl ? ' has-hover' : ''}`}>
        {el}
        {hoverEl}
      </span>
    );
  }

  if (link_id && el) {
    const linkObject = allLinks.find((l) => l.id === link_id);
    if (linkObject) {
      let className: string | undefined;
      let originId: string | null = null;

      if (linkObject.type === 'toggle_visibility_of_section') {
        const targetSection = allSections.find((s) => s.id === linkObject.section_id);
        if (targetSection) {
          originId = targetSection.origin_id;
        }

        className = 'toggleVisibility';
      } else if (linkObject.link_target === 'framebox') {
        className = 'showModal';
      }

      el = (
        <a
          href={macroReplacerString(linkObject.url)}
          target={linkObject.link_target === '_blank' ? '_blank' : '_self'}
          className={className}
          data-origin-id={originId}
          rel={linkObject.link_target === '_blank' ? 'noreferrer' : ''}
        >
          {el}
        </a>
      );
    }
  }

  return el;
};

export default TextBlockMedia;
