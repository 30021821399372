import { apiSlice } from '../../../common/api/apiSlice';
import { some } from 'lodash-es';
import SiteStyleOverride from 'common/models/SiteStyleOverride';
import CTALog from 'common/models/CTALog';
import UserRecentItem from 'common/models/UserRecentItem';
import Component from 'common/models/Component';
import SavedSearch from 'common/models/SavedSearch';
import CustomCode from 'common/models/CustomCode';
import CustomTooltip from 'common/models/CustomTooltip';
import Template from 'common/models/Template';
import Reports, { ReportConfig } from 'common/models/Reports';
import Briefing from 'common/models/Briefing';
import Member from 'common/models/Member';
import ButtonStyle from 'common/models/ButtonStyle';
import TextStyle from 'common/models/TextStyle';
import Section from 'common/models/Section';
import Sector from 'common/models/Sector';
import Instance from 'common/models/Instance';
import Site from 'common/models/Site';
import Domain from 'common/models/Domain';
import Page from 'common/models/Page';
import MemberList from 'common/models/MemberList';
import EmailSeries from 'common/models/EmailSeries';
import ComponentCategory from 'common/models/ComponentCategory';
import User from 'common/models/User';
import BackendErrorLogs from 'common/models/BackendErrorLogs';
import Trash from 'common/models/Trash';
import ContentBriefing from 'common/models/ContentBriefing';
import TemplateFragment from 'common/models/TemplateFragment';
import SavedReport from 'common/models/SavedReport';
import AIModel from 'common/models/AIModel';
import AITextPrompt from 'common/models/AITextPrompt';
import { transformEndpoints } from '../../../common/api/commonApiSlice';

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Generic object endpoint. Don't want to cache these at the moment because they may interfere with the
    // non-generic endpoints.
    getObjectById: builder.query({
      query: ({ type, id }) => `/objects/${type}/${id}`,
    }),
    checkBeforeDelete: builder.query({
      query: ({ instanceId, objectId, objectType }) => `/instances/${instanceId}/check_before_delete?object_id=${objectId}&object_type=${objectType}`,
    }),
    unsubscribeFromAll: builder.mutation({
      query: (body: { email: string }) => ({
        url: '/unsubscribe_from_all',
        method: 'POST',
        body,
      }),
    }),

    // Dashboard support form
    dashboardSupportForm: builder.mutation({
      query: (params: { instance_id: string }) => ({
        url: `/instances/${params.instance_id}/dashboard_support_form`,
        method: 'POST',
        body: params,
      }),
    }),

    // Instances
    getInstances: builder.query({
      query: () => '/instances?order_by=company_name&order_direction=asc',
      extraOptions: { modelClass: Instance },
      providesTags: ['instance'],
    }),
    getInstanceById: builder.query({
      query: (instanceId: string) => `/instances/${instanceId}`,
      extraOptions: { modelClass: Instance },
      providesTags: ['instance'],
    }),
    updateInstance: builder.mutation({
      query: (params: { id: string }) => ({
        url: `/instances/${params.id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: ['instance'],
      extraOptions: { modelClass: Instance },
    }),
    getMasterInstances: builder.query({
      query: () => '/instances/master_instances',
      providesTags: ['instance'],
    }),
    importInstanceFromLegacy: builder.mutation({
      query: ({ instanceId, siteId }) => ({
        url: `/instances/${instanceId}/import_instance?site_id=${siteId}`,
        method: 'POST',
      }),
      invalidatesTags: ['instance'],
    }),
    getInstanceData: builder.query({
      query: (instanceId: string) => `/instances/${instanceId}/instance_data`,
    }),
    duplicateInstance: builder.mutation({
      query: (params: Instance) => ({
        url: `/instances/${params.id}/duplicate`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['instance'],
    }),

    // Sites
    getSiteById: builder.query({
      query: (siteId: string) => `/sites/${siteId}`,
      providesTags: ['site'],
      extraOptions: { modelClass: Site },
    }),
    getSites: builder.query({
      query: (instanceId: string) => `/sites?instance_id=${instanceId}`,
      extraOptions: { modelClass: Site },
      providesTags: ['site'],
      transformResponse: (data: any) => {
        data?.data?.sort((o1, o2) => {
          // We want to put these at the bottom of the list: sites without domains, ACE sites, old mobile (m.) sites, and .wb.gs sites.
          const bottoms = [
            /^$/,
            /^ace-/,
            /\.ace-/,
            /^m\./,
            /\.wb\.gs/,
          ];

          if (typeof o1.label !== 'string') {
            o1.label = '';
          }
          if (typeof o2.label !== 'string') {
            o2.label = '';
          }

          const anyMatch1 = some(bottoms, (b) => o1.label.match(b));
          const anyMatch2 = some(bottoms, (b) => o2.label.match(b));

          if (anyMatch1 && anyMatch2) {
            return 0;
          }
          if (anyMatch1) {
            return 1;
          }
          if (anyMatch2) {
            return -1;
          }

          return o1.label > o2.label ? 1 : -1;
        });

        return data;
      },
    }),
    getAllSites: builder.query({
      query: () => ({
        url: `/sites`,
        method: 'GET',
      }),
      extraOptions: { modelClass: Site },
    }),
    createSite: builder.mutation({
      query: (payload: string) => ({
        url: `/instances/${payload}/sites`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['site'],
      extraOptions: { modelClass: Site },
    }),
    deleteSite: builder.mutation({
      query: ({ instance_id, id }) => ({
        url: `/instances/${instance_id}/sites/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['site'],
      extraOptions: { modelClass: Site },
    }),
    updateSite: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/sites/${params.id}`,
        method: 'PATCH',
        body: params,
      }),
      invalidatesTags: ['site'],
      extraOptions: { modelClass: Site },
    }),
    sitesLinkingToMe: builder.query({
      query: ({ instanceId, siteId }) => ({
        url: `/instances/${instanceId}/sites/${siteId}/linking_to_me`,
        method: 'GET',
      }),
    }),
    getGoogleAnalyticsAccountList: builder.query({
      query: (params: { instanceId: string | number; siteId: string | number; enableSpinner: boolean }) => `/instances/${params.instanceId}/sites/${params.siteId}/analytics_accounts?enable_spinner=${params.enableSpinner}`,
    }),
    getAnalyticsDataStreams: builder.query({
      query: (params: {
        instanceId: string | number;
        siteId: string | number;
        serviceAccountNumber: string | number;
        accountId: string | number;
        propertyId: string | number;
        enableSpinner: boolean;
      }) => `/instances/${params.instanceId}/sites/${params.siteId}/analytics_data_streams?service_account_number=${params.serviceAccountNumber}&account_id=${params.accountId}&property_id=${params.propertyId}&enable_spinner=${params.enableSpinner}`,
    }),
    createAnalyticsProperty: builder.mutation({
      query: (params: { instanceId: string | number; id: string | number }) => ({
        url: `/instances/${params.instanceId}/sites/${params.id}/create_analytics_property`,
        method: 'POST',
        body: params,
      }),
    }),

    // Domains
    getDomains: builder.query({
      query: (siteId: string) => `/sites/${siteId}/domains`,
      providesTags: ['domain'],
      extraOptions: { modelClass: Domain },
    }),
    getAllDomains: builder.query({
      query: ({ domainName, disableSpinner }) => ({
        url: `/domains?domain_name=${domainName}`,
        method: 'GET',
        disableSpinner,
      }),
      providesTags: ['domain'],
    }),
    addNewDomain: builder.mutation({
      query: (payload: Domain) => ({
        url: `/sites/${payload.site_id}/domains`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['domain'],
      extraOptions: { modelClass: Domain },
    }),
    updateDomain: builder.mutation({
      query: (domain: Domain) => ({
        url: `/sites/${domain.site_id}/domains/${domain.id}`,
        method: 'PATCH',
        body: domain,
      }),
      invalidatesTags: ['domain', 'site'], // Adding 'site' so that the site selector updates when changing domains
      extraOptions: { modelClass: Domain },
    }),
    deleteDomain: builder.mutation({
      query: ({ site_id, id }: Domain) => ({
        url: `/sites/${site_id}/domains/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['domain'],
    }),
    makeDomainPrimary: builder.mutation({
      query: (domain: Domain) => ({
        url: `/sites/${domain.site_id}/domains/${domain.id}/make_primary`,
        method: 'POST',
      }),
      invalidatesTags: ['domain', 'site'], // Adding 'site' so that the site selector updates when changing domains
    }),
    issueDomainCertificate: builder.mutation({
      query: (payload: Domain) => ({
        url: `/sites/${payload.site_id}/domains/${payload.id}/issue_certificate`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['domain'],
    }),
    domainEligibilityCheck: builder.query({
      query: (domain: Domain) => `/sites/${domain.site_id}/domains/${domain.id}/eligibility_check`,
    }),
    domainEnableEmail: builder.mutation({
      query: (domain: Domain) => ({
        url: `/sites/${domain.site_id}/domains/${domain.id}/enable_email`,
        method: 'POST',
      }),
      invalidatesTags: ['domain', 'site'],
    }),

    // Pages
    getPages: builder.query({
      query: ({ instanceId, siteId }) => `/sites/${siteId}/pages?instance_id=${instanceId}`,
      providesTags: ['page_list'],
      extraOptions: { modelClass: Page },
    }),
    getPage: builder.query({
      query: ({ id, instanceId, siteId }) => `/sites/${siteId}/pages/${id}?instance_id=${instanceId}`,
      providesTags: ['page'],
      extraOptions: { modelClass: Page },
    }),
    getOrphanPages: builder.query({
      query: ({ instanceId, siteId }) => `/sites/${siteId}/orphan_pages?instance_id=${instanceId}`,
      providesTags: ['orphan_page'],
      extraOptions: { modelClass: Page },
    }),
    getMasterPages: builder.query({
      query: ({ instanceId, siteId }) => `/sites/${siteId}/master_pages?instance_id=${instanceId}`,
      providesTags: ['master_page'],
      extraOptions: { modelClass: Page },
    }),
    getPageSections: builder.query({
      query: ({ instanceId, siteId, pageId }) => `/sites/${siteId}/pages/${pageId}/sections?instance_id=${instanceId}`,
    }),
    addNewPage: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/pages`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, args) => {
        if (args?.type === 'master') {
          return ['master_page'];
        }
        if (args?.type === 'normal' && !args?.parent_id) {
          return ['orphan_page'];
        }
        return ['page_list'];
      },
      extraOptions: { modelClass: Page },
    }),
    deletePage: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/pages/${payload.current_page}?instance_id=${payload.instance_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => {
        if (args?.type === 'master') {
          return ['master_page'];
        }
        if (args?.type === 'normal' && !args?.parent_id) {
          return ['orphan_page'];
        }
        return ['page_list'];
      },
      extraOptions: { modelClass: Page },
    }),
    pageUpdate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/pages/${payload?.page_id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_list', 'page_data', 'section'],
      extraOptions: { modelClass: Page },
    }),
    duplicatePage: builder.mutation({
      query: (params: Page) => ({
        url: `/pages/${params.id}/duplicate`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['page_list'],
      extraOptions: { modelClass: Page },
    }),
    getPageTree: builder.query({
      query: ({ instanceId, siteId }) => `/instances/${instanceId}/sites/${siteId}/page_tree`,
    }),
    pageBulkEdit: builder.mutation({
      query: (payload) => ({
        url: `sites/${payload.site_id}/pages/bulk_edit`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_list', 'page_data', 'section'],
      // extraOptions: { modelClass: Page },
    }),

    // Email Pages
    getEmails: builder.query({
      query: ({ instanceId }) => `/instances/${instanceId}/email_pages`,
      providesTags: ['page_list'],
      extraOptions: { modelClass: Page },
    }),
    emailPageUpdate: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/email_pages/${payload?.page_id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_list', 'page_data', 'section'],
      extraOptions: { modelClass: Page },
    }),
    emailPageDuplicate: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/email_pages/${params.id}/duplicate`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['page_list'],
      extraOptions: { modelClass: Page },
    }),
    emailPageCreate: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/email_pages`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['page_list'],
      extraOptions: { modelClass: Page },
    }),
    emailPageDelete: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/email_pages/${params.id}`,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['page_list'],
      extraOptions: { modelClass: Page },
    }),
    emailPageProof: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/email_pages/${params.id}/email_proof`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['page_list'],
    }),

    // Page Versions
    getPageVersions: builder.query({
      query: ({ pageId, siteId, ...payload }) => `/sites/${siteId}/pages/${pageId}/page_versions?${new URLSearchParams(payload)}`,
      providesTags: ['page_version'],
    }),
    getPageVersion: builder.query({
      query: ({
        id, pageId, instanceId, siteId,
      }) => `/sites/${siteId}/pages/${pageId}/page_versions/${id}?instance_id=${instanceId}`,
      providesTags: ['page_version'],
    }),
    getEmailPageVersions: builder.query({
      query: ({ pageId, instanceId }) => `/instances/${instanceId}/email_pages/${pageId}/page_versions`,
      providesTags: ['page_version'],
    }),
    pageVersionCreate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/pages/${payload.page_id}/page_versions/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['page_version'],
    }),
    pageVersionDelete: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.siteId}/pages/${payload.pageId}/page_versions/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['page_version'],
    }),
    pageVersionPublish: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/pages/${payload.page_id}/page_versions/${payload.id}/change_publish_draft`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_version'],
    }),
    pageVersionUpdate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload?.site_id}/pages/${payload?.page_id}/page_versions/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_version', 'page_data', 'section'],
    }),

    // URLs
    getURLById: builder.query({
      query: (id: string) => `/urls/${id}`,
      providesTags: ['url'],
    }),

    // Sectors
    getAllSectorsFromSite: builder.query({
      query: ({ instanceId, siteId }) => `/instances/${instanceId}/sites/${siteId}/sectors`,
      providesTags: ['sector'],
    }),
    sectorCreate: builder.mutation({
      query: (payload: Sector) => ({
        url: `/instances/${payload.instance_id}/sectors`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['sector', 'template'],
      extraOptions: { modelClass: Sector },
    }),
    sectorUpdate: builder.mutation({
      query: (payload: Sector) => ({
        url: `/instances/${payload.instance_id}/sectors/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['template'],
      extraOptions: { modelClass: Sector },
    }),
    sectorDelete: builder.mutation({
      query: (params: { instanceId: string | number; id: string }) => ({
        url: `/instances/${params.instanceId}/sectors/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['template'],
    }),
    sectorPosition: builder.mutation({
      query: (payload: Sector) => ({
        url: `/instances/${payload.instance_id}/sectors/update_position`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['template'],
      extraOptions: { modelClass: Sector },
    }),

    // Sections
    getSections: builder.query({
      query: (params: { siteId: string | number; pageId: string | number; pageVersionId: string | number; includeContent: boolean }) => `/sites/${params?.siteId}/pages/${params?.pageId}/page_versions/${params?.pageVersionId}/sections${params?.includeContent ? '?include_content=true' : ''}`,
      providesTags: (result) => (result
        ? [
            ...result.data.map(({ id }) => ({ type: 'section', id })),
            { type: 'section', id: 'LIST' },
          ]
        : [{ type: 'section', id: 'LIST' }]),
      extraOptions: { modelClass: Section },
    }),
    getMasterSections: builder.query({
      query: (params: { siteId: string | number; pageId: string | number; pageVersionId: string | number }) => `/sites/${params?.siteId}/pages/${params?.pageId}/page_versions/${params?.pageVersionId}/master_sections`,
      providesTags: (result) => (result
        ? [
            ...result.data.map(({ id }) => ({ type: 'section', id })),
            { type: 'section', id: 'LIST' },
          ]
        : [{ type: 'section', id: 'LIST' }]
      ),
      extraOptions: { modelClass: Section },
    }),
    getSectionHTML: builder.query({
      query: (params: { instanceId: string | number; sectionId: string }) => `/instances/${params?.instanceId}/sections/${params?.sectionId}/html`,
      providesTags: (result, error, args: { sectionId: string }) => [{ type: 'section_html', id: args.sectionId }],
    }),
    sectionCreate: builder.mutation({
      query: (payload: Section) => ({
        url: `/instances/${payload.instance_id}/sections`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
    }),
    getSection: builder.query({
      query: (params: { instanceId: string | number; sectionId: string }) => `/instances/${params?.instanceId}/sections/${params?.sectionId}`,
      providesTags: (result, error, args: { sectionId: string }) => [{ type: 'section', id: args.sectionId }],
      extraOptions: { modelClass: Section },
    }),
    sectionUpdate: builder.mutation({
      query: ({ instance_id, id, ...payload }) => ({
        url: `/instances/${instance_id}/sections/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, args) => (
        [
          ...['page_data', { type: 'section', id: args.id }, { type: 'section_html', id: args.id }],
          ...(args?.column_section?.id ? [{ type: 'section', id: args?.column_section.id }] : []),
          ...(args?.column_section?.id ? [{ type: 'section_html', id: args?.column_section.id }] : []),
          ...(args?.column_attributes ? args.column_attributes.map((c) => ({ type: 'section', id: c.id })) : []),
          ...(args?.column_attributes ? args.column_attributes.map((c) => ({ type: 'section_html', id: c.id })) : []),
        ]
      ),
      extraOptions: { modelClass: Section },
    }),
    sectionDelete: builder.mutation({
      query: ({ instance_id, id }) => ({
        url: `/instances/${instance_id}/sections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
    }),
    getColumns: builder.query({
      query: ({ instanceId, sectionId }) => `/instances/${instanceId}/sections/${sectionId}/columns`,
      providesTags: (result) => (result
        ? [
            ...result.data.map(({ id }) => ({ type: 'section', id })),
            { type: 'section', id: 'LIST' },
          ]
        : [{ type: 'section', id: 'LIST' }]),
    }),
    sectionPositionUpdate: builder.mutation({
      query: ({ instance_id, ...payload }) => ({
        url: `/instances/${instance_id}/sections/update_position`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
      extraOptions: { modelClass: Section },
    }),
    getSectionsByType: builder.query({
      query: ({
        instanceId, siteId, type, version,
      }) => `/instances/${instanceId}/sites/${siteId}/sections?type=${type}&version=${version}`,
    }),
    duplicateSection: builder.mutation({
      query: ({ instanceId, ...params }) => ({
        url: `/instances/${instanceId}/sections/duplicate`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
    }),

    // Text Styles
    getTextStyles: builder.query({
      query: (siteId: string) => `/sites/${siteId}/text_styles`,
      providesTags: ['text_style'],
      extraOptions: { modelClass: TextStyle },
    }),
    getTextStyleById: builder.query({
      query: (params: TextStyle) => `/sites/${params?.site_id}/text_styles/${params?.id}`,
      providesTags: ['text_style'],
      extraOptions: { modelClass: TextStyle },
    }),
    addNewTextStyle: builder.mutation({
      query: (payload: TextStyle) => ({
        url: `/sites/${payload.site_id}/text_styles`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['text_style'],
      extraOptions: { modelClass: TextStyle },
    }),
    updateTextStyle: builder.mutation({
      query: (payload: TextStyle) => ({
        url: `/sites/${payload?.site_id}/text_styles/${payload?.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['text_style'],
      extraOptions: { modelClass: TextStyle },
    }),
    deleteTextStyle: builder.mutation({
      query: (params: TextStyle) => ({
        url: `/sites/${params?.site_id}/text_styles/${params?.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['text_style'],
      extraOptions: { modelClass: TextStyle },
    }),

    // Custom Fonts
    getCustomFonts: builder.query({
      query: () => '/custom_fonts',
    }),

    // Load Media Tool
    getMediaFiles: builder.query({
      query: (instanceId: string) => `/instances/${instanceId}/load_media_tool`,
    }),
    getMediaById: builder.query({
      query: (payload) => `/instances/${payload.instance_id}/media/${payload.id}`,
    }),
    createMedia: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/media`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateMedia: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/media/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteMedia: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/media/${payload.id}`,
        method: 'DELETE',
      }),
    }),

    // Button Styles
    getButtonStyles: builder.query({
      query: (siteId: string) => `/sites/${siteId}/button_styles`,
      providesTags: ['button_style'],
      extraOptions: { modelClass: ButtonStyle },
    }),
    getButtonStyleById: builder.query({
      query: (params: ButtonStyle) => `/sites/${params?.site_id}/button_styles/${params?.id}`,
      providesTags: ['button_style'],
      extraOptions: { modelClass: ButtonStyle },
    }),
    addButtonStyle: builder.mutation({
      query: (payload: ButtonStyle) => ({
        url: `/sites/${payload.site_id}/button_styles`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['button_style'],
      extraOptions: { modelClass: ButtonStyle },
    }),
    updateButtonStyle: builder.mutation({
      query: (payload: ButtonStyle) => ({
        url: `/sites/${payload?.site_id}/button_styles/${payload?.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['button_style'],
      extraOptions: { modelClass: ButtonStyle },
    }),
    deleteButtonStyle: builder.mutation({
      query: (params: ButtonStyle) => ({
        url: `/sites/${params?.site_id}/button_styles/${params?.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['button_style'],
      extraOptions: { modelClass: ButtonStyle },
    }),

    // Member email logs
    getMemberEmailLogs: builder.query({
      query: (params) => `/instances/${params?.instance_id}/members/${params?.member_id}/email_logs`,
    }),

    // Export Members
    exportMembers: builder.query({
      query: (params) => `/searches/${params?.id}/export_members?export_type=${params?.type}&instance_id=${params?.instance_id}`,
    }),

    // Members
    getMembers: builder.query({
      query: (params) => `/instances/${params?.instance_id}/members?page=${params.page}&order_by=${params.order_by}&order_direction=${params.order_direction}&page_size=${params.page_size}`,
      providesTags: (result) => [
        'member',
        ...result.data.members.map(({ id }) => ({ type: 'member', id })),
      ],
      extraOptions: { modelClass: MemberList },
    }),
    getMember: builder.query({
      query: (params) => `/instances/${params.instance_id}/members/${params.id}`,
      providesTags: (result, error, { id }) => [{ type: 'member', id }],
      extraOptions: { modelClass: Member },
    }),
    createMember: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/members`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'member', id }],
      extraOptions: { modelClass: Member },
    }),
    updateMember: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/members/${payload.member_id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'member', id }],
      extraOptions: { modelClass: Member },
    }),
    deleteMember: builder.mutation({
      query: ({ instanceId, id }) => ({
        url: `/instances/${instanceId}/members/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['member'],
    }),
    deleteSearchMember: builder.mutation({
      query: ({ instanceId, id }) => ({
        url: `/searches/${id}/delete_members?instance_id=${instanceId}`,
        method: 'POST',
      }),
      invalidatesTags: ['member'],
    }),
    getMemberSearchById: builder.query({
      query: (params) => `/instances/${params.instance_id}/searches/${params.id || '-'}/result?page=${params.page}&order_by=${params.order_by}&order_direction=${params.order_direction}&page_size=${params.page_size}`,
      providesTags: ['member'],
    }),
    memberSearchCreate: builder.mutation({
      query: (payload) => ({
        url: '/searches',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['member'],
    }),
    memberSearchUpdate: builder.mutation({
      query: (payload) => ({
        url: `/searches/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['member'],
    }),
    uploadCSVFile: builder.mutation({
      query: (payload) => {
        const formData = new FormData();
        formData.append('file', payload.file);
        formData.append('someOtherField', payload.someOtherField);

        return {
          url: `/instances/${payload.instance_id}/member_import/upload_csv`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    importMember: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/member_import`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['member'],
    }),
    cancelMemberEmailSeriesSubscription: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/members/${payload.member_id}/email_series_subscriptions/${payload.id}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['member'],
    }),

    // Background Job Progress
    getJobProgress: builder.query({
      query: (payload: { instanceId: string | number; jobId: string | number }) => ({
        url: `/instances/${payload.instanceId}/job_progress/${payload.jobId}`,
        method: 'GET',
        disableSpinner: true,
      }),
    }),
    getBGJobProgress: builder.query({
      query: (params: { instanceId: string | number; pageSize: number; pageNo: number }) => ({
        url: `/instances/${params.instanceId}/job_progress`,
        method: 'GET',
        params,
        disableSpinner: true,
      }),
    }),
    backgroundJobCancel: builder.mutation({
      query: (params: { instance_id: string | number; id: string | number }) => ({
        url: `/instances/${params.instance_id}/job_progress/${params.id}/cancel`,
        method: 'POST',
        body: params,
      }),
    }),
    getJobProgressReport: builder.query({
      query: (params: { instanceId: string | number; reportId: string | number }) => ({
        url: `/instances/${params.instanceId}/job_progress/${params.reportId}/report`,
        method: 'GET',
      }),
    }),

    // Bulk Email
    sendBulkEmail: builder.mutation({
      query: (params) => ({
        url: `/bulk_emails`,
        method: 'POST',
        body: params,
      }),
    }),

    // Site Briefing
    createSiteBriefing: builder.mutation({
      query: (params) => ({
        url: '/briefings',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['briefing'],
    }),
    getSiteBriefings: builder.query({
      query: (params) => ({
        url: `/briefings${params?.siteId ? `?site_id=${params?.siteId}` : ''}`,
        params,
        method: 'GET',
      }),
      providesTags: (result) => [
        'briefing',
        ...result.data.briefings.map(({ id }) => ({ type: 'briefing', id })),
      ],
    }),
    getSiteBriefing: builder.query({
      query: (id) => `/briefings/${id}`,
      providesTags: (result, error, args) => [{ type: 'briefing', id: args.id }],
      extraOptions: { modelClass: Briefing },
    }),
    deleteSiteBriefing: builder.mutation({
      query: (briefing: Briefing) => ({
        url: `/briefings/${briefing.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['briefing'],
    }),
    updateSiteBriefing: builder.mutation({
      query: ({ disableSpinner, ...briefing }) => ({
        url: `/briefings/${briefing.id}`,
        method: 'PATCH',
        body: briefing,
        disableSpinner,
      }),
      onQueryStarted(patch, { dispatch, queryFulfilled }) {
        dispatch(dashboardApi.util.updateQueryData('getSiteBriefing', patch.id, (briefing) => Object.assign(briefing, patch)));
        dispatch(dashboardApi.util.invalidateTags([{ type: 'briefing', id: 'LIST' }]));
        queryFulfilled.catch(() => dispatch(dashboardApi.util.invalidateTags(['briefing'])));
      },
      extraOptions: { modelClass: Briefing },
    }),
    addImageToBriefing: builder.mutation({
      query: ({
        disableSpinner, briefing, name, file,
      }) => {
        const body = new FormData();
        body.append('name', name);
        body.append('file', file);
        return {
          url: `/briefings/${briefing.id}/image`,
          method: 'POST',
          body,
          disableSpinner,
        };
      },
      invalidatesTags: ['briefing'],
    }),
    generateInstanceFromSiteBriefing: builder.mutation({
      query: ({ briefing, content }: { briefing: Briefing; content: boolean }) => ({
        url: `/briefings/${briefing.id}/generate_instance?content=${content ? 1 : 0}`,
        method: 'POST',
      }),
      invalidatesTags: ['briefing'],
    }),
    generateContentFromSiteBriefing: builder.mutation({
      query: (briefing: Briefing) => ({
        url: `/briefings/${briefing.id}/generate_content`,
        method: 'POST',
      }),
      invalidatesTags: ['briefing'],
    }),
    generateQuestions: builder.query({
      query: ({ briefingId, productPageId, subPageId }) => `/briefings/${briefingId}/questions?product_page_id=${productPageId}&sub_page_id=${subPageId}`,
    }),
    importFromPipedrive: builder.mutation({
      query: (briefing: Briefing) => ({
        url: `/briefings/${briefing.id}/import_from_pipedrive`,
        method: 'POST',
      }),
      invalidatesTags: ['briefing'],
    }),
    generateOrientationStatement: builder.mutation({
      query: (briefing: Briefing) => ({
        url: `/briefings/${briefing.id}/generate_orientation_statement`,
        method: 'POST',
      }),
      invalidatesTags: ['briefing'],
    }),
    generateSellingPoints: builder.mutation({
      query: (briefing: Briefing) => ({
        url: `/briefings/${briefing.id}/generate_selling_points`,
        method: 'POST',
      }),
      invalidatesTags: ['briefing'],
    }),
    generatePageSellingPoints: builder.mutation({
      query: (briefing: Briefing) => ({
        url: `/briefings/${briefing.id}/generate_page_selling_points`,
        method: 'POST',
      }),
      invalidatesTags: ['briefing'],
    }),
    crawlSite: builder.mutation({
      query: (briefing: Briefing) => ({
        url: `/briefings/${briefing.id}/crawl_site`,
        method: 'POST',
      }),
      invalidatesTags: ['briefing'],
    }),
    sendBDQNotification: builder.mutation({
      query: ({ briefing, email }: { briefing: Briefing; email: string }) => ({
        url: `/briefings/${briefing.id}/send_bdq_notification`,
        method: 'POST',
        body: { email },
      }),
      invalidatesTags: ['briefing'],
    }),
    sendDiscussionDeckNotification: builder.mutation({
      query: ({
        briefing,
        project_manager_id,
        meeting_date_time,
        email,
      }: {
        briefing: Briefing;
        project_manager_id: string;
        meeting_date_time: string;
        email: string;
      }) => ({
        url: `/briefings/${briefing.id}/send_discussion_deck_notification`,
        method: 'POST',
        body: { project_manager_id, meeting_date_time, email },
      }),
      invalidatesTags: ['briefing'],
    }),
    generateBriefingAIContent: builder.mutation({
      query: ({ briefing, prompt_name, page_index }: { briefing: Briefing; prompt_name: string; page_index: number }) => ({
        url: `/briefings/${briefing.id}/generate_ai_content`,
        method: 'POST',
        body: { prompt_name, page_index },
      }),
      invalidatesTags: ['briefing'],
    }),

    // Misc
    getSpelling: builder.query({
      query: () => '/spelling',
    }),

    // Link Tool
    loadLinkTool: builder.query({
      query: (id: string) => `/instances/${id}/load_link_tool`,
    }),
    getLinkData: builder.query({
      query: (params: { instanceId: string | number; linkId: string | number }) => `/instances/${params.instanceId}/links/${params?.linkId}`,
    }),
    getLinkToolData: builder.query({
      query: (instanceId: string | number) => `/instances/${instanceId}/load_link_tool`,
    }),
    linkToolCreateLink: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/links`,
        method: 'POST',
        body: params,
      }),
    }),
    linkToolUpdateLink: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/links/${params.link_id}`,
        method: 'PUT',
        body: params,
      }),
    }),
    linkToolUnlink: builder.mutation({
      query: (params: { instanceId: string | number; linkId: string | number }) => ({
        url: `/instances/${params?.instanceId}/links/${params?.linkId}`,
        method: 'DELETE',
      }),
    }),

    // Reports
    getReportConfig: builder.query({
      query: () => '/reports/config',
      extraOptions: { modelClass: ReportConfig },
    }),
    getReportData: builder.query({
      query: (config) => ({
        url: '/reports/data',
        method: 'POST',
        body: config,
      }),
      extraOptions: { modelClass: Reports },
    }),

    // Tools
    runTool: builder.query({
      query: (params) => ({
        url: `/instances/${params.instance_id}/sites/${params.site_id}/tool?tool=${params.tool}`,
        method: 'GET',
        params,
      }),
    }),

    // Templates
    getTemplates: builder.query({
      query: (params: { siteId: string | number; instanceId: string | number }) => `/sites/${params?.siteId}/templates?instance_id=${params?.instanceId}`,
      providesTags: (result) => [
        'template',
        ...result.data.map(({ id }) => ({ type: 'template', id })),
      ],
      extraOptions: { modelClass: Template },
    }),
    getTemplate: builder.query({
      query: (params: { siteId: string | number; instanceId: string | number; id: string | number }) => `/sites/${params?.siteId}/templates/${params?.id}?instance_id=${params?.instanceId}`,
      providesTags: (result: any, error: any, args: { id: any }) => [{ type: 'template', id: args.id }],
    }),
    duplicateTemplates: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/templates/${payload.id}/duplicate`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
    }),
    createTemplate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/templates`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
      extraOptions: { modelClass: Template },
    }),
    updateTemplate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/templates/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
      extraOptions: { modelClass: Template },
    }),
    deleteTemplate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/templates/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
    }),

    // Custom Tooltips
    getCustomTooltips: builder.query({
      query: () => '/custom_tooltips',
      extraOptions: { modelClass: CustomTooltip },
      providesTags: ['tooltip'],
    }),
    createCustomTooltip: builder.mutation({
      query: (payload: CustomTooltip) => ({
        url: '/custom_tooltips',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['tooltip'],
    }),
    updateCustomTooltip: builder.mutation({
      query: (payload: CustomTooltip) => ({
        url: `/custom_tooltips/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['tooltip'],
    }),

    // Email Series
    getEmailSeries: builder.query({
      query: (instanceId: string | number) => `/instances/${instanceId}/email_series`,
      extraOptions: { modelClass: EmailSeries },
      providesTags: (result) => [
        'email_series',
        ...result.data.map(({ id }) => ({ type: 'email_series', id })),
      ],
    }),
    getEmailSeriesById: builder.query({
      query: (payload) => `/instances/${payload.instance_id}/email_series/${payload.id}`,
      extraOptions: { modelClass: EmailSeries },
      providesTags: (result, error, args: { id }) => [{ type: 'email_series', id: args.id }],
    }),
    createEmailSeries: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/email_series`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['email_series'],
    }),
    deleteEmailSeries: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/email_series/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'email_series', id }],
    }),
    updateEmailSeries: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/email_series/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      extraOptions: { modelClass: EmailSeries },
      invalidatesTags: (result, error, { id }) => [{ type: 'email_series', id }],
    }),
    emailSeriesSubscribeMember: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/searches/${payload.id}/subscribe_search_members_to_email_series`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['email_series'],
    }),

    // Custom Codes
    getCustomCodes: builder.query({
      query: (siteId: string) => `/sites/${siteId}/custom_codes`,
      providesTags: (result) => [
        'custom_code',
        ...result.data.map(({ id }) => ({ type: 'custom_code', id })),
      ],
      extraOptions: { modelClass: CustomCode },
    }),
    getCustomCode: builder.query({
      query: (params: CustomCode) => `/sites/${params?.site_id}/custom_codes/${params?.id}`,
      providesTags: (result, error, args: { id }) => [{ type: 'custom_code', id: args.id }],
      extraOptions: { modelClass: CustomCode },
    }),
    createCustomCode: builder.mutation({
      query: (payload: CustomCode) => ({
        url: `/sites/${payload?.site_id}/custom_codes`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result) => [{ type: 'custom_code', id: result?.data?.id }],
      extraOptions: { modelClass: CustomCode },
    }),
    deleteCustomCode: builder.mutation({
      query: (params: { siteId: string | number; id: string | number }) => ({
        url: `/sites/${params?.siteId}/custom_codes/${params?.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'custom_code', id }],
      extraOptions: { modelClass: CustomCode },
    }),
    updateCustomCode: builder.mutation({
      query: (payload: CustomCode) => ({
        url: `/sites/${payload?.site_id}/custom_codes/${payload?.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'custom_code', id }],
      extraOptions: { modelClass: CustomCode },
    }),

    // Database Fields
    getDatabaseFields: builder.query({
      query: (params: { instanceId: string | number }) => `/instances/${params?.instanceId}/database_fields`,
      providesTags: (result) => [
        'database_field',
        ...result.data.map(({ id }) => ({ type: 'database_field', id })),
      ],
    }),
    getDatabaseField: builder.query({
      query: (params) => `/instances/${params.instance_id}/database_fields/${params.field_id}`,
      providesTags: (result, error, args: { id }) => [{ type: 'database_field', id: args.id }],
    }),
    createDatabaseField: builder.mutation({
      query: (instanceId) => ({
        url: `/instances/${instanceId}/database_fields`,
        method: 'POST',
      }),
      invalidatesTags: ['database_field'],
    }),
    updateDatabaseField: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/database_fields/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'database_field', id }],
    }),
    deleteDatabaseField: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/database_fields/${params.field_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'database_field', id }],
    }),
    positionDatabaseField: builder.mutation({
      query: (payload) => ({
        url: `/instances/${payload.instance_id}/database_fields/update_position`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['database_field'],
    }),
    getDatabaseSearchFields: builder.query({
      query: (params: { instanceId: string | number }) => `/instances/${params?.instanceId}/database_fields/field_search`,
      providesTags: ['database_search_field'],
    }),

    // Saved Search
    getSavedSearches: builder.query({
      query: (instanceId) => `/instances/${instanceId}/searches`,
      providesTags: (result) => [
        'saved_search',
        ...result.data.map(({ id }) => ({ type: 'saved_search', id })),
      ],
      extraOptions: { modelClass: SavedSearch },
    }),
    getSavedSearch: builder.query({
      query: (searchId) => `/searches/${searchId}`,
      providesTags: (result, error, args: { id }) => [{ type: 'database_field', id: args.id }],
    }),
    createSavedSearch: builder.mutation({
      query: (payload) => ({
        url: `/searches/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['saved_search'],
      extraOptions: { modelClass: SavedSearch },
    }),
    deleteSavedSearches: builder.mutation({
      query: (id: { id: string | number }) => ({
        url: `/searches/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'saved_search', id }],
      extraOptions: { modelClass: SavedSearch },
    }),

    // Component Categories
    getComponentCategories: builder.query({
      query: () => '/component_categories',
      extraOptions: { modelClass: ComponentCategory },
      providesTags: (result) => [
        'component_category',
        ...result.data.map(({ id }) => ({ type: 'component_category', id })),
      ],
    }),
    getComponentCategory: builder.query({
      query: (id) => `/component_categories/${id}`,
      providesTags: (result, error, args: { id }) => [{ type: 'component_category', id: args.id }],
      extraOptions: { modelClass: ComponentCategory },
    }),
    updateComponentCategory: builder.mutation({
      query: (payload) => ({
        url: `/component_categories/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['component_category'],
      extraOptions: { modelClass: ComponentCategory },
    }),
    createComponentCategory: builder.mutation({
      query: (payload) => ({
        url: '/component_categories',
        method: 'POST',
        params: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'component_category', id }],
      extraOptions: { modelClass: ComponentCategory },
    }),
    deleteComponentCategory: builder.mutation({
      query: (params) => ({
        url: `/component_categories/${params?.id}`,
        params,
        method: 'DELETE',
      }),
      invalidatesTags: ['component_category'],
    }),

    // Components
    getComponents: builder.query({
      query: (params) => ({
        url: '/components',
        method: 'GET',
        params,
      }),
      extraOptions: { modelClass: Component },
      providesTags: (result, error, args: { id }) => [{ type: 'component', id: args.id }],
    }),
    getComponent: builder.query({
      query: (id) => ({
        url: `/components/${id}`,
        method: 'GET',
      }),
      extraOptions: { modelClass: Component },
      providesTags: (result, error, args: { id }) => [{ type: 'component', id: args.id }],
    }),
    duplicateComponent: builder.mutation({
      query: (id) => ({
        url: `/components/${id}/duplicate`,
        method: 'POST',
      }),
      extraOptions: { modelClass: Component },
      invalidatesTags: ['component'],
    }),
    deleteComponent: builder.mutation({
      query: (params) => ({
        url: `/components/${params?.id}`,
        params,
        method: 'DELETE',
      }),
      invalidatesTags: ['component'],
    }),
    createComponent: builder.mutation({
      query: (payload) => ({
        url: `/components`,
        method: 'POST',
        params: payload,
      }),
      extraOptions: { modelClass: Component },
      invalidatesTags: (result, error, { id }) => [{ type: 'component', id }],
    }),
    updateComponent: builder.mutation({
      query: (payload) => ({
        url: `/components/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      extraOptions: { modelClass: Component },
      invalidatesTags: (result, error, { id }) => [{ type: 'component', id }],
    }),

    // Page Data
    getPageData: builder.query({
      query: (params: { siteId: string | number; pageId: string | number; pageVersionId: string | number }) => `/sites/${params?.siteId}/pages/${params?.pageId}/page_versions/${params?.pageVersionId}/objects`,
      providesTags: ['page_data'],
    }),

    // Admin Roles
    getRoles: builder.query({
      query: () => '/roles',
      providesTags: ['role'],
    }),

    // Users
    getUser: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: ['user'],
      extraOptions: { modelClass: User },
    }),
    getUsers: builder.query({
      query: () => '/users',
      providesTags: ['user'],
      extraOptions: { modelClass: User },
    }),
    createUser: builder.mutation({
      query: (params) => ({
        url: '/users',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['user'],
      extraOptions: { modelClass: User },
    }),
    updateUser: builder.mutation({
      query: (params) => ({
        url: `/users/${params.id}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['user'],
    }),
    deleteUser: builder.mutation({
      query: (params) => ({
        url: `/users/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['user'],
    }),

    // Current User Profile
    loginUserEmail: builder.mutation({
      query: (params) => ({
        url: '/login',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['user'],
    }),
    loginUserGoogle: builder.mutation({
      query: (params) => ({
        url: '/google_login',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['user'],
    }),
    getUserProfile: builder.query({
      query: () => '/profile',
      providesTags: ['user'],

      // This is only used to figure out if the user is logged into the dashboard. It returns a 404 if they are not,
      // but we don't want to display an error message, so we ignore the 404.
      // TODO: find another way to do this
      transformErrorResponse: () => null,
      extraOptions: { modelClass: User },
    }),
    logoutUser: builder.mutation({
      query: (params) => ({
        url: '/logout',
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['user'],
    }),
    resetPassword: builder.mutation({
      query: (params) => ({
        url: '/reset_password',
        method: 'POST',
        body: params,
      }),
    }),
    verifyResetToken: builder.query({
      query: (token) => `/reset_password/${token}`,
    }),
    setNewPassword: builder.mutation({
      query: (params) => ({
        url: `/reset_password/${params.resetToken}`,
        method: 'PUT',
        body: params,
      }),
    }),
    setGmailSignature: builder.mutation({
      query: (params) => ({
        url: `/users/${params.id}/set_gmail_signature`,
        method: 'POST',
        body: params,
      }),
    }),
    sendPresence: builder.mutation({
      query: (params) => ({
        url: `/users/${params.id}/presence`,
        method: 'POST',
        body: params,
        disableSpinner: true,
      }),
    }),

    // TextBlock
    getTextBlockHistory: builder.query({
      query: (id) => `/history_trackers/${id}`,
    }),

    // Geographic Area
    getGeographicArea: builder.query({
      query: () => `/geo_areas`,
    }),

    // Business Niches
    getBusinessNiches: builder.query({
      query: () => `/business_niches`,
    }),

    // Geographic Code
    getGeographicCode: builder.query({
      query: (params: { address: string }) => `/geocode/?address=${params.address}`,
    }),

    // Recent Items
    getRecentItems: builder.query({
      query: (params: { userId: string; instanceId: string }) => ({
        url: `/users/${params.userId}/recent_items?instance_id=${params.instanceId}`,
        method: 'GET',
        disableSpinner: true,
      }),
      extraOptions: { modelClass: UserRecentItem },
      providesTags: ['recent_item'],
    }),
    updateRecentItems: builder.mutation({
      query: (params: { userId: string; items: UserRecentItem[] }) => ({
        url: `/users/${params.userId}/recent_items`,
        method: 'POST',
        body: { items: params.items },
        disableSpinner: true,
      }),
      invalidatesTags: ['recent_item'],
    }),

    // CTAs
    getChatTranscript: builder.query({
      query: (params: { instanceId: string; id: string }) => `/instances/${params.instanceId}/cta_logs/${params.id}/transcript`,
      providesTags: ['cta_log'],
    }),
    getCtaLogs: builder.query({
      query: (params) => {
        // Convert "types" into "types[]" so that Rails can read the array
        // TODO: Maybe make this part of the fetchBaseQuery
        const tempParams = { ...params };
        if (tempParams.types) {
          tempParams['types[]'] = tempParams.types;
          delete tempParams.types;
        }

        return {
          url: `/instances/${params.instance_id}/cta_logs`,
          params: tempParams,
          method: 'GET',
        };
      },
      providesTags: ['cta_log'],
      extraOptions: { modelClass: CTALog },
    }),
    getSpamCtaLogs: builder.query({
      query: (params) => ({
        url: '/all_spam_form_submissions',
        params,
        method: 'GET',
      }),
      providesTags: ['cta_log'],
      extraOptions: { modelClass: CTALog },
    }),
    getCtaLog: builder.query({
      query: (params) => ({
        url: `/instances/${params.instance_id}/cta_logs/${params.cta_log_id}`,
        params,
        method: 'GET',
      }),
      providesTags: ['cta_log'],
      extraOptions: { modelClass: CTALog },
    }),
    deleteCtaLog: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/cta_logs/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['cta_log'],
    }),
    markCtaLogNotSpam: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/cta_logs/${params.id}/approve`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['cta_log'],
      extraOptions: { modelClass: CTALog },
    }),

    // Style Override
    getStyleOverrides: builder.query({
      query: (siteId) => `/sites/${siteId}/break_points`,
      extraOptions: { modelClass: SiteStyleOverride },
      providesTags: (result) => [
        'style_override',
        ...result.data.map(({ id }) => ({ type: 'style_override', id })),
      ],
    }),
    createStyleOverride: builder.mutation({
      query: (payload: SiteStyleOverride) => ({
        url: `/sites/${payload.site_id}/break_points`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['style_override'],
    }),
    updateStyleOverride: builder.mutation({
      query: (payload: SiteStyleOverride) => ({
        url: `/sites/${payload.site_id}/break_points`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'style_override', id }],
    }),
    deleteStyleOverride: builder.mutation({
      query: (params: SiteStyleOverride) => ({
        url: `/sites/${params.site_id}/break_points/${params.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'style_override', id }],
    }),

    // Automated Reports
    automatedReports: builder.query({
      query: (params: { instanceId: string | number }) => `/instances/${params.instanceId}/job_list`,
      providesTags: (result) => [
        'job',
        ...result.data.map(({ id }) => ({ type: 'job', id })),
      ],
    }),
    runAutomatedReport: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/run_job/${params.job_id}`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['job'],
    }),

    // Trash
    getTrashes: builder.query({
      query: (params) => ({
        url: `/trashes`,
        params,
        method: 'GET',
      }),
      extraOptions: { modelClass: Trash },
      providesTags: ['trash'],
    }),
    deleteTrash: builder.mutation({
      query: (payload) => ({
        url: `/trashes/${payload.id}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['trash'],
    }),
    restoreTrash: builder.mutation({
      query: (payload) => ({
        url: `/trashes/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['trash'],
    }),

    // Backend Error Logs
    getBackendErrorLogs: builder.query({
      query: (params) => ({
        url: `/backend_error_logs`,
        params,
        method: 'GET',
      }),
      providesTags: ['backend_error_logs'],
      extraOptions: { modelClass: BackendErrorLogs },
    }),
    getBackendErrorLogCategories: builder.query({
      query: () => ({
        url: '/backend_error_logs/category_list',
        method: 'GET',
      }),
      providesTags: ['backend_error_logs'],
    }),

    // Content Briefings
    getContentBriefings: builder.query({
      query: () => `/content_briefings`,
      providesTags: ['content_briefing'],
      extraOptions: { modelClass: ContentBriefing },
    }),
    getContentBriefing: builder.query({
      query: (params: { id: string }) => `/content_briefings/${params.id}`,
      providesTags: ['content_briefing'],
      extraOptions: { modelClass: ContentBriefing },
    }),
    createContentBriefing: builder.mutation({
      query: (payload: ContentBriefing) => ({
        url: `/content_briefings`,
        method: 'POST',
        body: { ...payload },
      }),
      invalidatesTags: ['content_briefing'],
      extraOptions: { modelClass: ContentBriefing },
    }),
    updateContentBriefing: builder.mutation({
      query: (payload: ContentBriefing) => ({
        url: `/content_briefings/${payload.id}`,
        method: 'PATCH',
        body: { ...payload },
      }),
      invalidatesTags: ['content_briefing'],
      extraOptions: { modelClass: ContentBriefing },
    }),
    deleteContentBriefing: builder.mutation({
      query: (payload: ContentBriefing) => ({
        url: `/content_briefings/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['content_briefing'],
    }),
    generateTopicSuggestions: builder.mutation({
      query: (cb: ContentBriefing) => ({
        url: `/content_briefings/${cb.id}/generate_topic_suggestions`,
        method: 'POST',
      }),
      invalidatesTags: ['content_briefing'],
    }),
    generateContentTopicQuestions: builder.mutation({
      query: ({ cb, topic }: { cb: ContentBriefing; topic: string }) => ({
        url: `/content_briefings/${cb.id}/generate_content_topic_questions?topic=${encodeURIComponent(topic)}`,
        method: 'POST',
      }),
      invalidatesTags: ['content_briefing'],
    }),

    generateContentFromContentBriefing: builder.mutation({
      query: ({
        contentBriefing,
        destination,
        documentName,
        shareEmails,
      }: {
        contentBriefing: ContentBriefing;
        destination: string;
        documentName: string;
        shareEmails: string[];
      }) => ({
        url: `/content_briefings/${contentBriefing.id}/generate_content`,
        method: 'POST',
        body: {
          destination,
          document_name: documentName,
          share_emails: shareEmails,
        },
      }),
      invalidatesTags: ['content_briefing'],
      extraOptions: { modelClass: ContentBriefing },
    }),

    // Page URLs
    getPageURLs: builder.query({
      query: (payload) => ({
        url: `/urls?resource_id=${payload.pageId}&resource_type=Page`,
        method: 'GET',
      }),
      providesTags: ['page_url'],
    }),
    createPageURL: builder.mutation({
      query: (payload) => ({
        url: `/urls`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['page_url'],
    }),
    updatePageURL: builder.mutation({
      query: (payload) => ({
        url: `/urls/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_url'],
    }),
    updatePrefix: builder.mutation({
      query: (payload) => ({
        url: `/urls/update_prefix`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_url'],
    }),
    setPrimaryURL: builder.mutation({
      query: (payload) => ({
        url: `/urls/set_primary_url`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['page_url'],
    }),
    deletePageURL: builder.mutation({
      query: (id) => ({
        url: `/urls/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['page_url'],
    }),

    // Stats
    getDailyTraffic: builder.query({
      query: (params) => ({
        url: `/instances/${params.instance_id}/stats_days/daily_traffic`,
        method: 'GET',
        params,
      }),
    }),

    // Template Fragment
    getTemplateFragments: builder.query({
      query: (params: { siteId: string | number; instanceId: string | number }) => `/sites/${params.siteId}/template_fragments?instance_id=${params.instanceId}`,
      providesTags: (result) => [
        'template_fragment',
        ...result.data.map(({ id }) => ({ type: 'template_fragment', id })),
      ],
      extraOptions: { modelClass: TemplateFragment },
    }),
    getTemplateFragmentById: builder.query({
      query: (params: { siteId: string | number; id: string | number }) => ({
        url: `/sites/${params.siteId}/template_fragments/${params.id}`,
        method: 'GET',
      }),
      extraOptions: { modelClass: TemplateFragment },
      providesTags: (result, error, args: { id }) => [{ type: 'template_fragment', id: args.id }],
    }),
    templateFragmentDelete: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/template_fragments/${payload.id}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['template_fragment'],
      extraOptions: { modelClass: TemplateFragment },
    }),
    templateFragmentCreate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/template_fragments`,
        method: 'POST',
        params: payload,
      }),
      extraOptions: { modelClass: TemplateFragment },
      invalidatesTags: (result, error, { id }) => [{ type: 'template_fragment', id }],
    }),
    templateFragmentUpdate: builder.mutation({
      query: (payload) => ({
        url: `/sites/${payload.site_id}/template_fragments/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      extraOptions: { modelClass: TemplateFragment },
      invalidatesTags: (result, error, { id }) => [{ type: 'template_fragment', id }],
    }),

    // Media Centre
    getFiles: builder.query({
      query: (params) => ({
        url: `instances/${params.instance_id}/files`,
        method: 'GET',
        params,
      }),
      providesTags: (result) => [
        'file',
        ...result.data.files.map(({ id }) => ({ type: 'file', id })),
      ],
    }),
    fileDelete: builder.mutation({
      query: (payload) => ({
        url: `instances/${payload.instance_id}/files/${payload.id}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['file'],
    }),
    fileRename: builder.mutation({
      query: (payload) => ({
        url: `instances/${payload.instance_id}/files/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['file'],
    }),
    fileCreate: builder.mutation({
      query: (payload) => ({
        url: `instances/${payload.instance_id}/file_folders`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'file', id }],
    }),
    fileUpdate: builder.mutation({
      query: (payload) => ({
        url: `instances/${payload.instance_id}/file_folders/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['file'],
    }),
    fileFolderDelete: builder.mutation({
      query: (payload) => ({
        url: `instances/${payload.instance_id}/file_folders/${payload.id}`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['file'],
    }),

    // Saved Reports
    getSavedReports: builder.query({
      query: () => ({
        url: '/saved_reports',
        method: 'GET',
      }),
      extraOptions: { modelClass: SavedReport },
      providesTags: ['saved_report'],
    }),
    createSavedReport: builder.mutation({
      query: (payload) => ({
        url: '/saved_reports',
        method: 'POST',
        body: payload,
      }),
      extraOptions: { modelClass: SavedReport },
      invalidatesTags: ['saved_report'],
    }),
    updateSavedReport: builder.mutation({
      query: (payload) => ({
        url: `/saved_reports/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      extraOptions: { modelClass: SavedReport },
      invalidatesTags: ['saved_report'],
    }),
    exportSavedReport: builder.mutation({
      query: (payload) => ({
        url: `/saved_reports/${payload.id}/export_report`,
        method: 'POST',
        body: payload,
      }),
      extraOptions: { modelClass: SavedReport },
      invalidatesTags: ['saved_report'],
    }),
    deleteSavedReport: builder.mutation({
      query: (payload) => ({
        url: `/saved_reports/${payload.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['saved_report'],
    }),

    // ROI Reports
    roiReportSites: builder.query({
      query: (params) => ({
        url: '/roi_reports/site_list',
        method: 'GET',
        params,
      }),
      providesTags: ['roi_report_site'],
    }),
    roiRegenerateReport: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/sites/${params.id}/roi_report/regenerate`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['roi_report_site'],
    }),
    roiRegenerateAll: builder.mutation({
      query: () => ({
        url: `/roi_reports/regenerate_all`,
        method: 'POST',
      }),
      invalidatesTags: ['roi_report_site'],
    }),
    roiPullFromMYOB: builder.mutation({
      query: (params) => ({
        url: `/instances/${params.instance_id}/sites/${params.id}/resync_myob`,
        method: 'POST',
      }),
      invalidatesTags: ['roi_report_site'],
    }),
    roiSend: builder.mutation({
      query: (params) => ({
        url: '/roi_reports/send_all',
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['roi_report_site'],
    }),

    // Error Log
    createErrorLog: builder.mutation({
      query: (params) => ({
        url: '/client_js_error_logs',
        method: 'POST',
        body: params,
      }),
    }),

    // Services
    getXeroConnection: builder.query({
      query: (params: { instanceId: string | number }) => ({
        url: `/xero/check_xero_connection?instance_id=${params.instanceId}`,
        method: 'GET',
      }),
      providesTags: ['xero_connection'],
    }),
    xeroConnect: builder.mutation({
      query: (params) => ({
        url: '/xero/login',
        method: 'GET',
        params,
      }),
    }),
    xeroDisconnect: builder.mutation({
      query: (params) => ({
        url: '/xero/disconnect',
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: ['xero_connection'],
    }),

    // Keyword Research Tool
    keywordResearchTool: builder.mutation({
      query: (params) => ({
        url: `/keyword_research_tool`,
        method: 'POST',
        body: params,
      }),
    }),

    // AI Models
    getAiModels: builder.query({
      query: () => ({
        url: `/ai_models`,
        method: 'GET',
      }),
      extraOptions: { modelClass: AIModel },
    }),

    // AI Text Prompts
    getAiTextPrompts: builder.query({
      query: (params) => ({
        url: `/ai_text_prompts`,
        method: 'GET',
        params,
      }),
      extraOptions: { modelClass: AITextPrompt },
    }),
    getAiTextPrompt: builder.query({
      query: (id) => ({
        url: `/ai_text_prompts/${id}`,
        method: 'GET',
      }),
      extraOptions: { modelClass: AITextPrompt },
    }),
    updateAiTextPrompt: builder.mutation({
      query: (prompt: AITextPrompt) => ({
        url: `/ai_text_prompts/${prompt.id}`,
        method: 'PUT',
        body: prompt,
      }),
    }),
    testAiTextPrompt: builder.mutation({
      query: (prompt: AITextPrompt) => ({
        url: `/ai_text_prompts/${prompt.id}/test`,
        method: 'POST',
        body: prompt,
      }),
    }),
    // Blogs
    getBlogPosts: builder.query({
      query: (params: { instanceId: string | number; siteId: string | number }) => `/sites/${params.siteId}/blog_pages?instance_id=${params.instanceId}`,
      providesTags: ['blogs'],
    }),
  }),
  overrideExisting: false,
});

// Wrap all endpoints to add the "meta" key to the result

const transformedEndpoints = transformEndpoints(dashboardApi);

export const {
  endpoints,

  // Objects
  useGetObjectByIdQuery,
  useLazyCheckBeforeDeleteQuery,
  useUnsubscribeFromAllMutation,

  // Dashboard support form
  useDashboardSupportFormMutation,

  // Instances
  useGetInstancesQuery,
  useGetInstanceByIdQuery,
  useUpdateInstanceMutation,
  useGetMasterInstancesQuery,
  useImportInstanceFromLegacyMutation,
  useGetInstanceDataQuery,
  useDuplicateInstanceMutation,

  // Sites
  useGetSiteByIdQuery,
  useGetSitesQuery,
  useGetAllSitesQuery,
  useCreateSiteMutation,
  useDeleteSiteMutation,
  useUpdateSiteMutation,
  useSitesLinkingToMeQuery,
  useLazyGetGoogleAnalyticsAccountListQuery,
  useCreateAnalyticsPropertyMutation,
  useLazyGetAnalyticsDataStreamsQuery,

  // Geographic Area
  useGetGeographicAreaQuery,

  // Business Niches
  useGetBusinessNichesQuery,

  // Geographic Code
  useLazyGetGeographicCodeQuery,

  // Domains
  useGetDomainsQuery,
  useGetAllDomainsQuery,
  useUpdateDomainMutation,
  useDeleteDomainMutation,
  useAddNewDomainMutation,
  useMakeDomainPrimaryMutation,
  useIssueDomainCertificateMutation,
  useDomainEligibilityCheckQuery,
  useDomainEnableEmailMutation,

  // Pages
  useGetPagesQuery,
  useGetPageQuery,
  useGetOrphanPagesQuery,
  useGetMasterPagesQuery,
  useGetPageSectionsQuery,
  useAddNewPageMutation,
  useDeletePageMutation,
  usePageUpdateMutation,
  useDuplicatePageMutation,
  useGetPageTreeQuery,
  usePageBulkEditMutation,

  // Email Pages
  useGetEmailsQuery,
  useEmailPageUpdateMutation,
  useEmailPageDuplicateMutation,
  useEmailPageCreateMutation,
  useEmailPageDeleteMutation,
  useEmailPageProofMutation,

  // Page Versions
  useGetPageVersionsQuery,
  useGetPageVersionQuery,
  usePageVersionPublishMutation,
  useGetEmailPageVersionsQuery,
  usePageVersionCreateMutation,
  usePageVersionDeleteMutation,
  usePageVersionUpdateMutation,

  // URLs
  useGetURLByIdQuery,

  // Sectors
  useGetAllSectorsFromSiteQuery,
  useGetSectorsQuery,
  useSectorCreateMutation,
  useSectorUpdateMutation,
  useSectorDeleteMutation,
  useSectorPositionMutation,

  // Sections
  useGetSectionsQuery,
  useGetMasterSectionsQuery,
  useGetSectionHTMLQuery,
  useSectionCreateMutation,
  useGetSectionQuery,
  useSectionUpdateMutation,
  useSectionDeleteMutation,
  useGetColumnsQuery,
  useSectionPositionUpdateMutation,
  useGetSectionsByTypeQuery,
  useDuplicateSectionMutation,

  // Text Styles
  useGetTextStyleByIdQuery,
  useGetTextStylesQuery,
  useAddNewTextStyleMutation,
  useUpdateTextStyleMutation,
  useDeleteTextStyleMutation,

  // Custom Fonts
  useGetCustomFontsQuery,

  // Load Media Tool
  useGetMediaFilesQuery,
  useGetMediaByIdQuery,
  useCreateMediaMutation,
  useUpdateMediaMutation,
  useDeleteMediaMutation,

  // Button Styles
  useGetButtonStylesQuery,
  useAddButtonStyleMutation,
  useGetButtonStyleByIdQuery,
  useUpdateButtonStyleMutation,
  useDeleteButtonStyleMutation,

  // Member Email Logs
  useGetMemberEmailLogsQuery,

  // Export Members
  useExportMembersQuery,

  // Site Briefing
  useCreateSiteBriefingMutation,
  useGetSiteBriefingsQuery,
  useGetSiteBriefingQuery,
  useDeleteSiteBriefingMutation,
  useUpdateSiteBriefingMutation,
  useAddImageToBriefingMutation,
  useGenerateInstanceFromSiteBriefingMutation,
  useGenerateContentFromSiteBriefingMutation,
  useLazyGenerateQuestionsQuery,
  useImportFromPipedriveMutation,
  useGenerateOrientationStatementMutation,
  useGenerateSellingPointsMutation,
  useGeneratePageSellingPointsMutation,
  useSendBDQNotificationMutation,
  useCrawlSiteMutation,
  useSendDiscussionDeckNotificationMutation,
  useGenerateBriefingAIContentMutation,

  // Misc
  useGetSpellingQuery,

  // Link tool
  useLoadLinkToolQuery,
  useGetLinkDataQuery,
  useGetLinkToolDataQuery,
  useLinkToolCreateLinkMutation,
  useLinkToolUpdateLinkMutation,
  useLinkToolUnlinkMutation,

  // Reports
  useGetReportConfigQuery,
  useGetReportDataQuery,
  useLazyRunToolQuery,

  // Templates
  useGetTemplatesQuery,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  useDuplicateTemplatesMutation,

  // Custom Tooltips
  useGetCustomTooltipsQuery,
  useCreateCustomTooltipMutation,
  useUpdateCustomTooltipMutation,

  // Email Series
  useGetEmailSeriesQuery,
  useGetEmailSeriesByIdQuery,
  useCreateEmailSeriesMutation,
  useDeleteEmailSeriesMutation,
  useUpdateEmailSeriesMutation,
  useEmailSeriesSubscribeMemberMutation,

  // Custom Code
  useGetCustomCodesQuery,
  useGetCustomCodeQuery,
  useCreateCustomCodeMutation,
  useUpdateCustomCodeMutation,
  useDeleteCustomCodeMutation,

  // Database fields
  useGetDatabaseFieldsQuery,
  useGetDatabaseFieldQuery,
  useCreateDatabaseFieldMutation,
  useUpdateDatabaseFieldMutation,
  useDeleteDatabaseFieldMutation,
  usePositionDatabaseFieldMutation,
  useGetDatabaseSearchFieldsQuery,

  // Component Categories
  useGetComponentCategoriesQuery,
  useGetComponentCategoryQuery,
  useUpdateComponentCategoryMutation,
  useCreateComponentCategoryMutation,
  useDeleteComponentCategoryMutation,

  // Components
  useGetComponentsQuery,
  useGetComponentQuery,
  useDuplicateComponentMutation,
  useDeleteComponentMutation,
  useCreateComponentMutation,
  useUpdateComponentMutation,

  // Page Data
  useGetPageDataQuery,

  // Admin Roles
  useGetRolesQuery,

  // Users
  useGetUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,

  // Current User Profile
  useLoginUserEmailMutation,
  useLoginUserGoogleMutation,
  useGetUserProfileQuery,
  useLogoutUserMutation,
  useResetPasswordMutation,
  useVerifyResetTokenQuery,
  useSetNewPasswordMutation,
  useSetGmailSignatureMutation,
  useSendPresenceMutation,

  // TextBlock
  useGetTextBlockHistoryQuery,

  // Recent Items
  useGetRecentItemsQuery,
  useUpdateRecentItemsMutation,

  // CTAs
  useGetChatTranscriptQuery,
  useGetCtaLogsQuery,
  useGetSpamCtaLogsQuery,
  useGetCtaLogQuery,
  useDeleteCtaLogMutation,
  useMarkCtaLogNotSpamMutation,

  // Saved Search
  useGetSavedSearchesQuery,
  useGetSavedSearchQuery,
  useCreateSavedSearchMutation,
  useDeleteSavedSearchesMutation,

  // Members
  useGetMembersQuery,
  useGetMemberQuery,
  useGetMemberSearchByIdQuery,
  useDeleteMemberMutation,
  useDeleteSearchMemberMutation,
  useMemberSearchCreateMutation,
  useMemberSearchUpdateMutation,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useUploadCSVFileMutation,
  useImportMemberMutation,
  useCancelMemberEmailSeriesSubscriptionMutation,

  // Background Job Progress
  useGetJobProgressQuery,
  useGetBGJobProgressQuery,
  useBackgroundJobCancelMutation,
  useGetJobProgressReportQuery,

  // Bulk Email
  useSendBulkEmailMutation,

  // Style Override
  useGetStyleOverridesQuery,
  useCreateStyleOverrideMutation,
  useUpdateStyleOverrideMutation,
  useDeleteStyleOverrideMutation,

  // Automated Reports
  useAutomatedReportsQuery,
  useRunAutomatedReportMutation,

  // Trash
  useGetTrashesQuery,
  useDeleteTrashMutation,
  useRestoreTrashMutation,

  // Backend Error Logs
  useGetBackendErrorLogsQuery,
  useGetBackendErrorLogCategoriesQuery,

  // Content Briefings
  useGetContentBriefingsQuery,
  useGetContentBriefingQuery,
  useCreateContentBriefingMutation,
  useUpdateContentBriefingMutation,
  useDeleteContentBriefingMutation,
  useGenerateSuggestionsMutation,

  // Content Topics
  useGenerateTopicSuggestionsMutation,
  useGenerateContentTopicQuestionsMutation,
  useGenerateContentFromContentBriefingMutation,

  // Page URLs
  useGetPageURLsQuery,
  useCreatePageURLMutation,
  useUpdatePageURLMutation,
  useDeletePageURLMutation,
  useSetPrimaryURLMutation,
  useUpdatePrefixMutation,

  // Stats
  useGetDailyTrafficQuery,

  // Template Fragment
  useGetTemplateFragmentsQuery,
  useGetTemplateFragmentByIdQuery,
  useTemplateFragmentDeleteMutation,
  useTemplateFragmentCreateMutation,
  useTemplateFragmentUpdateMutation,

  // Media Centre
  useGetFilesQuery,
  useFileDeleteMutation,
  useFileRenameMutation,
  useFileCreateMutation,
  useFileUpdateMutation,
  useFileFolderDeleteMutation,

  // Saved Reports
  useGetSavedReportsQuery,
  useCreateSavedReportMutation,
  useUpdateSavedReportMutation,
  useExportSavedReportMutation,
  useDeleteSavedReportMutation,

  // ROI Reports
  useRoiReportSitesQuery,
  useRoiRegenerateReportMutation,
  useRoiRegenerateAllMutation,
  useRoiPullFromMYOBMutation,
  useRoiSendMutation,

  // Error Log
  useCreateErrorLogMutation,

  // Services
  useGetXeroConnectionQuery,
  useXeroConnectMutation,
  useXeroDisconnectMutation,

  // Keyword Research Tool
  useKeywordResearchToolMutation,

  // AI Models
  useGetAiModelsQuery,

  // AI Text Prompts
  useGetAiTextPromptsQuery,
  useGetAiTextPromptQuery,
  useUpdateAiTextPromptMutation,
  useTestAiTextPromptMutation,

  // Blogs
  useGetBlogPostsQuery,
} = transformedEndpoints as any;
