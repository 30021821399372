import { createStore } from '../rootStore';
import { PageData } from 'ts/interfaces';

type MacroTuple = [RegExp, string | undefined, boolean];

const getMacros = (): MacroTuple[] => {
  const store = createStore();
  const { pageData: { instance, site, page_version, member, macros: allMacros } } = store.getState() as { pageData: PageData };

  // The third element here is whether the macro should be HTML-escaped.
  const macros: MacroTuple[] = [
    [/\(resource:name\)/ig, page_version?.name, false],
    [/\(resource:copyright\)/ig, page_version?.copyright, false],
    [/\(resource:poweredby\)/ig, page_version?.powered_by, false],
    [/\(resource:sitemaplink\)/ig, page_version?.sitemap_link, false],
    [/\(resource:directorylink\)/ig, page_version?.directory_link, false],
    [/\(resource:title\)/ig, page_version?.page_title, false],
    [/\(resource:lastupdated\)/ig, page_version?.last_updated, false],

    [/\(instanceid\)/ig, instance?.id?.toString(), false],
    [/\(instance:id\)/ig, instance?.id?.toString(), false],
    [/\(siteid\)/ig, site?.id?.toString(), false],
    [/\(site:id\)/ig, site?.id?.toString(), false],
    [/\(site:name\)/ig, site?.display_name, false],
    [/\(site:phonenumber\)/ig, site?.phone_number, false],
    [/\(site:addressstreet\)/ig, site?.company_address_street, false],
    [/\(site:addresslocality\)/ig, site?.company_address_locality, false],
    [/\(site:addressregion\)/ig, site?.company_address_region, false],
    [/\(site:orientationstatement\)/ig, site?.orientation_statement, false],
    [/\(site:displayedphonenumber\)/ig, site?.displayed_phone_number !== '' ? site?.displayed_phone_number : site?.phone_number, false],
  ];

  if (member?.macros) {
    Object.keys(member.macros).forEach((macro) => {
      if (member.macros) {
        macros.push([new RegExp(`\\(${macro}\\)`, 'ig'), member.macros[macro], true]);
      }
    });
  } else if (allMacros) {
    // The member is not set, but there may still be database field macros on the page -- we replace these with empty
    // strings.
    allMacros.forEach((macro) => {
      macros.push([new RegExp(`\\(${macro}\\)`, 'ig'), '', true]);
    });
  }

  return macros;
};

export default getMacros;
