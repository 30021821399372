import Base from './Base';
import { PopupOptions } from './Page';
import { Color, Libraries, LibraryVersion } from './Reports';

export type BlogPostType = {
  id: string | number;
  url: string;
  absolute_url: string;
  title: string;
  blog_publish_date?: string;
  blog_post_image_id?: string;
  blog_post_image_url?: string;
  blog_tags?: string[];
  blog_author?: string;
  blog_excerpt?: string;
};

export type SocialMediaType = {
  label: string;
  value: string;
  icon: string;
  url: (post: BlogPostType) => string;
};

export const socialMedia: SocialMediaType[] = [
  {
    label: 'Facebook',
    value: 'facebook',
    icon: 'facebook',
    url: (post) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(post?.absolute_url)}`,
  },
  {
    label: 'X',
    value: 'x',
    icon: 'x-twitter',
    url: (post) => `https://twitter.com/intent/tweet?url=${encodeURIComponent(post?.absolute_url)}&text=${encodeURIComponent(post?.title)}`,
  },
  {
    label: 'Reddit',
    value: 'reddit',
    icon: 'reddit',
    url: (post) => `https://www.reddit.com/submit?url=${encodeURIComponent(post?.absolute_url)}&title=${encodeURIComponent(post?.title)}`,
  },
  {
    label: 'Bluesky',
    value: 'bluesky',
    icon: 'bluesky',
    url: (post) => `https://bsky.app/compose?text=${encodeURIComponent(post?.title)}%20${encodeURIComponent(post?.absolute_url)}`,
  },
  {
    label: 'Pinterest',
    value: 'pinterest',
    icon: 'pinterest',
    url: (post) => `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(post?.absolute_url)}&media=${encodeURIComponent(post?.blog_post_image_url || '')}&description=${encodeURIComponent(post?.title)}`,
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
    icon: 'linkedin',
    url: (post) => `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(post?.absolute_url)}`,
  },
  {
    label: 'Threads',
    value: 'threads',
    icon: 'threads',
    url: (post) => `https://www.threads.net/intent/post?url=${encodeURIComponent(post?.absolute_url)}&text=${encodeURIComponent(post?.title)}`,
  },
] as const;

interface SiteProps {
  id?:                                           string | number;
  background_for_button?:                        string;
  billing_active?:                               boolean;
  billing_advisor_id?:                           null;
  billing_company_name?:                         null;
  billing_contact_name?:                         null;
  billing_emails?:                               null;
  billing_monthly_fee_ace?:                      number;
  billing_monthly_fee_ads?:                      number;
  billing_monthly_fee_gre?:                      number;
  billing_monthly_fee_seo?:                      number;
  billing_monthly_fee?:                          number;
  billing_related_sites?:                        null;
  billing_wfl_upgrade_date?:                     null;
  broad_business_niche_ids?:                     any[];
  business_directory_master_page_id?:            null;
  campaign_budget_percentage?:                   number;
  chatbot_data_source?:                          string;
  chatbot_extra_instructions?:                   string;
  chatbot_initial_message?:                      string;
  chatbot_persona_icon_id?:                      null;
  chatbot_persona_name?:                         string;
  chatbot_style?:                                string;
  chatbot_text?:                                 string;
  colors?:                                       Color[];
  company_address_locality?:                     string;
  company_address_region?:                       string;
  company_address_street?:                       string;
  copyright_year?:                               number;
  default_form_email_addresses?:                 any[];
  default_form_email_subject?:                   string;
  default_form_mobile_number?:                   string;
  directory_auto_included_site_ids?:             any[];
  directory_excluded_site_ids?:                  any[];
  directory_manually_included_site_ids?:         any[];
  display_name?:                                 string;
  displayed_phone_number?:                       string;
  enable_instant_contact_form_chat?:             boolean;
  enable_instant_contact_form_form?:             boolean;
  enable_instant_contact_form?:                  boolean;
  enable_phone_breakpoint?:                      boolean;
  enable_tablet_breakpoint?:                     boolean;
  favicon_id?:                                   null;
  geo_area_id?:                                  null;
  geo_coords?:                                   null;
  google_ads_account_id?:                        null;
  google_analytics_account_id?:                  null;
  google_analytics_data_stream_id?:              null;
  google_analytics_property_id?:                 null;
  google_analytics_service_account?:             null;
  google_site_verification?:                     any[];
  google_tag_manager_id?:                        null;
  guaranteed_keyword_phrases?:                   any[];
  has_chatbot?:                                  boolean;
  home_page_id?:                                 string;
  hover_background_for_button?:                  string;
  in_directory?:                                 boolean;
  initial_visibility_percentage?:                null;
  initial_visibility_points?:                    null;
  instance_id?:                                  string;
  instant_contact_form_days_of_week?:            any[];
  instant_contact_form_end_time?:                Date;
  instant_contact_form_start_time?:              Date;
  instant_contact_interstitial_button_style_id?: string;
  instant_contact_interstitial_text_style_id?:   string;
  instant_contact_chat_text_style_id?:           string;
  instant_contact_intro_text?:                   string;
  instant_contact_master_page_id?:               null;
  instant_contact_page_id?:                      string;
  label?:                                        string;
  libraries?:                                    Libraries;
  library_version?:                              LibraryVersion;
  link_directory_number?:                        number;
  majestic_citation_flow_home?:                  null;
  majestic_trust_flow_home?:                     null;
  microdata_image_id?:                           null;
  microdata_logo_id?:                            null;
  myob_id?:                                      null;
  narrow_business_niche_ids?:                    any[];
  orientation_statement?:                        string;
  phone_number?:                                 null;
  popup_options?:                                PopupOptions;
  popup_page_id?:                                null;
  popup_visible_to?:                             string;
  price_range?:                                  string;
  primary_domain_id?:                            string;
  email_domain_id?:                              string;
  privacy_policy_master_page_id?:                null;
  privacy_policy_text?:                          string;
  products?:                                     any[];
  profile_info?:                                 any;
  recaptcha_bot_detection_threshold?:            number;
  recaptcha_site_key?:                           string;
  review_binary?:                                boolean;
  review_email_addresses?:                       any[];
  review_list_page_url?:                         string;
  review_list_page_id?:                          null;
  review_pages?:                                 any;
  review_star_labels?:                           string[];
  review_threshold?:                             number;
  seo_visibility_model?:                         number;
  site_description?:                             string;
  site_map_master_page_id?:                      null;
  site_search_master_page_id?:                   null;
  text_for_instant_contact_form?:                string;
  text_style_for_instant_contact_form_id?:       null;
  time_zone?:                                    string;
  tracking_phone_numbers?:                       any[];
  unsubscribe_master_page_id?:                   null;
  value?:                                        string;
  wg_hosted?:                                    boolean;
  primary_domain_name?:                          string;
  email_domain_name?:                            string;
  blog_page_id?:                                 string;
  blog_page_url?:                                string;
  blog_description?:                             string;
  blog_name?:                                    string;
  blog_social_media?:                            string[];
  default_blog_post_master_page_id?:             string;
  blog_list_layout?:                             number;
  blog_page_layout?:                             number;
}

class Site extends Base {
  id?:                                           string | number;
  background_for_button?:                        string;
  billing_active?:                               boolean;
  billing_advisor_id?:                           null;
  billing_company_name?:                         null;
  billing_contact_name?:                         null;
  billing_emails?:                               null;
  billing_monthly_fee_ace?:                      number;
  billing_monthly_fee_ads?:                      number;
  billing_monthly_fee_gre?:                      number;
  billing_monthly_fee_seo?:                      number;
  billing_monthly_fee?:                          number;
  billing_related_sites?:                        null;
  billing_wfl_upgrade_date?:                     null;
  broad_business_niche_ids?:                     any[];
  business_directory_master_page_id?:            null;
  campaign_budget_percentage?:                   number;
  chatbot_data_source?:                          string;
  chatbot_extra_instructions?:                   string;
  chatbot_initial_message?:                      string;
  chatbot_persona_icon_id?:                      null;
  chatbot_persona_name?:                         string;
  chatbot_style?:                                string;
  chatbot_text?:                                 string;
  colors?:                                       Color[];
  company_address_locality?:                     string;
  company_address_region?:                       string;
  company_address_street?:                       string;
  copyright_year?:                               number;
  default_form_email_addresses?:                 any[];
  default_form_email_subject?:                   string;
  default_form_mobile_number?:                   string;
  directory_auto_included_site_ids?:             any[];
  directory_excluded_site_ids?:                  any[];
  directory_manually_included_site_ids?:         any[];
  display_name?:                                 string;
  displayed_phone_number?:                       string;
  enable_instant_contact_form_chat?:             boolean;
  enable_instant_contact_form_form?:             boolean;
  enable_instant_contact_form?:                  boolean;
  enable_phone_breakpoint?:                      boolean;
  enable_tablet_breakpoint?:                     boolean;
  favicon_id?:                                   null;
  geo_area_id?:                                  null;
  geo_coords?:                                   null;
  google_ads_account_id?:                        null;
  google_analytics_account_id?:                  null;
  google_analytics_data_stream_id?:              null;
  google_analytics_property_id?:                 null;
  google_analytics_service_account?:             null;
  google_site_verification?:                     any[];
  google_tag_manager_id?:                        null;
  guaranteed_keyword_phrases?:                   any[];
  has_chatbot?:                                  boolean;
  home_page_id?:                                 string;
  hover_background_for_button?:                  string;
  in_directory?:                                 boolean;
  initial_visibility_percentage?:                null;
  initial_visibility_points?:                    null;
  instance_id?:                                  string;
  instant_contact_form_days_of_week?:            any[];
  instant_contact_form_end_time?:                Date;
  instant_contact_form_start_time?:              Date;
  instant_contact_interstitial_button_style_id?: string;
  instant_contact_interstitial_text_style_id?:   string;
  instant_contact_chat_text_style_id?:           string;
  instant_contact_intro_text?:                   string;
  instant_contact_master_page_id?:               null;
  instant_contact_page_id?:                      string;
  label?:                                        string;
  libraries?:                                    Libraries;
  library_version?:                              LibraryVersion;
  link_directory_number?:                        number;
  majestic_citation_flow_home?:                  null;
  majestic_trust_flow_home?:                     null;
  microdata_image_id?:                           null;
  microdata_logo_id?:                            null;
  myob_id?:                                      null;
  narrow_business_niche_ids?:                    any[];
  orientation_statement?:                        string;
  phone_number?:                                 null;
  popup_options?:                                PopupOptions;
  popup_page_id?:                                null;
  popup_visible_to?:                             string;
  price_range?:                                  string;
  primary_domain_id?:                            string;
  email_domain_id?:                              string;
  privacy_policy_master_page_id?:                null;
  privacy_policy_text?:                          string;
  products?:                                     any[];
  profile_info?:                                 any;
  recaptcha_bot_detection_threshold?:            number;
  recaptcha_site_key?:                           string;
  review_binary?:                                boolean;
  review_email_addresses?:                       any[];
  review_list_page_url?:                         string;
  review_list_page_id?:                          null;
  review_pages?:                                 any;
  review_star_labels?:                           string[];
  review_threshold?:                             number;
  seo_visibility_model?:                         number;
  site_description?:                             string;
  site_map_master_page_id?:                      null;
  site_search_master_page_id?:                   null;
  text_for_instant_contact_form?:                string;
  text_style_for_instant_contact_form_id?:       null;
  time_zone?:                                    string;
  tracking_phone_numbers?:                       any[];
  unsubscribe_master_page_id?:                   null;
  value?:                                        string;
  wg_hosted?:                                    boolean;
  primary_domain_name?:                          string;
  email_domain_name?:                            string;
  blog_page_id?:                                 string;
  blog_page_url?:                                string;
  blog_description?:                             string;
  blog_name?:                                    string;
  blog_social_media?:                            string[];
  default_blog_post_master_page_id?:             string;
  blog_title_style_id?:                          string;
  blog_post_title_style_id?:                     string;
  blog_body_style_id?:                           string;
  blog_list_layout?:                             number;
  blog_page_layout?:                             number;

  constructor(props: SiteProps) {
    super(props);
    this.id = String(props.id);
    Object.assign(this, props);
  }

  hasProduct(productName: string): boolean {
    if (!this.products || !Array.isArray(this.products)) {
      return false;
    }

    return this.products.some((product) => product.name === productName);
  }
}

export default Site;
