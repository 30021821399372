import CustomSelect from '../../../components/common/custom-select';
import { getPageHierarchy } from '../../../components/common/PageHierarchy';
import { getSelectedOption } from '../../../../../Utils';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Label, FormGroup } from 'reactstrap';
import { useGetInstanceDataQuery } from '../../../api/dashboardApiSlice';

function SitePagesHierarchy(props) {
  const { siteId, instanceId } = useParams();
  const { params, setParams, label } = props;
  const [pageData, setPageData] = useState([]);
  const { data } = useGetInstanceDataQuery(instanceId, { skip: isEmpty(instanceId) });

  useEffect(() => {
    if (data) {
      // Some sites have numeric IDs, and comparing with === doesn't work because the URL parameter is a string.

      const siteData = data.filter((item) => item.id == instanceId)[0].sites;
      // Some sites have numeric IDs, and comparing with === doesn't work because the URL parameter is a string.

      setPageData(siteData.filter((item) => item.id == siteId)[0]?.pages || []);
    }
  }, [data]);

  return (
    <FormGroup>
      <Label>{label}</Label>
      <CustomSelect
        options={getPageHierarchy(pageData, '')}
        className="flex-grow-1"
        value={getSelectedOption(getPageHierarchy(pageData), params.leave_review_page_id)}
        onChange={(e) => setParams({ ...params, leave_review_page_id: e.value })}
      />
    </FormGroup>
  );
}

export default SitePagesHierarchy;
