import React from 'react';
import { isEmpty } from 'lodash-es';
import ContentEditor from '../ContentType';
import ColumnType from '../ColumnType';
import FormSection from '../Form';
import ImageGallery from '../ImageGallery';
import MirrorSection from '../Mirror';
import Component from '../Component';
import ReviewList from '../ReviewList';
import SectionEcwid from '../Ecwid';
import Custom from '../Custom';
import withRouter from '../../../../helpers/withRouter';
import { updateStylesheet } from '../../../../../../Utils';
import { useSite } from '../../../../../../common/hooks';
import { refetchPageData } from 'common/api/apiSlice';

function SectionEdit(props) {
  const { section, params: { pageType } } = props;

  const sectionType = section?.type;

  const isEmailPage = pageType === 'email';

  const site = useSite();

  if (isEmpty(sectionType)) {
    return <></>;
  }

  const sectionProps = {
    ...props,
    isEmailPage,
    updateStylesheet: () => {
      if (isEmailPage) {
        // Don't need to do this, the styles are inlined in the HTML by MJML.
        return;
      }

      updateStylesheet({ siteId: site.id, pageId: section?.page_id, pageVersionId: section?.page_version_id });

      if (window.wg.env === 'public') {
        refetchPageData();
      }
    },
  };

  return (
    <>
      {sectionType === 'SectionContent' && (
        <ContentEditor {...sectionProps} />
      )}
      {sectionType === 'SectionColumn' && (
        <ColumnType {...sectionProps} />
      )}
      {sectionType === 'SectionForm' && (
        <FormSection {...sectionProps} />
      )}
      {sectionType === 'SectionImageGallery' && (
        <ImageGallery {...sectionProps} />
      )}
      {sectionType === 'SectionComponent' && (
        <Component {...sectionProps} />
      )}
      {sectionType === 'SectionMirror' && (
        <MirrorSection {...sectionProps} />
      )}
      {sectionType === 'SectionReviewList' && (
        <ReviewList {...sectionProps} />
      )}
      {sectionType === 'SectionEcwid' && (
        <SectionEcwid {...sectionProps} />
      )}
      {sectionType === 'SectionCustom' && (
        <Custom {...sectionProps} />
      )}
    </>
  );
}

export default withRouter(SectionEdit);
