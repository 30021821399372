import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Section from 'common/models/Section';

interface ClickedSectionState {
  clicked_section: Section | null;
}

const clickedSectionSlice = createSlice({
  name: 'clickedSection',
  initialState: {
    clicked_section: null,
  } as ClickedSectionState,
  reducers: {
    setClickedSection: (state, action: PayloadAction<Section | null>) => {
      state.clicked_section = action.payload;
    },
  },
});

export const { setClickedSection } = clickedSectionSlice.actions;

export default clickedSectionSlice;
