import React, { useMemo, useState, useEffect } from 'react';
import { isArray, isEmpty, cloneDeep } from 'lodash-es';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import { Container, Row, Col, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormText } from 'reactstrap';
import { MaterialReactTable } from 'material-react-table';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import CustomButton from 'Dashboard/src/components/common/button';
import { settingRoutes, replacePathParams, mainRoutes } from 'Dashboard/src/constants/routes';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import withRouter from 'Dashboard/src/helpers/withRouter';
import {
  useGetSavedReportsQuery,
  useCreateSavedReportMutation,
  useUpdateSavedReportMutation,
  useExportSavedReportMutation,
  useDeleteSavedReportMutation,
  useGetReportConfigQuery,
  useGetReportDataQuery,
} from 'Dashboard/src/api/dashboardApiSlice';
import './index.scss';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useUser } from 'common/hooks';
import SavedReport from 'common/models/SavedReport';
import { displayError, displaySuccess } from 'Dashboard/src/Utils';

function Cell({ bool }) {
  return <span style={{ fontSize: 14, color: bool ? 'green' : 'red' }}>{bool ? '✓' : '✗'}</span>;
}

function Reports(props) {
  const { data: savedReports } = useGetSavedReportsQuery();
  const [createSavedReport] = useCreateSavedReportMutation();
  const [updateSavedReport] = useUpdateSavedReportMutation();
  const [exportSavedReport] = useExportSavedReportMutation();
  const [deleteSavedReport] = useDeleteSavedReportMutation();
  const [savedReport, setSavedReport] = useState(null);
  const { data: reportConfig } = useGetReportConfigQuery();
  const [currentReport, setCurrentReport] = useState('Sites');
  const user = useUser();

  // Export modal state
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [spreadsheetName, setSpreadsheetName] = useState('');
  const [exportType, setExportType] = useState('one-time');
  const [exportFrequency, setExportFrequency] = useState('monthly');
  const [exportDay, setExportDay] = useState(1);
  const [createNewSheet, setCreateNewSheet] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  // Table state
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (!savedReport) return;

    const details = savedReport.details;
    setCurrentReport(details.currentReport);
    setColumnVisibility(details.columnVisibility);
    setSorting(details.sorting);

    // Set spreadsheet name from saved report
    setSpreadsheetName(savedReport.google_spreadsheet_name || `Report - ${savedReport.name}`);

    // Set export frequency if available
    if (savedReport.export_frequency) {
      setExportType('regular');
      setExportFrequency(savedReport.export_frequency);
      setCreateNewSheet(savedReport.create_new_sheet);
      setExportDay(savedReport.day || 1);
    } else {
      setExportType('one-time');
    }

    // Need to convert the value field in the filters to a Luxon DateTime object
    const filters = cloneDeep(details.columnFilters);
    filters.forEach((filter, i) => {
      if (!isArray(filter.value)) return;

      if (filter.value[0]) {
        try {
          const dt = DateTime.fromISO(filter.value[0]);
          if (dt?.isValid) {
            filter.value[0] = dt;
          }
        } catch {
          // Do nothing
        }
      }

      if (filter.value[1]) {
        try {
          const dt = DateTime.fromISO(filter.value[1]);
          if (dt?.isValid) {
            filter.value[1] = dt;
          }
        } catch {
          // Do nothing
        }
      }

      filters[i] = filter;
    });

    setColumnFilters(filters);
  }, [savedReport]);

  const currentReportConfig = reportConfig?.find((r) => r.name === currentReport);

  const initialColumnVisibility = {};
  currentReportConfig?.columns?.forEach((column) => initialColumnVisibility[column.accessorKey] = !column.hidden);

  let fullColumnVisibility;
  if (Object.keys(columnVisibility).length === 0) {
    fullColumnVisibility = initialColumnVisibility;
  } else {
    fullColumnVisibility = { ...columnVisibility };
    currentReportConfig?.columns?.forEach((column) => {
      if (typeof fullColumnVisibility[column.accessorKey] === 'undefined') {
        fullColumnVisibility[column.accessorKey] = !column.hidden;
      }
    });
  }

  const { data: reportData } = useGetReportDataQuery({
    currentReport,
    columnVisibility: fullColumnVisibility,
    columnFilters: cloneDeep(columnFilters),
    pagination,
    sorting,
  }, { skip: isEmpty(currentReport) });

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Reports', url: '' },
  ];

  // Function to handle the export
  const handleExport = async () => {
    if (!savedReport) return;

    try {
      setIsExporting(true);

      // Prepare the export payload
      const payload = {
        id: savedReport.id,
        google_spreadsheet_name: spreadsheetName,
        export_frequency: exportType === 'regular' ? exportFrequency : null,
        day: exportType === 'regular' ? exportDay : null,
        create_new_sheet: createNewSheet,
      };

      // Update the saved report with the new settings
      await updateSavedReport({
        ...savedReport,
        google_spreadsheet_name: spreadsheetName,
        export_frequency: exportType === 'regular' ? exportFrequency : null,
        day: exportType === 'regular' ? exportDay : null,
        create_new_sheet: createNewSheet,
      });

      if (exportType === 'one-time') {
        // Export the report
        const result = await exportSavedReport(payload);

        if (result.data?.meta?.is_success) {
          displaySuccess('Report exported successfully!');
          setSavedReport(result.data.data);
          setExportModalOpen(false);
        } else {
          displayError('Failed to export report: ' + (result.error?.data?.message || 'Unknown error'));
        }
      } else {
        setExportModalOpen(false);
      }
    } catch (error) {
      console.error('Export error:', error);
      displayError('Failed to export report');
    } finally {
      setIsExporting(false);
    }
  };

  const columns = useMemo(
    () => {
      if (!reportConfig || !currentReport || !reportData) {
        return [];
      }

      // The columns array is frozen when it comes out of RTK
      return currentReportConfig.columns.map((c) => {
        const column = { ...c };

        if (column.filterVariant === 'checkbox') {
          column.Cell = function CellOuter({ renderedCellValue }) {
            return <Cell bool={renderedCellValue} />;
          };
        } else if (column.link) {
          column.Cell = function CellLink({ renderedCellValue }) {
            if (!renderedCellValue) {
              return '';
            }

            return (
              <a href={renderedCellValue?.match(/^https?:\/\//) ? renderedCellValue : `https://${renderedCellValue}`} target="_blank" rel="noreferrer">
                {renderedCellValue}
              </a>
            );
          };
        }

        return column;
      });
    },
    [reportConfig, currentReport, reportData],
  );

  if (!reportConfig) {
    return <></>;
  }

  const sx = {
    fontSize: 12,
    fontFamily: 'Source Sans 3',
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Reports',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="reports">
        <div className="Reports">
          <Container fluid>
            <Row>
              <Col>
                <FormGroup style={{ maxWidth: '400px' }}>
                  <Label>Saved Reports</Label>
                  <CustomSelect
                    options={savedReports?.map((report) => ({ label: report.name, value: report.id }))}
                    className="flex-grow-1"
                    onChange={(e) => setSavedReport(savedReports.find((r) => r.id === e.value))}
                    value={{ label: savedReport?.name, value: savedReport?.id }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup style={{ maxWidth: '400px' }}>
                  <Label>Report</Label>
                  <CustomSelect
                    options={reportConfig.map((object) => ({ label: object.name, value: object.name }))}
                    className="flex-grow-1"
                    onChange={(e) => setCurrentReport(e.value)}
                    value={{ label: currentReport, value: currentReport }}
                  />
                </FormGroup>
              </Col>
              <Col className="position-relative me-3 mb-4">
                <div className="position-absolute bottom-0 end-0">
                  {savedReport?.id && (
                    <>
                      <CustomButton
                        size="md"
                        color="info"
                        title="Delete Saved Report"
                        onClick={async () => {
                          if (!confirm('Are you sure you want to delete this report?')) return;

                          await deleteSavedReport(savedReport);
                          setSavedReport(null);
                        }}
                      />
                      {' '}
                      <CustomButton
                        size="md"
                        color="info"
                        title="Export to Google Spreadsheet"
                        onClick={() => setExportModalOpen(true)}
                      />
                      {' '}
                    </>
                  )}
                  <CustomButton
                    size="md"
                    color="info"
                    title="Save Report"
                    onClick={async () => {
                      const name = prompt('Enter a name for the report', savedReport?.name);

                      if (!name) return;

                      let result;

                      const details = {
                        currentReport,
                        columnVisibility,
                        columnFilters,
                        sorting,
                      };

                      if (savedReport?.id) {
                        result = await updateSavedReport({ ...savedReport, name, details: { ...details, columnVisibility: fullColumnVisibility } });
                      } else {
                        result = await createSavedReport({ user_id: user.id, name, details: { ...details, columnVisibility: fullColumnVisibility } });
                      }

                      if (result?.data?.meta?.is_success) {
                        setSavedReport(new SavedReport(result.data.data));
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          {reportData && (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <MaterialReactTable
                columns={columns}
                data={reportData.rows}
                enableGlobalFilter={false}
                initialState={{
                  showColumnFilters: true,
                  columnVisibility: initialColumnVisibility,
                }}
                manualFiltering
                manualPagination
                manualSorting
                onColumnVisibilityChange={setColumnVisibility}
                onColumnFiltersChange={setColumnFilters}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={reportData.rowCount}
                state={{
                  columnVisibility: fullColumnVisibility,
                  columnFilters: cloneDeep(columnFilters),
                  pagination,
                  sorting,
                }}
                muiTableHeadCellProps={{ sx }}
                muiFormControlProps={{ sx }}
                muiTableBodyCellProps={{ sx }}
                muiTableHeadCellFilterTextFieldProps={{ sx }}
                muiTableContainerProps={{ sx }}
              />
            </LocalizationProvider>
          )}

          {/* Export Modal */}
          <Modal isOpen={exportModalOpen} toggle={() => setExportModalOpen(false)}>
            <ModalHeader toggle={() => setExportModalOpen(false)}>
              Export to Google Spreadsheet
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="spreadsheetName">Spreadsheet Name</Label>
                <Input
                  type="text"
                  id="spreadsheetName"
                  value={spreadsheetName}
                  onChange={(e) => setSpreadsheetName(e.target.value)}
                  disabled={savedReport?.google_spreadsheet_id}
                />
                {savedReport?.google_spreadsheet_id && (
                  <FormText>
                    This report is already linked to a spreadsheet. The name cannot be changed.
                  </FormText>
                )}
              </FormGroup>

              <FormGroup tag="fieldset">
                <Label>Export Type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="exportType"
                      value="one-time"
                      checked={exportType === 'one-time'}
                      onChange={() => setExportType('one-time')}
                    />
                    One-time export
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="exportType"
                      value="regular"
                      checked={exportType === 'regular'}
                      onChange={() => setExportType('regular')}
                    />
                    Regular export
                  </Label>
                </FormGroup>
              </FormGroup>

              {exportType === 'regular' && (
                <>
                  <FormGroup>
                    <Label for="exportFrequency">Export Frequency</Label>
                    <Input
                      type="select"
                      id="exportFrequency"
                      value={exportFrequency}
                      onChange={(e) => setExportFrequency(e.target.value)}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </Input>
                  </FormGroup>

                  {exportFrequency === 'weekly' && (
                    <FormGroup>
                      <Label for="exportDay">Day of Week</Label>
                      <Input
                        type="select"
                        id="exportDay"
                        value={exportDay}
                        onChange={(e) => setExportDay(parseInt(e.target.value, 10))}
                      >
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">Saturday</option>
                        <option value="7">Sunday</option>
                      </Input>
                    </FormGroup>
                  )}

                  {exportFrequency === 'monthly' && (
                    <FormGroup>
                      <Label for="exportDay">Day of Month</Label>
                      <Input
                        type="select"
                        id="exportDay"
                        value={exportDay}
                        onChange={(e) => setExportDay(parseInt(e.target.value, 10))}
                      >
                        {[...Array(28)].map((_, i) => (
                          <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                      </Input>
                    </FormGroup>
                  )}
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={createNewSheet}
                        onChange={() => setCreateNewSheet(!createNewSheet)}
                      />
                      Create new sheet for each export?
                    </Label>
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <CustomButton
                color="danger"
                title="Cancel"
                onClick={() => setExportModalOpen(false)}
              />
              <CustomButton
                color="success"
                title={exportType === 'regular' ? 'Save' : 'Export'}
                onClick={handleExport}
                disabled={isExporting || !spreadsheetName}
              />
            </ModalFooter>
          </Modal>
        </div>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(Reports);
