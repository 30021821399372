import React, { useEffect, useState } from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes } from 'Dashboard/src/constants/routes';
import {
  Row, Col, Card, CardBody, CardTitle, Container,
} from 'reactstrap';
import { DateTime } from 'luxon';
import { addRecentItem } from 'Dashboard/src/Utils';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import { isEmpty, map, compact, get } from 'lodash-es';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import EmailSeriesStats from './EmailSeriesStats';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import TimeFrameSelector from 'Dashboard/src/components/common/timeFrameSelector';
import withRouter from 'Dashboard/src/helpers/withRouter';
import { useGetSitesQuery } from 'Dashboard/src/api/dashboardApiSlice';
import { useUser, useInstance, useSite } from '../../../../../common/hooks';
import { useGetDailyTrafficQuery } from '../../../api/dashboardApiSlice';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const adder = (a, b) => a + b;

const formatDay = (day) => DateTime.fromJSDate(
  new Date(day.year, day.month - 1, day.day),
).toLocaleString(DateTime.DATE_MED);

function VisitsViewsChart(props) {
  const { data } = props;

  return (
    <Line
      {...props}
      data={{
        labels: data.map(formatDay),
        datasets: [
          {
            label: 'Visits',
            backgroundColor: '#45afd0',
            borderColor: '#45afd0',
            data: data.map(({ visit_count }) => visit_count),
          },
          {
            label: 'Page Views',
            backgroundColor: '#58bc6d',
            borderColor: '#58bc6d',
            data: data.map(({ hit_count }) => hit_count),
          },
        ],
      }}
    />
  );
}

function CTAChart(props) {
  const { data } = props;

  const ctaTypeNameMap = {
    CTALogFormSubmission: ['Form Submission', '#45afd0'],
    CTALogIncomingCall: ['Incoming Phone Call', '#58bc6d'],
    CTALogOutboundClick: ['Outbound Link Click', '#d06645'],
    CTALogReview: ['Review', '#aa92c0'],
    CTALogTxtMeNow: ['Txt Me Now', '#c0aa92'],
  };

  const ctaTypes = [...new Set(data.flatMap((day) => Object.keys(day.cta_counts)))];
  const labels = data.map(formatDay);
  const datasets = compact(map(ctaTypes, (ctaType) => {
    if (typeof ctaTypeNameMap[ctaType] === 'undefined') {
      return null;
    }

    return {
      label: ctaTypeNameMap[ctaType][0],
      backgroundColor: ctaTypeNameMap[ctaType][1],
      borderColor: ctaTypeNameMap[ctaType][1],
      data: map(data, (day) => day.cta_counts[ctaType] || 0),
    };
  }));

  return (
    <Bar
      {...props}
      data={{
        labels,
        datasets,
      }}
    />
  );
}

function EngagedSessionsChart(props) {
  const { data } = props;

  return (
    <Line
      {...props}
      data={{
        labels: data.map(formatDay),
        datasets: [
          {
            label: null,
            backgroundColor: '#45afd0',
            borderColor: '#45afd0',
            data: data.map((day) => (100 / day.visit_count) * day.engaged_sessions),
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
}

function EngagementDurationChart(props) {
  const { data } = props;

  return (
    <Line
      {...props}
      data={{
        labels: data.map(formatDay),
        datasets: [
          {
            label: 'Engagement Duration',
            backgroundColor: '#45afd0',
            borderColor: '#45afd0',
            data: map(data, (day) => day.engagement_duration),
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (t) => `${Math.floor(t.raw / 60)}m ${t.raw % 60}s`,
            },
          },
        },
      }}
    />
  );
}

function CountriesChart(props) {
  const { data } = props;

  const labels = [...new Set(data.flatMap((day) => map(day.countries, (country) => country[0])))];
  const chartData = map(labels, (country) => map(data, (day) => {
    const countryCount = (day.countries || []).filter((c) => c[0] === country)[0];
    return countryCount ? countryCount[1] : 0;
  }).reduce(adder));

  return (
    <Bar
      {...props}
      data={{
        labels,
        datasets: [
          {
            label: 'Country',
            backgroundColor: '#45afd0',
            borderColor: '#45afd0',
            data: chartData,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
}

function DevicesChart(props) {
  const { data } = props;

  const labels = [...new Set(data.flatMap((day) => map(day.devices, (device) => device[0])))];
  const chartData = map(labels, (device) => map(data, (day) => {
    const deviceCount = (day.devices || []).filter((c) => c[0] === device)[0];
    return deviceCount ? deviceCount[1] : 0;
  }).reduce(adder));

  return (
    <Bar
      {...props}
      data={{
        labels,
        datasets: [
          {
            label: 'Device Type',
            backgroundColor: '#45afd0',
            borderColor: '#45afd0',
            data: chartData,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
}

function ReferrersChart(props) {
  const { data } = props;

  const labels = [...new Set(data.flatMap(({ referrers }) => map(referrers, (referrer) => referrer[0])))];
  const chartData = labels.map((referrer) => (
    data.map(({ referrers }) => {
      const referrerCount = (referrers || []).filter((c) => c[0] === referrer)[0];
      return referrerCount ? referrerCount[1] : 0;
    }).reduce(adder)
  ));

  return (
    <Bar
      {...props}
      data={{
        labels,
        datasets: [
          {
            label: 'Referrer',
            backgroundColor: '#45afd0',
            borderColor: '#45afd0',
            data: chartData,
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
}

function StatsCenter(props) {
  const [selectedSite, setSelectedSite] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const user = useUser();
  const instance = useInstance();
  const site = useSite();
  const instanceId = get(instance, 'id', null);
  const siteId = get(site, 'id', null);

  const timeZone = get(user, 'time_zone', 'Pacific/Auckland');

  const { data: sites } = useGetSitesQuery(instanceId, { skip: !instanceId });

  const sendParams = {
    instance_id: instanceId,
    start_date: startDate ? DateTime.fromISO(startDate, { zone: timeZone }) : null,
    end_date: endDate ? DateTime.fromISO(endDate, { zone: timeZone }) : null,
    site_id: selectedSite?.value || siteId,
  };

  const { data: statsData = [] } = useGetDailyTrafficQuery(sendParams, { skip: !sendParams?.start_date || !sendParams?.end_date });

  if (selectedSite?.id) {
    addRecentItem({
      instance_id: instanceId,
      site_id: selectedSite.id,
      type: 'stats-center',
    });
  }

  // TODO - We have a few clients that have email series functionality but don't pay for it, need to figure out a
  // better way to decide whether or not to display the email stats table.
  const hasEmailSeries = true; // selectedSite?.billing_monthly_fee_ace > 0;

  const statsDayAction = async (obj) => {
    const { startDt, endDt } = obj;
    setStartDate(startDt);
    setEndDate(endDt);
  };

  useEffect(() => {
    if (!isEmpty(sites)) {
      setSelectedSite(sites[0]);
    }
  }, [sites]);

  const getSiteOptions = () => map(sites, (opt) => ({
    label: opt.label || '<no domain>',
    value: opt.id,
  }));

  const getSelectedOption = () => {
    if (isEmpty(selectedSite)) {
      return null;
    }
    return getSiteOptions().filter((o) => o.value === selectedSite.id)[0];
  };

  const onHandleChange = (e) => {
    const getSite = sites.filter((s) => s.id === e.value)[0];
    setSelectedSite(getSite);
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Stats Centre', url: '' },
  ];

  if (isEmpty(sites)) {
    return <></>;
  }

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Stats Centre',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: statsCenterRoutes.statsCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mt-3 mb-4 rounded">
        <CardBody>
          <Row>
            { sites.length > 1
              ? (
                  <Col xs="12" md="6" className="mb-2 mb-md-0">
                    <CustomSelect
                      value={getSelectedOption()}
                      onChange={onHandleChange}
                      options={getSiteOptions()}
                      isSearchable={false}
                      isDisabled={isEmpty(sites)}
                    />
                  </Col>
                )
              : (
                  <Col xs="12" md="6" />
                ) }
            <Col xs="12" md="6">
              {selectedSite
              && (
                <TimeFrameSelector
                  showOnlyTimeFrameSelector
                  onHandleSubmit={statsDayAction}
                  excludeTimeframe={['today', 'yesterday']}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      { statsData
        ? (
            <Container fluid>
              <Row>
                <Col sm={12}>
                  <Card className="mt-3 mb-4 rounded">
                    <CardBody>
                      <CardTitle>Visits and Page Views</CardTitle>
                      <VisitsViewsChart
                        height={60}
                        data={statsData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12}>
                  <Card className="mt-3 mb-4 rounded">
                    <CardBody>
                      <CardTitle>Leads Generated</CardTitle>
                      <CTAChart
                        height={60}
                        data={statsData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} xl={6}>
                  <Card className="mt-3 mb-4 rounded">
                    <CardBody>
                      <CardTitle>Engaged Sessions %</CardTitle>
                      <EngagedSessionsChart
                        width="50%"
                        height={20}
                        data={statsData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} xl={6}>
                  <Card className="mt-3 mb-4 rounded">
                    <CardBody>
                      <CardTitle>Session Engagement Duration (seconds)</CardTitle>
                      <EngagementDurationChart
                        width="50%"
                        height={20}
                        data={statsData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} xl={6}>
                  <Card className="mt-3 mb-4 rounded">
                    <CardBody>
                      <CardTitle>Visitor Countries</CardTitle>
                      <CountriesChart
                        width="50%"
                        height={20}
                        data={statsData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} xl={6}>
                  <Card className="mt-3 mb-4 rounded">
                    <CardBody>
                      <CardTitle>Visitor Devices</CardTitle>
                      <DevicesChart
                        width="50%"
                        height={20}
                        data={statsData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={12} xl={6}>
                  <Card className="mt-3 mb-4 rounded">
                    <CardBody>
                      <CardTitle>Traffic Sources</CardTitle>
                      <ReferrersChart
                        width="50%"
                        height={20}
                        data={statsData}
                      />
                    </CardBody>
                  </Card>
                </Col>
                {hasEmailSeries && (
                  <Col sm={12}>
                    <Card className="mt-3 mb-4 rounded">
                      <CardBody>
                        <CardTitle>Email Series Stats</CardTitle>
                        <EmailSeriesStats
                          instanceId={instanceId}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Container>
          )
        : '' }
    </>
  );
}

export default withRouter(StatsCenter);
