import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { round } from 'lodash-es';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import CustomButton from 'Dashboard/src/components/common/button';
import CustomSelect from 'Dashboard/src/components/common/custom-select';
import { settingRoutes, replacePathParams, mainRoutes } from 'Dashboard/src/constants/routes';
import UserFeaturePage from 'Dashboard/src/components/common/user-feature-page';
import withRouter from 'Dashboard/src/helpers/withRouter';

function ToolCurrentVisibilityScore(props) {
  const [urls, setUrls] = useState({});
  const [url, setUrl] = useState(null);
  const [model1Data, setModel1Data] = useState({});
  const [model2Data, setModel2Data] = useState({});

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    {
      name: 'Tools',
      url: replacePathParams(settingRoutes.tools, [], props),
    },
    { name: 'Current Visibility Score Tool', url: '' },
  ];

  const urlOptions = Object.keys(urls).map((u) => ({
    label: u,
    value: urls[u],
  }));

  const model2Points = (r) => {
    if (r > 50 || r === 0) {
      return 0;
    }

    if (r > 40) {
      return 1;
    }

    if (r > 30) {
      return 2;
    }

    if (r > 20) {
      return 3;
    }

    if (r > 10) {
      return 7;
    }

    return {
      1: 30,
      2: 27,
      3: 24,
      4: 21,
      5: 19,
      6: 17,
      7: 15,
      8: 13,
      9: 12,
      10: 11,
    }[Math.round(r)];
  };

  useEffect(() => {
    fetch('https://keyword.com/serp/api/?viewkey=getcategories&auth=22b5ad484a9584ec3494076843217d94&e=steven.webgenius@gmail.com')
      .then((response) => response.json())
      .then((data) => setUrls(data));
  }, []);

  const process = (e) => {
    e.preventDefault();

    if (!url) {
      return;
    }

    fetch(url)
      .then((response) => response.json())
      .then((kws) => {
        model1Data.startTotal = 0;
        model1Data.googleTotal = 0;
        model1Data.kwCount = 0;
        Object.keys(kws).forEach((i) => {
          const kw = kws[i];
          let startPoints = 101 - parseInt(kw.start, 10);
          let googlePoints = 101 - parseInt(kw.grank, 10);
          if (startPoints < 0 || startPoints > 100) {
            startPoints = 0;
          }
          if (googlePoints < 0 || googlePoints > 100) {
            googlePoints = 0;
          }
          model1Data.startTotal += startPoints;
          model1Data.googleTotal += googlePoints;
          model1Data.kwCount += 1;
        });

        model1Data.totalPossible = model1Data.kwCount * 100;
        model1Data.improvement = model1Data.googleTotal - model1Data.startTotal;
        model1Data.startPct = Math.round((model1Data.startTotal / model1Data.totalPossible) * 100);
        model1Data.currentPct = Math.round((model1Data.googleTotal / model1Data.totalPossible) * 100);

        setModel1Data({ ...model1Data });

        model2Data.startTotal = 0;
        model2Data.googleTotal = 0;
        model2Data.kwCount = 0;
        Object.keys(kws).forEach((i) => {
          const kw = kws[i];

          let googlePoints = model2Points(parseInt(kw.grank, 10)) * (10 / 3);
          let startPoints = model2Points(parseInt(kw.start, 10)) * (10 / 3);

          if (startPoints < 0 || startPoints > 100) {
            startPoints = 0;
          }
          if (googlePoints < 0 || googlePoints > 100) {
            googlePoints = 0;
          }
          model2Data.startTotal += startPoints;
          model2Data.googleTotal += googlePoints;
          model2Data.kwCount += 1;
        });

        model2Data.totalPossible = model2Data.kwCount * 100;
        model2Data.improvement = model2Data.googleTotal - model2Data.startTotal;
        model2Data.startPct = Math.round((model2Data.startTotal / model2Data.totalPossible) * 100);
        model2Data.currentPct = Math.round((model2Data.googleTotal / model2Data.totalPossible) * 100);

        setModel2Data({ ...model2Data });
      });
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Current Visibility Score',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="misc_tools">
            <Form>
              <FormGroup>
                <Label>
                  Site:
                </Label>
                <CustomSelect
                  onChange={(e) => setUrl(e.value)}
                  options={urlOptions}
                  value={urlOptions.find((o) => o.value === url)}
                />
              </FormGroup>

              <CustomButton
                size="xs"
                title="Run"
                color="success"
                className="custom-simple-icon"
                onClick={process}
              >
                Process
              </CustomButton>
            </Form>

            <table className="table">
              <thead>
                <tr>
                  <th>
                    &nbsp;
                  </th>
                  <th colSpan="2">
                    Model 1
                  </th>
                  <th colSpan="2">
                    Model 2
                  </th>
                </tr>
                <tr>
                  <th>
                    &nbsp;
                  </th>
                  <th>Value</th>
                  <th>Percentage</th>
                  <th>Value</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Total Possible
                  </td>
                  <td>
                    {model1Data.totalPossible}
                  </td>
                  <td>
                    100%
                  </td>
                  <td>
                    {model2Data.totalPossible}
                  </td>
                  <td>
                    100%
                  </td>
                </tr>
                <tr>
                  <td>
                    Benchmark
                  </td>
                  <td>
                    {model1Data.startTotal}
                  </td>
                  <td>
                    {model1Data.startPct}
                    %
                  </td>
                  <td>
                    {round(model2Data.startTotal, 2)}
                  </td>
                  <td>
                    {round(model2Data.startPct, 2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td>
                    Current
                  </td>
                  <td>
                    {model1Data.googleTotal}
                  </td>
                  <td>
                    {model1Data.currentPct}
                    %
                  </td>
                  <td>
                    {round(model2Data.googleTotal, 2)}
                  </td>
                  <td>
                    {round(model2Data.currentPct, 2)}
                    %
                  </td>
                </tr>
                <tr>
                  <td>
                    Improvement
                  </td>
                  <td>
                    {model1Data.improvement}
                  </td>
                  <td>
                    {model1Data.startPct === 0
                      ? model1Data.improvement * 100
                      : Math.round((model1Data.improvement / model1Data.startTotal) * 100)}
                    %
                  </td>
                  <td>
                    {round(model2Data.improvement, 2)}
                  </td>
                  <td>
                    {round(model2Data.startPct === 0
                      ? model2Data.improvement * 100
                      : Math.round((model2Data.improvement / model2Data.startTotal) * 100), 2)}
                    %
                  </td>
                </tr>
              </tbody>
            </table>

          </UserFeaturePage>
        </CardBody>
      </Card>
    </>
  );
}
export default withRouter(ToolCurrentVisibilityScore);
