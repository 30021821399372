import React, { /* StrictMode */ } from 'react';
import { createRoot } from 'react-dom/client';
import { setIframeHeight } from './Utils';
import { Provider } from 'react-redux';
import { createStore } from './rootStore';
import AdminSite from './Dashboard/src/App';

// TODO: Disabling strict mode for now as it causes issues with the AnyCable sockets. We are receiving messages twice
// and I can't figure out a way round it.

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Root element not found');
}
declare global {
  interface Window {
    setIframeHeight: typeof setIframeHeight;
  }
}
window.setIframeHeight = setIframeHeight;

const App: React.FC = () => {
  return (
    // <StrictMode>
    <Provider store={createStore()}>
      <AdminSite />
    </Provider>
    // </StrictMode>
  );
};

const root = createRoot(rootElement);
root.render(<App />);
