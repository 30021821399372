import { PageData } from '/ts/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const pageDataElement: HTMLElement | null = document.getElementById('pageData');

const pageData: PageData = pageDataElement ? JSON.parse(pageDataElement.textContent!) : {};

export const pageDataSlice = createSlice({
  name: 'page_data',
  initialState: pageData,
  reducers: {
    setPageData: (state: PageData, action: PayloadAction<Partial<PageData>>) => state = { ...state, ...action.payload },
  },
});

export const { setPageData } = pageDataSlice.actions;

const getDeviceFromWidth = (width: number) => {
  if (width >= 1200) {
    return 'desktop';
  } else if (width > 576) {
    return 'tablet';
  } else {
    return 'phone';
  }
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    device: getDeviceFromWidth(window.innerWidth),
  },
  reducers: {
    setDevice: (state) => {
      state.device = getDeviceFromWidth(window.innerWidth);
    },
  },
});

export const { setDevice } = deviceSlice.actions;

const initialStateSpinner = {
  spinCount: 0,
};

export const spinSlice = createSlice({
  name: 'spin',
  initialState: initialStateSpinner,
  reducers: {
    spinCountInc: (state) => {
      state.spinCount += 1;
    },
    spinCountDec: (state) => {
      state.spinCount -= 1;
    },
  },
});

export const { spinCountInc, spinCountDec } = spinSlice.actions;
