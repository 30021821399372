import React from 'react';
import Select, { components } from 'react-select';
import { selectColorStyles } from '../../../../../Utils';
import './index.scss';

/**
 * Simple wrapper around react-select so that we can apply the correct CSS classes.
 */

export const allOption = { label: 'All', value: '*' };

function Option(props) {
  const { isSelected, label, data: { column_section_id } } = props;
  return (
    <div className={column_section_id ? 'ms-2' : ''}>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
        />
        {' '}
        <label htmlFor={label}>{label}</label>
      </components.Option>
    </div>
  );
}

function ValueContainer({ children, ...props }) {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
}

function MultiValue(props) {
  const { data: { label } } = props;
  return (
    <components.MultiValue {...props}>
      <span>{label}</span>
    </components.MultiValue>
  );
}

function CustomSelect(props) {
  const myProps = { ...props };

  const options = [myProps.allOption, ...(myProps.options || [])];

  // Allow passing just the value, rather than { label: '...', value: '...' }
  const value = props.value;
  if (typeof value !== 'undefined' && typeof value !== 'object') {
    myProps.value = {
      label: options.find((option) => option?.value === value)?.label,
      value,
    };
  }

  const className = `
    react-select custom-react-select ${typeof myProps.className !== 'undefined' ? myProps.className : ''}
  `;
  delete myProps.className;

  if (myProps.allowSelectAll) {
    return (
      <Select
        {...myProps}
        className={className}
        classNamePrefix="react-select"
        menuPortalTarget={document.body} // Necessary to make the z-index of the dropdown work
        styles={selectColorStyles}
        options={options}
        onChange={(selected, event) => {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === myProps.allOption.value) {
              return myProps.onChange([myProps.allOption, ...myProps.options]);
            }
            let result = [];
            if (selected.length === myProps.options.length) {
              if (selected.includes(myProps.allOption)) {
                result = selected.filter(
                  (option) => option.value !== myProps.allOption.value,
                );
              } else if (event.action === 'select-option') {
                result = [myProps.allOption, ...myProps.options];
              }
              return myProps.onChange(result);
            }
          }

          return myProps.onChange(selected);
        }}
        components={{
          Option,
          MultiValue,
          ValueContainer,
        }}
      />
    );
  }

  return (
    <Select
      className={className}
      classNamePrefix="react-select"
      styles={selectColorStyles}
      {...myProps}
    />
  );
}

export default CustomSelect;
