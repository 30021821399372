import React from 'react';
import { toast } from 'react-toastify';
import WebFont from 'webfontloader';
import { createStore } from 'rootStore';
import User from 'common/models/User';
import UserRecentItem from 'common/models/UserRecentItem';
import { dashboardApi } from 'Dashboard/src/api/dashboardApiSlice';
import webSafeFontOptions from 'common/schema/webSafeFontOptions';
import { GoogleFont } from 'ts/interfaces';

export const addRecentItem = (item: UserRecentItem) => {
  const store = createStore();
  const w = window as any;

  w.recentItems ||= {
    lastIdentity: null,
    timeout: null,
    items: [],
    push: (item: UserRecentItem) => {
      const ts = new Date().getTime();
      // The type should always be set, but the item_id might be falsey, ie null or undefined, that's why we only do a
      // double-equals check below rather than a threequals.
      // eslint-disable-next-line eqeqeq
      const index = w.recentItems.items.findIndex((i: UserRecentItem) => item.type === i.type && item.item_id == i.item_id);

      if (index > -1) {
        w.recentItems.items[index].ts = ts;
        w.recentItems.items[index].path = window.location.pathname;
      } else {
        w.recentItems.items.push({ ...item, ts, path: window.location.pathname });
      }

      // sort by timestamp and remove all but the most recent 30 items
      w.recentItems.items = w.recentItems.items.sort((a: UserRecentItem, b: UserRecentItem) => (b.ts || 0) > (a.ts || 0) ? 1 : -1).slice(0, 30);

      // only send the list if it has changed. we figure that out by creating an "identity" which is sort of a hash
      // of the list's contents.
      const identity = w.recentItems.items.map((i: UserRecentItem) => `${i.type}-${i.item_id}`).join(',');
      if (identity !== w.recentItems.lastIdentity) {
        w.recentItems.lastIdentity = identity;

        // the list has changed, queue up the send
        clearTimeout(w.recentItems.timeout);
        w.recentItems.timeout = setTimeout(() => w.recentItems.send(), 1000);
      }
    },
    send: async () => {
      const userWrapper = await store.dispatch(dashboardApi.endpoints.getUserProfile.initiate({}));
      const user = (userWrapper.data as { data: User }).data;
      if (!user?.id) {
        return;
      }
      store.dispatch(dashboardApi.endpoints.updateRecentItems.initiate({
        userId: user.id,
        items: w.recentItems.items,
      }));
    },
  };

  w.recentItems.push(item);
};

export const addGoogleFont = (googleFonts: GoogleFont[]): void => {
  googleFonts.map((item) => {
    // Don't add if it's a web-safe font.
    if (webSafeFontOptions.some((f) => f.name === item.fontName)) {
      return null;
    }

    const searchString = `${item.fontName}:${item.fontWeight}`;
    const stylesheets = [...document.styleSheets];
    const condition = stylesheets.filter(
      (sheet) => sheet.href !== null && sheet.href.includes(searchString),
    );
    if (condition.length === 0) {
      WebFont.load({ google: { families: [`${item.fontName}:${item.fontWeight}`] } });
    }
    return null;
  });
};

export const displayError = (message: string, timeOut = 3000): void => {
  toast.error(message, { autoClose: timeOut });
};

export const displaySuccess = (message: string, timeOut = 3000): void => {
  toast.success(message, { autoClose: timeOut });
};

export const displayInfo = (message: string, timeOut = 3000): void => {
  toast.info(message, { autoClose: timeOut });
};

export const isInFullScreen = () => {
  return document.fullscreenElement && document.fullscreenElement !== null;
};

export const toggleFullScreen = () => {
  const docElm = document.documentElement;
  if (!isInFullScreen()) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};

export const GreenTick = () => {
  return (
    <span style={{ fontSize: 14, color: 'green' }}>✓</span>
  );
};

export const RedCross = () => {
  return (
    <span style={{ fontSize: 14, color: 'red' }}>✗</span>
  );
};
