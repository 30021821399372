import React from 'react';
import { get } from 'lodash-es';
import { Label, FormGroup } from 'reactstrap';
import RichTextEditor3 from '../../RichTextEditor3';

function BlogDescription({ params, setParams }) {
  return (
    <FormGroup>
      <Label for="blog_description">Blog Description</Label>
      <RichTextEditor3
        index={0}
        features={['no_card']}
        countTextBlocks={1}
        isEmailPage={false}
        textBlockObj={{
          type: 'ms3',
          block_text: get(params, 'blog_description', ''),
          showSource: get(params, 'showSource', false),
        }}
        onUpdateTextBlock={({ block_text, showSource }) => {
          setParams({ ...params, blog_description: block_text, showSource });
        }}
      />
    </FormGroup>
  );
}

export default BlogDescription;
