import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  InputGroup,
  DropdownItem,
  DropdownMenu,
  ButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { cloneDeep, get, isEmpty, map } from 'lodash-es';
import {
  galleryStyleOption,
  widthTypeOption,
  submitType,
} from '../../../../../../common/schema/imageGallery';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import { getMaxValueByKey, getSelectedOption, selectColorStyles } from '../../../../../../Utils';
import { displayError } from 'Dashboard/src/Utils';
import confirm from '../../../../components/common/confirm';
import RadioButton from '../../../../components/common/radioButton';
import CheckBox from '../../../../components/common/checkBox';
import withRouter from '../../../../helpers/withRouter';
import { commonStyleNumericField } from './GalleryStyleOptions';
import Upload from '../../../media-uploads/Upload';
import './index.scss';
import LinkToolBox from '../LinkToolBox';
import DragDropComponent from '../../../../components/common/reactSortable';
import TextStyleListing from '../../../../components/common/textStyles';
import { MediaSelectBox } from '../../../../components/fields/site';
import {
  useGetMediaFilesQuery, useGetSectionQuery, useSectionUpdateMutation,
} from '../../../../api/dashboardApiSlice';
import { useUser } from '../../../../../../common/hooks';

const fadeStyleOption = {
  time_milliseconds: '4000',
  display_captions: 'yes',
  speed_of_transition: '1000',
  width_click_to_zoom: '800',
  display_dropshadow: 'yes',
  allow_click_to_zoom: 'yes',
};

const scrollStyleOption = {
  scroll_interval: '13',
  width_click_to_zoom: '800',
  scroll_automatically: 'yes',
  add_shadow_under_image: 'yes',
  allow_click_to_zoom: 'yes',
};

const updateLinkId = (id, galleryItemId, data) => map(data, (item) => {
  if (item.id === galleryItemId) {
    return { ...item, link_id: id };
  }
  return item;
});

function ImageGallery(props) {
  const dispatch = useDispatch();
  const {
    section: { id: sectionId },
    saveCounter,
    params: { instanceId, siteId },
    updateStylesheet,
  } = props;

  const [imageItem, setImageItem] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [modalParams, setModalParams] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setModal] = useState(false);
  const [linkId, setLinkId] = useState(null);
  const [galleryItemId, setGalleryItemId] = useState(null);
  const user = useUser();
  const [fileData, setFileData] = useState(null);

  const { refetch } = useGetMediaFilesQuery(instanceId);

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const [sectionUpdate] = useSectionUpdateMutation();

  const [params, setParams] = useState(null);

  useEffect(() => {
    if (section) {
      setParams(cloneDeep(section));
    }
  }, [section]);

  const onSave = async (payload) => {
    const tempPayload = { ...payload, instance_id: instanceId, id: params?.id };
    tempPayload.section_image_gallery_items_attributes = payload.section_image_gallery_items;
    delete tempPayload.section_image_gallery_items;
    await sectionUpdate(tempPayload);

    updateStylesheet();
  };

  useEffect(() => {
    if (saveCounter) {
      (async () => {
        await onSave(params);
      })();
    }
  }, [saveCounter]);

  const imageGalleryItems = get(params, 'section_image_gallery_items', []);

  useEffect(() => {
    if (!isEmpty(params)) {
      const { options, style } = params;
      if (isEmpty(options)) {
        switch (style) {
          case 'scroll':
            params.options = scrollStyleOption;
            break;
          default:
            params.options = fadeStyleOption;
        }
      }
      if (!galleryStyleOption.some((x) => x.value === params.style)) {
        params.style = 'fade';
      }
      setParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (!isEmpty(fileData)) {
      const isExistInList = imageGalleryItems.find((x) => x.filename === fileData.filename);
      let newOrder = getMaxValueByKey(imageGalleryItems, 'order');
      if (isExistInList) {
        newOrder = isExistInList.order;
      }
      const data = {
        file_id: fileData.id,
        width: fileData.width,
        height: fileData.height,
        filename: fileData.filename,
        alt: '',
        caption: '',
        order: newOrder,
        section_image_gallery_id: params.id,
      };
      params.section_image_gallery_items.push(data);
      setParams(params);

      refetch();

      const sendParams = {
        section_image_gallery_items: [{ ...data }],
      };
      onSave(sendParams);
      setFileData(null);
    }
  }, [dispatch, fileData]);

  const onHandleChangeWidthType = (e) => {
    setParams({
      ...params,
      image_width_type: e.target.getAttribute('dropDownValue'),
    });
  };

  const onHandleChange = (e) => {
    const { value, name } = e.target;
    setParams({ ...params, [name]: value });
  };

  const onHandleChangeGalleryOptions = (e) => {
    const { value, name } = e.target;
    setParams({
      ...params,
      options: {
        ...params.options,
        [name]: value,
      },
    });
  };

  const onHandleSubmit = async (type) => {
    let sendParams;
    switch (type) {
      case submitType.SAVE:
        sendParams = {
          ...params,
          initial_visibility: params.initial_visibility,
          break_point: params.break_point,
        };
        break;
      case submitType.ADD:
        if (!isEmpty(imageItem)) {
          sendParams = {
            section_image_gallery_items: [...imageGalleryItems, imageItem],
          };
        } else {
          displayError('select an image from Image Centre option');
        }
        break;
      case submitType.UPDATE:
        sendParams = {
          section_image_gallery_items: [
            ...imageGalleryItems,
          ],
          initial_visibility: params.initial_visibility,
        };
        break;
      default: break;
    }

    const res = await onSave(sendParams);

    if (res) {
      setModal(false);
      setImageItem({});
    }
  };

  const onHandleImageChange = (e) => {
    const { value, dimensions, label } = e;
    const imageDimensions = dimensions.split('x');
    let imageDataFromList = {};

    if (imageDimensions) {
      imageDataFromList = {
        width: imageDimensions[0],
        height: imageDimensions[1],
        filename: label,
      };
    }

    const data = {
      ...imageDataFromList,
      file_id: value,
      alt: '',
      caption: '',
      order: getMaxValueByKey(imageGalleryItems, 'order'),
      section_image_gallery_id: params.id,
    };
    setImageItem(data);
  };

  const onHandleStyleChange = (e) => {
    const { value } = e;
    switch (value) {
      case 'scroll':
        setParams({
          ...params,
          options: scrollStyleOption,
          style: value,
          image_width_type: 'pixel',
        });
        break;
      default: {
        setParams({
          ...params,
          options: fadeStyleOption,
          style: value,
          image_width_type: 'pixel',
        });
      }
    }
  };

  const onHandleSelectChange = (e, keyName) => {
    const { value } = e;
    setParams({ ...params, [keyName]: value });
  };

  const getGalleryOptions = (styleType) => {
    switch (styleType) {
      case 'fade':
        return (
          <Row>
            <Col lg={4}>
              {commonStyleNumericField(
                'Timing (milliseconds between transitions)',
                'time_milliseconds',
                params?.options.time_milliseconds,
                false,
                (e) => onHandleChangeGalleryOptions(e),
              )}
            </Col>
            <Col lg={4}>
              {commonStyleNumericField(
                'Speed of transitions (in milliseconds)',
                'speed_of_transition',
                params?.options.speed_of_transition,
                false,
                (e) => onHandleChangeGalleryOptions(e),
              )}
            </Col>
            <Col lg={4}>
              {commonStyleNumericField(
                'Width (in pixels) of click-to-zoom image:',
                'width_click_to_zoom',
                params?.options.width_click_to_zoom,
                true,
                (e) => onHandleChangeGalleryOptions(e),
              )}
            </Col>
            <Col lg={4}>
              <Label className="w-100">Display dropshadow?</Label>
              <RadioButton
                name="display_dropshadow"
                title="Yes"
                className="me-2"
                checked={params?.options.display_dropshadow === 'yes'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'display_dropshadow', value: 'yes' } },
                )}
              />
              <RadioButton
                name="display_dropshadow"
                title="No"
                checked={params?.options.display_dropshadow === 'no'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'display_dropshadow', value: 'no' } },
                )}
              />
            </Col>
            <Col lg={4}>
              <Label className="w-100">Display captions?</Label>
              <RadioButton
                name="display_captions"
                title="Yes"
                className="me-2"
                checked={params?.options.display_captions === 'yes'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'display_captions', value: 'yes' } },
                )}
              />
              <RadioButton
                name="display_captions"
                title="No"
                checked={params?.options.display_captions === 'no'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'display_captions', value: 'no' } },
                )}
              />
            </Col>
            <Col lg={4}>
              <Label className="w-100">Arrow Position</Label>
              <RadioButton
                name="arrow_position"
                title="Outside Image"
                className="me-2"
                checked={params?.options.arrow_position === 'outside_image'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'arrow_position', value: 'outside_image' } },
                )}
              />
              <RadioButton
                name="arrow_position"
                title="Inside Image"
                checked={params?.options.arrow_position === 'inside_image'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'arrow_position', value: 'inside_image' } },
                )}
              />
            </Col>
            <Col lg={4}>
              <Label className="w-100">
                Allow click-to-zoom if there is a larger image available? (note: setting a link overrides this)
              </Label>
              <RadioButton
                name="allow_click_to_zoom"
                title="Yes"
                className="me-2"
                checked={params?.options.allow_click_to_zoom === 'yes'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'allow_click_to_zoom', value: 'yes' } },
                )}
              />
              <RadioButton
                name="allow_click_to_zoom"
                title="No"
                checked={params?.options.allow_click_to_zoom === 'no'}
                onChange={() => onHandleChangeGalleryOptions(
                  { target: { name: 'allow_click_to_zoom', value: 'no' } },
                )}
              />
            </Col>
            <Col lg={4}>
              <Label className="w-100">Include Left/Right Arrows</Label>
              <CheckBox
                checked={params?.options.include_arrows}
                onChange={() => onHandleChangeGalleryOptions({
                  target: {
                    name: 'include_arrows', value: !params?.options.include_arrows,
                  },
                })}
                title="Yes"
              />
            </Col>
            <Col lg={4}>
              <Label className="w-100">Include Indicators</Label>
              <CheckBox
                checked={params?.options.include_indicators}
                onChange={() => onHandleChangeGalleryOptions({
                  target: { name: 'include_indicators', value: !params?.options.include_indicators },
                })}
                title="Yes"
              />
            </Col>
          </Row>
        );
      case 'scroll':
        return (
          <Row>
            <Col lg={3} md={6} xs={12} className="mt-3">
              <Row>
                <Col lg={12}>
                  {commonStyleNumericField(
                    'Scroll interval (in milliseconds, lower is faster):',
                    'scroll_interval',
                    params?.options.scroll_interval,
                    false,
                    (e) => onHandleChangeGalleryOptions(e),
                  )}
                </Col>
                <Col lg={12}>
                  {commonStyleNumericField(
                    'Width (in pixels) of click-to-zoom image:',
                    'width_click_to_zoom',
                    params?.options.width_click_to_zoom,
                    true,
                    (e) => onHandleChangeGalleryOptions(e),
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={9} md={6} xs={12} className="mt-3">
              <Row>
                <Col lg={4} md={12}>
                  <Label className="w-100">Scroll automatically?</Label>
                  <RadioButton
                    name="scroll_automatically"
                    title="Yes"
                    className="me-2"
                    checked={params?.options.scroll_automatically === 'yes'}
                    onChange={() => onHandleChangeGalleryOptions(
                      { target: { name: 'scroll_automatically', value: 'yes' } },
                    )}
                  />
                  <RadioButton
                    name="scroll_automatically"
                    title="No"
                    checked={params?.options.scroll_automatically === 'no'}
                    onChange={() => onHandleChangeGalleryOptions(
                      { target: { name: 'scroll_automatically', value: 'no' } },
                    )}
                  />
                </Col>
                <Col lg={4} md={12}>
                  <Label className="w-100">Add shadow under images?</Label>
                  <RadioButton
                    name="add_shadow_under_image"
                    title="Yes"
                    className="me-2"
                    checked={params?.options.add_shadow_under_image === 'yes'}
                    onChange={() => onHandleChangeGalleryOptions(
                      { target: { name: 'add_shadow_under_image', value: 'yes' } },
                    )}
                  />
                  <RadioButton
                    name="add_shadow_under_image"
                    title="No"
                    checked={params?.options.add_shadow_under_image === 'no'}
                    onChange={() => onHandleChangeGalleryOptions(
                      { target: { name: 'add_shadow_under_image', value: 'no' } },
                    )}
                  />
                </Col>
                <Col lg={12}>
                  <Label className="w-100">
                    Allow click-to-zoom if there is a larger image available? (note: setting a link overrides this)
                  </Label>
                  <RadioButton
                    name="allow_click_to_zoom"
                    title="Yes"
                    className="me-2"
                    checked={params?.options.allow_click_to_zoom === 'yes'}
                    onChange={() => onHandleChangeGalleryOptions(
                      { target: { name: 'allow_click_to_zoom', value: 'yes' } },
                    )}
                  />
                  <RadioButton
                    name="allow_click_to_zoom"
                    title="No"
                    checked={params?.options.allow_click_to_zoom === 'no'}
                    onChange={() => onHandleChangeGalleryOptions(
                      { target: { name: 'allow_click_to_zoom', value: 'no' } },
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        );
      default:
        return '';
    }
  };

  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const toggleEditModal = () => setEditOpen(!editOpen);

  const openEditModal = (e) => {
    setModalParams({ ...params.section_image_gallery_items[e], index: e });
    setEditOpen(true);
  };

  const onHandleEnter = (event) => {
    if (event.keyCode === 13) {
      setEditOpen(false);
    }
  };

  const onHandleModalChange = (e) => {
    const { name, value } = e.target;
    const params = { ...modalParams };
    params[name] = value;
    setModalParams(params);
  };

  const onHandleDeleteImage = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const i = imageGalleryItems.findIndex((c) => c.id === id);
      imageGalleryItems[i].is_deleted = true;
      imageGalleryItems[i].deleted_by_id = user.id;
      imageGalleryItems[i].deleted_at = new Date().toUTCString();

      let counter = 0;
      onSave({
        ...params,
        section_image_gallery_items: imageGalleryItems.map((s) => ({ ...s, order: s.is_deleted ? null : ++counter })),
      });
    }
  };

  const updateImageData = () => {
    const { index, ...rest } = modalParams;
    imageGalleryItems[index] = { ...rest };
    params.section_image_gallery_items = imageGalleryItems;
    setParams(params);
    onHandleSubmit(submitType.UPDATE);
    setEditOpen(false);
  };

  const onHandleDrop = (newState) => {
    const temp = [...imageGalleryItems];
    newState.forEach((element, i) => {
      const getIndex = temp.findIndex((c) => c.id === element.id);
      if (getIndex > -1) {
        temp[getIndex].order = i + 1;
      }
    });
    onSave({ ...params, section_image_gallery_items: [...temp].sort((a, b) => (a.order > b.order ? 1 : -1)) });
  };

  const onHandleLinkClick = async (d) => {
    setLinkId(d?.link_id);
    setGalleryItemId(d?.id);
    setModal(!showModal);
  };

  const renderGalleryItems = () => {
    const returnRows = map(imageGalleryItems.filter((item) => !item.is_deleted), (item, index) => (
      <tr>
        <td>
          <Button className="custom-simple-txt-btn me-2 handle-sortable">
            <i className="fa-solid fa-arrows" />
          </Button>
        </td>
        <td>{item.filename}</td>
        <td>{item.caption}</td>
        <td>{item.alt}</td>
        <td>{item.width}</td>
        <td>{item.height}</td>
        <td>
          <Button
            color="link"
            size="xs"
            onClick={() => onHandleLinkClick(item)}
          >
            <i className="fal fa-link" />
          </Button>
        </td>
        <td>
          <Button
            className="custom-simple-icon"
            color="info"
            size="xs"
            onClick={() => openEditModal(index)}
          >
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={() => onHandleDeleteImage(item?.id)}
          >
            <i className="fal fa-trash" />
          </Button>
        </td>
      </tr>
    ));
    return (
      <DragDropComponent
        tag="tbody"
        list={map(imageGalleryItems, (i) => ({ ...i, chosen: true }))}
        setNewState={(newState) => onHandleDrop(newState)}
        keyName="section_image_gallery_item_ids"
        params={{
          instance_id: instanceId,
          id: params.id,
        }}
        handle=".handle-sortable"
        returnData={returnRows}
      />
    );
  };

  const onLinkBoxSaveButton = (response) => {
    const tempData = { ...params };
    if (!isEmpty(response)) {
      tempData.section_image_gallery_items = updateLinkId(
        response.id,
        galleryItemId,
        params?.section_image_gallery_items,
      );
      setParams(tempData);
      setModal(false);
      setGalleryItemId(null);
    }
  };

  const onHandleToggle = () => {
    setGalleryItemId(null);
    setLinkId(null);
    setModal(!showModal);
  };

  const handleUnlink = () => {
    params.section_image_gallery_items = updateLinkId(
      null,
      galleryItemId,
      params?.section_image_gallery_items,
    );
    setParams(params);
    setGalleryItemId(null);
    setLinkId(null);
    setModal(!showModal);
  };

  if (isEmpty(params)) {
    return <></>;
  }

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={false}
        section={params}
        setSection={setParams}
        handleSaveBreakpoint={(e) => setParams({ ...params, break_point: e })}
        onHandleSectionIsOffline={() => setParams({ ...params, is_offline: !params.is_offline })}
        onChangeInitialVisibility={() => setParams({ ...params, initial_visibility: !params.initial_visibility })}
        onHandleChangeVisibility={(e) => setParams({ ...params, visible_to: e.value })}
      />
      <div className="ImageGallery">
        <h2>Gallery Style:</h2>
        <Row>
          <Col>
            <Select
              value={getSelectedOption(galleryStyleOption, params?.style)}
              onChange={(e) => onHandleStyleChange(e)}
              options={galleryStyleOption}
              styles={selectColorStyles}
            />
          </Col>
        </Row>
        <hr />
        <h2 className="mt-3">Image Gallery options:</h2>
        {getGalleryOptions(params?.style)}
        <hr />
        <h2 className="mt-3">Add a new image:</h2>
        <Row className="mb-5 mt-3">
          <Col>
            <Row>
              <Col lg={12}>
                <Label for="image_width">
                  Enter the width of the main image.
                  To display images in their original size, enter 0.
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="image_width"
                    value={params?.image_width}
                    onChange={(e) => onHandleChange(e)}
                  />
                  <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
                    <DropdownToggle
                      caret
                      className="dropdown-toggle custom-dropdown-button"
                      disabled={params?.style === 'scroll'}
                    >
                      {params?.image_width_type === 'pixel' ? 'pixel' : '%'}
                    </DropdownToggle>
                    <DropdownMenu className="currency-dropdown ">
                      {map(widthTypeOption, (ele) => (
                        <DropdownItem
                          key={ele.value}
                          dropdownvalue={ele.value}
                          onClick={(e) => onHandleChangeWidthType(e)}
                        >
                          {ele.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </InputGroup>
              </Col>
              <Col lg={12}>
                {commonStyleNumericField(
                  `For the best looking image gallery,
                  all images should be the same size.
                  If you need to specify the height of the gallery in pixels, enter it here:`,
                  'image_height',
                  params?.image_height,
                  false,
                  (e) => onHandleChange(e),
                )}
              </Col>
              <Col lg={12}>
                <div>
                  <Label>Select the text style for captions:</Label>
                  <TextStyleListing
                    style={params?.text_style_id}
                    onChange={(e) => onHandleSelectChange(e, 'text_style_id')}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          {!isEmpty(imageGalleryItems) && (
            <Table className="mt-5">
              <thead>
                <tr>
                  <th />
                  <th>Image</th>
                  <th>Caption</th>
                  <th>Alt</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>Link</th>
                  <th>Action?</th>
                </tr>
              </thead>
              {renderGalleryItems()}
            </Table>
          )}
          <Col lg={12} className="mt-3">
            <Label>Upload a new image:</Label>
            <Upload type="image-gallery" galleryType setUploadedFileData={setFileData} />
          </Col>
          <Col lg={4} md={10} xs={12}>
            <Label>or, select an image from Image Centre:</Label>
            <MediaSelectBox
              value={imageItem?.file_id}
              onchange={(e) => onHandleImageChange(e)}
            />
          </Col>
          <Col lg={2} md={10} xs={12} className="mt-4">
            <Button color="primary" onClick={() => onHandleSubmit(submitType.ADD)}>
              <b>
                <i className="fal fa-plus-circle" />
                {' '}
                Add
              </b>
            </Button>
          </Col>
        </Row>
        {showModal && (
          <LinkToolBox
            linkId={linkId}
            showUnlinkButton
            isEmailPage={false}
            instanceId={instanceId}
            toggle={onHandleToggle}
            onUnlink={handleUnlink}
            galleryItemId={galleryItemId}
            onLinkBoxSaveButton={onLinkBoxSaveButton}
          />
        )}
      </div>
      {editOpen && (
        <Modal isOpen={editOpen} toggle={toggleEditModal} autoFocus={false} container={window.modalRef}>
          <ModalHeader toggle={toggleEditModal}>
            <b>Edit</b>
          </ModalHeader>
          <ModalBody>
            <b>Caption:</b>
            <Input
              autoFocus
              name="caption"
              type="text"
              value={modalParams?.caption || ''}
              onChange={onHandleModalChange}
              onKeyDown={onHandleEnter}
            />
            <b>Alt:</b>
            <Input
              name="alt"
              type="text"
              value={modalParams?.alt || ''}
              onChange={onHandleModalChange}
              onKeyDown={onHandleEnter}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleEditModal}><b>Cancel</b></Button>
            <Button color="success" onClick={updateImageData}><b>Update</b></Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default withRouter(ImageGallery);
