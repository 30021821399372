import CustomSelect from '../../../components/common/custom-select';
import React from 'react';
import { filter, isEmpty, map } from 'lodash-es';
import { useParams } from 'react-router';
import { useGetMediaFilesQuery } from '../../../api/dashboardApiSlice';
import { useSelector } from 'react-redux';

const getSelectedOptionById = (options, selected_option) => {
  if (!isEmpty(options) && !isEmpty(selected_option)) {
    const data = filter(options.media, (item) => (item.id === selected_option));
    return !isEmpty(data) ? ({ label: data[0].name, value: data[0].id }) : null;
  }
  return null;
};

const getImageOptions = (data) => {
  if (data && !isEmpty(data.media)) {
    const { media } = data;
    return map(media, (ele) => ({
      ...ele,
      label: ele.name,
      value: ele.id,
      url: ele.media_url,
    }));
  }
  return [];
};

function MediaSelectBox(props) {
  const { instanceId } = useParams();
  const { instance } = useSelector((s) => s.pageData);
  const { value, onchange } = props;
  const { data } = useGetMediaFilesQuery(instance?.id || instanceId);

  return (
    <CustomSelect
      {...props}
      value={getSelectedOptionById(data, value)}
      onChange={(e) => onchange(e)}
      options={getImageOptions(data)}
    />
  );
}

export default MediaSelectBox;
