import Base from './Base';

interface ButtonStyleProps {
  id?:                 string;
  site_id?:            string | number;
  name?:               string;
  css?:                CSS;
  hover_css?:          CSS;
  full_width_phone?:   boolean;
  full_width_tablet?:  boolean;
  full_width_desktop?: boolean;
}

interface CSS {
  [key: string]: any;
}

class ButtonStyle extends Base {
  id?:                 string;
  site_id?:            string | number;
  name?:               string;
  css?:                CSS;
  hover_css?:          CSS;
  full_width_phone?:   boolean;
  full_width_tablet?:  boolean;
  full_width_desktop?: boolean;

  constructor(props: ButtonStyleProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default ButtonStyle;
