import React from 'react';
import { useInstance, useSite } from 'common/hooks';
import { Label, FormGroup } from 'reactstrap';
import { MasterPageSelectBox } from 'Dashboard/src/components/fields/site';

function BlogMasterPage({ params, setParams }) {
  const instance = useInstance();
  const site = useSite();

  return (
    <FormGroup>
      <Label for="default_blog_post_master_page_id">Default Master Page for Blog Posts</Label>
      <MasterPageSelectBox
        instanceId={instance?.id}
        siteId={site?.id}
        params={params}
        setParams={setParams}
        keyName="default_blog_post_master_page_id"
      />
    </FormGroup>
  );
}

export default BlogMasterPage;
