import Base from './Base';

interface BriefingInfo {
  [key: string]: any;
};

class Briefing extends Base {
  id?:                      string;
  site_id?:                 string | number;
  site_created_at?:         null;
  info?:                    BriefingInfo;
  project_manager_name?:    string;
  project_manager_email?:   string;
  adviser_name?:            string;
  pm_only?:                 boolean;
  adviser_completed?:       boolean;
  bdq_request_sent?:        boolean;
  bdq_completed?:           boolean;
  content_created?:         boolean;
  requested_book?:          boolean;
  ai_content?:              { [key: string]: any };
  meeting_feedback?:        { [key: string]: any };
  google_drive_folder_id?:  string;

  constructor(props) {
    super(props);
    Object.assign(this, props);
  }
};

export default Briefing;
