import React, { useEffect, useState, useRef } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, mainRoutes, replacePathParams } from '../../../constants/routes';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import UserFeatureComponent from 'Dashboard/src/components/common/user-feature-component';
import Pagination from '../../../components/common/custom-pagination';
import { useParams } from 'react-router-dom';
import { get, uniqueId } from 'lodash-es';
import { capitalizeFirstLetter } from '../../../../../Utils';
import CheckBox from '../../../components/common/checkBox';
import withRouter from '../../../helpers/withRouter';
import { useGetBackendErrorLogCategoriesQuery, useGetBackendErrorLogsQuery } from '../../../api/dashboardApiSlice';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function Logs(props) {
  const { navigate } = props;
  const { pageNo } = useParams();
  const [orderBy, setOrderBy] = useState('desc');
  const [pageSize, setPageSize] = useState(10);
  const fetchIdRef = useRef(0);
  const [catToggle, setCatToggle] = useState(false);
  const [selectedCat, setCategory] = useState([]);

  const commonPathParams = (path) => replacePathParams(path, [], props);

  fetchIdRef.current += 1;
  const fetchId = fetchIdRef.current;

  const sendParams = new URLSearchParams({
    page: String(pageNo),
    page_size: String(pageSize),
    order_direction: orderBy,
  });

  selectedCat.forEach((cat) => {
    sendParams.append('category[]', cat);
  });

  const { data } = useGetBackendErrorLogsQuery(sendParams.toString(), { skip: fetchId !== fetchIdRef.current });
  const { data: categories = [] } = useGetBackendErrorLogCategoriesQuery();
  const errorLogs = get(data, 'backend_error_logs_data', []);
  const pagination = get(data, 'pagination', {});

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'System Logs', url: '' },
  ];

  useEffect(() => {
    if (categories) {
      setCategory(categories);
    }
  }, [categories]);

  const onCatChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setCategory([...selectedCat, value]);
    } else {
      setCategory(selectedCat.filter((e) => e !== value));
    }
  };

  const dataTableColumns = [
    {
      header: (
        <Button
          onClick={() => {
            setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date/Time '}
            <i className={` ${orderBy === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`} />
          </span>
        </Button>
      ),
      id: 'date',
      accessorFn: (row) => row.date,
      cell: ({ getValue }) => (
        <DateTimeFormat datetime={getValue() || ''} localeString={DateTime.DATETIME_FULL} />
      ),
    },
    {
      header: (
        <ButtonDropdown toggle={() => setCatToggle(!catToggle)} isOpen={catToggle}>
          <DropdownToggle caret className="custom-simple-txt-btn fw-bold">
            {' Category'}
          </DropdownToggle>
          <DropdownMenu end>
            {categories.map((el) => (
              <div key={el} style={{ whiteSpace: 'nowrap' }}>
                <CheckBox
                  value={el}
                  key={uniqueId('log-category-')}
                  className="ms-2 me-2"
                  checked={selectedCat.includes(el)}
                  onChange={(e) => onCatChange(e)}
                  title={capitalizeFirstLetter(el.replace(/_/g, ' '))}
                />
              </div>
            ))}
            <Button className="ms-2" onClick={() => setCategory([])}>
              Clear
            </Button>
          </DropdownMenu>
        </ButtonDropdown>
      ),
      id: 'category',
      accessorFn: (row) => row.category,
      cell: ({ getValue }) => (
        <span className="text-capitalize">{getValue()?.replace(/_/g, ' ')}</span>
      ),
    },
    {
      header: 'Instance',
      id: 'instance',
      accessorFn: (row) => ({
        instanceName: row.instance_name,
        instanceId: row.instance_id,
        siteId: row.site_id,
      }),
      cell: ({ getValue }) => {
        const { instanceName, instanceId, siteId } = getValue();
        if (instanceName) {
          if (instanceId && siteId) {
            return (
              <a href={`/dashboards/instance/${instanceId}/site/${siteId}`} target="_blank" rel="noreferrer">
                {instanceName}
              </a>
            );
          }
          return instanceName;
        }
        return instanceId;
      },
    },
    {
      header: 'Site',
      id: 'site',
      accessorFn: (row) => ({
        domain: row.domain,
        instanceId: row.instance_id,
        siteId: row.site_id,
      }),
      cell: ({ getValue }) => {
        const { domain, instanceId, siteId } = getValue();
        if (domain && instanceId && siteId) {
          return (
            <a href={`/dashboards/instance/${instanceId}/site/${siteId}`} target="_blank" rel="noreferrer">
              {domain}
            </a>
          );
        }
        return siteId;
      },
    },
    {
      header: 'Text',
      id: 'text',
      accessorKey: 'text',
    },
  ];

  const onChangePage = (e) => {
    navigate(
      replacePathParams(
        settingRoutes.logs,
        [
          { key: 'pageNo', value: e },
        ], props,
      ),
    );
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'System Logs',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: commonPathParams(settingRoutes.dashboard),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Pagination
        type={['logs', 'logs']}
        currentPage={Number(pageNo)}
        totalRecord={pagination.count}
        perPage={pagination.per_page}
        totalPages={pagination.pages}
        records={errorLogs}
        onChangePage={onChangePage}
        getPagination={pagination}
        pageSize={pageSize}
        changePageSize={changePageSize}
      />
      <Card>
        <CardBody>
          <UserFeatureComponent feature="system_logs">
            <Row>
              <Col>
                <ReactTable8
                  data={errorLogs}
                  columns={dataTableColumns}
                />
              </Col>
            </Row>
          </UserFeatureComponent>
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(Logs);
