import Instance from '../models/Instance';
import Site from '../models/Site';
import User from '../models/User';
import { apiSlice } from './apiSlice';

export const commonApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => '/profile',
      providesTags: ['user'],

      // This is only used to figure out if the user is logged into the dashboard. It returns a 404 if they are not,
      // but we don't want to display an error message, so we ignore the 404.
      // TODO: find another way to do this
      transformErrorResponse: () => null,
      extraOptions: { modelClass: User },
    }),
    getSiteById: builder.query({
      query: (siteId: string) => `/sites/${siteId}`,
      providesTags: ['site'],
      extraOptions: { modelClass: Site },
    }),
    getInstanceById: builder.query({
      query: (instanceId: string) => `/instances/${instanceId}`,
      extraOptions: { modelClass: Instance },
      providesTags: ['instance'],
    }),
  }),
});

export const transformEndpoints = (endpoints: any) => {
  const transformedEndpoints = {};

  Object.keys(endpoints).forEach((key) => {
    if (key.match(/Mutation$/)) {
      transformedEndpoints[key] = function (...args) {
        const result = endpoints[key](...args);
        return [
          result[0],
          {
            ...result[1],
            data: result[1].data?.data,
            meta: result[1].data?.meta,
          },
        ];
      };
    } else if (key.match(/^useLazy/)) {
      // TODO: Haven't figured out what to do here. Should try to get the meta data into the result but we don't use
      // many lazy queries so not a big deal.
      transformedEndpoints[key] = endpoints[key];
    } else {
      transformedEndpoints[key] = function (...args) {
        const result = endpoints[key](...args);
        return {
          ...result,
          data: result.data?.data,
          meta: result.data?.meta,
        };
      };
    }
  });

  return transformedEndpoints;
};

const transformedEndpoints = transformEndpoints(commonApiSlice);

export const {
  endpoints,

  useGetUserProfileQuery,
  useGetInstanceByIdQuery,
  useGetSiteByIdQuery,
} = transformedEndpoints as any;
