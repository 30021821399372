/* eslint-disable no-useless-escape */

import React, { useEffect, useState } from 'react';
import {
  Row, Col, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup,
} from 'reactstrap';
import Select, { components } from 'react-select';
import { omit, get, isEmpty } from 'lodash-es';
import {
  validateEmail,
  selectColorStyles,
  getSelectedOption,
  selectBoxOptions,
  updateStylesheet,
} from '../../../../../../Utils';
import { displayError, displaySuccess } from 'Dashboard/src/Utils';
import { getPageHierarchy } from '../../../../components/common/PageHierarchy';
import './index.scss';
import { ButtonStylesDropdown } from '../../../../components/common/textStyles';
import {
  useGetButtonStylesQuery,
  useGetLinkDataQuery,
  useGetLinkToolDataQuery,
  useLinkToolCreateLinkMutation,
  useLinkToolUnlinkMutation,
  useLinkToolUpdateLinkMutation,
} from 'Dashboard/src/api/dashboardApiSlice';
import withRouter from '../../../../helpers/withRouter';
import SectionBox from '../../../../components/fields/page/SectionBox';
import ColorPicker from '../../../../components/common/color-picker';
import { useInstance, useSite } from 'common/hooks';

function CustomOption(props) {
  const { label, data: { column_section_id } } = props;
  return (
    <div className={column_section_id ? 'ms-3' : ''}>
      <components.Option {...props}>
        <label htmlFor={label}>{label}</label>
      </components.Option>
    </div>
  );
}

const linkTargetOptions = [
  { label: 'Existing window', value: '_self' },
  { label: 'New window', value: '_blank' },
  { label: 'Pop-up window', value: 'framebox' },
];

const anotherSiteTargetOptions = [
  { label: 'Existing window', value: '_self' },
  { label: 'New window', value: '_blank' },
];

const removeLinkTypeOptions = [
  'back_to_top_of_page',
  'close_browser_window',
  'back_to_previous_page',
  'toggle_visibility_of_section',
];

const linkDisplayOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Button', value: 'button' },
];

const validateURL = (url) => {
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  if (!re.test(url)) {
    return false;
  }
  return true;
};

function LinkToolBox(props) {
  const {
    linkId,
    params: { pageId, pageVersionId },
    toggle,
    isMedia,
    onUnlink,
    isEmailPage,
    galleryItemId,
    selectedString,
    onLinkBoxSaveButton,
    selectedTextBlockId,
    showUnlinkButton,
  } = props;

  const instance = useInstance();
  const site = useSite();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);

  const [linkToolData, setLinkToolData] = useState({
    link_display: 'text',
    type: null,
    color: null,
    hover_color: null,
  });
  const [selectedSiteId, setSelectedSiteId] = useState(siteId);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const { data: buttonStyles } = useGetButtonStylesQuery(siteId, { skip: isEmpty(siteId) });

  const {
    type,
    color,
    phone,
    file_id,
    site_phone,
    section_id,
    hover_color,
    external_url,
    link_display,
    email_address,
    email_subject,
    button_style_id,
    file_disposition,
    close_other_sections,
  } = linkToolData;

  const { data } = useGetLinkDataQuery({ instanceId, linkId }, { skip: isEmpty(instanceId) || isEmpty(linkId) });
  const { data: linkToolOptions = [] } = useGetLinkToolDataQuery(instanceId, { skip: isEmpty(instanceId) });
  const [createLink] = useLinkToolCreateLinkMutation();
  const [updateLink] = useLinkToolUpdateLinkMutation();
  const [linkUnlink] = useLinkToolUnlinkMutation();

  useEffect(() => {
    if (data) {
      setLinkToolData(data.attributes);
      setSelectedPageId(get(data.attributes, 'page_id', pageId));
      setSelectedSiteId(get(data.attributes, 'site_id', siteId));
    } else {
      setSelectedSiteId(siteId);
    }
  }, [data, siteId]);

  const checkSelectedLinkType = async (value) => {
    setLinkToolData({ ...linkToolData, type: value });

    // if (linkToolOptions.length === 0) {

    // }
  };

  const linkTypeOptions = () => {
    const removeOptions = (data, options) => data.filter((item) => options.every(
      (item2) => item2 !== item.option_value,
    ));

    if (isEmailPage) {
      return selectBoxOptions(removeOptions(linkToolOptions, removeLinkTypeOptions), 'option', 'option_value');
    }
    return selectBoxOptions(removeOptions(linkToolOptions, ['unsubscribe_page']), 'option', 'option_value');
  };

  const onHandleLinkTypeChange = (e) => {
    const { value } = e;
    checkSelectedLinkType(value);
  };

  const onHandleSiteChange = (e) => {
    const { value } = e;
    setSelectedSiteId(value);
  };

  const onHandleFileChange = (e) => {
    const { value } = e;
    setLinkToolData({ ...linkToolData, file_id: value });
  };

  const onLinkDropdownChange = (keyName, e) => {
    const { value } = e;
    setLinkToolData({ ...linkToolData, [keyName]: value });
  };

  const onHandleDownloadBehavior = (e) => {
    const { target: { value } } = e;
    setLinkToolData({ ...linkToolData, file_disposition: value });
  };

  const linkTargetBox = (isAnotherSite) => {
    const targetOptions = isAnotherSite ? anotherSiteTargetOptions : linkTargetOptions;

    if (!isEmailPage && linkToolData?.type !== 'toggle_visibility_of_section') {
      return (
        <Col className="col-12">
          <Label className="mt-2"><b>Link Target:</b></Label>
          <Select
            value={
              getSelectedOption(
                targetOptions,
                get(linkToolData, 'link_target', '_self'),
              )
            }
            onChange={(e) => onLinkDropdownChange('link_target', e)}
            options={targetOptions}
            menuPortalTarget={window.modalRef?.current || document.body} // Necessary to make the z-index of the dropdown work
            styles={selectColorStyles}
          />
        </Col>
      );
    }
    return null;
  };

  const linkDisplayBox = () => (
    <>
      {!isMedia && !isEmpty(buttonStyles) && (
        <Col className="col-12">
          <Label className="mt-2"><b>Link Display:</b></Label>
          <Select
            value={getSelectedOption(linkDisplayOptions, link_display)}
            onChange={(e) => onLinkDropdownChange('link_display', e)}
            options={linkDisplayOptions}
            menuPortalTarget={window.modalRef?.current || document.body} // Necessary to make the z-index of the dropdown work
            styles={selectColorStyles}
          />
        </Col>
      )}
      {!isMedia && !isEmpty(buttonStyles) && link_display === 'button' && (
        <Col className="col-12">
          <Label className="mt-2"><b>Button Style:</b></Label>
          <ButtonStylesDropdown
            value={button_style_id}
            siteId={siteId}
            styles={selectColorStyles}
            onChange={(e) => onLinkDropdownChange('button_style_id', e)}
          />
        </Col>
      )}
      {!isMedia && link_display === 'text' && (
        <>
          <Col className={`col-${isEmailPage ? 12 : 6}`}>
            <Label className="mt-2"><b>Override Link Colour:</b></Label>
            <ColorPicker
              isEmailPage={isEmailPage}
              color={color}
              onChange={({ target: { value } }) => {
                setLinkToolData({ ...linkToolData, color: value });
              }}
              onDeleteValue={() => {
                setLinkToolData({ ...linkToolData, color: null });
              }}
            />
          </Col>
          {!isEmailPage && (
            <Col className="col-6">
              <Label className="mt-2"><b>Override Link Hover Colour:</b></Label>
              <ColorPicker
                isEmailPage={isEmailPage}
                color={hover_color}
                onChange={({ target: { value } }) => {
                  setLinkToolData({ ...linkToolData, hover_color: value });
                }}
                onDeleteValue={() => {
                  setLinkToolData({ ...linkToolData, hover_color: null });
                }}
              />
            </Col>
          )}
        </>
      )}
    </>
  );

  const onHandleChangeInput = (e) => {
    const { target: { name, value } } = e;
    setLinkToolData({ ...linkToolData, [name]: value });
  };

  const renderTheBox = () => {
    if (isEmpty(linkToolData)) {
      return null;
    }

    if (
      ['page_on_your_site', 'section_on_page_on_your_site', 'toggle_visibility_of_section'].includes(type)
    ) {
      const isToggleSection = type === 'toggle_visibility_of_section';
      const siteData = linkToolOptions.find((item) => item.option_value === 'page_on_your_site')?.sites || [];
      const siteOptions = selectBoxOptions(siteData, 'name', 'id');
      const pageData = siteData.find((site) => site.id == selectedSiteId)?.pages || [];
      const pageOptions = getPageHierarchy(pageData, '');

      return (
        <>
          <Col className="col-12">
            <Label className="mt-2"><b>Site:</b></Label>
            <Select
              isDisabled={isToggleSection}
              onChange={(e) => onHandleSiteChange(e, type)}
              options={siteOptions}
              value={getSelectedOption(siteOptions, isToggleSection ? siteId : selectedSiteId)}
              menuPortalTarget={window.modalRef?.current || document.body} // Necessary to make the z-index of the dropdown work
              styles={selectColorStyles}
            />
            <Label className="mt-2"><b>Page:</b></Label>
            <Select
              isDisabled={isToggleSection}
              onChange={(e) => setSelectedPageId(e.value)}
              options={pageOptions}
              value={getSelectedOption(pageOptions, isToggleSection ? pageId : selectedPageId)}
              menuPortalTarget={window.modalRef?.current || document.body} // Necessary to make the z-index of the dropdown work
              styles={selectColorStyles}
            />
            {['section_on_page_on_your_site', 'toggle_visibility_of_section'].includes(type) && (
              <div>
                <Label className="mt-2"><b>Section:</b></Label>
                <SectionBox
                  params={props?.params}
                  menuPortalTarget={window.modalRef?.current || document.body} // Necessary to make the z-index of the dropdown work
                  selectedPageId={isToggleSection ? pageId : selectedPageId}
                  onChange={(e) => onLinkDropdownChange('section_id', e)}
                  value={section_id}
                  components={{
                    Option: CustomOption,
                  }}
                />
              </div>
            )}
            {isToggleSection && (
              <FormGroup check className="mt-2">
                <Label check>
                  <Input
                    type="checkbox"
                    value
                    checked={close_other_sections}
                    onChange={(e) => onHandleChangeInput({
                      target: { name: 'close_other_sections', value: e.target.checked },
                    })}
                  />
                  Close other toggled sections when this link is clicked
                </Label>
              </FormGroup>
            )}
          </Col>
          {linkTargetBox()}
          {linkDisplayBox()}
        </>
      );
    }

    if (type === 'page_on_another_site') {
      return (
        <>
          <Col className="col-12">
            <Label className="mt-2"><b>URL:</b></Label>
            <Input
              type="text"
              name="external_url"
              value={external_url || ''}
              onChange={(e) => onHandleChangeInput(e)}
            />
            <p>(eg www.yahoo.com)</p>
          </Col>
          {linkTargetBox(true)}
          {linkDisplayBox()}
        </>
      );
    }

    if (type === 'file_from_your_site') {
      const files = linkToolOptions.find((item) => item.option_value === 'file_from_your_site')?.files || [];
      const fileOptions = files.map((item) => ({ label: item.name, value: item.id }));

      return (
        <>
          <Col className="col-12">
            <Label className="mt-2"><b>File:</b></Label>
            <Select
              value={getSelectedOption(fileOptions, file_id)}
              onChange={onHandleFileChange}
              options={fileOptions}
              menuPortalTarget={window.modalRef?.current || document.body} // Necessary to make the z-index of the dropdown work
              styles={selectColorStyles}
            />
            <ul className="custom-ul-layout">
              <Label className="mt-2"><b>Download behavior:</b></Label>
              <li>
                <Label className="radio-container checkbox-radio-container">
                  <Input
                    type="radio"
                    name="download-behavior"
                    onChange={(e) => onHandleDownloadBehavior(e)}
                    checked={file_disposition === 'inline'}
                    value="inline"
                  />
                  Open file in browser
                  <span className="checkmark checkmark-radio" />
                </Label>
              </li>
              <li>
                <Label className="radio-container checkbox-radio-container">
                  <Input
                    type="radio"
                    name="download-behavior"
                    onChange={(e) => onHandleDownloadBehavior(e)}
                    checked={file_disposition === 'attachment'}
                    value="attachment"
                  />
                  Download file to disk
                  <span className="checkmark checkmark-radio" />
                </Label>
              </li>
            </ul>
          </Col>
          {linkTargetBox()}
          {linkDisplayBox()}
        </>
      );
    }

    if (type === 'email_link') {
      return (
        <>
          <Col className="col-12">
            <Label className="mt-2"><b>Email address:</b></Label>
            <Input
              type="email"
              name="email_address"
              value={email_address || ''}
              onChange={(e) => onHandleChangeInput(e)}
            />
            <Label className="mt-2"><b>Email Subject (optional):</b></Label>
            <Input
              type="text"
              name="email_subject"
              value={email_subject || ''}
              onChange={(e) => onHandleChangeInput(e)}
            />
          </Col>
          {linkDisplayBox()}
        </>
      );
    }

    if (type === 'phone_number') {
      return (
        <>
          <Col className="col-12">
            <FormGroup check className="mt-2">
              <Label check>
                <Input
                  type="checkbox"
                  value
                  checked={site_phone}
                  onChange={(e) => onHandleChangeInput({
                    target: { name: 'site_phone', value: e.target.checked },
                  })}
                />
                Use site&apos;s configured tracking phone number?
              </Label>
            </FormGroup>
            {!site_phone && (
              <>
                <Label className="mt-2"><b>Phone number:</b></Label>
                <Input
                  type="tel"
                  name="phone"
                  value={phone || ''}
                  onChange={(e) => onHandleChangeInput(e)}
                />
              </>
            )}
          </Col>
          {linkDisplayBox()}
        </>
      );
    }

    if (type === 'unsubscribe_page') {
      return linkDisplayBox();
    }

    return null;
  };

  const onHandleInsertLink = async () => {
    const isToggleSection = type === 'toggle_visibility_of_section';

    const selectedLinkTarget = get(linkToolData, 'link_target', '_self');

    let sendParams = {
      link_id: linkId,
      phone,
      site_phone,
      close_other_sections,
      type,
      instance_id: instanceId,
      page_id: isToggleSection ? pageId : selectedPageId,
      file_id,
      link_text: selectedString,
      external_url,
      email_address,
      email_subject,
      section_id,
      link_target: selectedLinkTarget,
      link_display,
      text_block_id: selectedTextBlockId,
      file_disposition,
      button_style_id: link_display === 'text' ? null : button_style_id,
      color,
      hover_color,
    };

    if (galleryItemId) {
      sendParams = { ...sendParams, gallery_item_id: galleryItemId };
    }

    if (isEmailPage) {
      sendParams = omit(sendParams, ['link_target']);
    }

    let attrType = '';

    if (!type) {
      displayError('Please select link type.');
    } else if (type) {
      if (type === 'page_on_your_site' || type === 'section_on_page_on_your_site') {
        if (!selectedSiteId) {
          displayError('Please select site.');
        } else if (!selectedPageId) {
          displayError('Please select page.');
        } else if (type === 'section_on_page_on_your_site' && !section_id) {
          displayError('Please select section.');
        } else if (!isEmailPage && !selectedLinkTarget) {
          displayError('Please link target.');
        } else {
          attrType = 'anchor-link';
        }
      } else if (type === 'page_on_another_site') {
        const urlStatus = validateURL(external_url);
        if (!external_url) {
          displayError('Please enter url.');
        } else if (urlStatus !== true) {
          displayError('Please enter valid URL.');
        } else if (!isEmailPage && !selectedLinkTarget) {
          displayError('Please link target.');
        } else {
          attrType = 'anchor-link';
        }
      } else if (type === 'file_from_your_site') {
        attrType = 'anchor-link';
      } else if (type === 'email_link') {
        if (!email_address) {
          displayError('Please enter an email address.');
        } else if (!validateEmail(email_address)) {
          displayError('Enter a valid email address.');
        } else {
          attrType = 'email-link';
        }
      } else if (type === 'phone_number' && !site_phone) {
        if (!phone) {
          displayError('Please enter a phone number.');
        } else {
          attrType = 'phone-link';
        }
      } else {
        attrType = 'anchor-link';
      }
      let response;
      if (attrType && !linkId) {
        response = await createLink(sendParams);
      } else if (attrType && linkId) {
        response = await updateLink(sendParams);
      }
      if (response && response?.data?.data) {
        let successMessage = '';
        if (linkId) {
          successMessage = 'Link Updated.';
        } else {
          successMessage = 'Link Created.';
        }
        const { data } = response.data;
        displaySuccess(successMessage);
        const sendResponse = {
          id: data.id,
          ...data.attributes,
          attr_type: attrType,
          button_style: data.attributes?.button_style_id
            && buttonStyles.filter((s) => s.id === data.attributes?.button_style_id)[0],
        };
        updateStylesheet({ siteId, pageId, pageVersionId });
        onLinkBoxSaveButton(sendResponse);
      }
    }
  };

  const onHandleClose = () => {
    setSelectedSiteId(siteId);
    setSelectedPageId(pageId);
    setLinkToolData({ ...linkToolData, file_id: '', type: '' });
    toggle();
  };

  const onHandleUnlink = async (id, instanceId) => {
    const response = await linkUnlink({ linkId: id, instanceId });
    if (response && response?.data?.data?.id) {
      onUnlink();
    }
  };

  const linkType = get(linkToolData, 'type', '');

  return (
    <Modal
      container={window.modalRef}
      isOpen
      className="LinkToolBox"
    >
      <ModalHeader toggle={() => onHandleClose()}><b>Link Tool</b></ModalHeader>
      <ModalBody>
        <Row>
          <Col className="col-12">
            <Label><b>Please select the link type:</b></Label>
            <Select
              onChange={onHandleLinkTypeChange}
              options={linkTypeOptions()}
              value={
                getSelectedOption(linkTypeOptions(), linkType)
              }
              menuPortalTarget={window.modalRef?.current || document.body} // Necessary to make the z-index of the dropdown work
              styles={selectColorStyles}
            />
          </Col>
          {renderTheBox()}
        </Row>
      </ModalBody>
      <ModalFooter>
        {
          showUnlinkButton && linkId
          && (
            <Button
              color="danger"
              onClick={() => onHandleUnlink(linkId, instanceId)}
            >
              <b>Unlink</b>
            </Button>
          )
        }
        <Button
          color="danger"
          onClick={() => onHandleClose()}
        >
          <b>Cancel</b>
        </Button>
        <Button
          color="success"
          onClick={() => onHandleInsertLink()}
        >
          <b>{linkId ? 'Update' : 'Save'}</b>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(LinkToolBox);
