import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, Alert, Card, CardBody, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import classnames from 'classnames';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, mainRoutes, replacePathParams } from '../../../constants/routes';
import { cloneDeep, isEmpty } from 'lodash-es';
import BottomActionToolbar from '../../../components/common/toolbar';
import CustomSelect from '../../../components/common/custom-select';
import { getSelectedOption } from '../../../../../Utils';
import { displaySuccess } from 'Dashboard/src/Utils';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import InstanceName from '../../../components/fields/instance/InstanceName';
import { useParams } from 'react-router';
import withRouter from '../../../helpers/withRouter';
import SitePagesHierarchy from '../../../components/fields/site/SitePages';
import SMSName from '../../../components/fields/instance/SMSName';
import Switch from 'rc-switch';
import { useGetDatabaseFieldsQuery, useGetInstanceByIdQuery, useUpdateInstanceMutation } from '../../../api/dashboardApiSlice';
import UserFeatureComponent from '../../../components/common/user-feature-component';

const getOptions = (options) => options.map((ele) => ({
  label: ele.attributes.field_name,
  value: ele.id,
}));

function InstanceSettings(props) {
  const param = useParams();
  const { instanceId } = param;
  const [params, setParams] = useState({});
  const [tab, setTab] = useState('Settings');
  const [updateInstance] = useUpdateInstanceMutation();
  const { data: instance = {} } = useGetInstanceByIdQuery(instanceId, { skip: isEmpty(instanceId) });
  const { data: fieldsData = [] } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) });

  useEffect(() => {
    if (instance) {
      setParams(cloneDeep(instance));
    }
  }, [instance]);

  const commonPathParams = (path) => replacePathParams(path, [], props);

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'Instance Settings', url: '' },
  ];

  const onHandleSubmit = async () => {
    // e.preventDefault();
    const sendParams = {
      instance_id: instanceId,
      ...params,
    };
    const result = await updateInstance(sendParams);
    if (result && result?.data?.meta?.is_success) {
      displaySuccess(result?.data?.meta?.messages);
    }
  };

  const onHandleDropdownChange = (e, name) => {
    const { value } = e;
    setParams({ ...params, [name]: value });
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Instance Settings',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: commonPathParams(settingRoutes.dashboard),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Form onSubmit={onHandleSubmit}>
        <Row className="mb-4">
          <Col lg={6}>
            <Card className="h-100">
              <CardBody>
                <Nav tabs className="separator-tabs ms-0 mb-5 custom-cursor-pointer">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 'Settings' })}
                      onClick={() => setTab('Settings')}
                    >
                      Settings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 'Advanced Settings' })}
                      onClick={() => setTab('Advanced Settings')}
                    >
                      Advanced Settings
                    </NavLink>
                  </NavItem>
                </Nav>
                {tab === 'Settings' && (
                  <Row>
                    <UserFeatureComponent feature="set_instance_name">
                      <Col lg={12} md={12}>
                        <InstanceName params={params} setParams={setParams} />
                      </Col>
                    </UserFeatureComponent>
                    <UserFeatureComponent feature="set_instance_as_master">
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label check>Can be used as a duplication master?</Label>
                          <Switch
                            className="custom-switch custom-switch-primary"
                            checked={params.master}
                            onClick={(e) => setParams({ ...params, master: e })}
                          />
                        </FormGroup>
                      </Col>
                    </UserFeatureComponent>
                    <UserFeatureComponent feature="instance_settings">
                      <Col lg={12} md={12}>
                        <SitePagesHierarchy params={params} setParams={setParams} label="Leave Review Page" />
                      </Col>
                      <Col lg={12} md={12}>
                        <SMSName params={params} setParams={setParams} />
                      </Col>
                    </UserFeatureComponent>
                  </Row>
                )}

                {tab === 'Advanced Settings' && (
                  <UserFeatureComponent feature="instance_settings">
                    <Row>
                      <Col>
                        <Alert color="danger" className="custom-alert-span">
                          <b>
                            WARNING:
                            {' '}
                          </b>
                          These settings should generally not be changed,
                          please only change them if you know what you&apos;re doing.
                        </Alert>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label for="firstname_field_id">First Name Database Field</Label>
                          <CustomSelect
                            value={getSelectedOption(getOptions(fieldsData), params.firstname_field_id)}
                            options={getOptions(fieldsData)}
                            onChange={(e) => onHandleDropdownChange(e, 'firstname_field_id')}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label for="lastname_field_id">Last Name Database Field</Label>
                          <CustomSelect
                            value={getSelectedOption(getOptions(fieldsData), params.lastname_field_id)}
                            options={getOptions(fieldsData)}
                            onChange={(e) => onHandleDropdownChange(e, 'lastname_field_id')}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label for="email_field_id">Email Address Database Field</Label>
                          <CustomSelect
                            value={getSelectedOption(getOptions(fieldsData), params.email_field_id)}
                            options={getOptions(fieldsData)}
                            onChange={(e) => onHandleDropdownChange(e, 'email_field_id')}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12}>
                        <FormGroup>
                          <Label for="mobile_number_field_id">Mobile Number Database Field</Label>
                          <CustomSelect
                            value={getSelectedOption(getOptions(fieldsData), params.mobile_number_field_id)}
                            options={getOptions(fieldsData)}
                            onChange={(e) => onHandleDropdownChange(e, 'mobile_number_field_id')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </UserFeatureComponent>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={commonPathParams(mainRoutes.setting)}
          />
        )}
      />
    </>
  );
}

export default withRouter(InstanceSettings);
