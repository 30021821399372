import React, { useState } from 'react';
import {
  Label,
  Row,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from 'reactstrap';
import CustomSelect from '../../../components/common/custom-select';
import CustomButton from '../../../components/common/button';
// import './index.scss';
import { DateTime } from 'luxon';
import { usePageVersionPublishMutation, usePageVersionUpdateMutation } from '../../../api/dashboardApiSlice';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash-es';
import confirm from '../../../components/common/confirm';

const getSelectedOption = (options, selected_option) => (
  options.filter((option) => option.id === selected_option.id)
);

const validateUrl = (str) => {
  if (str.indexOf('http://') === 0 || str.indexOf('https://') === 0) {
    return str;
  }

  return `https://${str}`;
};

export const redirectToAnotherPage = (previewUrl, target = '_blank', features = '') => {
  window.open(validateUrl(previewUrl), target, features);
};

const showLabelVersionNumber = (version) => {
  if (version.is_published) {
    return `${version.version_number} (Published)`;
  }

  const scheduleStr = version.publish_at ? `, scheduled to be published ${DateTime.fromISO(version?.publish_at).toLocaleString(DateTime.DATETIME_MED)}` : '';

  return `${version.version_number} (Draft${scheduleStr})`;
};

function PageEditOption(props) {
  const {
    page,
    previewUrl,
    handleSelect,
    page_version,
    page_versions,
    pageDraftHandler,
    handleHistoryPage,
  } = props;

  const isPublished = page_version?.is_published;

  const [payload, setPayload] = useState({ publish_now: true, publish_at: '' });
  const [modal, setModal] = useState(false);
  const { instanceId, siteId, pageId, pageVersionId } = useParams();

  const [publishPageVersion] = usePageVersionPublishMutation();
  const [pageVersionUpdate] = usePageVersionUpdateMutation();

  // The format required by <input type=datetime>, in Luxon/moment's tokens.
  const fmt = 'yyyy-LL-dd\'T\'HH:mm';

  const onHandlePublish = async () => {
    const sendParams = {
      ...payload,
      site_id: siteId,
      page_id: pageId,
      id: pageVersionId,
      publish_at: payload?.publish_now ? null : payload?.publish_at,
    };
    const response = await publishPageVersion(sendParams);
    if (undefined !== response) {
      setModal(false);
    }
  };

  const onHandleCancelPublish = async () => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure you want to cancel publishing this draft version?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const params = {
        page_id: pageId,
        site_id: siteId,
        publish_at: null,
        publish_cancelled: true,
        instance_id: instanceId,
        ...page_version,
      };
      pageVersionUpdate(params);
    }
  };

  const renderPublishButton = () => {
    if (!isPublished && isEmpty(page_version?.publish_at)) {
      return (
        <Col lg={6} md={12}>
          <CustomButton
            color="success"
            onClick={() => setModal(!modal)}
            className="common-success-button button-lg w-100 mt-2 fw-normal"
            title=" PUBLISH"
            icon="fa fa-upload"
          />
        </Col>
      );
    } else if (!isPublished && !isEmpty(page_version?.publish_at)) {
      return (
        <>
          <Col lg={6} md={12}>
            <CustomButton
              color="danger"
              onClick={() => onHandleCancelPublish(page)}
              title=" CANCEL PUBLISHING"
              icon="fa-solid fa-xmark"
              className="page-cancel-button button-lg w-100 mt-2 fw-normal"
            />
          </Col>
          <Badge className="mt-3 font-size-15px" color="warning">
            {`This version is scheduled to be published at ${DateTime.fromISO(page_version?.publish_at).toLocaleString(DateTime.DATETIME_MED)}`}
          </Badge>
        </>
      );
    }
    return '';
  };

  if (page_versions) {
    return (
      <>
        <div className="PageVersionBox">
          <Row className="mb-3">
            <Col>
              <FormGroup>
                <Label>Select Version</Label>
                <CustomSelect
                  value={getSelectedOption(page_versions, page_version)}
                  name="version"
                  isSearchable={false}
                  getOptionLabel={(option) => showLabelVersionNumber(option)}
                  getOptionValue={(option) => option.id}
                  options={page_versions}
                  onChange={(version) => handleSelect(version)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={6} xs={6}>
              <CustomButton
                color="info"
                onClick={() => pageDraftHandler(page)}
                className="add-new-button button-lg w-100 mb-3 fw-normal"
                title=" NEW VERSION"
                icon="fa fa-plus"
                disabled={!isPublished && !isEmpty(page_version?.publish_at)}
              />
            </Col>
            <Col lg={6} md={6} sm={6} xs={6}>
              <CustomButton
                color="info"
                onClick={() => handleHistoryPage()}
                className="page-history-button button-lg w-100 mb-3 fw-normal"
                title=" PAGE HISTORY"
                icon="fa fa-history"
              />
            </Col>
            <Col lg={6} md={12}>
              <CustomButton
                color="success"
                onClick={() => redirectToAnotherPage(previewUrl)}
                className="page-preview-button button-lg w-100 mt-2 fw-normal"
                title=" PREVIEW"
                icon="fa fa-share-square-o"
              />
            </Col>
            {renderPublishButton()}
          </Row>
        </div>
        {modal && (
          <Modal isOpen={modal} toggle={setModal}>
            <ModalHeader><b>Confirm!</b></ModalHeader>
            <ModalBody>
              <p>Do you want to publish this page now, or later?</p>
              <FormGroup check>
                <Input
                  id="publish-now"
                  type="radio"
                  checked={payload?.publish_now}
                  onChange={() => setPayload({ ...payload, publish_now: true })}
                />
                <Label for="publish-now">
                  Publish Now
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="publish-later"
                  type="radio"
                  checked={!payload?.publish_now}
                  onChange={() => setPayload({ ...payload, publish_now: false, publish_at: payload.publish_at || DateTime.utc().plus({ days: 1 }).toFormat(fmt) })}
                />
                <Label for="publish-later">
                  Publish Later
                </Label>
              </FormGroup>
              {!payload?.publish_now && (
                <FormGroup>
                  <Label>
                    Select Date
                  </Label>
                  <Input
                    type="datetime-local"
                    min={DateTime.utc().toLocal().toFormat(fmt)}
                    value={DateTime.fromFormat(payload.publish_at, fmt, { zone: 'utc' }).toLocal().toFormat(fmt)}
                    onChange={(e) => setPayload({ ...payload, publish_at: DateTime.fromFormat(e.target.value, fmt).toUTC().toFormat(fmt) })}
                  />
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <CustomButton
                color="danger"
                onClick={() => setModal(false)}
                title="CANCEL"
              />
              <CustomButton
                color="success"
                title="PUBLISH"
                onClick={onHandlePublish}
              />
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
  return (<h2>Loading</h2>);
}

export default PageEditOption;
