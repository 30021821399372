import React from 'react';
import { createStore } from '../../../rootStore';
import Section from 'common/models/Section';

interface SectionProps {
  section: Section;
  onClick: (section: Section) => void;
}

const SectionEcwid: React.FC<SectionProps> = ({ section, onClick }) => {
  const store = createStore();
  const { pageData: { site } } = store.getState();

  if (!site?.ecwid_store_id) {
    return <></>;
  }

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionEcwid bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{ display: section.initial_visibility || window.wg.env === 'dashboard' ? '' : 'none' }}
        onClick={() => onClick(section)}
      >
        {section.element === 'product_list' && section.category_id && (
          <div
            id={`ecwid_${section.id}`}
            className="ecwid-container"
            data-category={section.category_id}
          />
        )}
        {section.element === 'category_list' && (
          <div
            id={`ecwid_${section.id}`}
            className="ecwid-category-container"
          />
        )}
      </section>
    </>
  );
};

export default SectionEcwid;
