import React, { useEffect, useState } from 'react';
import {
  Card,
  CardColumns,
  CardBody,
  Container,
  Row,
  Col,
  Label,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import BottomActionToolbar from '../../../components/common/toolbar';
import { MasterPageSelectBox, PrivacyPolicyText } from '../../../components/fields/site';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import withRouter from '../../../helpers/withRouter';
import { useUpdateSiteMutation } from '../../../api/dashboardApiSlice';
import UserFeatureComponent from 'Dashboard/src/components/common/user-feature-component';
import { useInstance, useSite } from '../../../../../common/hooks';
import { get } from 'lodash-es';
import { addRecentItem } from 'Dashboard/src/Utils';
import './EditSite.scss';
function SiteSettingPrivacyPolicy(props) {
  const [params, setParams] = useState({});

  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const [updateSite] = useUpdateSiteMutation();

  useEffect(() => {
    if (site) {
      setParams(site);
    }
  }, [site]);

  const onSave = () => {
    updateSite({
      id: siteId,
      instance_id: instanceId,
      ...params,
    });

    return true;
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'settings-privacy-policy',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Privacy Policy', url: '' },
  ];

  return (
    <div className="EditSite">
      <HeaderComponent
        setPath={{
          headingName: 'Privacy Policy',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.siteList,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <UserFeatureComponent feature="privacy_policy_text">
        <CardColumns>
          <Card>
            <CardBody>
              <Container>
                <Row>
                  <Col lg={6}>
                    <Label>
                      Master Page
                    </Label>
                    <MasterPageSelectBox
                      siteId={siteId}
                      params={params}
                      setParams={setParams}
                      instanceId={instanceId}
                      keyName="privacy_policy_master_page_id"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="full-width" lg={12} md={12}>
                    <PrivacyPolicyText params={params} setParams={setParams} />
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </CardColumns>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSave}
              onContinue={replacePathParams(settingRoutes.siteList, [], props)}
            />
          )}
        />
      </UserFeatureComponent>
    </div>
  );
}

export default withRouter(SiteSettingPrivacyPolicy);
