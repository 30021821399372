import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Tree, NodeApi, TreeApi } from 'react-arborist';
import Briefing from 'common/models/Briefing';
import { useUpdateSiteBriefingMutation } from '../../api/dashboardApiSlice';
import { cable, BriefingMeetingChannel } from 'Dashboard/src/Channels';
import { isInFullScreen, toggleFullScreen } from 'Dashboard/src/Utils';
import { usePageClassName } from 'common/hooks';

interface PMMeetingFormProps {
  briefing: Briefing;
  singleWindow?: boolean;
}

// This shows just the PM Meeting form, it's meant for users who have two monitors. It is called from the
// PMMeetingFormContainer component.
const PMMeetingForm: React.FC<PMMeetingFormProps> = ({ briefing, singleWindow }) => {
  usePageClassName(singleWindow ? 'PMMeetingSingleWindow' : 'PMMeetingDualWindow');

  const [channel, setChannel] = useState<BriefingMeetingChannel | null>(null);

  const textareaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({});

  const [tempBriefing, setTempBriefing] = React.useState<Briefing | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resizeCounter, setResizeCounter] = useState(0);
  const [updateSiteBriefing] = useUpdateSiteBriefingMutation();

  const onresize = () => setResizeCounter((prev) => prev + 1);

  useEffect(() => {
    if (!briefing?.id || channel)
      return;

    if (!tempBriefing) {
      let websiteStructure = briefing.meeting_feedback?.WebsiteStructure;

      if (!websiteStructure) {
        // Create a default website structure
        websiteStructure = [
          {
            id: 'Home',
            name: 'Home',
            children: [
              {
                id: 'About',
                name: 'About Us',
              },
            ],
          },
        ];

        briefing.info?.asap_pages.forEach((page, index) => {
          websiteStructure[0].children.push({
            id: `${index + 1}`,
            name: page,
          });
        });

        websiteStructure[0].children.push({
          id: 'Contact',
          name: 'Contact Us',
        });
      }

      setTempBriefing({
        ...briefing,
        meeting_feedback: {
          ...briefing.meeting_feedback,
          WebsiteStructure: websiteStructure,
        },
      });
    }

    const _channel = new BriefingMeetingChannel({ briefing_id: briefing.id });
    cable.subscribe(_channel);

    setChannel(_channel);

    (window as any).resizeInterval = setInterval(() => onresize(), 1000);

    window.onresize = onresize;
    onresize();
    return () => {
      window.onresize = null;
      clearInterval((window as any).resizeInterval);
      clearInterval((window as any).setPageInterval);
    };
  }, [briefing]);

  useEffect(() => {
    if (!tempBriefing?.id || !channel)
      return;

    channel?.changePage(tempBriefing.id, currentPage, Math.random());
  }, [tempBriefing]);

  useEffect(() => {
    if (!tempBriefing?.id || !channel)
      return;

    channel?.changePage(tempBriefing.id, currentPage, Math.random());
  }, [resizeCounter]);

  useEffect(() => {
    if (!tempBriefing?.id || !channel)
      return;

    (async () => {
      await channel?.changePage(tempBriefing.id!, currentPage);
    })();

    // Save the briefing
    if (tempBriefing && tempBriefing.meeting_feedback && Object.keys(tempBriefing.meeting_feedback).length > 0) {
      (async () => {
        await updateSiteBriefing(tempBriefing).unwrap();
        channel?.refetchBriefing(tempBriefing.id!);
      })();
    }

    // Focus current textarea
    textareaRefs.current[currentPage]?.focus();
  }, [currentPage]);

  const setFeedback = (key: string, value: any) => {
    if (!tempBriefing) {
      return;
    }

    setTempBriefing({
      ...tempBriefing,
      meeting_feedback: {
        ...tempBriefing.meeting_feedback,
        [key]: value,
      },
    });
  };

  const save = async () => {
    if (!tempBriefing) {
      return;
    }

    await updateSiteBriefing(tempBriefing).unwrap();
    channel?.refetchBriefing(tempBriefing.id!);
  };

  if (!tempBriefing) {
    return null;
  }

  const pages = [] as string[];

  pages.push('Intro');
  pages.push('Project Manager');
  pages.push('Contact Details');
  pages.push('Website Structure');
  pages.push('Draft Website Structure');
  pages.push('Content Snippets');
  pages.push('What Are Your Customers Looking For?');
  pages.push('Your Big Promise');
  pages.push('Your Key Differentiators');
  pages.push('Main Google Keyword Phrases');
  pages.push('Business History');
  pages.push('The Business Today');

  briefing?.info?.business_owners.forEach((_owner, index) => {
    if (isEmpty(briefing?.info?.business_owner_background?.[index]))
      return;
    pages.push(`Business Owner Profile ${index + 1}`);
  });

  pages.push('Business Owner Quotes');
  pages.push('Team Members');

  briefing?.info?.asap_pages.forEach((page, pageIndex) => {
    pages.push(`ASAP Page ${pageIndex + 1} Selling Points`);
    pages.push(`ASAP Page ${pageIndex + 1} Key Differentiators`);
    pages.push(`ASAP Page ${pageIndex + 1} Potential Content Topics`);
    pages.push(`ASAP Page ${pageIndex + 1} Google Keyword Phrases`);
  });

  pages.push('Design And Graphic Details');
  pages.push('Design Ideas');

  for (let i = 1; i <= 5; i++)
    pages.push(`Design References ${i}`);

  pages.push('Photo Images Brief');
  pages.push('Upload Photos');
  pages.push('Other Pages And Elements');
  pages.push('Header');
  pages.push('Footer');
  pages.push('Calls To Action');
  pages.push('Home Page Elements');
  pages.push('Contact Page Elements');
  pages.push('Other Page Elements');

  let component;

  switch (pages[currentPage - 1]) {
    case 'Draft Website Structure': {
      type NodeRendererProps<T> = {
        style: CSSProperties;
        node: NodeApi<T>;
        tree: TreeApi<T>;
        dragHandle?: (el: HTMLDivElement | null) => void;
        preview?: boolean;
      };

      const websiteStructure = tempBriefing.meeting_feedback?.WebsiteStructure;
      const structureCopy = cloneDeep(tempBriefing?.meeting_feedback?.WebsiteStructure);

      const findPage = (pages: any[], id: string) => {
        for (const page of pages) {
          if (page.id === id) {
            return page;
          }
          if (page.children) {
            const found = findPage(page.children, id);
            if (found) {
              return found;
            }
          }
        }
        return null;
      };

      const findParentPage = (pages: any[], id: string) => {
        for (const page of pages) {
          if (page.children && page.children.some((child: any) => child.id === id)) {
            return page;
          }
          if (page.children) {
            const found = findParentPage(page.children, id);
            if (found) {
              return found;
            }
          }
        }
        return null;
      };

      function Node(props: NodeRendererProps<any>) {
        const { node, style, dragHandle } = props;

        return (
          <div style={style} ref={dragHandle}>
            {node.isOpen && node.children && node.children.length > 0 && (
              <i className="fal fa-chevron-down me-2" />
            )}

            {(!node.children || node.children.length === 0) && (
              <i className="fal fa-file me-2" />
            )}

            {node.children && node.children.length > 0 && !node.isOpen && (
              <i className="fal fa-chevron-right me-2" />
            )}

            {node.data.name}

            <span
              onClick={() => {
                const page = findPage(structureCopy, node.id);
                if (!page) {
                  return;
                }
                const newPageName = prompt('New page name:');
                if (!newPageName) {
                  return;
                }
                const newNode = {
                  id: crypto.randomUUID(),
                  name: newPageName,
                  children: [],
                };
                page.children ||= [];
                page.children.push(newNode);

                setTempBriefing((prev) => ({
                  ...prev,
                  meeting_feedback: {
                    ...prev?.meeting_feedback,
                    WebsiteStructure: structureCopy,
                  },
                }));
              }}
              className="ms-2"
              style={{
                cursor: 'pointer',
                color: '#007bff',
              }}
            >
              <i className="fas fa-plus" />
            </span>
            <span
              onClick={() => {
                const page = findPage(structureCopy, node.id);
                if (!page) {
                  return;
                }

                const parentPage = findParentPage(structureCopy, page.id);
                if (parentPage) {
                  parentPage.children = parentPage.children.filter((child) => child.id !== page.id);
                }

                setTempBriefing((prev) => ({
                  ...prev,
                  meeting_feedback: {
                    ...prev?.meeting_feedback,
                    WebsiteStructure: structureCopy,
                  },
                }));
              }}
              className="ms-2"
              style={{
                cursor: 'pointer',
                color: '#dc3545',
              }}
            >
              <i className="fas fa-minus" />
            </span>
          </div>
        );
      }

      // TODO: This currently works for pages on the same level, but moving between levels duplicates the node.
      // Disabling moving for now.
      // const onMove = (props) => {
      //   const movedNode = props.dragNodes[0];
      //   const root = movedNode.tree.root.children[0];

      //   const newParent = props.parentId;
      //   const newIndex = props.index;

      //   const mapNode = (node) => ({
      //     id: node.id,
      //     name: node.data.name,
      //     children: node.children ? node.children.map(mapNode) : [],
      //   });

      //   const newStructure = [mapNode(root)];

      //   // Move node to new parent
      //   const parentNode = findPage(newStructure, newParent);
      //   if (!parentNode) return;

      //   const movedNodeData = findPage(newStructure, movedNode.id);
      //   if (!movedNodeData) return;

      //   const movedNodeIndex = parentNode.children.findIndex((child) => child.id === movedNodeData.id);
      //   if (movedNodeIndex !== -1) {
      //     parentNode.children.splice(movedNodeIndex, 1);
      //   }
      //   parentNode.children.splice(newIndex, 0, movedNodeData);

      //   // Update the state with the new structure
      //   setTempBriefing((prev) => ({
      //     ...prev,
      //     meeting_feedback: {
      //       ...prev?.meeting_feedback,
      //       WebsiteStructure: newStructure,
      //     },
      //   }));
      // };

      component = (
        <div
          className="text-start p-3 mb-3"
          style={{
            backgroundColor: 'white',
          }}
        >
          <Tree
            data={websiteStructure}
            // onMove={onMove} TODO: Disabled, see note above.
            disableDrag={true}
            width={500}
            height={200}
          >
            {Node}
          </Tree>
        </div>
      );
      break;
    }
    case 'Main Google Keyword Phrases':
      component = (
        <div
          className="p-3 mb-3"
          style={{
            backgroundColor: 'white',
          }}
        >
          <div
            className="text-start"
            style={{
              columnCount: 2,
              columnGap: '20px',
            }}
          >
            {tempBriefing.ai_content?.GoogleKeywords?.map((keyword, index) => {
              const isSelected = tempBriefing.info?.google_keywords_selected?.includes(keyword) || false;

              return (
                <div key={index} className="keyword-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="me-2"
                      checked={isSelected}
                      onChange={() => {
                        const currentSelected = [...(tempBriefing.info?.google_keywords_selected || [])];

                        if (isSelected) {
                          // Remove keyword if already selected
                          const updatedKeywords = currentSelected.filter(k => k !== keyword);
                          setTempBriefing({
                            ...tempBriefing,
                            info: {
                              ...tempBriefing.info,
                              google_keywords_selected: updatedKeywords,
                            },
                          });
                        } else {
                          // Add keyword if not selected
                          setTempBriefing({
                            ...tempBriefing,
                            info: {
                              ...tempBriefing.info,
                              google_keywords_selected: [...currentSelected, keyword],
                            },
                          });
                        }
                      }}
                    />
                    {keyword}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                const newKeyword = prompt('Keyword:');
                if (newKeyword && newKeyword.trim() !== '') {
                  // Make sure the arrays exist before modifying them
                  const currentKeywords = [...(tempBriefing.ai_content?.GoogleKeywords || [])];
                  const currentSelected = [...(tempBriefing.info?.google_keywords_selected || [])];

                  // Update the state with the new keyword
                  setTempBriefing({
                    ...tempBriefing,
                    ai_content: {
                      ...tempBriefing.ai_content,
                      GoogleKeywords: [...currentKeywords, newKeyword],
                    },
                    info: {
                      ...tempBriefing.info,
                      google_keywords_selected: [...currentSelected, newKeyword],
                    },
                  });
                }
              }}
            >
              Add a new keyword
            </button>
          </div>
        </div>
      );
      break;
    case 'Business Owner Quotes':
      component = (
        <div
          className="p-3 mb-3"
          style={{
            backgroundColor: 'white',
            maxHeight: '220px',
            overflowY: 'auto',
          }}
        >
          {tempBriefing.ai_content?.ClientSatisfaction?.map((quote, index) => (
            <div key={index} className="mb-3">
              <div className="row">
                <div className="col-10">
                  <textarea
                    className="form-control"
                    value={quote}
                    onChange={(e) => {
                      const updatedQuotes = [...(tempBriefing.ai_content?.ClientSatisfaction || [])];
                      updatedQuotes[index] = e.target.value;

                      setTempBriefing({
                        ...tempBriefing,
                        ai_content: {
                          ...tempBriefing.ai_content,
                          ClientSatisfaction: updatedQuotes,
                        },
                      });
                    }}
                    rows={2}
                  />
                </div>
                <div className="col-2">
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      const updatedQuotes = [...(tempBriefing.ai_content?.ClientSatisfaction || [])];
                      updatedQuotes.splice(index, 1);

                      setTempBriefing({
                        ...tempBriefing,
                        ai_content: {
                          ...tempBriefing.ai_content,
                          ClientSatisfaction: updatedQuotes,
                        },
                      });
                    }}
                  >
                    <i className="fa fa-trash"></i>
                    {' '}
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                const currentQuotes = [...(tempBriefing.ai_content?.ClientSatisfaction || [])];

                setTempBriefing({
                  ...tempBriefing,
                  ai_content: {
                    ...tempBriefing.ai_content,
                    ClientSatisfaction: [...currentQuotes, ''],
                  },
                });
              }}
            >
              Add a new quote
            </button>
          </div>
        </div>
      );
      break;
    case 'Calls To Action': {
      const ctas = [
        'Call us',
        'Text us',
        'Chat with our chatbot',
        'Contact us',
        'Get a quote',
        'Book a consultation',
        'Contact us for "reason here"',
        'Arrange a site visit',
      ];

      component = (
        <div
          className="p-3 mb-3"
          style={{
            backgroundColor: 'white',
          }}
        >
          <div
            className="text-start"
            style={{
              columnCount: 3,
              columnGap: '20px',
            }}
          >
            {ctas.map((cta, index) => (
              <div key={index} className="keyword-checkbox">
                <label>
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={tempBriefing.meeting_feedback?.calls_to_action_selected?.includes(cta)}
                    onChange={() => {
                      const currentCtas = tempBriefing.meeting_feedback?.calls_to_action_selected || [];
                      if (currentCtas.includes(cta)) {
                        // Remove cta if already selected
                        const updatedCtas = currentCtas.filter((item) => item !== cta);
                        setFeedback('calls_to_action_selected', updatedCtas);
                      } else {
                        // Add cta if not selected
                        setFeedback('calls_to_action_selected', [...currentCtas, cta]);
                      }
                    }}
                  />
                  {cta}
                </label>
              </div>
            ))}
          </div>

          <div className="mt-3">
            <input
              type="text"
              className="form-control mb-1"
              placeholder="How quickly can you get back to clients?"
              value={tempBriefing.meeting_feedback?.how_quickly || ''}
              onChange={(e) => setFeedback('how_quickly', e.target.value)}
            />
            <input
              type="text"
              className="form-control mb-1"
              placeholder="Any extra form fields required?"
              value={tempBriefing.meeting_feedback?.extra_form_fields || ''}
              onChange={(e) => setFeedback('extra_form_fields', e.target.value)}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Other feedback"
              value={tempBriefing.meeting_feedback?.cta_other_feedback || ''}
              onChange={(e) => setFeedback('cta_other_feedback', e.target.value)}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      component = (
        <textarea
          className={`form-control ${currentPage}`}
          ref={(ref) => textareaRefs.current[currentPage] = ref}
          placeholder="Enter your feedback"
          value={tempBriefing.meeting_feedback?.[pages[currentPage - 1]] || ''}
          onChange={(e) => setFeedback(pages[currentPage - 1], e.target.value)}
          rows={10}
        />
      );
  }

  return (
    <div className="bottom">
      {component}

      <div>
        <select
          style={{
            marginRight: '10px',
            maxWidth: '200px',
          }}
          onChange={(e) => {
            const selectedPage = parseInt(e.target.value, 10);
            setCurrentPage(selectedPage);
          }}
        >
          {pages.map((page, index) => (
            <option
              key={index}
              value={index + 1}
              selected={currentPage === index + 1}
            >
              {page}
            </option>
          ))}
        </select>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          style={{ marginRight: '10px' }}
          disabled={currentPage <= 1}
        >
          Previous Page
        </button>

        <button
          onClick={save}
          style={{ marginLeft: '10px', marginRight: '10px' }}
        >
          Save
        </button>

        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= pages.length}
        >
          Next Page
        </button>
        {singleWindow && (
          <button
            onClick={() => toggleFullScreen()}
            style={{ marginLeft: '10px' }}
          >
            {isInFullScreen()
              ? (
                  <i className="fal fa-compress" />
                )
              : (
                  <i className="fal fa-expand" />
                )}
          </button>
        )}
      </div>
    </div>
  );
};

export default PMMeetingForm;
