import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { createSlice } from '@reduxjs/toolkit';
import { get, isString, isArray } from 'lodash-es';
import { createStore } from '../../rootStore';
import { getCookie } from '../../Utils';
import { setPageData } from './commonSlice';

const baseQueryWithSpinner: BaseQueryFn<
  string | { url: string; method?: string; body?: any }, // args
  unknown, // result
  FetchBaseQueryError, // error
  { modelClass?: (new (object) => any) }, // extraOptions
  any // meta
> = async (args, api, extraOptions) => {
  const store = createStore();

  const { pageData: { admin_domain } } = store.getState();

  const adminDomain = admin_domain ? `https://${admin_domain}` : '';

  // We have a tricky situation with the spinner... we want to be able to disable the spinner for some operations. Usually that's easy, we just pass disableSpinner in the args. But if a mutation triggers a cache invalidation then there is no way to disable the spinner on the resulting GET request. We are currently looking at the "forced" variable, because this is always true for GET requests that result from a cache invalidation. We are not currently using "forceRefetch", but if we ever do then these requests will always have the spinner disabled, and we will need to re-think how we do this.
  // 2024-11-01 Update: It turns out that lazy queries have the "forced" property set, so lazy queries will never trigger the spinner. We now have the ridiculous situation of having a disableSpinner and an enableSpinner flag. The enable flag overrides the disable flag. Lazy queries are always queries, never mutations, so we put the flag in the URL.
  let doSpinner = false;

  if (isString(args) && args.match(/enable_spinner=true/)) {
    doSpinner = true;
  } else if (get(args, 'disableSpinner') !== true && api.forced !== true) {
    doSpinner = true;
  }

  if (doSpinner) {
    store.dispatch(spinCountInc());
  }

  // The X-User-Token is actually only used by the logout route, but there's no harm in adding it to all requests.
  const token = getCookie('USER_TOKEN');
  const headers = {};
  if (token) {
    headers['X-User-Token'] = token;
  }

  const result = await fetchBaseQuery({
    baseUrl: `${adminDomain}/api/v1`,
    credentials: 'include',
    headers,
  })(args, api, extraOptions);

  if (doSpinner) {
    store.dispatch(spinCountDec());
  }

  const resultData = result.data as { data: any };

  if (extraOptions?.modelClass) {
    if (result.error) {
      return result;
    }

    if (isArray(resultData?.data)) {
      return {
        data: {
          data: resultData.data.map((d) => extraOptions.modelClass ? new extraOptions.modelClass(d) : d),
          meta: resultData,
        },
      };
    }

    return {
      data: {
        data: resultData ? new extraOptions.modelClass(resultData?.data) : null,
        meta: resultData,
      },
    };
  }

  if (result.error) {
    return result;
  }

  return {
    data: {
      data: resultData?.data,
      meta: resultData,
    },
  };
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithSpinner,
  tagTypes: [
    'instance',
    'site',
    'domain',
    'text_style',
    'button_style',
    'briefing',
    'sector',
    'section',
    'section_html',
    'tooltip',
    'page',
    'page_list',
    'orphan_page',
    'master_page',
    'page_version',
    'template',
    'url',
    'custom_code',
    'database_field',
    'database_search_field',
    'component_category',
    'page_data',
    'role',
    'user',
    'recent_item',
    'cta_log',
    'email_series',
    'saved_search',
    'member',
    'style_override',
    'job',
    'trash',
    'component',
    'backend_error_logs',
    'content_briefing',
    'page_url',
    'template_fragment',
    'file',
    'saved_report',
    'roi_report_site',
    'xero_connection',
    'blogs',
  ],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getPageData: builder.query({
      query: (params: { siteId: string | number; pageId: string | number; pageVersionId: string | number }) => `/sites/${params?.siteId}/pages/${params?.pageId}/page_versions/${params?.pageVersionId}/objects`,
      providesTags: ['page_data'],
    }),
  }),
});

export const refetchPageData = async () => {
  const store = createStore();
  const { pageData: { page, page_version: pageVersion, site } } = store.getState();
  const res = await store.dispatch(apiSlice.endpoints.getPageData.initiate({ siteId: site?.id, pageId: page?.id, pageVersionId: pageVersion?.id }));
  if (undefined !== res) {
    store.dispatch(setPageData(res?.data?.data));
  }
};

const initialStateSpinner = {
  spinCount: 0,
};

export const spinSlice = createSlice({
  name: 'spin',
  initialState: initialStateSpinner,
  reducers: {
    spinCountInc: (state) => {
      state.spinCount += 1;
    },
    spinCountDec: (state) => {
      state.spinCount -= 1;
    },
  },
});

export const { spinCountInc, spinCountDec } = spinSlice.actions;

export const windowSizeSlice = createSlice({
  name: 'windowSize',
  initialState: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  reducers: {
    setWindowSize: (state) => {
      state.width = window.innerWidth;
      state.height = window.innerHeight;
    },
  },
});

export const { setWindowSize } = windowSizeSlice.actions;

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: { menuHidden: false },
  reducers: {
    toggleSideBar: (state) => {
      state.menuHidden = !state.menuHidden;
    },
  },
});
export const { toggleSideBar } = sidebarSlice.actions;

export const searchTextSlice = createSlice({
  name: 'searchText',
  initialState: { text: '' },
  reducers: {
    setSearchText: (state, action) => {
      state.text = action.payload;
    },
  },
});
export const { setSearchText } = searchTextSlice.actions;
