import React from 'react';
import { Label, FormGroup, Table } from 'reactstrap';
import TextStyleListing from '../../common/textStyles';

function BlogTextStyles({ params, setParams }) {
  return (
    <FormGroup>
      <Label>Blog Text Styles</Label>

      <Table>
        <thead>
          <tr>
            <th>Element</th>
            <th>Style</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Blog Title
            </td>
            <td>
              <TextStyleListing
                style={params.blog_title_style_id}
                onChange={(e) => setParams({ ...params, blog_title_style_id: e.value })}
              />
            </td>
          </tr>
          <tr>
            <td>
              Post Title
            </td>
            <td>
              <TextStyleListing
                style={params.blog_post_title_style_id}
                onChange={(e) => setParams({ ...params, blog_post_title_style_id: e.value })}
              />
            </td>
          </tr>
          <tr>
            <td>
              Post Body
            </td>
            <td>
              <TextStyleListing
                style={params.blog_body_style_id}
                onChange={(e) => setParams({ ...params, blog_body_style_id: e.value })}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </FormGroup>
  );
}

export default BlogTextStyles;
