import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Row, Col, Alert } from 'reactstrap';
import { setPageTitle } from '../../../../../../../Utils';
import { displaySuccess } from 'Dashboard/src/Utils';
import HeaderComponent from '../../../../../components/common/header-component';
import { settingRoutes, replacePathParams, siteCenter } from '../../../../../constants/routes';
import SaveAndContinue from '../../../../../components/common/saveAndContinue';
import BottomActionToolbar from '../../../../../components/common/toolbar';
import withRouter from '../../../../../helpers/withRouter';
import FragmentCodeEditor from '../code-editor';
import { addRecentItem } from 'Dashboard/src/Utils';
import {
  useGetTemplateFragmentByIdQuery,
  useTemplateFragmentCreateMutation,
  useTemplateFragmentUpdateMutation,
} from '../../../../../api/dashboardApiSlice';
import { cloneDeep } from 'lodash-es';

function TemplateFragmentRoot(props) {
  const { params: { siteId, id, instanceId } } = props;
  const navigate = useNavigate();
  const [errorVal, setError] = useState('');

  const [getEditorError, setEditorError] = useState(null);

  const [params, setParams] = useState({
    id: null,
    name: '',
    html: '',
    site_id: siteId,
    instance_id: instanceId,
  });

  const paramsTemp = { ...params };

  const { data } = useGetTemplateFragmentByIdQuery({ id, siteId, instanceId }, { skip: !id || !siteId });

  const [fragmentCreate] = useTemplateFragmentCreateMutation();
  const [fragmentUpdate] = useTemplateFragmentUpdateMutation();

  useEffect(() => {
    if (data) {
      setParams(cloneDeep(data));
    }
  }, [data]);

  useEffect(() => {
    if (id) {
      setPageTitle('Update fragment');
    } else {
      setPageTitle('Create fragment');
    }
  }, [id]);

  const onHandleKeyPress = (event) => {
    const inputValue = event.which;
    if (
      (inputValue >= 65 && inputValue <= 90) || (inputValue === 45)
      || (inputValue >= 48 && inputValue <= 57) || (inputValue === 95)
      || (inputValue >= 97 && inputValue <= 122)) {
      return true;
    }
    return event.preventDefault();
  };

  const onFileNameChange = (e) => {
    paramsTemp.name = e.target.value;
    setParams(paramsTemp);
    setError('');
  };

  const getEditorData = (data) => {
    paramsTemp.html = `${data}`;
    setParams(paramsTemp);
  };

  const validateEditorError = (error) => {
    const filterError = error.filter((item) => item.type !== 'info');
    if (filterError.length >= 1) {
      setEditorError(filterError);
    } else {
      setError('');
      setEditorError(null);
    }
  };

  const onHandleSubmit = async () => {
    if (!params.name) {
      setError(<span>File name should not be blank!</span>);
    } else if (!params.html) {
      setError(<span>Code editor should not be blank!</span>);
    } else if (getEditorError !== null) {
      const getErrors = getEditorError.map(
        (item, idx) => <span key={idx}>{`Line ${item.row + 1} Column  ${item.column}, ${item.text}`}</span>,
      );
      setError(getErrors);
    } else {
      setError('');
      let response = null;
      if (id) {
        response = await fragmentUpdate(params);
      } else {
        response = await fragmentCreate(params);
        if (response && response?.data?.meta?.is_success) {
          navigate(replacePathParams(
            siteCenter.editTemplateFragment,
            [
              { key: 'id', value: response?.data?.data?.id },
            ], props,
          ));
        }
      }
      if (response && response?.data?.meta?.is_success) {
        displaySuccess(response?.data?.meta?.messages);
      }
    }
  };

  const setHeading = id ? 'Edit Fragment' : 'Add New Fragment';

  addRecentItem({
    instance_id: instanceId,
    type: id ? 'edit-fragment' : 'create-fragment',
    item_id: id,
  });

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    {
      name: 'Template Fragments',
      url: replacePathParams(siteCenter.templateFragmentList, [], props),
    },
    { name: `${params.name || ''}`, url: '' },
  ];
  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: `${setHeading}`,

        }}
      />
      {errorVal ? <Alert color="danger" className="custom-alert-span">{errorVal}</Alert> : ''}
      <Row>
        <Col className="col-sm-10">
          <Input
            autoFocus
            placeholder="Enter title here"
            value={params.name || ''}
            onKeyPress={onHandleKeyPress}
            onChange={onFileNameChange}
            // onBlur={onBlurAddPrefix}
          />
        </Col>
        <Col className="col-sm-2 text-end">
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.templateFragmentList, [], props)}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="col-sm-12">
          <FragmentCodeEditor
            editorData={getEditorData}
            validateEditor={validateEditorError}
            defaultComponentValue={params.html}
          />
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.templateFragmentList, [], props)}
          />
        )}
      />
    </>
  );
}
export default withRouter(TemplateFragmentRoot);
