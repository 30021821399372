import React, { useEffect, useState } from 'react';
import { setPageTitle } from '../../../../../../Utils';
import { displaySuccess } from 'Dashboard/src/Utils';
import HeaderComponent from '../../../../components/common/header-component';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../../constants/routes';
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label, Badge } from 'reactstrap';
import withRouter from '../../../../helpers/withRouter';
import { isEmpty } from 'lodash-es';
import BottomActionToolbar from '../../../../components/common/toolbar';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import confirm from '../../../../components/common/confirm';
import UserFeaturePage from '../../../../components/common/user-feature-page';
import {
  useGetComponentsQuery,
  useDeleteComponentMutation,
  useGetComponentCategoryQuery,
  useUpdateComponentCategoryMutation,
  useDuplicateComponentMutation,
} from '../../../../api/dashboardApiSlice';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function ComponentList(props) {
  const { params: { categoryId, instanceId, siteId }, navigate } = props;
  const [categoryName, setCategoryName] = useState('');

  const { data: components = [] } = useGetComponentsQuery({ component_category_id: categoryId }, { skip: isEmpty(categoryId) });
  const { data: category } = useGetComponentCategoryQuery(categoryId, { skip: isEmpty(categoryId) });
  const [updateCategory] = useUpdateComponentCategoryMutation();
  const [deleteComponent] = useDeleteComponentMutation();
  const [duplicateComponent] = useDuplicateComponentMutation();

  useEffect(() => {
    setPageTitle('Components');
  }, []);

  useEffect(() => {
    if (!category) {
      return;
    }

    setCategoryName(category.name);
  }, [category]);

  const onEditAction = (component) => {
    navigate(replacePathParams(
      settingRoutes.componentEdit,
      [
        { key: 'instanceId', value: instanceId },
        { key: 'siteId', value: siteId },
        { key: 'categoryId', value: component.component_category_id },
        { key: 'componentId', value: component.id },
      ], props,
    ));
  };

  const onSubmit = async () => {
    const result = await updateCategory({
      ...category,
      name: categoryName,
    });

    const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = result;
    if (isSuccess) {
      displaySuccess(messages);
    }
  };

  const addNewComponent = () => {
    navigate(replacePathParams(
      settingRoutes.componentCreate,
      [
        { key: 'categoryId', value: categoryId },
      ], props,
    ));
  };

  const onDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const res = await deleteComponent({ id, instance_id: instanceId });
      const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = res;
      if (isSuccess) {
        displaySuccess(messages);
      }
    }
  };

  const onDuplicate = async (id) => {
    const res = await duplicateComponent(id);
    const { data: { meta: { is_success: isSuccess, messages } = {} } = {} } = res;
    if (isSuccess) {
      displaySuccess(messages);
    }
  };

  const tableColumns = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row: { original: c } }) => (
        <>
          { c.published ? <Badge color="success" className="me-2">PUBLISHED</Badge> : '' }
          { !c.published ? <Badge color="warning" className="me-2">DRAFT</Badge> : '' }
          { c.deprecated ? <Badge color="danger" className="me-2">DEPRECATED</Badge> : '' }
        </>
      ),
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => (
        <>
          <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditAction(row.original)}>
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => onDuplicate(row.original.id)}
          >
            <i className="fal fa-copy" />
          </Button>
          {' '}
          <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onDelete(row.original.id)}>
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    {
      name: 'Component Categories',
      url: replacePathParams(settingRoutes.componentCategoryList, [], props),
    },
    { name: category?.name, url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: category?.name,
          addNewBtnName: '',
          addNewPath: '',
        }}
      />
      <UserFeaturePage feature="manage_components">
        <Card className="mb-4">
          <CardBody>
            <CardTitle>Category</CardTitle>
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                placeholder="Category name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </FormGroup>
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardBody>
            <CardTitle>Components</CardTitle>
            <ReactTable8
              columns={tableColumns}
              data={components}
            />
          </CardBody>
        </Card>
        <BottomActionToolbar
          component={(
            <>
              <Button color="info" onClick={() => addNewComponent()}>NEW COMPONENT</Button>
              {' '}
              <SaveAndContinue
                onSave={onSubmit}
                onContinue={replacePathParams(settingRoutes.componentCategoryList, [], props)}
              />
            </>
          )}
        />
      </UserFeaturePage>
    </>
  );
}

export default withRouter(ComponentList);
