import React from 'react';
import { Label, FormGroup, Input } from 'reactstrap';

function BlogPostLayout({ params, setParams }) {
  return (
    <FormGroup>
      <Label>Blog Post Page Layout</Label>

      <Input
        type="select"
        value={params.blog_page_layout}
        onChange={(e) => setParams({ ...params, blog_page_layout: e.target.value })}
      >
        <option value="layout_1">Layout 1</option>
        {/* <option value="layout_2">Layout 2</option> */}
      </Input>
    </FormGroup>
  );
}

export default BlogPostLayout;
