import React, { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useGetPageSectionsQuery } from '../../../api/dashboardApiSlice';
import CustomSelect, { allOption } from '../../common/custom-select';
import { getSelectedOption, selectColorStyles } from '../../../../../Utils';

function SectionBox(props) {
  const {
    onChange, selectedPageId, value, allowSelectAll, isMulti,
  } = props;
  const { instanceId, siteId, pageId } = props.params || {};

  const { data: sections, refetch } = useGetPageSectionsQuery(
    { instanceId, siteId, pageId: selectedPageId || pageId },
    { skip: isEmpty(siteId) && isEmpty(pageId) },
  );

  useEffect(() => {
    if (selectedPageId) {
      refetch();
    }
  }, [selectedPageId]);

  if (isEmpty(sections)) {
    return null;
  }

  const sectionOptions = sections.map((opt) => {
    let labelName = '';
    const sectorName = `${opt.sector_name} >> ${opt.section_name}`;

    if (!opt.content) {
      labelName = `${sectorName}: (Empty)`;
    } else if (!opt.is_offline && opt.content) {
      labelName = `${sectorName}: ${opt.content}`;
    } else {
      labelName = `${sectorName}: (Offline)`;
    }
    return (
      {
        label: labelName,
        value: opt.id,
        column_section_id: opt.column_section_id,
      }
    );
  });

  return (
    <CustomSelect
      {...props}
      allOption={allowSelectAll ? allOption : []}
      styles={selectColorStyles}
      value={isMulti ? value : getSelectedOption(sectionOptions, value)}
      placeholder="Select Section"
      hideSelectedOptions={false}
      options={sectionOptions}
      onChange={onChange}
    />
  );
}
export default SectionBox;
