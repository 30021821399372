import React, { useState, useEffect, Fragment } from 'react';
import {
  Button,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import Select from 'react-select';
import { getSelectedOption, selectColorStyles } from '../../../../Utils';
import HeaderComponent from '../../components/common/header-component';
import { dataCenter, settingRoutes, mainRoutes, replacePathParams } from '../../constants/routes';
import './index.scss';
import BottomActionToolbar from '../../components/common/toolbar';
import SaveAndContinue from '../../components/common/saveAndContinue';
import { DBFieldTypeOptions } from '../../../../common/schema';
import { useParams } from 'react-router';
import CheckBox from '../../components/common/checkBox';
import withRouter from '../../helpers/withRouter';
import { useGetDatabaseFieldQuery, useUpdateDatabaseFieldMutation } from '../../api/dashboardApiSlice';
import { isEmpty, get } from 'lodash-es';

function EditDataField(props) {
  const { id, instanceId } = useParams();
  const [params, setParams] = useState({});

  const { data } = useGetDatabaseFieldQuery({ field_id: id, instance_id: instanceId }, { skip: isEmpty(id) || isEmpty(instanceId) });
  const [updateDatabaseField] = useUpdateDatabaseFieldMutation();

  useEffect(() => {
    if (data) {
      setParams(data);
    }
  }, [data]);

  const fieldName = get(params, 'attributes.field_name', '');
  const shortName = get(params, 'attributes.short_name', '');
  const macro = get(params, 'attributes.macro', '');
  const fieldType = get(params, 'attributes.field_type', '');
  const values = get(params, 'attributes.values', []);
  const showResultPage = get(params, 'attributes.show_on_data_search_result_page', false);

  const onFieldTypeChange = (option) => {
    setParams((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        field_type: option.value,
      },
    }));
  };

  const onHandleChange = (e) => {
    const { name, value } = e.currentTarget;
    setParams((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        [name]: value,
      },
    }));
  };

  const onHandleValueChange = (e, index) => {
    const { value } = e.target;
    const updatedArray = [...values];
    updatedArray[index] = value;
    setParams((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        values: updatedArray,
      },
    }));
  };

  const removeValues = (e, index) => {
    const updatedArray = [...values];
    updatedArray.splice(index, 1);
    setParams((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        values: updatedArray,
      },
    }));
  };

  const valuesListing = (field_type) => {
    let placeholderName = '';
    if (field_type === 'select_list') {
      placeholderName = 'Option name';
    } else if (field_type === 'checkboxes') {
      placeholderName = 'Checkbox label name';
    } else if (field_type === 'radio_button') {
      placeholderName = 'Radio name';
    }

    return values.map((val, index) => (
      <FormGroup key={index}>
        <li className="dropdown-options-li">
          <Input
            type="text"
            placeholder={`${placeholderName}`}
            value={`${val || ''}`}
            onChange={(e) => onHandleValueChange(e, index)}
          />
          <Button
            align="right"
            color="danger"
            className="custom-simple-icon btn btn-info btn-xs"
            onClick={(e) => removeValues(e, index)}
          >
            <i className="fal fa-minus-circle" />
          </Button>
        </li>
      </FormGroup>
    ));
  };

  const addMoreValues = () => {
    setParams((prevState) => ({
      ...prevState,
      attributes: {
        ...prevState.attributes,
        values: [...prevState.attributes.values, ''],
      },
    }));
  };

  const renderFieldValues = () => {
    if (['select_list', 'radio_button', 'checkboxes'].includes(fieldType)) {
      return (
        <ul>
          {valuesListing(fieldType)}
          <Button
            align="right"
            color="primary"
            className="custom-simple-icon btn btn-info btn-xs"
            onClick={addMoreValues}
          >
            <i className="fal fa-plus-circle" />
          </Button>
        </ul>
      );
    }
    return null;
  };

  const onHandleSubmit = async () => {
    const getArrayData = values.filter((data) => data.length > 0);
    const sendParams = {
      id,
      instance_id: instanceId,
      field_name: fieldName,
      short_name: shortName,
      macro,
      field_type: fieldType,
      values: getArrayData,
      show_on_data_search_result_page: showResultPage,
    };
    await updateDatabaseField(sendParams);
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    {
      name: 'Database Fields',
      url: replacePathParams(dataCenter.databaseFields, [], props),
    },
    { name: 'Edit Database Field', url: '' },
  ];
  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Edit Database Field',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: replacePathParams(dataCenter.databaseFields, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Row>
        <Col lg={9} sm={12}>
          <Card body>
            <Form>
              <FormGroup>
                <Label for="fieldName">Field Name</Label>
                <Input
                  autoFocus
                  type="text"
                  name="field_name"
                  id="fieldName"
                  value={`${fieldName}`}
                  onChange={onHandleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="shortName">Short Name</Label>
                <Input
                  type="text"
                  name="short_name"
                  id="shortName"
                  value={`${shortName}`}
                  onChange={onHandleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="macro">Macro</Label>
                <Input
                  type="text"
                  name="macro"
                  id="macro"
                  value={macro}
                  onChange={onHandleChange}
                />
              </FormGroup>
              <Label className="form-group has-top-label">
                Field Type:
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={getSelectedOption(DBFieldTypeOptions, fieldType)}
                  options={DBFieldTypeOptions}
                  onChange={onFieldTypeChange}
                  styles={selectColorStyles}
                />
              </Label>
              {renderFieldValues()}
            </Form>
          </Card>
        </Col>
        <Col lg={3} sm={12}>
          <div className="custom-right-sidebar-box">
            <div className="custom-sidebar-bg mb-4">
              <p><b>Options</b></p>
              <CheckBox
                title="Show on Data Centre Search Results Page?"
                checked={showResultPage}
                onChange={(e) => setParams((prevState) => ({
                  ...prevState,
                  show_on_data_search_result_page: e.target.checked,
                }))}
              />
            </div>
          </div>
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={() => onHandleSubmit()}
            onContinue={replacePathParams(dataCenter.databaseFields, [], props)}
          />
        )}
      />
    </>
  );
}
export default withRouter(EditDataField);
