import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { isEmpty, get } from 'lodash-es';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import CustomButton from '../../components/common/button';
import confirm from '../../components/common/confirm';
import withRouter from '../../helpers/withRouter';
import getCTAFormFields from './getCTAFormFields';
import { useInstance, useIsMobile } from '../../../../common/hooks';
import { useGetSpamCtaLogsQuery, useDeleteCtaLogMutation, useMarkCtaLogNotSpamMutation } from '../../api/dashboardApiSlice';
import CTATable8 from './CTATable8';

const assignReactKeyToChildren = (d) => React.Children.toArray(d);

function CtaReport(props) {
  const {
    dataTableColumns,
    subRow,
  } = props;

  const [startDate, _setStartDate] = useState(DateTime.now().minus({ days: 30 }).toISO());
  const [endDate, _setEndDate] = useState(DateTime.now().toISO());

  let sendParams = {
    start_date: startDate,
    end_date: endDate,
    page: 1,
    page_size: 20,
    order_by: 'created_at',
    order_direction: 'desc',
  };

  const { data } = useGetSpamCtaLogsQuery(sendParams);

  const ctaLogs = get(data, 'cta_log', []);

  return (
    <CTATable8
      subRow={subRow}
      data={ctaLogs}
      showColumnListTh
      columns={dataTableColumns}
    />
  );
}

function CtaReportSpamFormSubmission() {
  const [deleteCtaLog] = useDeleteCtaLogMutation();
  const [markCtaLogNotSpam] = useMarkCtaLogNotSpamMutation();

  const instance = useInstance();

  const onClickNotSpam = async (log) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure this is NOT spam?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      markCtaLogNotSpam({ id: log.id, instance_id: instance.id });
    }
  };

  const onHandleDelete = async (log) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure you want to delete this submission?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      deleteCtaLog({ id: log.id, instance_id: instance.id });
    }
  };

  const isMobile = useIsMobile();

  const tableColumns = [
    {
      id: 'Date',
      header: 'Date',
      cell: ({ row }) => {
        const d = row.original;
        return (
          <Button
            onClick={() => {}}
            disabled={d.type === 'CTALogOutboundClick'}
            className="custom-simple-txt-btn cta-log-more-info"
          >
            <DateTimeFormat
              datetime={get(d, 'created_at', '')}
              localeString={DateTime.DATETIME_SHORT}
            />
          </Button>
        );
      },
    },
    {
      header: 'Site',
      accessorKey: 'site.hostname',
      display: !isMobile,
    },
    {
      id: 'Actions',
      header: 'Actions',
      cell: ({ row }) => {
        const log = row.original;
        return (
          <>
            <CustomButton
              size="xs"
              title=" "
              color="success"
              icon="fal fa-thumbs-up"
              className="custom-simple-icon"
              onClick={() => onClickNotSpam(log)}
            />
            {' '}
            <CustomButton
              size="xs"
              title=" "
              color="danger"
              icon="fal fa-trash"
              className="custom-simple-icon"
              onClick={() => onHandleDelete(log)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <CtaReport
        dataTableColumns={tableColumns}
        subRow={(columns, row) => (
          !isEmpty(row.data)
          && (
            <tr>
              <td colSpan={columns.length + 1}>
                {assignReactKeyToChildren(
                  row.data.map((el, index) => (
                    <span
                      className="me-2"
                      key={el.id || `data-${index}`}
                    >
                      {getCTAFormFields(el)}
                    </span>
                  )),
                )}
              </td>
            </tr>
          )
        )}
      />
    </>
  );
}

export default withRouter(CtaReportSpamFormSubmission);
