import React from 'react';
import { Card, CardBody } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import CtaReportSpamFormSubmission from 'Dashboard/src/views/cta-reports/ctaReportSpamFormSubmission';

function Test() {
  return (
    <Card>
      <CardBody>
        <h1>Spam Form Submissions</h1>
        <CtaReportSpamFormSubmission />
      </CardBody>
    </Card>
  );
}

export default withRouter(Test);
