import React, { useEffect, useState, useRef } from 'react';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { matchPath, useNavigate } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { uniqueId, map } from 'lodash-es';
import withRouter from '../../helpers/withRouter';
import IntlMessages from '../../helpers/IntlMessages';
import { useInstance, useIsMobile, useSite, useUser } from '../../../../common/hooks';
import { useGetRecentItemsQuery } from '../../api/dashboardApiSlice';
import { setSearchText } from '../../../../common/api/apiSlice';
import './index.scss';
import useMenuData from '../../constants/menu';
import { useDispatch } from 'react-redux';

function Sidebar(props) {
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState({ parentMenu: '', childMenu: '' });
  const [viewingParentMenu, setViewingParentMenu] = useState('');
  const [isSubMenuHidden, setIsSubHidden] = useState(true);
  const [subShowTemporary, setSubShowTemporary] = useState(false);
  const [isMenuMobile, setIsMenuMobile] = useState(false);
  const [collapsedMenus, setCollapsedMenus] = useState([]);
  const user = useUser();
  const instance = useInstance();
  const { data: recentItems } = useGetRecentItemsQuery({ userId: user?.id, instanceId: ('' + instance?.id) }, { skip: !user || !instance });
  const { setMainShowTemporary, menuRef, mainShowtemporary } = props;
  const location = useLocation();
  const { pathname } = location;
  const containerRef = useRef();
  const site = useSite();
  const dispatch = useDispatch();

  const siteId = site?.id;

  const menuItems = useMenuData(props, user, recentItems, siteId);

  const isMobile = useIsMobile();

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && menuRef.current && !menuRef.current.contains(event.target)
        && !containerRef.current.contains(event.target)) {
        setIsSubHidden(true);
        setMainShowTemporary(false);
        setSubShowTemporary(false);
      }
    }
    if (!isSubMenuHidden || subShowTemporary || mainShowtemporary) {
      ['click', 'touchstart', 'touchend'].forEach(
        (event) => document.addEventListener(event, handleClickOutside, true),
      );
      return () => {
        ['click', 'touchstart', 'touchend'].forEach(
          (event) => document.removeEventListener(event, handleClickOutside, true),
        );
      };
    }
    return () => {

    };
    // return null;
  }, [containerRef, isSubMenuHidden, subShowTemporary, mainShowtemporary]);

  const handleWindowResize = (event) => {
    if (event && !event.isTrusted) {
      return;
    }
    if (isMobile) {
      setIsMenuMobile(true);
    } else {
      setIsMenuMobile(false);
      setMainShowTemporary(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const anyRoutesAreActive = (routes) => routes.some((r) => matchPath(
    { path: r, exact: true, strict: false },
    pathname,
  ));

  const setSelectedLiActive = () => {
    const oldli = document.querySelector('.sub-menu  li.active');
    if (oldli != null) {
      oldli.classList.remove('active');
    }

    const menuItemsIds = [];
    const subMenuItemsIds = [];

    let activeParent;
    let activeChild;

    menuItems.forEach((menuItem) => {
      if (!menuItem.sideNav) {
        return;
      }
      menuItemsIds.push(menuItem.id);
      if (menuItem.activeRoutes && anyRoutesAreActive(menuItem.activeRoutes)) {
        activeParent = menuItem.id;
      }
      if (menuItem && menuItem.subs) {
        menuItem.subs.forEach((subMenuItem) => {
          subMenuItemsIds.push(subMenuItem.id);
          if (subMenuItem.activeRoutes && anyRoutesAreActive(subMenuItem.activeRoutes)) {
            activeParent = menuItem.id;
            activeChild = subMenuItem.id;
          }
        });
      }
    });

    setSelectedMenu({
      parentMenu: activeParent,
      childMenu: activeChild,
    });

    setIsSubHidden(true);
    setSubShowTemporary(false);
  };

  useEffect(() => {
    if (user) {
      setSelectedLiActive();
    }
    window.scrollTo(0, 0);
  }, [pathname, user]);

  const openSubMenu = (e, menuItem) => {
    const selectedParent = menuItem.id;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    if (!hasSubMenu) {
      setSelectedMenu((prevState) => ({ ...prevState, parentMenu: selectedParent }));
      setViewingParentMenu(selectedParent);
    } else {
      const sidebarContainer = document.querySelector('.sidebar');
      e.preventDefault();
      if (sidebarContainer.classList.contains('menu-mobile')) {
        setSubShowTemporary(true);
      } else {
        setIsSubHidden(false);
      }
      setViewingParentMenu(selectedParent);
    }
  };

  const toggleMenuCollapse = (e, menuKey) => {
    e.preventDefault();

    if (collapsedMenus.indexOf(menuKey) > -1) {
      setCollapsedMenus(collapsedMenus.filter(x => x !== menuKey));
    } else {
      setCollapsedMenus([...collapsedMenus, menuKey]);
    }

    return false;
  };

  return (
    <div
      className={`sidebar ${isMenuMobile ? 'menu-mobile' : ''}
      ${isSubMenuHidden ? 'menu-sub-hidden' : ''}
      ${subShowTemporary ? 'sub-show-temporary' : ''} `}
      ref={containerRef}
      onMouseLeave={() => {
        setIsSubHidden(true);
        setMainShowTemporary(false);
        setSubShowTemporary(false);
      }}
    >
      <div className="main-menu">
        <div className="scroll">
          <Nav vertical className="list-unstyled">
            {menuItems
            && menuItems.filter((i) => i.sideNav).map((item) => (
              <NavItem
                key={uniqueId(item.id)}
                className={classnames({
                  active: selectedMenu.parentMenu === item.id,
                })}
                id={item.id}
                onClick={() => {
                  dispatch(setSearchText(''));

                  if (!item.subs) {
                    setMainShowTemporary(false);
                  }
                }}
              >
                {item.newWindow
                  ? (
                      <a
                        onClick={() => navigate(item.to)}
                        href={item.to}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className={item.icon} />
                        {' '}
                        {item.intl === false ? item.label : <IntlMessages id={item.label} />}
                      </a>
                    )
                  : (
                      <NavLink
                        to={item.to}
                        onMouseOver={(e) => {
                          if (item.subs) {
                            openSubMenu(e, item);
                          } else {
                            setIsSubHidden(true);
                          }
                        }}
                        data-flag={item.id}
                      >
                        <i className={item.icon} />
                        {' '}
                        {item.intl === false ? item.label : <IntlMessages id={item.label} />}
                      </NavLink>
                    )}
              </NavItem>
            ))}
          </Nav>
        </div>
      </div>

      <div className="sub-menu">
        <div className="scroll">
          {menuItems
          && menuItems.filter((i) => i.sideNav).map((item) => (
            <Nav
              key={item.id}
              className={classnames({
                'd-block':
                      (selectedMenu.parentMenu === item.id
                        && viewingParentMenu === '')
                        || viewingParentMenu === item.id,
              })}
              data-parent={item.id}
            >
              <>
                {item.subs
                && map(item.subs, (sub, index) => (
                  <NavItem
                    key={uniqueId(sub.id)}
                    active={
                      selectedMenu.childMenu === sub.id
                    }
                    onClick={() => {
                      dispatch(setSearchText(''));
                      setMainShowTemporary(false);
                    }}
                  >
                    {sub.newWindow
                      ? (
                          <NavLink
                            to={sub.to}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <i className={sub.icon} />
                            {' '}
                            {sub.intl === false ? sub.label : <IntlMessages id={sub.label} />}
                          </NavLink>
                        )
                      : sub.subs && sub.subs.length > 0
                        ? (
                            <>
                              <NavLink
                                className={collapsedMenus.indexOf(
                                  `${item.id}_${index}`,
                                ) === -1
                                  ? ''
                                  : 'collapsed'}
                                to={sub.to}
                                id={`${item.id}_${index}`}
                                onClick={(e) => {
                                  toggleMenuCollapse(e, `${item.id}_${index}`);
                                  dispatch(setSearchText(''));
                                }}
                              >
                                <i className={`fal fa-chevron-${collapsedMenus.indexOf(`${item.id}_${index}`) > -1 ? 'down' : 'right'}`} />
                                {' '}
                                {sub.intl === false ? sub.label : <IntlMessages id={sub.label} />}
                              </NavLink>

                              <Collapse
                                isOpen={
                                  collapsedMenus.indexOf(
                                    `${item.id}_${index}`,
                                  ) > -1
                                }
                              >
                                <Nav className="third-level-menu">
                                  {sub.subs.map((thirdSub, thirdIndex) => {
                                    return (
                                      <NavItem
                                        key={`${
                                          item.id
                                        }_${index}_${thirdIndex}`}
                                        onClick={() => dispatch(setSearchText(''))}
                                      >
                                        {thirdSub.newWindow
                                          ? (
                                              <a
                                                href={thirdSub.to}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                              >
                                                <i className={thirdSub.icon} />
                                                {' '}
                                                {thirdSub.intl === false ? thirdSub.label : <IntlMessages id={thirdSub.label} />}
                                              </a>
                                            )
                                          : (
                                              <NavLink to={thirdSub.to}>
                                                <i className={thirdSub.icon} />
                                                {' '}
                                                {thirdSub.intl === false ? thirdSub.label : <IntlMessages id={thirdSub.label} />}
                                              </NavLink>
                                            )}
                                      </NavItem>
                                    );
                                  })}
                                </Nav>
                              </Collapse>
                            </>
                          )
                        : (
                            <NavLink
                              to={sub.to}
                              onClick={() => {
                                setIsSubHidden(true);
                                setSubShowTemporary(false);
                              }}
                            >
                              <i className={sub.icon} />
                              {' '}
                              {sub.intl === false ? sub.label : <IntlMessages id={sub.label} />}
                            </NavLink>
                          )}
                  </NavItem>
                ))}
              </>
            </Nav>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
