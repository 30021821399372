/* eslint-disable @stylistic/operator-linebreak */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../../common/hooks';
import { cable, UserPresenceChannel } from 'Dashboard/src/Channels';
import './user-presence.scss';

function UserPresence() {
  const user = useUser();
  const { instanceId } = useParams();
  const [otherUsers, setOtherUsers] = useState([]);

  const documentIsActive = () => document.visibilityState === 'visible' && document.hasFocus();

  useEffect(() => {
    if (!instanceId || !user) return;

    const channel = new UserPresenceChannel();
    cable.subscribe(channel);

    channel.on('message', (users) => {
      setOtherUsers(users.filter((u) => u.id !== user.id));
    });

    const update = () => {
      if (documentIsActive()) {
        channel.online(instanceId);
      } else {
        channel.away(instanceId);
      }
    };

    window.addEventListener('focus', update);
    window.addEventListener('blur', update);
    document.addEventListener('visibilitychange', update);

    channel.online(instanceId);
  }, [user, instanceId]);

  // This list of colours was selected from the CSS colour list and filtered so that we only have colours that have
  // good contrast with white text.

  const colors = ('gray maroon red purple fuchsia green olive blue teal blueviolet brown cadetblue chocolate ' +
    'cornflowerblue crimson darkcyan darkgoldenrod darkgreen darkmagenta darkolivegreen darkorchid darkred ' +
    'darkslateblue darkslategray darkviolet deeppink dimgray dodgerblue firebrick forestgreen grey hotpink ' +
    'indianred lightcoral lightseagreen lightslategray magenta mediumorchid mediumpurple mediumseagreen ' +
    'mediumslateblue mediumvioletred olivedrab orangered orchid palevioletred peru rosybrown royalblue saddlebrown ' +
    'seagreen sienna slateblue slategray steelblue tomato rebeccapurple').split(' ');

  if (!instanceId || !user) {
    return <></>;
  }

  return (
    <div className="user-presence d-inline-block align-middle">
      {otherUsers.map((user) => {
        const parts = user.full_name.toUpperCase().split(/\s/);
        const initials = `${parts[0][0]}${parts[1] ? parts[1][0] : ''}`;

        // Getting the colour from the other user's ID so that it is always the same for each user.
        const backgroundColor = colors[parseInt(user.id, 16) % colors.length];

        const styles = { backgroundColor };

        if (!user.online) {
          styles.filter = 'opacity(0.25)';
        }

        if (user.avatar_url) {
          return <img key={user.id} src={user.avatar_url} alt={user.full_name} title={user.full_name} style={styles} />;
        }

        return (
          <div key={user.id} title={user.full_name} style={styles}>
            {initials}
          </div>
        );
      })}
    </div>
  );
}

export default UserPresence;
