import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  CardColumns,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import BottomActionToolbar from '../../../components/common/toolbar';
import { PhoneNumber, DisplayedPhoneNumber, TrackingPhoneNumbers } from '../../../components/fields/site';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import withRouter from '../../../helpers/withRouter';
import { useUpdateSiteMutation } from '../../../api/dashboardApiSlice';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { displayError } from 'Dashboard/src/Utils';
import { useInstance, useSite } from '../../../../../common/hooks';
import { get } from 'lodash-es';
import { addRecentItem } from 'Dashboard/src/Utils';
import './EditSite.scss';
function SiteSettingPhoneNumbers(props) {
  const [params, setParams] = useState({});

  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const [updateSite] = useUpdateSiteMutation();

  const formRef = useRef(null);

  useEffect(() => {
    if (site) {
      setParams(site);
    }
  }, [site]);

  const onSave = () => {
    if (!formRef.current.checkValidity() || formRef.current.querySelectorAll('.is-invalid').length > 0) {
      displayError('Please correct the highlighted errors.');
      formRef.current.reportValidity();
      formRef.current.querySelector('.is-invalid')?.scrollIntoView({ block: 'center' });
      return false;
    }

    updateSite({
      id: siteId,
      instance_id: instanceId,
      ...params,
    });

    return true;
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'settings-phone-number',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Phone Numbers', url: '' },
  ];

  return (
    <div className="EditSite">
      <HeaderComponent
        setPath={{
          headingName: 'Phone Numbers',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.siteList,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <UserFeaturePage feature="set_phone_numbers">
        <Form innerRef={formRef}>
          <CardColumns>
            <Card>
              <CardBody>
                <Container>
                  <Row>
                    <Col>
                      <PhoneNumber params={params} setParams={setParams} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DisplayedPhoneNumber params={params} setParams={setParams} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TrackingPhoneNumbers params={params} setParams={setParams} />
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </CardColumns>
        </Form>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSave}
              onContinue={replacePathParams(settingRoutes.siteList, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </div>
  );
}

export default withRouter(SiteSettingPhoneNumbers);
