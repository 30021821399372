import React, { useState, useEffect, ChangeEvent, Fragment, useRef } from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from 'Dashboard/src/constants/routes';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Collapse,
} from 'reactstrap';
import { addRecentItem } from 'Dashboard/src/Utils';
import { cloneDeep, isEmpty, debounce } from 'lodash-es';
import { useInstance, useSite } from 'common/hooks';
import HeaderComponent from '../../components/common/header-component';
import confirm from '../../components/common/confirm';
import CheckBox from 'Dashboard/src/components/common/checkBox';
import RadioButton from 'Dashboard/src/components/common/radioButton';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import {
  useGetContentBriefingQuery,
  useUpdateContentBriefingMutation,
  useGenerateTopicSuggestionsMutation,
  useGenerateContentTopicQuestionsMutation,
} from 'Dashboard/src/api/dashboardApiSlice';
import { default as ContentBriefing } from 'common/models/ContentBriefing';
import BottomActionToolbar from '../../components/common/toolbar';
import CustomButton from '../../components/common/button';
import SaveAndContinue from '../../components/common/saveAndContinue';
import './ContentBriefingForm.css';

// Automatically set textarea height depending on the number of lines.
const autoHeightTextArea = (e) => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
};

const AutoHeightTextArea = (
  { name, value, onChange, style }: { name: string; value: string; onChange: (e: ChangeEvent<HTMLInputElement>) => void; style?: any },
) => (
  <Input
    type="textarea"
    name={name}
    value={value}
    onChange={onChange}
    style={style}
  />
);

function ContentBriefingForm(props) {
  const { navigate } = props;
  const params = useParams();
  const instance = useInstance();
  const site = useSite();
  const { data: cb } = useGetContentBriefingQuery(
    {
      siteId: site?.id,
      id: params.id,
    },
    { skip: isEmpty(params.id) || !site },
  );

  const [editingCb, setEditingCb] = useState<ContentBriefing | null>(cb);
  const [topicGenerateOption, setTopicGenerateOption] = useState<string>('ai');
  const [blankPageCount, setBlankPageCount] = useState<number>(1);
  const [topics, setTopics] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [updateContentBriefing] = useUpdateContentBriefingMutation();
  const [generateTopicSuggestions] = useGenerateTopicSuggestionsMutation();
  const [generateContentTopicQuestions] = useGenerateContentTopicQuestionsMutation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cb && !editingCb) {
      setEditingCb(cb);

      setTimeout(() => {
        ref.current?.querySelectorAll('textarea').forEach((t) => {
          autoHeightTextArea({ target: t });
        });
      }, 1000);
    }
  }, [cb]);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.querySelectorAll('textarea').forEach((t) => {
      autoHeightTextArea({ target: t });
    });
  }, [editingCb, topicGenerateOption]);

  addRecentItem({
    instance_id: instance?.id,
    site_id: site?.id,
    type: 'content-center',
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditingCb((prev) => new ContentBriefing({
      ...prev,
      [name]: value,
    }));
  };

  const update = async () => {
    if (!editingCb) {
      return false;
    }

    const result = await updateContentBriefing(editingCb);
    if (result) {
      return true;
    }
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Content Centre',
      url: replacePathParams(contentCenterRoutes.contentCenter, [], props),
    },
    { name: 'Content Brief Form', url: '' },
  ];

  const setTopicField = (value, index, field) => {
    setEditingCb((prev) => {
      const newPages = cloneDeep(prev?.pages) || [];
      newPages[index][field] = value;
      return new ContentBriefing({
        ...prev,
        pages: newPages,
      });
    });
  };

  // Highlight the current section in the brief nav
  const scrollHandler = debounce(() => {
    const boxes = document.querySelectorAll('.anchor-box');
    document.querySelectorAll('.ContentBriefingForm nav a').forEach((a) => a.classList.remove('active'));

    for (let i = 0; i < boxes.length; i++) {
      const box = boxes[i];
      const rect = box.getBoundingClientRect();
      const top = rect.top - 200;
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - 200;
      const percentVisible = 5;

      const isInViewport = !(
        Math.floor(100 - (((top >= 0 ? 0 : top) / +-rect.height) * 100)) < percentVisible
        || Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
      );

      if (isInViewport) {
        const boxName = [...box.classList].find((c) => c.match(/anchor-box-/))?.replace(/anchor-box-/, '');
        const navLink = document.querySelector(`.ContentBriefingForm nav a[href="#${boxName}"]`);
        if (!navLink) {
          break;
        }
        navLink.classList.add('active');
        navLink.scrollIntoView({ block: 'nearest' });

        break;
      }
    }
  }, 100);

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);

    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  if (!cb || !editingCb) {
    return <></>;
  }

  return (
    <div className="ContentBriefingForm" ref={ref}>
      <HeaderComponent setPath={{
        headingName: 'Content Brief Form',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: statsCenterRoutes.contentCenter,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />

      <Navbar expand className="h-auto mb-4">
        <Collapse isOpen={true} navbar>
          <Nav className="ms-2 align-items-center flex-wrap" navbar>
            <NavItem>
              <NavLink href="#business-info">
                Business Info
              </NavLink>
            </NavItem>
            {editingCb.pages?.map((page, index) => (
              <NavItem key={index}>
                <NavLink href={`#page-${index + 1}`}>
                  {page.name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Navbar>

      <a className="anchor" id="business-info" />
      <Card className="mt-3 mb-4 rounded anchor-box anchor-box-business-info">
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Business Name</Label>
                <Input name="business_name" value={editingCb.business_name || ''} onChange={onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Location / Servicing area</Label>
                <AutoHeightTextArea
                  name="service_location"
                  value={editingCb.service_location || ''}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <RadioButton
                name="Generate Topic"
                title="Generate topics with AI"
                className="me-3"
                checked={topicGenerateOption === 'ai'}
                onChange={() => setTopicGenerateOption('ai')}
              />
              <RadioButton
                name="Generate Topic"
                title="Add topics manually"
                className="me-3"
                checked={topicGenerateOption === 'manual'}
                onChange={() => setTopicGenerateOption('manual')}
              />
            </Col>
          </Row>
          {topicGenerateOption === 'ai' && (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>What the business does</Label>
                    <AutoHeightTextArea
                      name="business_type"
                      value={editingCb.business_type || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Existing pages</Label>
                    <AutoHeightTextArea
                      name="existing_website_content"
                      value={editingCb.existing_website_content || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Missing pages and other page ideas</Label>
                    <AutoHeightTextArea
                      name="other_site_content"
                      value={editingCb.other_site_content || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Any seed keywords</Label>
                    <AutoHeightTextArea
                      name="target_keywords"
                      value={editingCb.target_keywords || ''}
                      onChange={onChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomButton
                    color="success"
                    className="common-success-button fw-bold"
                    title="GENERATE TOPICS"
                    onClick={async () => {
                      const result = await update();
                      if (result) {
                        const response = await generateTopicSuggestions(cb);
                        if (response) {
                          setTopics(response.data.data);
                        }
                      }
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  {topics.length > 0 && (
                    <CardTitle tag="h5" className="mt-4">Topics</CardTitle>
                  )}

                  {topics.map((topic) => (
                    <Fragment key={topic}>
                      <CheckBox
                        title={topic}
                        checked={selectedTopics.includes(topic)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedTopics((prev) => [...prev, topic]);
                          } else {
                            setSelectedTopics((prev) => prev.filter((t) => t !== topic));
                          }
                        }}
                      />
                      <br />
                    </Fragment>
                  ))}
                  {topics.length > 0 && (
                    <CustomButton
                      color="success"
                      className="common-success-button fw-bold mt-2"
                      title="GENERATE PAGES FROM TOPICS"
                      onClick={() => {
                        setEditingCb((prev) => {
                          const pages = [
                            ...(editingCb.pages || []),
                            ...selectedTopics.map((topic) => ({
                              name: topic,
                              topic,
                            })),
                          ];
                          return new ContentBriefing({ ...prev, pages });
                        });
                      }}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}

          {topicGenerateOption === 'manual' && (
            <Row>
              <Col className="d-flex align-items-center">
                Add
                <Input
                  className="d-inline-block mx-2"
                  style={{ width: '60px' }}
                  type="number"
                  value={blankPageCount}
                  onChange={(e) => setBlankPageCount(+e.target.value)}
                />
                blank pages
                <CustomButton
                  color="success"
                  className="common-success-button fw-bold mx-2"
                  title="+"
                  onClick={() => {
                    setEditingCb((prev) => {
                      const pages = [
                        ...(editingCb.pages || []),
                        ...Array.from({ length: blankPageCount }).map(() => ({ name: 'New Page' })),
                      ];
                      return new ContentBriefing({ ...prev, pages });
                    });
                  }}
                />
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      {editingCb.pages?.map((page, index) => (
        <Fragment key={index}>
          <a className="anchor" id={`page-${index + 1}`} />

          <Card className={`mb-4 anchor-box anchor-box-page-${index + 1}`}>
            <CardBody>
              <CardTitle tag="h5">
                {page.name}
                {' - '}
                <a
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();

                    const result = await confirm({
                      title: <b>Confirm!</b>,
                      message: 'Are you sure?',
                      confirmText: 'Yes',
                      confirmColor: 'success',
                      cancelColor: 'btn btn-danger',
                      cancelText: 'No',
                    });

                    if (!result) return;

                    setEditingCb((prev) => {
                      const newPages = cloneDeep(prev?.pages) || [];
                      newPages.splice(index, 1);
                      return new ContentBriefing({
                        ...prev,
                        pages: newPages,
                      });
                    });
                  }}
                >
                  Delete
                </a>
              </CardTitle>

              <Row>
                <Col>
                  <FormGroup>
                    <Label>Page name</Label>
                    <Input
                      value={page.name}
                      onChange={(e) => setTopicField(e.target.value, index, 'name')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Topic</Label>
                    <Input
                      value={page.topic}
                      onChange={(e) => setTopicField(e.target.value, index, 'topic')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Extra info or seed keywords</Label>
                    <Input
                      value={page.extra_info}
                      onChange={(e) => setTopicField(e.target.value, index, 'extra_info')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Info from client</Label>
                    <AutoHeightTextArea
                      name="info_from_client"
                      value={page.info_from_client}
                      onChange={(e) => setTopicField(e.target.value, index, 'info_from_client')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomButton
                    color="success"
                    className="common-success-button fw-bold mb-2"
                    title="GENERATE QUESTIONS"
                    onClick={async () => {
                      const result = await update();
                      if (result) {
                        const response = await generateContentTopicQuestions({ cb, topic: page.topic });
                        if (response) {
                          setTopicField(`${response.data.data.join('\n\n')}${page.questions ? `\n\n${page.questions}` : ''}`, index, 'questions');
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Questions</Label>
                    <AutoHeightTextArea
                      name="questions"
                      value={page.questions}
                      onChange={(e) => setTopicField(e.target.value, index, 'questions')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Internal Info (not for AI)</Label>
                    <AutoHeightTextArea
                      name="internal_info"
                      value={page.internal_info}
                      onChange={(e) => setTopicField(e.target.value, index, 'internal_info')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Primary keyword</Label>
                    <AutoHeightTextArea
                      name="primary_keyword"
                      value={page.primary_keyword}
                      onChange={(e) => setTopicField(e.target.value, index, 'primary_keyword')}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Secondary keywords</Label>
                    <AutoHeightTextArea
                      name="secondary_keywords"
                      value={page.secondary_keywords}
                      onChange={(e) => setTopicField(e.target.value, index, 'secondary_keywords')}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Fragment>
      ))}

      <Card>
        <CardBody>
          <Row>
            <Col className="d-flex align-items-center">
              Add
              <Input
                className="d-inline-block mx-2"
                style={{ width: '60px' }}
                type="number"
                value={blankPageCount}
                onChange={(e) => setBlankPageCount(+e.target.value)}
              />
              blank pages
              <CustomButton
                color="success"
                className="common-success-button fw-bold mx-2"
                title="+"
                onClick={() => {
                  setEditingCb((prev) => {
                    const pages = [
                      ...(editingCb.pages || []),
                      ...Array.from({ length: blankPageCount }).map(() => ({ name: 'New Page' })),
                    ];
                    return new ContentBriefing({ ...prev, pages });
                  });
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <BottomActionToolbar
        component={(
          <>
            <SaveAndContinue
              onSave={update}
              onContinue={() => {
                navigate(replacePathParams(contentCenterRoutes.contentCenter, [], props));
              }}
            />
            {' '}
            <CustomButton
              color="success"
              className="common-success-button fw-bold"
              title="GENERATE DRAFT PAGE CONTENT"
              onClick={async () => {
                const result = await update();
                if (result) {
                  navigate(replacePathParams(contentCenterRoutes.generateDraftContent, [{ key: 'id', value: cb?.id }], props));
                }
              }}
            />
          </>
        )}
      />
    </div>
  );
}

export default withRouter(ContentBriefingForm);
