import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Button, ModalHeader } from 'reactstrap';
import { isEmpty } from 'lodash-es';
import { getSelectedOption, selectBoxOptions, selectColorStyles } from '../../../../../../Utils';
import { displayError } from 'Dashboard/src/Utils';
import CustomSelect from '../../../../components/common/custom-select';
import { getPageHierarchy } from '../../../../components/common/PageHierarchy';
import { MenuList } from '../../../../containers/navs/InstanceSelection';
import SectionBox from '../../../../components/fields/page/SectionBox';
import {
  useDuplicateSectionMutation,
  useGetInstanceDataQuery,
  useGetInstancesQuery,
} from '../../../../api/dashboardApiSlice';

function DuplicateSectionModal(props) {
  const {
    orderNo,
    setModal,
    modalClose,
    getModalBody,
    selectedSectorLabel,
    duplicateSectionApiResponse,
    params: {
      siteId, instanceId, pageVersionId,
    },
  } = props;

  const [selectedSiteId, setSiteId] = useState(null);
  const [selectedInstanceId, setInstanceId] = useState(null);
  const [siteData, setSiteData] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const [selectedPageId, setPageId] = useState(null);
  const [sectionsId, setSectionsId] = useState([]);

  const getSiteId = selectedSiteId || siteId;
  const getInstanceId = selectedInstanceId || instanceId;

  const { data: instances = [] } = useGetInstancesQuery();
  const { data: sectionsData = [], refetch } = useGetInstanceDataQuery(getInstanceId, { skip: isEmpty(getInstanceId) });
  const [duplicateSection, { isSuccess }] = useDuplicateSectionMutation();

  const getSiteData = (id) => {
    const getData = sectionsData.filter((item) => item.id == id)[0].sites;
    setSiteData(getData);
    setSiteOptions(selectBoxOptions(getData, 'name', 'id'));
  };

  const populateDefaultData = (id) => {
    const getPageData = siteData.filter((item) => item.id == id)[0].pages;
    const getPageOptions = getPageHierarchy(getPageData, '');
    setPageOptions(getPageOptions);
    setPageId(null);
    setSectionsId([]);
  };

  useEffect(() => {
    if (selectedInstanceId) {
      refetch(selectedInstanceId);
    }
  }, [selectedInstanceId]);

  useEffect(() => {
    if (!isEmpty(sectionsData)) {
      getSiteData(getInstanceId);
    }
  }, [sectionsData]);

  useEffect(() => {
    if (!isEmpty(getSiteId) && isEmpty(selectedPageId) && !isEmpty(siteData)) {
      populateDefaultData(getSiteId);
    }
  }, [getSiteId, selectedPageId, siteData]);

  useEffect(() => {
    if (isSuccess) {
      duplicateSectionApiResponse();
      modalClose();
    }
  }, [isSuccess]);

  const onHandleSubmit = async () => {
    let error = null;

    if (selectedPageId === '') {
      error = 'Please select page!';
    } else if (isEmpty(sectionsId)) {
      error = 'Please select section!';
    } else {
      error = false;
    }

    if (error) {
      displayError(error);
    } else {
      let payload = {
        order: orderNo,
        site_id: getSiteId,
        sector: selectedSectorLabel,
        page_version_id: pageVersionId,
        section_ids: Array.isArray(sectionsId) ? sectionsId.map((x) => x.value) : [],
      };

      payload = { ...payload, section_ids: payload.section_ids.filter((item) => item !== '*') };
      duplicateSection({ instanceId: getInstanceId, ...payload });
    }
  };

  const onHandleInstanceChange = async (e) => {
    const { value } = e;
    setInstanceId(value);
    setPageOptions([]);
    setSectionsId([]);
  };

  const onHandleSiteChange = (e) => {
    const { value } = e;
    setSiteId(value);
    populateDefaultData(value);
  };

  const onHandlePageSectionChange = (e) => {
    setSectionsId(e);
  };

  const instanceOptions = () => selectBoxOptions(instances, 'company_name', 'id');

  const modalBodyHTML = () => (
    <>
      <Row>
        <Col lg={12} className="mb-3">
          <CustomSelect
            isDisabled
            components={{ MenuList }}
            options={instanceOptions()}
            styles={selectColorStyles}
            placeholder="Select Instance"
            onChange={onHandleInstanceChange}
            value={getSelectedOption(instanceOptions(), getInstanceId)}
          />
        </Col>
        {getInstanceId && siteOptions.length > 0
        && (
          <Col lg={12} className="mb-3">
            <CustomSelect
              isDisabled
              components={{ MenuList }}
              placeholder="Select Site"
              onChange={onHandleSiteChange}
              options={siteOptions}
              styles={selectColorStyles}
              value={getSelectedOption(siteOptions, getSiteId)}
            />
          </Col>
        )}
        {getSiteId && pageOptions.length > 0
        && (
          <Col lg={12} className="mb-3">
            <CustomSelect
              components={{ MenuList }}
              placeholder="Select Page"
              onChange={(e) => setPageId(e.value)}
              options={pageOptions}
              styles={selectColorStyles}
              value={pageOptions.filter((option) => option.value === selectedPageId)}
            />
          </Col>
        )}
        <Col lg={12} className="mb-3">
          <SectionBox
            isMulti
            params={props?.params}
            allowSelectAll
            closeMenuOnSelect={false}
            value={sectionsId}
            selectedPageId={selectedPageId}
            onChange={onHandlePageSectionChange}
          />
        </Col>
        <Col lg={12} className="mb-3">
          <Button
            className="add-new-button button-lg w-100 mt-4"
            onClick={onHandleSubmit}
            color="info"
          >
            <i className="fa fa-plus" />
            <b> ADD</b>
          </Button>
        </Col>
      </Row>
    </>
  );
  return getModalBody
    ? modalBodyHTML()
    : (
        <Modal isOpen={setModal} toggle={modalClose} autoFocus={false} container={window.modalRef}>
          <ModalHeader toggle={modalClose}>
            <b>Duplicate Section</b>
          </ModalHeader>
        </Modal>
      );
}
export default DuplicateSectionModal;
