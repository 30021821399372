//#conditional

/* eslint-disable @stylistic/spaced-comment */
/* eslint-disable @stylistic/indent */
/* eslint-disable no-unreachable */

// We are using conditional compilation to include the NLUX code only if the NLUX_LOADED flag is set -- see
// esbuild-plugin-conditional-build and build.mjs.
//
// The conditional build plugin requires the magic comment at the top of the file. This unfortunately means that we
// can't make lint happy, I don't know a way around this at present.

import React, { useState, useEffect, useMemo } from 'react';
import Site from 'common/models/Site';


interface ThreadResponse {
  data: string;
}

interface Instance {
  id: number;
}

function ActionPageInstantContactChat() {
  return <></>;

  interface Observer {
    next: (content: string) => void;
    error: (error: Error) => void;
    complete: () => void;
  }

  interface StreamAdapter {
    streamText: (prompt: string, observer: Observer) => Promise<void>;
  }

  const makeStreamAdapter = (instance: Instance, site: Site, threadId: string | null): StreamAdapter => ({
    streamText: async (prompt: string, observer: Observer) => {
      const body = { prompt, thread_id: threadId };
      const response = await fetch(`/api/v1/instances/${instance.id}/sites/${site.id}/chats`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      if (response.status !== 200) {
        observer.error(new Error('Failed to connect to the server'));
        return;
      }

      if (!response.body) {
        return;
      }

      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const content = textDecoder.decode(value);
        if (content) {
          observer.next(content);
        }
      }

      observer.complete();
    },
  });

  const instance = useInstance() as Instance;
  const site = useSite() as Site;

  const [showTerms, setShowTerms] = useState<boolean>(false);

  const [threadId, setThreadId] = useState<string | null>(null);
  const adapter = useMemo(() => makeStreamAdapter(instance, site, threadId), [instance, site, threadId]);

  useEffect(() => {
    // We add the nlux stylesheet to the top of the head rather than the bottom, so that our custom styles can override it.
    const style = document.createElement('link');
    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = 'https://cdn.jsdelivr.net/npm/@nlux/themes@2.17.1/nova.css';
    document.head.insertBefore(style, document.head.firstChild);
  }, []);

  useEffect(() => {
    if (!instance || !site) {
      return;
    }

    if (threadId || !site?.enable_instant_contact_form_chat) {
      return;
    }

    (async () => {
      const response = await fetch(`/api/v1/instances/${instance.id}/sites/${site.id}/chats`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const responseData: ThreadResponse = await response.json();
      setThreadId(responseData.data);
    })();
  }, [instance, site, threadId]);

  // This is ugly... but nlux's built-in autoFocus is not working for me (MP)
  useEffect(() => {
    const focusInterval = setInterval(() => {
      const textarea = document.querySelector('.nlux-comp-composer textarea');
      if (textarea) {
        (textarea as HTMLTextAreaElement).focus();
        clearInterval(focusInterval);
      }
    }, 250);

    return () => clearInterval(focusInterval);
  }, []);

  useFireRenderEvent();

  if (!site?.enable_instant_contact_form_chat) {
    // If we don't return anything here the sector might be hidden by HideEmptySectors.
    return (
      <div className="action-page" />
    );
  }

  return (
    <div className={`action-page style-${site?.instant_contact_chat_text_style_id}`}>
      <div className="chatbot">
        <AiChat
          displayOptions={{
            height: '400px',
          }}
          composerOptions={{
            autoFocus: true,
            placeholder: (
              site.chatbot_persona_name ? `Message ${site.chatbot_persona_name}...` : 'Say something...'
            ),
          }}
          personaOptions={{
            assistant: {
              name: site.chatbot_persona_name || '',
              avatar: `/c/${site.chatbot_persona_icon_id}/avatar-icon`,
            },
          }}
          initialConversation={[
            {
              role: 'assistant',
              message: site.chatbot_initial_message,
            },
          ]}
          adapter={adapter}
        />
      </div>

      <small className="chat-terms">
        <div className="text-end">
          <a
            href="#"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              setShowTerms(!showTerms);
            }}
          >
            Terms
            {' '}
            <i className={`fa fa-chevron-${showTerms ? 'up' : 'down'}`} />
          </a>
        </div>
        {showTerms && (
          <div className="mt-2">
            <p>
              The following disclaimer is intended to inform users of the limitations and usage guidelines of this AI chatbot. Please read this disclaimer carefully before engaging with the chatbot.
            </p>
            <p>
              General Information: The AI chatbot is an automated system powered by OpenAI. It is designed to provide information and assistance based on the public documentation on this website. While this chatbot is designed to provide accurate information about
              {' '}
              {site.display_name}
              , there is no guarantee as to the accuracy, completeness, or up-to-date nature of any of the information provided. We recommend that you use responses as a starting point only and verify the information by contacting
              {' '}
              {site.display_name}
              {' '}
              directly.
            </p>
            <p>
              User Responsibility: Users of this AI chatbot bear sole responsibility for their interactions and reliance on the information provided. It is important to exercise caution and use your discretion while interpreting and acting upon the chatbot&apos;s responses. We cannot be held liable for any actions, losses, or damages resulting from the use of the chatbot.
            </p>
            <p>
              Data Privacy and Security: We prioritise the privacy and security of our users&apos; information. Be aware that chatbot conversations may be reviewed by Web Genius to improve results. OpenAI may retain logs of these conversations, and this data may be used to train their AI models. Web Genius does not share any sensitive or personal information in your conversations with any other parties.
            </p>
            <p>
              By using this AI chatbot, you indicate your acceptance and understanding of the above disclaimer. If you do not agree with any part of this disclaimer, you should refrain from using the chatbot. For further assistance or enquiries, please contact
              {' '}
              <a href="mailto:support@webgenius.co.nz">support@webgenius.co.nz</a>
              .
            </p>
          </div>
        )}
      </small>
    </div>
  );
}

export default ActionPageInstantContactChat;
