import React, { useEffect } from 'react';
import { setPageTitle, getFontSize } from '../../../../../../Utils';
import { displaySuccess, displayError } from 'Dashboard/src/Utils';
import { addGoogleFont } from 'Dashboard/src/Utils';
import HeaderComponent from '../../../../components/common/header-component';
import { settingRoutes, siteCenter, replacePathParams } from '../../../../constants/routes';
import { Button, Card, CardBody } from 'reactstrap';
import confirm from '../../../../components/common/confirm';
import { isEmpty, map, get } from 'lodash-es';
import hexToRGBA from '../../../../components/common/color-picker/hexToRgba';
import withRouter from '../../../../helpers/withRouter';
import { useDeleteTextStyleMutation, useGetTextStylesQuery, useLazyCheckBeforeDeleteQuery } from '../../../../api/dashboardApiSlice';
import BottomActionToolbar from '../../../../components/common/toolbar';
import CustomButton from '../../../../components/common/button';
import { addRecentItem } from 'Dashboard/src/Utils';
import { useInstance, useSite } from '../../../../../../common/hooks';
import ReactTable8 from 'Dashboard/src/components/common/Table8';

function TextStyleListing(props) {
  const { navigate } = props;
  const instance = useInstance();
  const site = useSite();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const { data: textStyleList } = useGetTextStylesQuery(siteId, { skip: isEmpty(siteId) });
  const [deleteTextStyle] = useDeleteTextStyleMutation();
  const [checkBeforeDelete] = useLazyCheckBeforeDeleteQuery();

  useEffect(() => {
    setPageTitle('Text Styles');
  }, []);

  useEffect(() => {
    if (!isEmpty(textStyleList)) {
      addGoogleFont(map(textStyleList, (f) => ({
        fontName: f.fonts,
        fontWeight: f.font_weight,
      })));
    }
  }, [textStyleList]);

  const onDeleteAction = async (id) => {
    const checkParams = { instanceId, objectId: id, objectType: 'TextStyle' };
    const checkResponse = await checkBeforeDelete(checkParams);
    let result;
    try {
      if (undefined !== checkResponse) {
        const { data: { data: { total, desc } } } = checkResponse;
        result = await confirm({
          title: <b>Confirm!</b>,
          message: (
            <>
              <b>Total Uses:</b>
              {' '}
              {`${total}`}
              <p>
                {' '}
                {desc.map((val) => <li key={val}>{val}</li>)}
              </p>
              <p>Are you sure?</p>
            </>
          ),
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
        });
      }
      if (result) {
        const sendParams = { id, siteId };
        const res = await deleteTextStyle(sendParams);
        if (res && res?.data?.meta?.is_success) {
          displaySuccess(res?.data?.meta?.messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const presetColors = site && get(site, 'colors', []);

  const getBackgroundColor = (color) => {
    const realColor = getColor(color);
    const rgbaColor = hexToRGBA(realColor);

    if (rgbaColor.r * 0.299 + rgbaColor.g * 0.587 + rgbaColor.b * 0.114 > 186) {
      return '#000000';
    }
    return '#ffffff';
  };

  const getColor = (color) => {
    if (presetColors && color < 0) {
      const presetColor = presetColors.find((c) => Math.abs(color) === c.id);
      if (presetColor) {
        return presetColor.hex;
      }
    }
    return color;
  };

  const onEditAction = (id) => {
    navigate(replacePathParams(
      siteCenter.editTextStyle,
      [
        { key: 'id', value: id },
        { key: 'siteId', value: siteId },
      ],
      props,
    ));
  };

  const tableColumns = [
    {
      header: 'Name',
      id: 'name',
      cell: ({ row }) => {
        const { fonts, color, font_weight, font_style, name } = row.original;
        return (
          <div style={{
            fontFamily: fonts,
            fontSize: getFontSize(row.original),
            fontWeight: font_weight,
            fontStyle: font_style,
            color: getColor(color),
            backgroundColor: getBackgroundColor(color),
            padding: '4px 6px',
          }}
          >
            {name}
          </div>
        );
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      header: 'Color',
      id: 'color',
      cell: ({ row }) => {
        const { color } = row.original;
        if (presetColors && color < 0) {
          const presetColor = presetColors.find((c) => Math.abs(color) === c.id);
          if (presetColor) {
            return `${presetColor.label} (${presetColor.hex})`;
          }
        }
        return color;
      },
    },
    {
      header: 'Font',
      cell: ({ row }) => {
        const { fonts, fallback_font } = row.original;
        return `${fonts} ${fallback_font ? ` ⤷ ${fallback_font}` : ''}`;
      },
    },
    {
      accessorKey: 'tag',
      header: 'Tag',
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => {
        const { id, site_id } = row.original;
        return (
          <>
            <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditAction(id)}>
              <i className="fal fa-pen" />
            </Button>
            {' '}
            <Button
              className="custom-simple-icon"
              color="danger"
              size="xs"
              onClick={() => onDeleteAction(id, site_id)}
            >
              <i className="fal fa-trash" />
            </Button>
          </>
        );
      },
    },
  ];

  if (siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'text-styles',
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    { name: 'Text Styles', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          addNewBtnName: '',
          addNewPath: '',
          headingName: 'Text Styles',
        }}
      />
      <Card className="mb-4">
        <CardBody>
          <ReactTable8
            columns={tableColumns}
            data={textStyleList || []}
          />
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <CustomButton
            color="info"
            className="add-new-button button-md fw-bold"
            title="ADD TEXT STYLE"
            icon="fa fa-plus"
            onClick={() => navigate(replacePathParams(siteCenter.addTextStyle, [
              { key: 'siteId', value: siteId },
              { key: 'instanceId', value: instanceId },
            ]))}
          />
        )}
      />
    </>
  );
}
export default withRouter(TextStyleListing);
