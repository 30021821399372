import Base from './Base';

export type ExportFrequency = 'daily' | 'weekly' | 'monthly';

interface SavedReportProps {
  id?: string | number;
  user_id?: string;
  name?: string;
  details?: SavedReportDetails;
  google_spreadsheet_id?: string;
  google_spreadsheet_name?: string;
  create_new_sheet?: boolean;
  export_frequency?: ExportFrequency;
}

interface SavedReportDetails {
  currentReport?: string;
  columnFilters?: any;
  columnVisibility?: any;
  sorting?: any;
}

class SavedReport extends Base {
  id?: string;
  user_id?: string;
  name?: string;
  details?: SavedReportDetails;
  google_spreadsheet_id?: string;
  google_spreadsheet_name?: string;
  create_new_sheet?: boolean;
  export_frequency?: ExportFrequency;

  constructor(props: SavedReportProps) {
    super(props);
  }
}

export default SavedReport;
