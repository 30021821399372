import React, { Fragment, useEffect, useCallback, useRef, PropsWithChildren, ElementType } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { filter, isEmpty, uniqueId } from 'lodash-es';
import TextBlockMedia from '../../TextBlockMedia';
import { replaceLinks, sectionObject } from '../../../Utils';
import macroReplacerNode from '../../macroReplacerNode';
import macroReplacerString from '../../macroReplacerString';
import { useAppSelector } from '../../../rootStore';
import Section from 'common/models/Section';
import { TextBlock } from 'ts/interfaces';
import { createPublicFacingStore } from '../../../rootStore/publicFacingStore';

interface SectionProps {
  section: Section;
  onClick: (section: Section) => void;
}

interface MacroContainerProps {
  node: HTMLDivElement;
}

// We need to add linebreaks inside the dom element manually, so we need the break element as a string.
const lineBreakText = (tb: TextBlock) => {
  if (tb.type === 'ms3') {
    return '';
  }

  switch (tb.line_break) {
    case 'single_line':
      return '<br>';
    case 'double_line':
      return '<br><br>';
    case 'clear_left':
      return '<br class=clear-left>';
    case 'clear_right':
      return '<br class=clear-right>';
  }
  return '';
};

// And we also need to add linebreaks when there is no tag, and for this we need the break element as a component.
const lineBreakElement = (tb: TextBlock) => {
  if (tb.type === 'ms3') {
    return null;
  }

  switch (tb.line_break) {
    case 'single_line':
      return <br />;
    case 'double_line':
      return (
        <>
          <br />
          <br />
        </>
      );
    case 'clear_left':
      return <br className="clear-left" />;
    case 'clear_right':
      return <br className="clear-right" />;
  }
  return null;
};

// This is a container to support section macros inside textblocks. It just returns whatever children are inside it,
// and when it has finished rendering it calls macroReplacerNode() on the node that is passed to it via the node prop.
const MacroContainer: React.FC<PropsWithChildren<MacroContainerProps>> = ({ node, children }) => {
  useEffect(() => {
    macroReplacerNode(node);
  }, [node]);

  return <>{children}</>;
};

const SectionContentNewModel: React.FC<SectionProps> = ({ section, onClick }) => {
  const page = useAppSelector((state) => state.pageData.page);
  const allTextblocks = useAppSelector((state) => state.pageData.text_block);
  const allTextStyles = useAppSelector((state) => state.pageData.text_style);
  const allLinks = useAppSelector((state) => state.pageData.link);
  const allSections = useAppSelector((state) => state.pageData.section);
  const allButtonStyles = useAppSelector((state) => state.pageData.button_style);
  const refs = {};
  const textBlocks: TextBlock[] = filter(allTextblocks, (t) => t.section_content_id === section.id && !t.is_offline);
  const sectionRegex = /\(section:([0-9a-f]{24})\)/ig;

  // React requires us to have the same amount of hooks on each render, so we can't just create a ref for each
  // textblock because if we add a textblock then the number of hooks will change. So, we create a bunch of empty refs
  // and just use the ones that we need to.
  for (let i = 0; i < 200; i++) {
    refs[i] = useRef<HTMLElement | null>(null);
  }

  // We need to remove the "style" attribute from all buttons set via the CMS. This is because we want the styles from
  // the Button Styles page in admin to override whatever is set in the textblock

  const stripButtonStyles = (node: HTMLElement) => {
    node?.querySelectorAll<HTMLAnchorElement>('a[data-button-style-id]').forEach((a) => {
      const styleId = a.dataset.buttonStyleId;
      if (!styleId) {
        return;
      }

      const style = allButtonStyles?.find((s) => s.id === styleId);
      if (!style) {
        return;
      }

      a.querySelectorAll('button').forEach((b) => b.removeAttribute('style'));
      a.querySelectorAll('button > i').forEach((i) => i.removeAttribute('style'));
    });
  };

  useEffect(() => {
    textBlocks.forEach((textBlock, i) => {
      const ref = refs[i];
      if (!ref?.current) {
        return;
      }

      // <script> tags that are injected into the DOM are basically ignored, so we need to run them manually. We don't
      // want to run them more than once, so we keep track of which sections we have processed.
      const scriptTags = ref.current.querySelectorAll('script');
      if (scriptTags.length > 0) {
        if (typeof window.scriptsRun === 'undefined') {
          window.scriptsRun = [];
        }
        if (section?.id !== undefined && typeof section.id === 'string' && !window.scriptsRun.includes(section.id)) {
          window.scriptsRun.push(section.id);

          scriptTags.forEach((s) => {
            if (s.src) {
              const newS = document.createElement('script');
              newS.src = s.src;
              document.head.appendChild(newS);
            } else {
              try {
                (new Function(s.innerHTML))();
              } catch (e) {
                console.log('Error when evaluating raw textblock:', e);
              }
            }
          });
        }
      }

      if (textBlock.block_text.match(sectionRegex)) {
        // Process section macros in textblocks. This is a bit complicated because we need to render a React component
        // (the section) inside the textblock text, which is raw HTML.

        // First make an array of all text nodes inside the textblock.
        let node: Node | null = null;
        const allNodes: Node[] = [];
        const walker = document.createTreeWalker(ref.current, NodeFilter.SHOW_TEXT);

        while ((node = walker.nextNode()) !== null) {
          allNodes?.push(node);
        }

        // Then loop through each node
        allNodes?.forEach((node) => {
          if (!node) {
            return;
          }

          const matches = [...((node instanceof HTMLElement) ? node.innerHTML : node?.textContent)!.matchAll(sectionRegex)];
          matches?.forEach((match) => {
            const sectionId = match[1];
            const section = allSections.find((s) => s.id === sectionId || s.origin_id === sectionId);
            if (!section) {
              return;
            }

            const reactNode = document.createElement('div');
            const newNode = document.createElement('div');
            const root = createRoot(reactNode);
            root.render(
              <MacroContainer node={newNode}>
                <Provider store={createPublicFacingStore()}>
                  {sectionObject(page, section, onClick)}
                </Provider>
              </MacroContainer>,
            );

            const newId = `section${(Math.random() * 9999999999).toFixed()}`;
            newNode.innerHTML = (node instanceof HTMLElement ? node.innerHTML : node.textContent)!.replace(match[0], `<div id="${newId}"></div>`);
            newNode.querySelector(`#${newId}`)?.replaceWith(reactNode);
            if (node instanceof HTMLElement || node instanceof Text) {
              node.replaceWith(newNode);
            }
          });
        });
      }

      replaceLinks(ref.current, allLinks);
      stripButtonStyles(ref.current);
      macroReplacerNode(ref.current);
    });
  }, []);

  const textBlockElements = textBlocks.map((textBlock, i) => {
    const textStyle = textBlock.text_style_id ? allTextStyles.find((s) => s.id === textBlock.text_style_id) : null;
    const Tag = (textStyle ? textStyle.tag : 'span') as ElementType;
    const media = textBlock.media ? <TextBlockMedia media={textBlock.media} /> : null;

    // Both the old platform and the Ruby version always add the line break, regardless of whether there is any text in
    // the textblock or not. But they add it inside the tag if there is text, and without the tag if there is no text.
    // If there is text, the linebreak is added above in the useEffect() call, and if there is no text we add it here
    // with the call to lineBreakElement().
    return (
      <Fragment key={textBlock.id}>
        {media}
        {isEmpty(textBlock.block_text) && lineBreakElement(textBlock)}
        {textBlock.type === 'raw' && (
          <span
            className="tb raw-tb"
            ref={refs[i]}
            dangerouslySetInnerHTML={{ __html: textBlock.block_text }}
          />
        )}
        {textBlock.type !== 'raw' && !isEmpty(textBlock.block_text) && (
          <Tag
            className={`tb type-${textBlock.type} style-${textBlock.text_style_id}`}
            ref={refs[i]}
            dangerouslySetInnerHTML={{ __html: textBlock.block_text + lineBreakText(textBlock) }}
          />
        )}
      </Fragment>
    );
  });

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionContent bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{
          overflow: section?.column_section?.id ? 'auto' : 'visible',
          display: (section.initial_visibility || window.wg.env === 'dashboard') ? '' : 'none',
        }}
        onClick={() => onClick(section)}
      >
        {textBlockElements}
      </section>
    </>
  );
};

const SectionContentOldModel: React.FC<SectionProps> = ({ section, onClick }) => {
  const page = useAppSelector((state) => state.pageData.page);
  const allTextblocks = useAppSelector((state) => state.pageData.text_block);
  const allTextStyles = useAppSelector((state) => state.pageData.text_style);
  const allLinks = useAppSelector((state) => state.pageData.link);
  const allSections = useAppSelector((state) => state.pageData.section);
  const allButtonStyles = useAppSelector((state) => state.pageData.button_style);
  const textBlocks: TextBlock[] = filter(allTextblocks, (t) => t.section_content_id === section.id && !t.is_offline);
  const sectionRegex = /\(section:([0-9a-f]{24})\)/ig;

  const render = async (component: JSX.Element) => {
    const node = document.createElement('div');
    const root = createRoot(node);
    await root.render(
      <Provider store={createPublicFacingStore()}>
        {component}
      </Provider>,
    );
    return node.innerHTML;
  };

  const sectionRef = useCallback(async (sectionNode) => {
    if (!sectionNode) {
      return;
    }

    let html = '';

    const textBlockMedias = {};
    const promises = [] as Promise<string | void>[];
    textBlocks.forEach(async (textBlock) => {
      if (textBlock.media) {
        promises.push(render(<TextBlockMedia media={textBlock.media} />).then((html) => {
          textBlockMedias[textBlock.id] = html;
        }));
      }
    });
    await Promise.all(promises);

    textBlocks.forEach(async (textBlock) => {
      const textStyle = textBlock.text_style_id ? allTextStyles.find((s) => s.id === textBlock.text_style_id) : null;

      // Both the old platform and the Ruby version always add the line break, regardless of whether there is any text in
      // the textblock or not. But they add it inside the tag if there is text, and without the tag if there is no text.
      // If there is text, the linebreak is added above in the useEffect() call, and if there is no text we add it here
      // with the call to lineBreakElement().
      //
      // We also have a slightly hackish solution for raw textblocks. We sometimes use a system where we have a raw
      // textblock with an opening HTML tag (like <div>) and then a normal textblock with text and/or an image etc, and
      // then another raw textblock with a closing HTML tag (like </div>). If we try to assemble DOM elements with these
      // textblocks it won't work, because the browser will try to fix the mismatching tags and we will end up with
      // <div></div> [text and/or images] <div></div>. So we add a placeholder (.raw-tb) and we replace it with the real
      // HTML later, in the useEffect() call above.
      if (textBlockMedias[textBlock.id]) {
        html += textBlockMedias[textBlock.id];
      }

      if (isEmpty(textBlock.block_text)) {
        html += lineBreakText(textBlock) ?? '';
      }

      if (textBlock.type === 'raw') {
        html += macroReplacerString(textBlock.block_text);
        html += lineBreakText(textBlock);
      }

      if (textBlock.type !== 'raw' && !isEmpty(textBlock.block_text)) {
        const tag = textStyle ? textStyle.tag : 'span';

        html += `<${tag} class="tb style-${textBlock.text_style_id}">`;
        html += macroReplacerString(textBlock.block_text);
        html += lineBreakText(textBlock);
        html += `</${tag}>`;
      }
    });

    sectionNode.innerHTML = html;

    // <script> tags that are injected into the DOM are basically ignored, so we need to run them manually. We don't
    // want to run them more than once, so we keep track of which sections we have processed.
    // const scriptTags: NodeListOf<HTMLScriptElement> = sectionNode.querySelectorAll('script');
    const scriptTags: NodeListOf<HTMLScriptElement> = sectionNode.querySelectorAll('script');
    if (scriptTags.length > 0) {
      if (typeof window.scriptsRun === 'undefined') {
        window.scriptsRun = [];
      }
      if (section?.id !== undefined && typeof section.id === 'string' && !window.scriptsRun.includes(section.id)) {
        window.scriptsRun.push(section.id);

        scriptTags.forEach((s) => {
          if (s.src) {
            const newS = document.createElement('script');
            newS.src = s.src;
            document.head.appendChild(newS);
          } else {
            try {
              (new Function(s.innerHTML))();
            } catch (e) {
              console.log('Error when evaluating raw textblock:', e);
            }
          }
        });
      }
    }

    // Don't bother doing the complicated stuff if there are no section macros.
    if (sectionNode.innerHTML.match(sectionRegex)) {
      // Process section macros in textblocks. This is a bit complicated because we need to render a React component
      // (the section) inside the textblock text, which is raw HTML.

      // First make an array of all text nodes inside the textblock.
      let node: Node | null = null;
      const allNodes: Node[] = [];
      const walker = document.createTreeWalker(sectionNode, NodeFilter.SHOW_TEXT);
      while ((node = walker.nextNode()) !== null) {
        allNodes?.push(node);
      }

      // Then loop through each node
      allNodes?.forEach((node) => {
        if (!node) {
          return;
        }

        const matches = [...(node instanceof HTMLElement ? node.innerHTML : node.textContent)!.matchAll(sectionRegex)];
        matches?.forEach((match) => {
          const sectionId = match[1];

          const section = allSections.find((s) => s.id === sectionId || s.origin_id === sectionId);
          if (!section) {
            return;
          }

          const reactNode = document.createElement('div');
          const newNode = document.createElement('div');
          const root = createRoot(reactNode);
          root.render(
            <MacroContainer node={newNode}>
              <Provider store={createPublicFacingStore()}>
                {sectionObject(page, section, onClick)}
              </Provider>
            </MacroContainer>,
          );

          const newId = `section${(Math.random() * 9999999999).toFixed()}`;
          newNode.innerHTML = (node instanceof HTMLElement ? node.innerHTML : node.textContent)!.replace(match[0], `<div id="${newId}"></div>`);
          newNode.querySelector(`#${newId}`)?.replaceWith(reactNode);
          if (node instanceof HTMLElement || node instanceof Text) {
            node.replaceWith(newNode);
          }
        });
      });
    }

    replaceLinks(sectionNode, allLinks);
    stripButtonStyles(sectionNode);
  }, []);

  // We need to remove the "style" attribute from all buttons set via the CMS. This is because we want the styles from
  // the Button Styles page in admin to override whatever is set in the textblock.
  const stripButtonStyles = (node: HTMLElement) => {
    node?.querySelectorAll<HTMLAnchorElement>('a[data-button-style-id]').forEach((a) => {
      const styleId = a.dataset.buttonStyleId;
      if (!styleId) {
        return;
      }

      const style = allButtonStyles?.find((s) => s.id === styleId);
      if (!style) {
        return;
      }

      a.querySelectorAll('button').forEach((b) => b.removeAttribute('style'));
      a.querySelectorAll('button > i').forEach((i) => i.removeAttribute('style'));
    });
  };

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        // The uniqueId below is because we need to force a re-render of the section when we are editing via the
        // dashboard. This is because of the hackish way we are replacing textblock elements using the above useCallback.
        key={uniqueId()}
        id={`section${section.id}`}
        ref={sectionRef}
        className={`SectionContent bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{
          overflow: section.column_section?.id ? 'auto' : 'visible',
          display: (section.initial_visibility || window.wg.env === 'dashboard') ? '' : 'none',
        }}
        onClick={() => onClick(section)}
      />
    </>
  );
};

const SectionContent: React.FC<SectionProps> = ({ section, onClick }) => {
  // Old model textblocks are not loading in the dashboard since f70d9aa, and I (MP) can't figure out why. So for now
  // we just use the new model when rendering in the dashboard.
  if (window.contentSectionModel === 2 || window.wg.env === 'dashboard') {
    return <SectionContentNewModel section={section} onClick={onClick} />;
  }

  return <SectionContentOldModel section={section} onClick={onClick} />;
};

export default SectionContent;
