import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { isArray } from 'lodash-es';
import { Action, Middleware, combineReducers } from 'redux';
import { deviceSlice, pageDataSlice } from 'common/api/commonSlice';
import { spinSlice } from 'common/api/commonSlice';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { apiSlice } from 'common/api/apiSlice';

export const rtkQueryErrorLogger = () => (next: (arg0: any) => any) => (
  action: {
    payload: {
      data: {
        messages: any;
        data: any[];
      };
    };
    meta: {
      arg: {
        originalArgs: any;
      };
    };
  },
) => {
  if (isRejectedWithValue(action)) {
    if (action.meta.arg.originalArgs.ignoreErrors) {
      return next(action);
    }

    let message = action.payload.data.messages;

    if (isArray(action.payload.data.data)) {
      action.payload.data.data.forEach((m: any) => message += `\n${m}`);
    } else if (typeof action.payload.data.data === 'object') {
      Object.keys(action.payload.data.data).forEach((key) => {
        message += `\n${action.payload.data.data[key]}`;
      });
    }

    console.error(message);
  }

  return next(action);
};

const publicFacingRootReducer = combineReducers({
  device: deviceSlice.reducer,
  pageData: pageDataSlice.reducer,
  spin: spinSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

type RootState = ReturnType<typeof publicFacingRootReducer>;

export const createPublicFacingStore = () => {
  if ((window as any).store) return (window as any).store;

  return (window as any).store = configureStore({
    reducer: publicFacingRootReducer,
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware({ serializableCheck: false })
    //     .concat(thunk, rtkQueryErrorLogger as Middleware),
    middleware: (getDefaultMiddleware) => (
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(thunk, apiSlice.middleware, rtkQueryErrorLogger as Middleware<
        (action: Action<'specialAction'>) => number,
        RootState
      >)
    ),
  });
};

export const usePublicFacingAppDispatch = () => {
  const store = createPublicFacingStore();
  return store.dispatch;
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
