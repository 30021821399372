import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import { get, isEmpty } from 'lodash-es';
import { ErrorBoundary } from 'react-error-boundary';
import LinkToolBox from '../../views/site-centre/Section/LinkToolBox';
import MediaToolBox from '../../views/site-centre/Section/MediaToolBox';
import { baseDomain } from '../../../../api';
import confirm from '../common/confirm';
import { getContrastingColor } from '../../../../Utils';
import { displayError, displaySuccess } from 'Dashboard/src/Utils';
import withRouter from '../../helpers/withRouter';
import SectionHistory from '../SectionHistory';
import AIPromptWriter from '../AIPromptWriter';
import { useDeleteMediaMutation, useGetMediaByIdQuery, useGetTextStylesQuery } from '../../api/dashboardApiSlice';
import { useInstance, useSite } from '../../../../common/hooks';
import './index.scss';
import { EditorContent, useEditor } from '@tiptap/react';
import HardBreak from '@tiptap/extension-hard-break';
import { BulletList } from '@tiptap/extension-bullet-list';
import { History } from '@tiptap/extension-history';
import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { Bold } from '@tiptap/extension-bold';
import { Italic } from '@tiptap/extension-italic';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Text } from '@tiptap/extension-text';
import { Document } from '@tiptap/extension-document';
import { TextAlign } from '@tiptap/extension-text-align';
import Link from '@tiptap/extension-link';
import CustomStyle from './CustomStyle';
import Paragraph from './Paragraph';
import MenuBar from './MenuBar';
import { getColorVal } from '../common/breakpoint';

const LinkHelper = ({ position, onEdit, dataId, onUnlink }) => {
  return (
    <div
      className="link-helper"
      style={{ left: `${position.left}px`, top: `${position.top}px` }}
    >
      <span
        role="button"
        tabIndex={0}
        onKeyDown={() => { }}
        onClick={() => onEdit(dataId)}
      >
        <i className="fal fa-pen cursor-pointer ps-2 pe-2" />
      </span>
      &nbsp;&nbsp;
      <i className="fal fa-pipe" />
      &nbsp;&nbsp;
      <span
        role="button"
        tabIndex={0}
        onKeyDown={() => { }}
        onClick={onUnlink}
      >
        <i className="fal fa-unlink cursor-pointer ps-2 pe-2" />
      </span>
    </div>
  );
};

function RichTextEditor3(props) {
  const {
    index,
    features,
    isEmailPage,
    textBlockObj,
    onHandleMove,
    countTextBlocks,
    onUpdateTextBlock,
  } = props;

  const instance = useInstance();
  const instanceId = get(instance, 'id', null);

  const editorType = get(textBlockObj, 'type', '');

  const [toolData, setToolData] = useState({});
  const [showLinkTool, setShowLinkTool] = useState(false);
  const [linkId, setLinkId] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [ai, setAI] = useState(false);
  const [mediaId, setMediaId] = useState(null);
  const [linkHelper, setLinkHelper] = useState(null);

  const [deleteMedia] = useDeleteMediaMutation();
  const { data } = useGetMediaByIdQuery({ id: mediaId, instance_id: instanceId }, { skip: isEmpty(mediaId) });
  const site = useSite();

  useEffect(() => {
    if (data) {
      if (data?.link_id) {
        setLinkId(data.link_id);
      }
      setToolData({
        toolType: 'media',
        isEditAction: true,
        mediaToolOnText: true,
        showModal: true,
        selectedMediaToolData: data,
      });
    }
  }, [data]);

  // We use the counter to reset the editor, we do this when switching from source mode back to normal.
  const [editorCounter, setEditorCounter] = useState(0);

  const hasFeature = (f) => features.indexOf(f) > -1;

  const toggleModal = () => {
    const { showModal } = toolData;
    setToolData({ showModal: !showModal });
  };

  const displayMedia = (media) => {
    let mediaTag = '';
    if (media === null) {
      mediaTag = (
        <div>
          No media selected.
          <br />
          <br />
          <Button color="success" className="button-sm common-success-button">Add Media</Button>
        </div>
      );
    } else if (undefined !== media?.hover_media_url && media?.hover_media_url) {
      // mediaTag = (
      //   <HoverImage
      //     src={baseDomain + media?.media_url}
      //     hoverSrc={baseDomain + media?.hover_media_url}
      //     className=""
      //     alt=""
      //   />
      // );
      mediaTag = '';
    } else {
      mediaTag = <img src={`${baseDomain}${media?.media_url}`} alt="" />;
    }
    return mediaTag;
  };

  const onClickMedia = async (media) => {
    if (media && media?.id) {
      setMediaId(media?.id);
    } else {
      setToolData({
        toolType: 'media',
        isEditAction: false,
        mediaToolOnText: true,
        selectedMediaToolData: {},
        showModal: true,
      });
    }
  };

  const updateTextBlockData = (data) => {
    onUpdateTextBlock({ ...textBlockObj, media: data });
  };

  const onMediaBoxSaveButton = (mediaData) => {
    updateTextBlockData(mediaData);
    setToolData({ showModal: false });
  };

  const onHandleDeleteMedia = async () => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });

      if (result) {
        const sendParams = {
          id: textBlockObj?.media?.id,
          instance_id: instanceId,
        };
        const response = await deleteMedia(sendParams);
        if (response && response?.data?.meta?.is_success) {
          displaySuccess(response?.data?.meta?.messages);
          onUpdateTextBlock({ ...textBlockObj, media: null });
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const renderToolTypeComponent = () => {
    const {
      toolType,
      selectedString,
      mediaToolOnText,
      selectedMediaToolData,
    } = toolData;

    if (toolType === 'media') {
      return (
        <MediaToolBox
          toggle={toggleModal}
          instanceId={instanceId}
          isEmailPage={isEmailPage}
          selectedString={selectedString}
          mediaToolOnText={mediaToolOnText}
          selectedTextBlockId={textBlockObj?.id}
          media={selectedMediaToolData}
          updateTextBlockData={updateTextBlockData}
          onMediaBoxSaveButton={onMediaBoxSaveButton}
        />
      );
    }

    return <></>;
  };

  const getColumnSize = () => {
    if ((editorType === 'normal' || editorType === 'ms3') && !hasFeature('media')) {
      return '12';
    }
    if (editorType === 'normal' || editorType === 'ms3') {
      return '9';
    }
    return '12';
  };

  const CustomLink = Link.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        'id': {
          default: null,
          parseHTML: (element) => element.getAttribute('id'),
          renderHTML: (attributes) => ({ id: attributes.id }),
        },
        'data-id': {
          default: null,
          parseHTML: (element) => element.getAttribute('data-id'),
          renderHTML: (attributes) => ({ 'data-id': attributes['data-id'] }),
        },
        'data-textid': {
          default: null,
          parseHTML: (element) => element.getAttribute('data-textid'),
          renderHTML: (attributes) => ({ 'data-textid': attributes['data-textid'] }),
        },
        'data-body': {
          default: null,
          parseHTML: (element) => element.getAttribute('data-body'),
          renderHTML: (attributes) => ({ 'data-body': attributes['data-body'] }),
        },
        'data-description': {
          default: null,
          parseHTML: (element) => element.getAttribute('data-description'),
          renderHTML: (attributes) => ({ 'data-description': attributes['data-description'] }),
        },
        'data-button-style-id': {
          default: null,
          parseHTML: (element) => element.getAttribute('data-button-style-id'),
          renderHTML: (attributes) => ({ 'data-button-style-id': attributes['data-button-style-id'] }),
        },
      };
    },
  });

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      BulletList,
      History,
      HorizontalRule,
      Bold,
      Italic,
      ListItem,
      OrderedList,
      Text,
      TextAlign.configure({
        types: ['paragraph'],
      }),
      HardBreak,
      CustomStyle,
      CustomLink.configure({
        autolink: false,
        openOnClick: false, // Optionally disable open on click
      }),
    ],
    content: (() => {
      // Strip out the trailing break that is added by the editor, see onUpdate below.
      const parser = new DOMParser();
      const doc = parser.parseFromString(textBlockObj?.block_text, 'text/html');
      doc.querySelectorAll('div .trailing-break').forEach((br) => br.remove());
      return doc.body.innerHTML;
    })(textBlockObj?.block_text),
    onUpdate: ({ editor }) => {
      if (!textBlockObj?.showSource) {
        function getAllTextNodes(element) {
          const textNodes = [];

          function traverse(node) {
            if (node.nodeType === Node.TEXT_NODE) {
              textNodes.push(node);
            }

            node.childNodes.forEach(traverse);
          }

          traverse(element);
          return textNodes;
        };

        let html = editor.getHTML();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // MP: Prosemirror, which Tiptap is based on, adds a trailing break to the end of paragraphs, but this is not
        // serialized to the HTML that we get from editor.getHTML(). We need to add it though, for reasons I honestly
        // don't really understand. Without this, the rendered textblocks will have missing line breaks.
        doc.querySelectorAll('div br').forEach((br) => {
          const div = br.closest('div');
          if (!div) return;
          if (br !== div.lastChild) return;

          const newBr = br.cloneNode();
          newBr.classList.add('trailing-break');
          br.parentNode.appendChild(newBr);
        });

        // Say we have something like this:
        // <div data-custom-style="1">
        //   <span data-custom-style="2">Some text</span>
        // </div>
        // If the parent element contains only other styled elements, then it doesn't need the style attribute itself,
        // and we can remove it. We do this mostly because of line-height -- say the parent has a line-height of 2 and
        // the child has a line-height of 1.5, the child will still have a line-height of 2, and there's not much we
        // can do about that, other than remove the parent's class.
        doc.querySelectorAll('[data-custom-style]').forEach((container) => {
          const usedStyles = [];
          getAllTextNodes(container).forEach((node) => {
            const parentNodeWithStyle = node.parentNode.closest('[data-custom-style]');
            if (!parentNodeWithStyle) return;
            if (parentNodeWithStyle === container) return;
            if (node.textContent.trim() === '') return;
            const customStyle = parentNodeWithStyle.getAttribute('data-custom-style');
            if (!usedStyles.includes(customStyle)) {
              usedStyles.push(customStyle);
            }
          });
          if (usedStyles.length === 1 && usedStyles[0] !== container.getAttribute('data-custom-style')) {
            container.removeAttribute('data-custom-style');
          }
        });

        html = doc.body.innerHTML;
        onUpdateTextBlock({ ...textBlockObj, block_text: html });
      }
    },
    editorProps: {
      transformPastedText(text) {
        // This may just be text, or it may be from the user pressing (Ctrl|Cmd)+Shift+V to paste. We parse the text as
        // HTML, even though it may be plain text, there should be no harm in doing so.
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        return doc.body.innerText;
      },
      transformPastedHTML(html) {
        if (html.includes('data-custom-style')) {
          // This is from an RTE3 editor, we don't need to do anything with it.
          return html;
        }

        // This is from somewhere else, possibly an old editor. We try to match the text style, window.styleIds is set
        // in MenuBar.handleChange.
        const styleId = (window.styleIds || {})[textBlockObj?.id];
        if (!styleId) {
          return html;
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return `<span data-custom-style="${styleId}">${doc.body.innerHTML}</span>`;
      },
    },
  });

  const previousContent = useRef(textBlockObj.block_text);
  const updateTimeout = useRef(null);

  useEffect(() => {
    if (editor && textBlockObj?.block_text && previousContent.current !== textBlockObj.block_text) {
      // Clear any existing timeout to debounce content update
      if (updateTimeout.current) {
        clearTimeout(updateTimeout.current);
      }

      // Set a timeout to update content after a delay, allowing the editor to manage real-time input
      updateTimeout.current = window.setTimeout(() => {
        // Only set content if the editor is not currently focused
        if (!editor.isFocused) {
          editor.commands.setContent(textBlockObj.block_text);
          previousContent.current = textBlockObj.block_text; // Update previous content ref
        }
      }, 300); // Delay of 300ms to debounce

      // Clear timeout when the component unmounts
      return () => {
        if (updateTimeout.current) clearTimeout(updateTimeout.current);
      };
    }
  }, [textBlockObj.block_text, editor]);

  const getSelectedText = () => {
    const { from, to } = editor.state.selection;
    const selectedText = editor.state.doc.textBetween(from, to, ' ');
    return selectedText;
  };

  const onMenuClick = async ({ type, move }) => {
    switch (type) {
      case 'link': {
        setLinkId(null);

        const selectedText = getSelectedText();
        if (!selectedText) {
          displayError('Please select the text to link before clicking on the link tool.');
        } else {
          setShowLinkTool((prev) => !prev);
        }
        break;
      }

      case 'is_offline': {
        onUpdateTextBlock({ ...textBlockObj, is_offline: !textBlockObj?.is_offline });
        break;
      }

      case 'trash': {
        try {
          const result = await confirm({
            title: <b>Confirm!</b>,
            message: 'Are you sure?',
            confirmText: 'Yes',
            confirmColor: 'success',
            cancelColor: 'btn btn-danger',
            cancelText: 'No',
          });

          if (result) {
            onUpdateTextBlock({ ...textBlockObj, _destroy: true });
          }
        } catch {
          displayError('Failed to delete the text block');
        }
        break;
      }

      case 'code': {
        onUpdateTextBlock({ ...textBlockObj, showSource: !textBlockObj?.showSource });
        break;
      }

      case 'history': {
        setShowHistory(!showHistory);
        break;
      }

      case 'media': {
        if (textBlockObj?.media && textBlockObj?.media?.id) {
          setMediaId(textBlockObj?.media?.id);
        } else {
          setToolData({
            toolType: 'media',
            isEditAction: false,
            mediaToolOnText: true,
            selectedMediaToolData: {},
            showModal: true,
          });
        }
        break;
      }

      case 'ai': {
        setAI(!ai);
        break;
      }

      case 'reorder': {
        onHandleMove(index, move);
        break;
      }

      default:
        break;
    }
  };

  const handleEditorClick = (event) => {
    const target = event.target;
    const link = target.closest('.editor__content a');

    if (link) {
      const dataId = link.getAttribute('data-id') || '';
      const editorElement = link.closest('.editor');
      const positionLeft = link.offsetLeft;
      const positionTop = link.offsetTop + link.offsetHeight - editorElement.scrollTop;
      if (dataId) {
        setLinkHelper({
          dataId: dataId,
          position: { top: positionTop, left: positionLeft },
        });

        return;
      }
    }

    setLinkHelper(null);
  };

  const handleUnlink = () => {
    if (editor) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run(); // Remove link
      setLinkHelper(null); // Close the helper
    }
  };

  const handleTextareaChange = (e) => {
    onUpdateTextBlock({ ...textBlockObj, block_text: e.target.value });
    // Update editor content if source mode is active
    if (editor && textBlockObj?.showSource) {
      editor.commands.setContent(e.target.value, false);
    }
  };

  const { data: textStyleList = [] } = useGetTextStylesQuery(site?.id, { skip: isEmpty(site?.id) });

  const editorStyles = () => {
    const elements = [...editor.view.dom.querySelectorAll('[data-custom-style]')].filter((el) => (el.innerText || '').trim() !== '');
    const presetColors = site?.colors || [];
    const textStyleColors = Array.from(elements)
      .map((n) => {
        const styleId = n.getAttribute('data-custom-style');
        const textStyle = textStyleList?.find(style => style.id === styleId && style.color !== '');
        return getColorVal(textStyle?.color, presetColors);
      })
      .filter(Boolean);

    if (!isEmpty(textStyleColors)) {
      return { backgroundColor: getContrastingColor(textStyleColors) };
    }

    return {};
  };

  const editorElement = (
    <>
      {toolData.showModal ? renderToolTypeComponent() : ''}
      <div id={`toolbarEditor_${index}`} key={editorCounter} className="rich-text-editor new-new-editor">
        <Row className="mt-3">
          <Col className={`col-${getColumnSize()}`}>
            <Card className="editor-outer border rounded">
              <ErrorBoundary fallback={(
                <div className="p-3">
                  Error:
                  <br />
                  <br />
                  <pre>
                    {textBlockObj?.block_text}
                  </pre>
                </div>
              )}
              >
                <div className="editor-toolbar p-2 border-bottom">
                  {editor && (
                    <MenuBar
                      index={index}
                      editor={editor}
                      onClick={onMenuClick}
                      features={features}
                      textBlockCount={countTextBlocks}
                      textBlock={textBlockObj}
                    />
                  )}
                </div>
                <div className="editor-container rte3 p-2">
                  <div className="style-prefix-container">
                    <div className="device-desktop">
                      <div className="editor" style={{ position: 'relative', ...editorStyles() }}>
                        {linkHelper && (
                          <LinkHelper
                            dataId={linkHelper.dataId}
                            position={linkHelper.position}
                            onEdit={(e) => {
                              setLinkId(e);
                              setShowLinkTool(!showLinkTool);
                              setLinkHelper(null);
                            }}
                            onUnlink={handleUnlink}
                          />
                        )}
                        {ai && (
                          <AIPromptWriter
                            onAppendContent={(string) => {
                              onUpdateTextBlock({ ...textBlockObj, block_text: textBlockObj.block_text + string });
                            }}
                          />
                        )}
                        {textBlockObj?.showSource
                          ? (
                              <textarea
                                value={editor?.getHTML() || ''}
                                className="form-control"
                                onChange={handleTextareaChange}
                              />
                            )
                          : (
                              <EditorContent
                                editor={editor}
                                className="editor__content type-ms3"
                                onClick={handleEditorClick}
                              />
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </ErrorBoundary>
            </Card>
          </Col>
          {hasFeature('media') && editorType !== 'raw' && (
            <Col className="col-3">
              <Card className="border rounded h-100">
                <CardBody>
                  <Row className="custom-btm-border">
                    <Col className="col-10">
                      <h5>Media for this textblock</h5>
                    </Col>
                    <Col className="col-2 text-end">
                      {textBlockObj?.media != null && textBlockObj?.media?.id && (
                        <Button
                          className="custom-simple-txt-btn"
                          size="xs"
                          onClick={onHandleDeleteMedia}
                        >
                          <b>
                            <i className="fal fa-trash" />
                          </b>
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row className="custom-btm-border">
                    <Col
                      className="col-12 show-text-block-media"
                      onClick={() => onClickMedia(textBlockObj?.media)}
                    >
                      <div className="preview-original-media">
                        {displayMedia(textBlockObj?.media)}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
      {showLinkTool && (
        <LinkToolBox
          linkId={linkId}
          toggle={() => setShowLinkTool(false)}
          isEmailPage={isEmailPage}
          selectedString={getSelectedText()}
          selectedTextBlockId={textBlockObj?.id}
          onLinkBoxSaveButton={(e) => {
            if (e?.url) {
              editor.chain().focus().extendMarkRange('link').setLink({
                'href': e?.url,
                'id': `link${e?.id}`,
                'data-id': e?.id,
                'data-textid': e?.text_block_id,
                'data-body': 'popup body text',
                'data-description': e?.link_detail,
                'data-button-style-id': e?.link_display === 'button' ? e?.button_style_id : null,
              }).run();
              setShowLinkTool(false);
            }
          }}
        />
      )}
    </>
  );

  if (hasFeature('no_card')) {
    return editorElement;
  }

  return (
    <>
      <Card className="mb-4">
        <CardBody>
          {editorElement}
        </CardBody>
      </Card>
      {showHistory && (
        <SectionHistory
          id={textBlockObj?.id}
          modal={showHistory}
          setModal={setShowHistory}
          onRestore={(e) => {
            onUpdateTextBlock({ ...textBlockObj, block_text: e?.original?.block_text });
            setEditorCounter(editorCounter + 1);
            setShowHistory(false);
          }}
        />
      )}
    </>
  );
}

export default withRouter(RichTextEditor3);
