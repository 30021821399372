import React from 'react';

interface ChatMessageProps {
  waiting?: boolean;
  name: string;
  message: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ waiting, name, message }) => (
  <div className={`chat-message chat-message-from-${name} ${waiting ? 'chat-message-waiting' : ''}`}>
    {name === 'assistant' && (
      <img src="/assets/images/avatar-icon.png" alt="AI Avatar" />
    )}
    {waiting && (
      <div className="nlux-comp-loaderContainer">
        <div className="spinning-loader" />
      </div>
    )}
    {!waiting && (
      <div className="chat-message-body">
        {message}
      </div>
    )}
  </div>
);

export default ChatMessage;
