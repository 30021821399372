import React, { useState } from 'react';
import {
  Col,
  Row,
  Label,
  Input,
  FormGroup,
  Container,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  Button,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  InputGroup,
  InputGroupText,
  ModalHeader,
} from 'reactstrap';
import {
  get,
  map,
  clone,
  isEmpty,
} from 'lodash-es';
import CarbonCopyEmail from '../../../components/common/carbonCopyEmail';
import RadioButton from '../../../components/common/radioButton';
import PageSelectBox from '../../../components/fields/page/PageSelectBox';
import CheckBox from '../../../components/common/checkBox';
import { displayInfo } from 'Dashboard/src/Utils';

function GoogleReviewEngine(props) {
  const { setParams, params } = props;
  const {
    review_threshold,
    review_pages,
    review_email_addresses,
    review_binary,
    review_star_labels,
  } = params;

  const [modal, setModal] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState('1');
  const [wgPlatformChecked, setWGPlatformChecked] = useState(false);
  const [includeAverage, setIncludeAverage] = useState(false);
  const [reviewListURL, setReviewListURL] = useState('');
  const toggleAccordion = (id) => {
    if (accordionOpen === id) {
      setAccordionOpen();
    } else {
      setAccordionOpen(id);
    }
  };

  const onEmailAddressChange = (email) => {
    setParams({ ...params, review_email_addresses: email });
  };

  if (isEmpty(params)) {
    return;
  }

  return (
    <>
      <Row>
        <Col lg={12} md={12}>
          <FormGroup>
            <Label className="has-tooltip" id="googlePlaceID">Google Place ID</Label>
            <UncontrolledTooltip placement="right" target="googlePlaceID" autohide={false} style={{ textAlign: 'left' }}>
              An ID which identifies a business in Google.
              <br />
              <br />
              <a
                href="https://developers.google.com/maps/documentation/places/web-service/place-id"
                target="_blank"
                rel="noreferrer"
              >
                More information
              </a>
            </UncontrolledTooltip>
            <Input
              type="text"
              id="google"
              autoComplete="off"
              pattern="^[A-Za-z0-9-_]+$"
              value={get(review_pages, 'google', '')}
              onChange={(e) => setParams({
                ...params,
                review_pages: {
                  ...review_pages,
                  google: e.target.value,
                },
              })}
            />
          </FormGroup>
        </Col>
        <Col lg={12} md={12}>
          <FormGroup>
            <Label for="facebook_review">Facebook Review URL</Label>
            <Input
              type="text"
              id="facebook"
              autoComplete="off"
              value={get(review_pages, 'facebook', '')}
              onChange={(e) => setParams({
                ...params,
                review_pages: {
                  ...review_pages,
                  facebook: e.target.value,
                },
              })}
            />
          </FormGroup>
        </Col>
        <Col lg={12} md={12}>
          <FormGroup>
            <Label>No Cowboys Review URL</Label>
            <Input
              type="text"
              id="nocowboys"
              autoComplete="off"
              value={get(review_pages, 'nocowboys', '')}
              onChange={(e) => setParams({
                ...params,
                review_pages: {
                  ...review_pages,
                  nocowboys: e.target.value,
                },
              })}
            />
          </FormGroup>
        </Col>
        <Col lg={12} md={12}>
          <FormGroup>
            <Label for="email_addresses">Email addresses to send reviews</Label>
            <CarbonCopyEmail
              placeholder="To"
              id="review_email_addresses"
              name="review_email_addresses"
              selectedEmails={review_email_addresses}
              onChange={(e) => onEmailAddressChange(e)}
            />
          </FormGroup>
        </Col>
        <Col lg={12} md={12}>
          <FormGroup>
            <Label>Review Model</Label>
            <ul className="custom-ul-layout d-flex gap-4">
              <RadioButton
                name="Review Model"
                title="Binary (Thumb Up / Thumbs Down)"
                checked={review_binary}
                onChange={() => {
                  setParams({ ...params, review_binary: true });
                }}
              />
              <RadioButton
                name="Review Model"
                title="Stars"
                checked={!review_binary}
                onChange={() => {
                  setParams({ ...params, review_binary: false });
                }}
              />
            </ul>
          </FormGroup>
        </Col>
        <Col lg={12} md={12}>
          <FormGroup>
            <Label for="review_threshold">Positive Review Threshold</Label>
            <ul className="custom-ul-layout d-flex gap-4">
              {map([1, 2, 3, 4, 5], (item, idx) => (
                <li key={idx}>
                  <RadioButton
                    name="Positive Review Threshold"
                    title={`${item}`}
                    checked={item === review_threshold}
                    onChange={() => {
                      setParams({ ...params, review_threshold: parseInt(item, 10) });
                    }}
                  />
                </li>
              ))}
            </ul>
          </FormGroup>
        </Col>
        {!review_binary && (
          <Col lg={12} md={12}>
            <FormGroup>
              <Label>Star Labels</Label>
              <Container fluid>
                {[4, 3, 2, 1, 0].map((n) => (
                  <Row key={n} className="align-items-center mb-1">
                    <Col xs={1}>
                      {n + 1}
                      :
                    </Col>
                    <Col>
                      <Input
                        value={review_star_labels && review_star_labels[n]}
                        onChange={(e) => {
                          const labelsClone = clone(review_star_labels);
                          labelsClone[n] = e.target.value;
                          setParams({ ...params, review_star_labels: labelsClone });
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </Container>
            </FormGroup>
          </Col>
        )}
        <Col lg={12} md={12}>
          <FormGroup>
            <Label for="review_list_page">Review List Page</Label>
            <PageSelectBox
              params={{ review_list_page_id: params.review_list_page_id }}
              keyName="review_list_page_id"
              onChange={(e) => setParams({ ...params, review_list_page_id: e ? e.value : null })}
            />
          </FormGroup>
        </Col>
        <Col lg={12} md={12}>
          <Button
            color="success"
            className="common-success-button button-md fw-bold"
            onClick={() => setModal(true)}
          >
            Embed HTML
          </Button>
        </Col>
      </Row>
      {modal && (
        <Modal
          isOpen={true}
          toggle={() => setModal(false)}
          size="lg"
          container={window.modalRef}
        >
          <ModalHeader toggle={() => setModal(false)}>Review Embed HTML</ModalHeader>
          <ModalBody>
            <CheckBox
              title="Web Genius-hosted site"
              value={true}
              onChange={(e) => setWGPlatformChecked(e.target.checked)}
              checked={wgPlatformChecked}
            />
            <Accordion open={accordionOpen} toggle={toggleAccordion}>
              <AccordionItem>
                <AccordionHeader targetId="list">Review List</AccordionHeader>
                <AccordionBody accordionId="list">
                  <CheckBox
                    title="Include average"
                    value={true}
                    onChange={(e) => setIncludeAverage(e.target.checked)}
                    checked={includeAverage}
                  />
                  <InputGroup>
                    <Input
                      type="textarea"
                      readOnly
                      style={{ fontFamily: 'monospace', fontSize: '12px', height: '150px' }}
                      value={`
                        <!---------------------------->
                        <!-- Web Genius Review List -->
                        <!---------------------------->
                        <div id=reviewList></div>
                        <script src="https://admin.webgenius.co.nz/api/v1/instances/${wgPlatformChecked ? '(InstanceID)' : params.instance_id}/sites/${wgPlatformChecked ? '(SiteID)' : params.id}/embed_review_list?${includeAverage ? 'include_average=1&' : ''}font=Open+Sans:300"></script>
                      `.trim().replaceAll(/[\r\n]\s+/g, '\n')}
                    />
                    <InputGroupText
                      onClick={(e) => {
                        navigator.clipboard.writeText(e.target.closest('.input-group').querySelector('textarea').value);
                        displayInfo('Copied to clipboard!');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="fal fa-clipboard-list" />
                    </InputGroupText>
                  </InputGroup>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="form">Review Form</AccordionHeader>
                <AccordionBody accordionId="form">
                  <InputGroup>
                    <Input
                      type="textarea"
                      readOnly
                      style={{ fontFamily: 'monospace', fontSize: '12px', height: '150px' }}
                      value={`
                        <!---------------------------->
                        <!-- Web Genius Review Form -->
                        <!---------------------------->
                        <div id=reviewForm></div>
                        <script src="https://admin.webgenius.co.nz/api/v1/instances/${wgPlatformChecked ? '(InstanceID)' : params.instance_id}/sites/${wgPlatformChecked ? '(SiteID)' : params.id}/embed_review_form?font=Open+Sans:300"></script>
                      `.trim().replaceAll(/[\r\n]\s+/g, '\n')}
                    />
                    <InputGroupText
                      onClick={(e) => {
                        navigator.clipboard.writeText(e.target.closest('.input-group').querySelector('textarea').value);
                        displayInfo('Copied to clipboard!');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="fal fa-clipboard-list" />
                    </InputGroupText>
                  </InputGroup>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="carousel">Review Carousel</AccordionHeader>
                <AccordionBody accordionId="carousel">
                  <FormGroup>
                    <Label>
                      Review List URL (overrides &quot;Review List Page&quot; setting. Enter a full URL, ie
                      {' '}
                      <code>https://www.example.com/pages/reviews</code>
                      )
                    </Label>
                    <Input
                      type="text"
                      value={reviewListURL}
                      onChange={(e) => setReviewListURL(e.target.value)}
                    />
                  </FormGroup>
                  <InputGroup>
                    <Input
                      type="textarea"
                      readOnly
                      style={{ fontFamily: 'monospace', fontSize: '12px', height: '140px' }}
                      value={`
                        <!-------------------------------->
                        <!-- Web Genius Review Carousel -->
                        <!-------------------------------->
                        <script src="https://admin.webgenius.co.nz/api/v1/instances/${wgPlatformChecked ? '(InstanceID)' : params.instance_id}/sites/${wgPlatformChecked ? '(SiteID)' : params.id}/embed_review_widget?font=Open+Sans:400${!isEmpty(reviewListURL) ? `&url=${encodeURIComponent(reviewListURL)}` : ''}"></script>
                      `.trim().replaceAll(/[\r\n]\s+/g, '\n')}
                    />
                    <InputGroupText
                      onClick={(e) => {
                        navigator.clipboard.writeText(e.target.closest('.input-group').querySelector('textarea').value);
                        displayInfo('Copied to clipboard!');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="fal fa-clipboard-list" />
                    </InputGroupText>
                  </InputGroup>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

export default GoogleReviewEngine;
