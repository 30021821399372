import React, { useEffect } from 'react';
import { createStore } from '../../../rootStore';
import DialogBox from 'PublicFacingSite/Components/DialogBox';

type PlatformType = 'google' | 'facebook' | 'nocowboys' | string;

interface HighRatingFormHTMLProps {
  modal?: boolean;
  toggle: (state: boolean) => void;
}

interface PageData {
  site: {
    review_pages: Record<string, string>;
  };
}

const platformName = (platform: PlatformType): string => {
  switch (platform) {
    case 'google':
      return 'Google';
    case 'facebook':
      return 'Facebook';
    case 'nocowboys':
      return 'No Cowboys';
    default: return '';
  }
};

const platformURL = (platform: PlatformType, url: string): string => {
  switch (platform) {
    case 'google':
      return `https://search.google.com/local/writereview?placeid=${url}`;
    case 'facebook':
      return url;
    case 'nocowboys':
      return `${url}#rate`;
    default: return '';
  }
};

const HighRatingFormHTML: React.FC<HighRatingFormHTMLProps> = ({ modal = false, toggle }) => {
  const store = createStore();
  const { pageData: { site: { review_pages } } } = store.getState() as { pageData: PageData };
  const reviewPages = Object.keys(review_pages).filter((e) => review_pages[e] !== '');

  useEffect(() => {
    if (modal && reviewPages.length === 1) {
      const timeoutId = setTimeout(() => {
        window.open(platformURL(reviewPages[0], review_pages[reviewPages[0]]));
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [modal, reviewPages, review_pages]);

  const message = (): string => {
    if (reviewPages.length > 1) {
      return 'Would you share your experience on one of these sites?';
    }
    if (reviewPages.length === 1) {
      return `You will shortly be redirected to ${platformName(reviewPages[0])}. Please place your review there.`;
    }
    return '';
  };

  const platformHTML = (): React.ReactNode[] => reviewPages.map((page) => {
    if (review_pages[page] !== '') {
      return (
        <div className="review-link-wrap" key={page}>
          <a data-platform={page} target="_blank" href={platformURL(page, review_pages[page])} className="d-block" rel="noreferrer">
            <div className={`review-source review-source-large review-source-${page} d-block`} />
            {platformName(page)}
          </a>
        </div>
      );
    }
    return null;
  });

  return (
    <DialogBox
      open={modal}
      toggle={toggle}
      content={(
        <>
          <p>
            Thank you!
            {' '}
            <strong>We need your help.</strong>
            {' '}
            {message()}
          </p>
          <div id="review-links">
            {platformHTML()}
          </div>
        </>
      )}
    />
  );
};

export default HighRatingFormHTML;
