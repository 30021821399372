import React, { useEffect, useState } from 'react';
import { Row, Card, CardTitle, Form, Label, Button, Input } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { validateEmail, setPageTitle } from '../../../../Utils';
import { displaySuccess, displayError } from 'Dashboard/src/Utils';
import { Colxx } from '../../components/common/CustomBootstrap';
import IntlMessages from '../../helpers/IntlMessages';
import './index.scss';
import { useResetPasswordMutation } from '../../api/dashboardApiSlice';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [resetPassword] = useResetPasswordMutation();

  useEffect(() => {
    setPageTitle('Forgot Password');
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      const response = await resetPassword({ email });
      if (response && response?.data?.meta?.is_success) {
        displaySuccess('A link to reset your password has been sent to your email address. Please check your inbox and follow the instructions to complete the process.');
      }
    } else {
      displayError('Email is not valid');
    }
  };

  return (
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side" />
          <div className="form-side">
            <NavLink to="/" className="white">
              <span className="logo-single" />
            </NavLink>
            <CardTitle className="mb-4">
              <IntlMessages id="user.forgot-password" />
            </CardTitle>
            <Form onSubmit={onSubmit}>
              <Label className="form-group has-float-label mb-4">
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  autoFocus
                />
                <IntlMessages id="user.email" />
              </Label>
              <Label className="has-float-label text-end">
                <NavLink to="/user/login">
                  Remembered your password? Back to login
                </NavLink>
              </Label>

              <div className="d-flex justify-content-center align-items-center">
                <Button
                  color="primary"
                  className="btn-shadow"
                  size="lg"
                  onClick={onSubmit}
                >
                  <IntlMessages id="user.reset-password-button" />
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </Colxx>
    </Row>
  );
}
export default ForgotPassword;
