import React, { useState, useMemo } from 'react';
import { Card, CardBody, Table, Input, Button, InputGroup, InputGroupText } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import { useKeywordResearchToolMutation } from 'Dashboard/src/api/dashboardApiSlice';
import { MaterialReactTable } from 'material-react-table';

interface IKeywordDataMetrics {
  keywords: string;
  avg: number;
  competition: string;
}

interface IKeywordDataIdeas {
  seed: string;
  text: string;
  avg: number;
  competition: string;
}

function KeywordResearchTool() {
  const [customerId, setCustomerId] = useState('487-990-1735');
  const [seedKeywords, setSeedKeywords] = useState<string[]>([]);
  const [keywordResearchTool] = useKeywordResearchToolMutation();
  const [results, setResults] = useState({
    metrics: [] as IKeywordDataMetrics[],
    ideas: [] as IKeywordDataIdeas[],
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'seed',
        header: 'Seed Keyword',
        filterVariant: 'multi-select' as const,
        filterSelectOptions: seedKeywords,
      },
      {
        accessorKey: 'text',
        header: 'Keywords',
        enableGrouping: false,
      },
      {
        accessorKey: 'avg',
        Cell: ({ renderedCellValue }) => new Intl.NumberFormat().format(renderedCellValue),
        header: 'Search Volume',
        enableGrouping: false,
      },
      {
        accessorKey: 'competition',
        header: 'Competition',
        filterVariant: 'multi-select' as const,
        filterSelectOptions: [
          'LOW',
          'MEDIUM',
          'HIGH',
          'UNSPECIFIED',
        ],
        enableGrouping: false,
      },
    ], [results]);

  return (
    <Card>
      <CardBody>
        <h1>Google Ads Keyword Research Tool</h1>
        <p>Customer ID</p>
        <Input
          type="text"
          className="mb-3"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
        />
        <p>Seed keywords</p>
        {new Array(4).fill(0).map((_, i) => (
          <InputGroup key={i} className="mb-3">
            <InputGroupText>{i + 1}</InputGroupText>
            <Input
              value={seedKeywords[i] || ''}
              onPaste={(e) => {
                const newSeedKeywords = [...seedKeywords];
                const pastedKeywords = e.clipboardData.getData('Text').split(/[\r\n\t]+/);
                pastedKeywords.forEach((keyword, j) => {
                  if (j < 4) {
                    newSeedKeywords[i + j] = keyword;
                  }
                });
                setSeedKeywords(newSeedKeywords);
              }}
              onChange={(e) => {
                if ((e.nativeEvent as InputEvent).inputType === 'insertFromPaste') {
                  // We are handling pasting above
                  return;
                }

                const newSeedKeywords = [...seedKeywords];
                newSeedKeywords[i] = e.target.value;
                setSeedKeywords(newSeedKeywords);
              }}
            />
          </InputGroup>
        ))}
        <Button
          color="success"
          type="submit"
          className="button-sm fw-bold btn btn-success btn-lg common-success-button mb-3"
          onClick={async () => {
            const result = await keywordResearchTool({ seed_keywords: seedKeywords.join('\n'), customer_id: customerId });
            setResults(result.data.data);
          }}
        >
          GET DATA
        </Button>
        {results.metrics.length > 0 && (
          <>
            <h2>Metrics</h2>
            <Table>
              <thead>
                <tr>
                  <th>Keywords</th>
                  <th>Average Monthly Searches (last 12 months)</th>
                  <th>Competition</th>
                </tr>
              </thead>
              <tbody>
                {results.metrics.map((keywordData) => (
                  <tr key={keywordData.keywords}>
                    <td>{keywordData.keywords}</td>
                    <td>{new Intl.NumberFormat().format(keywordData.avg)}</td>
                    <td>{keywordData.competition}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
        {results.ideas.length > 0 && (
          <>
            <h2>Ideas</h2>
            <MaterialReactTable
              columns={columns}
              data={results.ideas}
              enableGrouping={true}
              initialState={{
                grouping: ['seed'],
                expanded: true,
                showColumnFilters: true,
                sorting: [{ id: 'avg', desc: true }],
                pagination: { pageSize: 50, pageIndex: 0 },
              }}
            />
            <br />
            <br />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                const csv = [
                  ['Seed Keyword', 'Keywords', 'Search Volume', 'Competition'],
                  ...results.ideas.map((keywordData) => [
                    keywordData.seed,
                    keywordData.text,
                    keywordData.avg,
                    keywordData.competition,
                  ]),
                ].map((row) => row.join(',')).join('\n');
                const blob = new Blob([csv], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'keyword-research-tool.csv';
                a.click();
              }}
            >
              Download CSV
            </a>
          </>
        )}
      </CardBody>
    </Card>
  );
}

export default withRouter(KeywordResearchTool);
