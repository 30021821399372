import React, { useState } from 'react';
import { validateEmail } from '../../../Utils';
import { createStore } from '../../../rootStore';
import DialogBox from 'PublicFacingSite/Components/DialogBox';
import { RootState } from 'ts/interfaces';

interface LowRatingFormHTMLProps {
  modal?: boolean;
  toggle: (state: boolean) => void;
}

interface FormState {
  name: string;
  email: string;
  phone: string;
  message: string;
  [key: string]: string;
}

const LowRatingFormHTML: React.FC<LowRatingFormHTMLProps> = ({ modal = false, toggle }) => {
  const [lowRatingForm, setLowRatingForm] = useState<FormState>({
    name: '', email: '', phone: '', message: '',
  });

  const store = createStore();
  const { pageData: { instance, site } } = store.getState() as RootState;

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { target: { name, value } } = e;
    setLowRatingForm({ ...lowRatingForm, [name]: value });
  };

  const onSubmitForm = (): void => {
    const {
      name, email, phone, message,
    } = lowRatingForm;
    if (name === '') {
      alert('Please enter your name.');
    } else if (!validateEmail(email)) {
      alert('Please enter a valid email address.');
    } else {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('message', message);

      const searchParams = new URLSearchParams();
      for (const [key, value] of formData.entries()) {
        searchParams.append(key, value as string);
      }
      const queryString = searchParams.toString();
      fetch(`/api/v1/instances/${instance?.id}/sites/${site?.id}/negative_review_form?${queryString}`, {
        method: 'POST',
      }).then((response) => {
        response.json().then(() => {
          toggle(false);
          setLowRatingForm({
            name: '', email: '', phone: '', message: '',
          });
          alert('Your message has been submitted. Thank you for your feedback!');
        });
      });
    }
  };

  return (
    <DialogBox
      open={modal}
      toggle={toggle}
      content={(
        <>
          <p>We strive for 100% customer satisfaction. If we fell short, please tell us more so we can address your concerns.</p>
          <div className="row mb-2">
            <div className="col">
              <label htmlFor="review-name">
                Your name
                <b>*</b>
              </label>
              <input
                type="text"
                className="form-control"
                id="review-name"
                name="name"
                placeholder="Your name"
                value={lowRatingForm?.name || ''}
                onChange={(e) => onHandleChange(e)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <label htmlFor="review-phone">Phone</label>
              <input
                type="text"
                className="form-control"
                id="review-phone"
                name="phone"
                placeholder="Phone"
                value={lowRatingForm?.phone || ''}
                onChange={(e) => onHandleChange(e)}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <label htmlFor="review-email">
                Email
                <b>*</b>
              </label>
              <input
                type="text"
                className="form-control"
                id="review-email"
                name="email"
                placeholder="Email"
                value={lowRatingForm?.email || ''}
                onChange={(e) => onHandleChange(e)}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <label htmlFor="review-message">Message</label>
              <input
                type="textarea"
                className="form-control"
                id="review-message"
                name="message"
                placeholder="Message"
                value={lowRatingForm?.message || ''}
                onChange={(e) => onHandleChange(e)}
              />
            </div>
          </div>
        </>
      )}
      footerButtons={[
        {
          onClick: onSubmitForm,
          label: 'Send Message',
        },
      ]}
    />
  );
};

export default LowRatingFormHTML;
