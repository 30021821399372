import getMacros from './getMacros';

type MacroTuple = [string | RegExp, string, boolean];

/**
 * Replace macros in a string
 * @param s - The string to replace macros in
 * @returns The string with macros replaced
 */
const macroReplacerString = (s: string): string => {
  const macros = getMacros() as MacroTuple[];

  let replaced = '' + s;

  // Currently we don't do any HTML escaping here.
  macros.filter((m) => !m[2]).forEach(([macro, replacement]) => {
    replaced = replaced.replaceAll(macro, replacement);
  });

  return replaced;
};

export default macroReplacerString;
