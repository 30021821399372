import Base from './Base';

interface AITextPromptProps {
  id?:               string;
  system_prompt_id?: string;
  type?:             string;
  context?:          string;
  name?:             string;
  text?:             string;
  description?:      string;
  temperature?:      number;
  top_p?:            number;
}

class AITextPrompt extends Base {
  id?:               string;
  system_prompt_id?: string;
  type?:             string;
  context?:          string;
  name?:             string;
  text?:             string;
  description?:      string;
  temperature?:      number;
  top_p?:            number;

  constructor(props: AITextPromptProps) {
    super(props);
  }
}

export default AITextPrompt;
