import React, { useEffect } from 'react';
import { Button, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { setPageTitle } from '../../../../../Utils';
import HeaderComponent from 'Dashboard/src/components/common/header-component';
import UserFeatureComponent from 'Dashboard/src/components/common/user-feature-component';
import { settingRoutes, replacePathParams, userRoutes, contentCenterRoutes, mainRoutes } from 'Dashboard/src/constants/routes';
import withRouter from 'Dashboard/src/helpers/withRouter';

function SettingHome(props) {
  const { navigate } = props;

  useEffect(() => {
    setPageTitle('Settings');
  }, []);

  const redirectToListing = (path) => {
    navigate(replacePathParams(path, [], props));
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Settings', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Settings',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: replacePathParams(settingRoutes.dashboard, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card className="mb-3">
        <CardBody>
          <CardTitle>Instance Settings</CardTitle>
          <Row className="settings-menu-outer">
            <UserFeatureComponent feature={[
              'set_instance_name',
              'set_instance_as_master',
              'instance_settings',
            ]}
            >
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.instanceSettings)}>
                  <i className="fal fa-cogs" />
                  <p>General</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="manage_sites">
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.siteList)}>
                  <i className="fal fa-sitemap" />
                  <p>Sites</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="connections">
              <Col lg={2}>
                <Button color="info" onClick={() => navigate(replacePathParams(settingRoutes.services, [], props))}>
                  <i className="fa-light fa-link" />
                  <p>Connections</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="trash">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(settingRoutes.trash, [
                    { key: 'pageNo', value: 1 },
                  ], props))}
                >
                  <i className="fal fa-trash" />
                  <p>Trash</p>
                </Button>
              </Col>
            </UserFeatureComponent>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle>System</CardTitle>
          <Row className="settings-menu-outer">
            <UserFeatureComponent
              feature={[
                'view_all_users',
                'create_new_users',
                'create_login_qr_codes',
                'delete_user',
                'add_gmail_signature',
              ]}
            >
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(userRoutes.usersList)}>
                  <i className="fal fa-users" />
                  <p>Users</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="view_briefings">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(settingRoutes.siteBriefings, [
                    { key: 'pageNo', value: 1 },
                  ], props))}
                >
                  <i className="fal fa-people-group" />
                  <p>Briefings</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="system_logs">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(settingRoutes.logs, [
                    { key: 'pageNo', value: 1 },
                  ], props))}
                >
                  <i className="fal fa-clipboard-list" />
                  <p>System Logs</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="duplicate_instance">
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.duplicateInstance)}>
                  <i className="fal fa-copy" />
                  <p>Duplicate Instance</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="import_instance">
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.importInstance)}>
                  <i className="fal fa-file-import" />
                  <p>Import Instance From Legacy</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="misc_tools">
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.tools)}>
                  <i className="fa-light fa-toolbox" />
                  <p>Tools</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="reports">
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.reports)}>
                  <i className="fa-light fa-chart-mixed" />
                  <p>Reports</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="reports">
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.automatedReports)}>
                  <span className="fa-stack">
                    <i className="fa-light fa-file fa-stack-1x" />
                    <i className="fa-light fa-clock fa-stack-1x fa-sm" />
                  </span>
                  <p>Automated Reports</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="view_roi_report_list">
              <Col lg={2}>
                <Button color="info" onClick={() => redirectToListing(settingRoutes.roiReports)}>
                  <i className="fa-light fa-money-bill-trend-up" />
                  <p>ROI Reports</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="manage_scheduled_jobs">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(settingRoutes.backgroundJobs, [
                    { key: 'pageNo', value: 1 },
                  ], props))}
                >
                  <i className="fa-light fa-timer" />
                  <p>Background Jobs</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="manage_components">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(settingRoutes.componentCategoryList, [], props))}
                >
                  <i className="fal fa-puzzle" />
                  <p>Components</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="content_briefings">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(contentCenterRoutes.contentCenter, [], props))}
                >
                  <i className="fal fa-file-lines" />
                  <p>Content Centre</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="ai_text_prompts">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(mainRoutes.aiTextPrompts, [], props))}
                >
                  <i className="fa-light fa-head-side-brain" />
                  <p>AI Text Prompts</p>
                </Button>
              </Col>
            </UserFeatureComponent>
            <UserFeatureComponent feature="spam_form_submissions">
              <Col lg={2}>
                <Button
                  color="info"
                  onClick={() => navigate(replacePathParams(mainRoutes.spamFormSubmissions, [], props))}
                >
                  <i className="fa-light fa-can-food" />
                  <p>Spam Form Submissions</p>
                </Button>
              </Col>
            </UserFeatureComponent>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(SettingHome);
