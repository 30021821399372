import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { useInstance, useSite, useUser } from 'common/hooks';
import DialogBox from '../../../../PublicFacingSite/Components/DialogBox/index';
import { cable, DashboardChatChannel } from 'Dashboard/src/Channels';
import Chat from 'Dashboard/src/components/Chat';
import './index.css';

interface ThreadResponse {
  data: string;
}

type Message = object | string | number;

const DashboardChat: React.FC = () => {
  const instance = useInstance();
  const site = useSite();
  const user = useUser();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [channel, setChannel] = useState<DashboardChatChannel | null>(null);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [streamReceiver, setStreamReceiver] = useState<(() => (observer: any) => void) | null>(null);

  useEffect(() => {
    if (!threadId) {
      return;
    }

    const _channel = new DashboardChatChannel({ thread_id: threadId });
    cable.subscribe(_channel);
    setChannel(_channel);

    setStreamReceiver(() => (observer) => {
      _channel?.on('message', (messageString: Message) => {
        observer.next(messageString.toString());
      });
    });
  }, [threadId]);

  useEffect(() => {
    if (!isOpen || !instance || !site) {
      return;
    }

    if (threadId) {
      return;
    }

    (async () => {
      const response = await fetch(`/api/v1/dashboard_chats?instance_id=${instance?.id}&site_id=${site?.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const responseData: ThreadResponse = await response.json();
      setThreadId(responseData.data);
    })();
  }, [isOpen, instance, site, threadId]);

  if (!instance || !site || !user) {
    return null;
  }

  const chatbot = streamReceiver
    ? (
        <div className="chatbot">
          <Chat
            initialMessage="Hello! How can I help you today?"
            onSendFromUser={(message) => {
              if (!channel) {
                return;
              }

              channel.sendMessage({
                instance_id: instance.id!,
                site_id: site.id!,
                thread_id: threadId,
                user_message: message,
              });
            }}
            streamReceiver={streamReceiver}
          />
        </div>
      )
    : null;

  return (
    <>
      <DialogBox
        open={isOpen}
        toggle={setIsOpen}
        content={chatbot}
        size="lg"
        dismissOnClickOutside={false}
        header=" "
      />

      <Button
        className="dashboard-chat-button"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        <i className="fal fa-message-bot" />
      </Button>
    </>
  );
};

export default DashboardChat;
