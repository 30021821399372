import React, { useEffect, useState } from 'react';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import { cloneDeep, isEmpty } from 'lodash-es';
import withRouter from '../../../../helpers/withRouter';
import { useGetSectionQuery, useSectionUpdateMutation } from '../../../../api/dashboardApiSlice';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import CustomSelect from 'Dashboard/src/components/common/custom-select';

function Ecwid(props) {
  const {
    section: { id: sectionId },
    isEmailPage,
    saveCounter,
    params: { instanceId, siteId },
    updateStylesheet,
  } = props;

  const [tempSection, setTempSection] = useState(null);

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const [sectionUpdate] = useSectionUpdateMutation();

  useEffect(() => {
    if (section) {
      setTempSection(cloneDeep(section));
    }
  }, [section]);

  const onSave = async (payload) => {
    await sectionUpdate({
      ...payload,
      instance_id: instanceId,
    });

    updateStylesheet();
  };

  useEffect(() => {
    if (saveCounter) {
      (async () => {
        await onSave(tempSection);
      })();
    }
  }, [saveCounter]);

  if (isEmpty(tempSection)) {
    return <></>;
  }

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        section={tempSection}
        setSection={setTempSection}
        handleSaveBreakpoint={(e) => setTempSection({ ...tempSection, break_point: e })}
        onHandleSectionIsOffline={() => setTempSection({ ...tempSection, is_offline: !tempSection?.is_offline })}
        onChangeInitialVisibility={() => setTempSection({ ...tempSection, initial_visibility: !tempSection?.initial_visibility })}
        onHandleChangeVisibility={(e) => setTempSection({ ...tempSection, visible_to: e.value })}
      />
      <Row className="mt-4">
        <Col>
          <FormGroup>
            <Label>Element</Label>
            <CustomSelect
              value={tempSection.element}
              options={[
                { value: 'category_list', label: 'Category List' },
                { value: 'product_list', label: 'Product List' },
              ]}
              onChange={(e) => setTempSection({ ...tempSection, element: e.value })}
            />
          </FormGroup>
        </Col>
      </Row>
      {tempSection.element === 'product_list' && (
        <Row className="mt-4">
          <Col>
            <FormGroup>
              <Label>Category ID</Label>
              <Input
                type="number"
                value={tempSection.category_id}
                onChange={(e) => setTempSection({ ...tempSection, category_id: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </>
  );
}

export default withRouter(Ecwid);
