import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import macroReplacerString from '../../macroReplacerString';
import { replaceLinks } from '../../../Utils';
import Section from 'common/models/Section';
import { RootState } from 'ts/interfaces';

interface SectionComponentProps {
  section: Section;
  onClick: (section: Section) => void;
}

function SectionComponent({ section, onClick }: SectionComponentProps) {
  const allLinks = useSelector((state: RootState) => state.pageData.link);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (section.boot_func) {
      try {
        // Would normally do an eval here, but it's helpful to be able to `return;` from the boot_func, and we can do
        // this with Function().
        new Function(section.boot_func)();
      } catch (error) {
        console.error(`Error in boot_func for section ${section.id}:`, error);
      }
    }
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!section.full_size) {
      return;
    }

    const component = ref.current.querySelector(`#component-section-${section.id}`) as HTMLElement | null;

    if (!component) {
      return;
    }

    const wrapper = component.closest('.wrapper') as HTMLElement | null;
    const sector = component.closest('.sector') as HTMLElement | null;

    if (!wrapper || !sector) {
      // We may be in the admin dashboard.
      return;
    }

    wrapper.style.margin = '0';
    wrapper.style.padding = '0';

    sector.style.width = '100%';
    sector.style.margin = '0';
    sector.style.padding = '0';
  }, [ref]);

  if (section.html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(section.html, 'text/html');

    const body = doc.body;

    if (!body) {
      return null;
    }

    const updatedHtml = (allLinks ? replaceLinks(body, allLinks) : body) as HTMLElement;

    // The server-side code returns the full section HTML including the <a> and <section> tags.
    return (
      <div
        ref={ref}
        dangerouslySetInnerHTML={{ __html: macroReplacerString(updatedHtml.outerHTML) }}
        onClick={() => onClick(section)}
      />
    );
  }

  return <></>;
}

export default SectionComponent;
