import Base from './Base';

interface AIModelProps {
  name?:             string;
  id?:               string;
}

class AIModel extends Base {
  name?:             string;
  id?:               string;

  constructor(props: AIModelProps) {
    super(props);
  }
}

export default AIModel;
