import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../components/common/header-component';
import { deliveryCenterRoutes, settingRoutes, replacePathParams } from '../../constants/routes';
import { setPageTitle, validateEmail, selectColorStyles, selectBoxOptions } from '../../../../Utils';
import { displayError } from 'Dashboard/src/Utils';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Label,
  InputGroupText,
  Input,
  InputGroup,
} from 'reactstrap';
import CustomButton from '../../components/common/button';
import RadioButton from '../../components/common/radioButton';
import CarbonCopyEmail from '../../components/common/carbonCopyEmail';
import { isEmpty, map, cloneDeep, get } from 'lodash-es';
import confirm from '../../components/common/confirm';
import CustomSelect from '../../components/common/custom-select';
import {
  useCreateEmailSeriesMutation,
  useGetEmailSeriesByIdQuery,
  useGetEmailsQuery,
  useGetSitesQuery,
  useUpdateEmailSeriesMutation,
  useGetDomainsQuery,
} from '../../api/dashboardApiSlice';
import CheckBox from '../../components/common/checkBox';
import SaveAndContinue from '../../components/common/saveAndContinue';
import { useParams } from 'react-router-dom';
import BottomActionToolbar from '../../components/common/toolbar';
import withRouter from '../../helpers/withRouter';
import { useSite } from 'common/hooks';
import EmailInputWithDomain from 'Dashboard/src/components/EmailInputWithDomain';

let titleName = 'New Series';

const triggersType = {
  MEMBER_SUBMITING_A_FORM: 'member_submitting_a_form',
  MEMBER_JOINING_A_DATABASE: 'member_joining_a_database',
  MEMBER_JOINING_VIA_SITES: 'member_joining_a_site',
};

function EmailSeries(props) {
  const { navigate } = props;
  const { id, instanceId } = useParams();
  const site = useSite();
  const [params, setParams] = useState(null);
  const [selectedSites, setSelectedSites] = useState([]);
  const [createEmailSeries] = useCreateEmailSeriesMutation();
  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });
  const { data: emailPages = [] } = useGetEmailsQuery({ instanceId }, { skip: isEmpty(instanceId) });
  const resp = useGetEmailSeriesByIdQuery({ id, instance_id: instanceId }, { skip: isEmpty(id) || isEmpty(instanceId) });
  const { data: emailSeries } = resp;
  const [updateSeries] = useUpdateEmailSeriesMutation();
  const { data: domains = [] } = useGetDomainsQuery(site?.id, { skip: !site?.id });

  const emailDomain = domains.find((domain) => domain.is_email);

  useEffect(() => {
    if (id) {
      titleName = 'Edit Series';
    }
    setPageTitle(titleName);
  });

  useEffect(() => {
    if (isEmpty(emailSeries)) {
      return;
    }
    setParams(emailSeries);
    setSelectedSites(emailSeries.email_series_triggers?.filter((t) => t.type === triggersType.MEMBER_JOINING_VIA_SITES).map((t) => t.trigger_id));
  }, [emailSeries]);

  const name = get(params, 'name', '');
  const runOnce = get(params, 'run_once', true);
  const fromName = get(params, 'from_name', '');
  const fromEmail = get(params, 'from_email', '');
  const extraEmails = get(params, 'extra_emails', []);
  const restartDays = get(params, 'restart_days', 0);
  const emailSeriesEmails = get(params, 'email_series_emails', []);
  const emailTriggers = get(params, 'email_series_triggers', []);

  const onHandleCancel = () => {
    navigate(replacePathParams(deliveryCenterRoutes.emailSeries, [], props));
  };

  const onHandleEmailChange = (email) => {
    setParams({ ...params, extra_emails: email });
  };

  const addEmailTemplate = () => {
    setParams({
      ...params,
      email_series_emails: [
        ...(params ? params.email_series_emails : []),
        {
          delay: 0,
          subject: '',
          page_email_id: '',
        },
      ],
    });
  };

  const onHandleChange = (e, index) => {
    const { value } = e.target;
    const tempState = cloneDeep(params);
    tempState.email_series_emails[index][e.target.name] = value;
    setParams(tempState);
  };

  const onHandleChangeTemplate = (e, index) => {
    const { value } = e;
    const tempState = cloneDeep(params);
    tempState.email_series_emails[index].page_email_id = value;
    setParams(tempState);
  };

  const onDeleteEmail = async (index) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        const tempState = cloneDeep(params);
        if (tempState.email_series_emails[index]) {
          tempState.email_series_emails[index]._destroy = true;
          setParams(tempState);
        }
      }
    } catch (catchError) {
      displayError(catchError);
    }
  };

  const emailTemplateOptions = selectBoxOptions(emailPages, 'name', 'id');

  const renderEmailPages = (emails) => {
    if (isEmpty(emails)) {
      return 'No emails found.';
    }

    return emails.map((item, rowIndex) => (
      item._destroy
        ? (
            null
          )
        : (
            <Row className="mb-2 align-items-center" key={rowIndex}>
              <Col>
                <CustomSelect
                  options={emailTemplateOptions}
                  onChange={(e) => onHandleChangeTemplate(e, rowIndex)}
                  value={emailTemplateOptions.filter((res) => res.value === item.page_email_id)}
                  styles={selectColorStyles}
                />
              </Col>
              <Col>
                <Input
                  type="number"
                  name="delay"
                  value={item.delay}
                  onChange={(e) => onHandleChange(e, rowIndex)}
                />
              </Col>
              <Col>
                <Input
                  type="text"
                  name="subject"
                  value={item.subject}
                  onChange={(e) => onHandleChange(e, rowIndex)}
                />
              </Col>
              <Col>
                <CustomButton
                  className="custom-simple-icon"
                  color="danger"
                  style={{ background: '#dc3545' }}
                  size="xs"
                  title=" "
                  icon="fal fa-trash"
                  onClick={() => onDeleteEmail(rowIndex)}
                />
              </Col>
            </Row>
          )
    ));
  };

  const onHandleSave = async () => {
    if (emailSeriesEmails.some((e) => isEmpty(e.page_email_id) || isEmpty(e.subject))) {
      displayError('Please enter all required fields.');
      return false;
    }

    let fromEmailWithDomain;
    if (fromEmail.includes('@')) {
      fromEmailWithDomain = fromEmail;
    } else {
      fromEmailWithDomain = `${fromEmail}@${emailDomain?.domain_name}`;
    }

    if (isEmpty(name)) {
      displayError('Please enter series name.');
      return false;
    } else if (!validateEmail(fromEmailWithDomain)) {
      displayError('Enter a valid from email address.');
      return false;
    } else {
      const sendParams = {
        id,
        name,
        run_once: runOnce,
        from_name: fromName,
        from_email: fromEmail,
        instance_id: instanceId,
        extra_emails: extraEmails,
        restart_days: restartDays,
        email_series_emails_attributes: emailSeriesEmails,
        email_series_triggers_attributes: emailTriggers,
      };

      if (id) {
        updateSeries(sendParams);
      } else {
        delete sendParams.id;
        const result = await createEmailSeries(sendParams);

        // We have just created a new record, navigate to the edit page so that subsequent saves don't try to create another new record.
        navigate(replacePathParams(deliveryCenterRoutes.editSeries, [{ key: 'id', value: result.data.data.id }], props));
      }
    }
  };

  const getTriggerTitle = (item) => {
    switch (item.type) {
      case triggersType.MEMBER_SUBMITING_A_FORM:
        return `On submission of form #${item.trigger_id} on page "${item.page_name}"`;
      case triggersType.MEMBER_JOINING_A_DATABASE:
        return 'Member joining via Data Centre';
      default: return '';
    }
  };

  const onHandleTriggerChange = (e, item, index) => {
    const { checked } = e.target;
    if (emailTriggers && emailTriggers[index]) {
      const tempState = cloneDeep(params);
      tempState.email_series_triggers[index]._destroy = !checked;
      setParams(tempState);
    }
  };

  const onDataCenterChange = (e) => {
    const { checked } = e.target;
    const triggerIndex = emailTriggers.findIndex((item) => item.type === triggersType.MEMBER_JOINING_A_DATABASE);
    const tempState = cloneDeep(params);
    if (triggerIndex > -1) {
      tempState.email_series_triggers[triggerIndex]._destroy = !checked;
    } else {
      tempState.email_series_triggers.push({
        type: triggersType.MEMBER_JOINING_A_DATABASE,
      });
    }
    setParams(tempState);
  };

  const currentScreenName = id ? 'Editing Series' : 'New Series';

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Delivery Centre',
      url: replacePathParams(deliveryCenterRoutes.deliveryCenter, [], props),
    },
    { name: id ? name : 'New series', url: '' },
  ];

  const siteOptions = (data) => map(data, (ele) => ({
    label: ele.label,
    value: ele.id,
  }));

  const onHandleSiteChange = (e) => {
    const siteIds = Array.isArray(e) ? e.map((x) => x.value) : [];
    setSelectedSites(siteIds);

    const tempState = cloneDeep(params);

    tempState.email_series_triggers.forEach((trigger) => {
      if (trigger.type === triggersType.MEMBER_JOINING_VIA_SITES) {
        trigger._destroy = true;
      }
    });

    siteIds.forEach((siteId) => {
      const triggerIndex = emailTriggers.findIndex((t) => t.type === triggersType.MEMBER_JOINING_VIA_SITES && t.trigger_id === siteId);
      if (triggerIndex > -1) {
        tempState.email_series_triggers[triggerIndex]._destroy = false;
      } else {
        tempState.email_series_triggers.push({
          type: triggersType.MEMBER_JOINING_VIA_SITES,
          trigger_type: 'Site',
          trigger_id: siteId,
        });
      }
    });

    setParams(tempState);
  };

  return (
    <>
      <HeaderComponent setPath={{
        addNewPath: '',
        addNewBtnName: '',
        staticBreadcrumbData,
        showBreadcrumb: false,
        backBtnName: 'Dashboard',
        headingName: currentScreenName,
        backToPath: replacePathParams(deliveryCenterRoutes.deliveryCenter, [], props),
      }}
      />
      <Row>
        <Col lg={12} className="mb-3">
          <Card className="rounded h-100">
            <CardBody>
              <CardTitle className="fw-bold mb-3">Settings</CardTitle>
              <Row>
                <Col lg={4}>
                  <Label className="w-100">
                    Series Name:*
                    <Input
                      type="text"
                      value={name || ''}
                      onChange={(e) => setParams({
                        ...params,
                        name: e.target.value,
                      })}
                    />
                  </Label>
                </Col>
                <Col lg={4}>
                  <Label className="w-100">
                    From Name:
                    <Input
                      type="text"
                      value={fromName || ''}
                      onChange={(e) => setParams({
                        ...params,
                        from_name: e.target.value,
                      })}
                    />
                  </Label>
                </Col>
                <Col lg={4}>
                  <Label className="w-100">
                    From Email Address:*
                    {emailDomain
                      ? (
                          <EmailInputWithDomain
                            value={fromEmail}
                            onChange={(value) => setParams({ ...params, from_email: value })}
                            domain={emailDomain?.domain_name}
                          />
                        )
                      : (
                          <Input
                            type="email"
                            value={fromEmail || ''}
                            onChange={(e) => setParams({
                              ...params,
                              from_email: e.target.value,
                            })}
                          />
                        )}
                  </Label>
                </Col>
                <Col lg={6}>
                  <Label className="w-100">
                    Restart: (Restart series once completed - leave blank to disable restarting)
                    <InputGroup>
                      <InputGroupText>After</InputGroupText>
                      <Input
                        min={0}
                        type="number"
                        value={restartDays === -1 ? '' : restartDays}
                        onChange={(e) => setParams({
                          ...params,
                          restart_days: e.target.value === '' ? -1 : parseInt(e.target.value, 10),
                        })}
                      />
                      <InputGroupText>days</InputGroupText>
                    </InputGroup>
                  </Label>
                </Col>
                <Col lg={6}>
                  <Label className="w-100">Send:</Label>
                  <ul className="custom-ul-layout">
                    {' '}
                    <RadioButton
                      name="Send"
                      display="block"
                      title="Once per member"
                      checked={runOnce}
                      onChange={() => setParams({
                        ...params,
                        run_once: true,
                      })}
                    />
                    <RadioButton
                      name="Send"
                      display="block"
                      title="Every time a trigger is activated"
                      checked={!runOnce}
                      onChange={() => setParams({
                        ...params,
                        run_once: false,
                      })}
                    />
                  </ul>
                </Col>
                <Col>
                  <Label className="w-100">
                    CC emails to addresses:
                    <CarbonCopyEmail
                      placeholder="To"
                      id="email_addresses"
                      name="email_addresses"
                      selectedEmails={extraEmails}
                      onChange={(e) => onHandleEmailChange(e)}
                    />
                  </Label>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12} className="mb-3">
          <Card className="rounded">
            <CardBody>
              <CardTitle className="fw-bold mb-3">Triggers</CardTitle>
              <Row>
                <Col lg={12}>
                  <Label>
                    Member joining via the following site(s):
                    <CustomSelect
                      isMulti
                      options={siteOptions(sites)}
                      onChange={(e) => onHandleSiteChange(e)}
                      value={
                        siteOptions(sites).filter((obj) => selectedSites.includes(obj.value))
                      }
                      styles={selectColorStyles}
                    />
                  </Label>
                </Col>
                <Col lg={12}>
                  <CheckBox
                    checked={emailTriggers.some((item) => item.type === triggersType.MEMBER_JOINING_A_DATABASE && !item._destroy)}
                    title="Member joining via Data Centre"
                    onChange={(e) => onDataCenterChange(e)}
                  />
                </Col>
                {!isEmpty(emailTriggers) && (
                  map(
                    emailTriggers,
                    (item, rowIndex) => item.type !== triggersType.MEMBER_JOINING_A_DATABASE && item.type !== triggersType.MEMBER_JOINING_VIA_SITES && (
                      <Col lg={12} key={rowIndex}>
                        <CheckBox
                          checked={!item._destroy}
                          title={getTriggerTitle(item)}
                          onChange={(e) => onHandleTriggerChange(e, item, rowIndex)}
                        />
                      </Col>
                    ),
                  )
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12} className="mb-3">
          <Card className="rounded">
            <CardBody>
              <Row className="text-center">
                <Col lg={12}>
                  <CardTitle className="fw-bold mb-3">Emails</CardTitle>
                </Col>
                <Col>
                  <CustomButton
                    color="success"
                    className="common-success-button button-md fw-bold"
                    title="ADD"
                    onClick={addEmailTemplate}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4 mb-4">
                <Col><b>Email Name*</b></Col>
                <Col><b>Delay After Series Start*</b></Col>
                <Col><b>Subject*</b></Col>
                <Col><b>Actions</b></Col>
              </Row>
              {params && renderEmailPages(params.email_series_emails)}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <>
            <CustomButton
              color="info"
              className="common-success-button button-md fw-bold me-2"
              title="CANCEL"
              icon="fas fa-times"
              onClick={() => onHandleCancel()}
              style={{ background: '#dc3545' }}
            />
            {' '}
            <SaveAndContinue
              onSave={() => onHandleSave()}
              onContinue={replacePathParams(deliveryCenterRoutes.emailSeries, [], props)}
            />
          </>
        )}
      />
    </>
  );
}
export default withRouter(EmailSeries);
