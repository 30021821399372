import React, { useState } from 'react';
import { Form, Input, InputGroup, InputGroupText } from 'reactstrap';

interface ChatInputProps {
  onSubmitMessage: (message: string) => void;
}

function ChatInput({ onSubmitMessage }: ChatInputProps): JSX.Element {
  const [message, setMessage] = useState<string>('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmitMessage(message);
    setMessage('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Input
          type="text"
          placeholder="Enter message..."
          value={message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
          autoFocus
        />
        <InputGroupText>
          <button>
            <i className="fal fa-lg fa-paper-plane" />
          </button>
        </InputGroupText>
      </InputGroup>
    </Form>
  );
}

export default ChatInput;
