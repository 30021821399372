import React from 'react';
import { useGetSiteBriefingQuery } from '../../api/dashboardApiSlice';
import withRouter from '../../helpers/withRouter';
import { usePageClassName } from 'common/hooks';
import PMMeetingForm from 'Dashboard/src/views/PMMeetingForm';

interface Props {
  params: {
    briefingId: string;
  };
}

// This view shows just the PM Meeting form, it's meant for users who have two monitors. The other monitor will
// presumably show the Discussion Deck.
function PMMeetingFormContainer(props: Props) {
  usePageClassName('PMMeeting');

  const { data: briefing } = useGetSiteBriefingQuery(props.params.briefingId);

  return (
    <PMMeetingForm
      briefing={briefing}
    />
  );
};

export default withRouter(PMMeetingFormContainer);
