import { isArray } from 'lodash-es';
import Base from './Base';

interface UserProps {
  id?: string | number;
  full_name?: string;
  email?: string;
  modules?: string[];
  role?: {
    name: string;
    features?: string[];
  };
  feature_overrides?: string[];
}

class User extends Base {
  id?: string;
  full_name?: string;
  email?: string;
  modules?: string[];
  role?: {
    name: string;
    features?: string[];
  };

  feature_overrides?: string[];

  constructor(props: UserProps) {
    super(props);
    Object.assign(this, props);
    this.id = String(props.id);
    this.email = String(props.email);
  }

  hasModule(module: string): boolean {
    return this.modules?.includes(module) ?? false;
  }

  // Check if the user has a feature enabled.
  // If the user is a System Administrator, they have access to all features, with a few exceptions.
  // @param feature - The feature to check for.
  // @param adminBypass - If true, System Administrators will have access to all features. For internal use (ie inside this function) only.
  // @return boolean
  hasFeature(feature: string | string[], adminBypass = true): boolean {
    // System Administrators have access to all features, with a few exceptions.

    const specialCasesFeatures = [
      // Custom sections give the ability to run arbitrary code on the servers, so we want to be extra careful with it.
      'custom_sections',
      'dashboard_chat',
    ];

    if (this.role?.name === 'System Administrator' && adminBypass) {
      if (isArray(feature)) {
        return (feature as string[]).some((f) => this.hasFeature(f));
      } else if (specialCasesFeatures.includes(feature)) {
        return this.hasFeature(feature, false);
      } else {
        return true;
      }
    }

    if (isArray(feature)) {
      // feature can be an array, if it is then we render the component if the user has ANY of the requested features enabled.
      return (feature as string[]).some((f) => this.hasFeature(f));
    }

    if (typeof feature === 'string') {
      if (this.feature_overrides?.includes(feature)) {
        return true;
      }

      return this.role?.features?.includes(feature) ?? false;
    }

    return false;
  }
}

export default User;
