import React from 'react';
import {
  Input,
  Label,
  FormGroup,
} from 'reactstrap';

function EcwidStoreID(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label for="ecwid_store_id">Ecwid Store ID</Label>
      <Input
        type="text"
        value={params.ecwid_store_id || ''}
        onChange={(e) => {
          const { value } = e.target;
          setParams({ ...params, ecwid_store_id: value });
        }}
      />
    </FormGroup>
  );
}

export default EcwidStoreID;
