import React from 'react';
import { get } from 'lodash-es';
import { Input, Label, FormGroup } from 'reactstrap';

function BlogName({ params, setParams }) {
  return (
    <FormGroup>
      <Label for="blog_name">Blog Name</Label>
      <Input
        type="text"
        name="blog_name"
        id="blog_name"
        value={get(params, 'blog_name', '')}
        onChange={(e) => setParams({ ...params, blog_name: e.target.value })}
      />
    </FormGroup>
  );
}

export default BlogName;
