import React from 'react';
import { cloneDeep, map } from 'lodash-es';
import { Label, FormGroup } from 'reactstrap';
import CheckBox from 'Dashboard/src/components/common/checkBox';
import { socialMedia } from 'common/models/Site';

function BlogSocialMedia({ params, setParams }) {
  return (
    <FormGroup>
      <Label for="blog_social_media">Blog Social Media Share Sites</Label>
      {map(socialMedia, (social) => (
        <CheckBox
          display="d-block"
          icon={`fa-brands fa-${social.icon}`}
          title={social.label}
          value={true}
          onChange={(e) => {
            const current = cloneDeep(params.blog_social_media);
            if (e.target.checked) {
              current.push(social.value);
            } else {
              current.splice(current.indexOf(social.value), 1);
            }

            setParams({ ...params, blog_social_media: current });
          }}
          checked={params.blog_social_media?.includes(social.value)}
        />
      ))}
    </FormGroup>
  );
}

export default BlogSocialMedia;
