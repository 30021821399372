import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
} from 'reactstrap';
import { isEmpty, get } from 'lodash-es';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import CustomButton from '../../components/common/button';
import confirm from '../../components/common/confirm';
import CustomTooltip from 'Dashboard/src/components/common/CustomTooltip';
import { replacePathParams, settingRoutes } from '../../constants/routes';
import withRouter from '../../helpers/withRouter';
import getCTAFormFields from './getCTAFormFields';
import CtaReport from './index';
import { useInstance, useIsMobile, useUser } from '../../../../common/hooks';
import { useDeleteCtaLogMutation, useGetCtaLogQuery, useMarkCtaLogNotSpamMutation } from '../../api/dashboardApiSlice';

const assignReactKeyToChildren = (d) => React.Children.toArray(d);

function CtaReportFormSubmission(props) {
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');
  const [dropdownOpen, setDropdown] = useState([]);
  const [currentCTA, setCurrentCTA] = useState(null);
  const [showModal, setModal] = useState(false);
  const { hash } = useLocation();
  const navigate = useNavigate();
  const instance = useInstance();
  const instanceId = instance?.id || null;

  const user = useUser();

  const { data: ctaLog = {} } = useGetCtaLogQuery(
    { instance_id: instanceId, cta_log_id: hash.replace(/[\\#]/g, '') },
    { skip: isEmpty(instanceId) || isEmpty(hash) },
  );

  const [deleteCtaLog] = useDeleteCtaLogMutation();
  const [markCtaLogNotSpam] = useMarkCtaLogNotSpamMutation();

  useEffect(() => {
    if (!isEmpty(ctaLog)) {
      setModal(true);
      setCurrentCTA(ctaLog);
    }
  }, [ctaLog]);

  const toggle = (index) => {
    const tempState = [...dropdownOpen];
    tempState[index] = !tempState[index];
    setDropdown(tempState);
  };

  const onHandleDateField = (cta) => {
    setCurrentCTA(cta);
    setModal(true);
  };

  const closeModal = (e) => {
    if (e) {
      navigate(replacePathParams(settingRoutes.dashboard, [], props));
    }
    setCurrentCTA(null);
    setModal(false);
  };

  const showModalBox = () => {
    const isLog = !isEmpty(ctaLog) && true;
    return (
      <Modal isOpen={showModal} toggle={() => closeModal(isLog)}>
        <ModalHeader toggle={() => closeModal(isLog)}>
          Form Submission
        </ModalHeader>
        <ModalBody>
          <Table>
            <tbody>
              {React.Children.toArray(
                currentCTA.data.map((el) => getCTAFormFields(el, true)),
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="pt-3 pe-3 pb-3 ps-3">
          <Button size="sm" color="danger" onClick={() => closeModal(isLog)}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  };

  const onAttachmentClick = (fileId, fileName) => {
    const url = `/api/v1/instances/${instance.id}/cta_logs/download_form_attachment?file_id=${fileId}&filename=${encodeURIComponent(fileName)}`;
    window.location.href = url;
  };

  const getLogAttachments = (data, index) => {
    if (!isEmpty(data)) {
      const { attachments } = data;
      if (!isEmpty(attachments)) {
        return (
          <ButtonDropdown isOpen={dropdownOpen[index]} toggle={() => toggle(index)}>
            <DropdownToggle className="custom-simple-txt-btn">
              <i className="fa fa-paperclip fw-normal font-size-15px" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu end>
              {
                attachments.map((item) => (
                  <DropdownItem
                    className="custom-cursor-pointer-draggable"
                    key={item}
                    onClick={() => onAttachmentClick(item.id, item.filename)}
                  >
                    {item.filename}
                  </DropdownItem>

                ))
              }
            </DropdownMenu>
          </ButtonDropdown>
        );
      }
    }
    return null;
  };

  const onClickNotSpam = async (id) => {
    const sendParams = {
      id,
      log: id,
      instance_id: instanceId,
    };
    await markCtaLogNotSpam(sendParams);
  };

  const onHandleDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const params = {
        id,
        instance_id: instanceId,
      };
      await deleteCtaLog(params);
    }
  };

  const isMobile = useIsMobile();

  const tableColumns = [
    {
      id: 'Date',
      header: () => (
        <Button
          onClick={() => {
            setOrderBy('created_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date '}
            {orderBy === 'created_at' && (
              <i
                className={
                  orderDirection === 'desc'
                    ? 'fa fa-chevron-circle-up'
                    : 'fa fa-chevron-circle-down'
                }
              />
            )}
          </span>
        </Button>
      ),
      cell: ({ row }) => {
        const d = row.original;
        return (
          <Button
            onClick={() => onHandleDateField(d)}
            disabled={d.type === 'CTALogOutboundClick'}
            className="custom-simple-txt-btn cta-log-more-info"
          >
            <DateTimeFormat
              datetime={get(d, 'created_at', '')}
              localeString={DateTime.DATETIME_SHORT}
            />
          </Button>
        );
      },
    },
    {
      header: 'Origin',
      accessorKey: 'page.page_name',
      display: !isMobile,
    },
    {
      header: 'Site',
      accessorKey: 'site.hostname',
      display: !isMobile,
    },
    {
      id: 'Attachments',
      header: 'Attachments',
      cell: ({ row }) => getLogAttachments(row.original, row.index),
      display: !isMobile,
    },
    {
      id: 'Spam',
      header: 'Spam',
      cell: ({ row }) => {
        const d = row.original;
        return (
          user?.hasFeature('view_spam_ctas')
          && d.spam && (
            <CustomTooltip name="MarkCTAAsNotSpam">
              <Button
                id={`spam_${row.index}`}
                className="custom-simple-txt-btn"
                onClick={() => onClickNotSpam(d.id, row.index)}
              >
                <i
                  className={
                    d.spam
                      ? 'far fa-exclamation-triangle font-size-15px'
                      : 'far fa-check-square font-size-15px'
                  }
                />
              </Button>
            </CustomTooltip>
          )
        );
      },
    },
    {
      id: 'Actions',
      header: 'Actions',
      cell: ({ row }) => {
        const d = row.original;
        return (
          user?.hasFeature('delete_cta_logs') && (
            <CustomButton
              size="xs"
              title=" "
              color="danger"
              icon="fal fa-trash"
              className="custom-simple-icon"
              style={{ background: '#dc3545' }}
              onClick={() => onHandleDelete(d.id)}
            />
          )
        );
      },
    },
  ];

  return (
    <>
      <CtaReport
        instanceId={instanceId}
        dataTableColumns={tableColumns}
        orderBy={orderBy}
        orderDirection={orderDirection}
        ctaType="CTALogFormSubmission"
        typeName={['lead', 'leads']}
        subRow={(columns, row) => (
          !isEmpty(row.data)
          && (
            <tr>
              <td colSpan={columns.length + 1}>
                {assignReactKeyToChildren(
                  row.data.map((el, index) => (
                    <span
                      className="me-2 text-ellipsis"
                      key={el.id || `data-${index}`}
                    >
                      {getCTAFormFields(el)}
                    </span>
                  )),
                )}
              </td>
            </tr>
          )
        )}
      />
      {showModal && showModalBox()}
    </>
  );
}

export default withRouter(CtaReportFormSubmission);
